
/*-----------------------------------------------------------------------------------

	Shortcodes: alerts.scss

-----------------------------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Alerts
-----------------------------------------------------------------*/

.style-msg,
.style-msg2 {
	position: relative;
	display: block;
	color: $text-color;
	border-left: 4px solid rgba($black,0.1);
	margin-bottom: 20px;
}

.style-msg2 {
	border-left: 4px solid rgba($black,0.2);
	.dark & { border-left: 4px solid rgba($black,0.3); }
}

.successmsg {
	background: lighten(desaturate(adjust-hue($success, 10), 12.63), 44.12);
	color: darken(desaturate(adjust-hue($success, -3), 9.84), 21.18);
}

.errormsg {
	background: lighten(saturate(adjust-hue($danger, -354), 29.54), 36.47); //#FFCCCC;
	color: darken(saturate(adjust-hue($danger, -353), 13.66), 28.82); //#740D0A;
}

.infomsg {
	background: lighten(desaturate(adjust-hue($info, 13), 4.94), 43.53); //#B9DFF4;
	color: darken(desaturate(adjust-hue($info, 13), 5.47), 15.10); //#124F70;
}

.alertmsg {
	background: lighten(desaturate(adjust-hue($warning, -9), 7.89), 33.73);// #FCE0B6;
	color: darken(desaturate(adjust-hue($warning, -9), 7.64), 20.59); //#975E06;
}

.style-msg .sb-msg,
.style-msg2 .msgtitle,
.style-msg2 .sb-msg {
	display: block;
	padding: 15px;
	border-left: 1px solid rgba($white,0.5);
	font-size: 14px;
	text-shadow: 1px 1px 1px rgba($white,0.6);
	.dark & {border-left-color: rgba($white,0.1);}
}

.style-msg2 .msgtitle {
	background: rgba($black,0.15);
	border-top: none;
	border-bottom: none;
	font-size: 15px;
	font-weight: bold;
}

.style-msg-light .sb-msg,
.style-msg-light .msgtitle {
	color: $white;
	text-shadow: 1px 1px 1px rgba($black,0.3);
}

.style-msg2 {
	.sb-msg {
		font-weight: normal;
	}
	ol,
	ul {
		margin: 0 0 0 30px;
	}
	ol {
		list-style-type: decimal;
	}
	ul {
		list-style-type: disc;
	}
}

.alert,
.sb-msg,
.msgtitle {
	i {
		position: relative;
		top: 1px;
		font-size: 14px;
		width: 14px;
		text-align: center;
		margin-right: 5px;
	}
}

.style-msg .close {
	position: absolute;
	top: 10px;
	left: auto;
	right: 10px;
}

//bootstrap
.alert  {
	a:hover { text-decoration: underline !important; }
	.close { font-size: 1.4rem; }
}
/*-----------------------------------------------------------------------------------

	Shortcodes: feature-box.scss

-----------------------------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Featured Boxes
-----------------------------------------------------------------*/

.feature-box {
  position: relative;
  margin-top: 20px;
  padding: 0 0 0 round($featured-box-icon * 1.25);
  &:first-child {
    margin-top: 0;
  }
  .fbox-icon {
    display: block;
    position: absolute;
    width: $featured-box-icon;
    height: $featured-box-icon;
    top: 0;
    left: 0;
    a,
    i,
    img {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      color: #fff;
    }
    i,
    img {
      @include border-radius(50%);
      background-color: $theme-color;
    }
    i {
      font-style: normal;
      font-size: round($featured-box-icon / 2.3);
      text-align: center;
      line-height: $featured-box-icon;
    }
  }
  h3 {
    font-size: $featured-box-font-size;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0;
    color: $featured-box-font-color;
    .dark & {
      color: darken($text-color-dark, 13.33%);
    }
  }
  p {
    margin: ($featured-box-font-size/2) 0 0 0;
    color: lighten($featured-box-font-color, 40%);
    .dark & {
      color: darken($text-color-dark, 40%);
    }
  }
  .before-heading {
    font-size: 14px;
  }
}

/* Feature Box - Icon Large
-----------------------------------------------------------------*/

.feature-box {
  &.fbox-large {
    padding: 0 0 0 round($featured-box-lg-icon * 1.2);
    .fbox-icon {
      width: $featured-box-lg-icon;
      height: $featured-box-lg-icon; //96
      i {
        font-size: round($featured-box-lg-icon / 2.285);
        line-height: $featured-box-lg-icon;
      }
    }
  }
}

/* Feature Box - Icon Outline
-----------------------------------------------------------------*/

.feature-box {
  &.fbox-outline {
    .fbox-icon {
      border: $featured-box-outline-border solid $theme-color;
      @include border-radius(50%);
      padding: $featured-box-outline-padding;
      i {
        line-height: $featured-box-icon - ($featured-box-outline-padding * 2) -
          ($featured-box-outline-border * 2);
      }
    }
  }
}

/* Feature Box - Icon Outline Large
-----------------------------------------------------------------*/

.feature-box {
  &.fbox-outline {
    &.fbox-large {
      .fbox-icon {
        padding: ($featured-box-outline-padding + 1);
        i {
          line-height: $featured-box-lg-icon -
            (($featured-box-outline-padding + 1) * 2) -
            ($featured-box-outline-border * 2);
        }
      }
    }
  }
}

/* Feature Box - Icon Rounded
-----------------------------------------------------------------*/

.feature-box {
  &.fbox-rounded {
    .fbox-icon {
      @include border-radius(3px !important);
      i,
      img {
        @include border-radius(3px !important);
      }
    }
  }
}

/* Feature Box - Icon Rounded & Large
-----------------------------------------------------------------*/

.feature-box {
  &.fbox-rounded {
    &.fbox-large {
      .fbox-icon {
        @include border-radius(4px !important);
        i,
        img {
          @include border-radius(4px !important);
        }
      }
    }
  }
}

/* Feature Box - Light Background
-----------------------------------------------------------------*/

.feature-box {
  &.fbox-light {
    &.fbox-outline {
      .fbox-icon {
        border-color: darken($white, 10.2%);
        .dark & {
          border-color: rgba($white, 0.15);
        }
        i {
          line-height: $featured-box-icon - ($featured-box-outline-padding * 2) -
            ($featured-box-outline-border * 2);
        }
      }
      &.fbox-large {
        .fbox-icon {
          i {
            line-height: $featured-box-lg-icon -
              (($featured-box-outline-padding + 1) * 2) -
              ($featured-box-outline-border * 2);
          }
        }
      }
    }
    .fbox-icon {
      i,
      img {
        border: 1px solid darken($white, 10.2%);
        background-color: darken($white, 3.92%);
        color: $text-color;
        .dark & {
          border-color: rgba($white, 0.1);
          background-color: rgba($white, 0.1);
          color: $white;
        }
      }
      i {
        line-height: ($featured-box-icon - 2);
      }
    }
  }
  &.fbox-large {
    .fbox-icon {
      i {
        line-height: $featured-box-lg-icon;
      }
    }
  }
}

/* Feature Box - Dark Background
-----------------------------------------------------------------*/

.feature-box {
  &.fbox-dark {
    &.fbox-outline {
      .fbox-icon {
        border-color: $text-color;
        .dark & {
          border-color: rgba($white, 0.15);
        }
      }
    }
    .fbox-icon {
      i,
      img {
        background-color: #333;
        .dark & {
          border-color: rgba($white, 0.1);
          background-color: rgba($white, 0.1);
          color: $white;
        }
      }
    }
  }
}

/* Feature Box - Border
-----------------------------------------------------------------*/

.feature-box {
  &.fbox-border {
    .fbox-icon {
      border: 1px solid $theme-color;
      @include border-radius(50%);
      i,
      img {
        border: none;
        background-color: transparent !important;
        color: $theme-color;
      }
    }
    .fbox-icon {
      padding: 0;
      i {
        line-height: ($featured-box-icon - 2) !important;
      }
    }
    &.fbox-large .fbox-icon i {
      line-height: ($featured-box-lg-icon - 2) !important;
    }
  }
}

/* Feature Box - Border - Light
-----------------------------------------------------------------*/

.feature-box {
  &.fbox-border {
    &.fbox-light {
      .fbox-icon {
        border-color: darken($white, 10.2%);
        .dark & {
          border-color: rgba($white, 0.15);
        }
      }
      i,
      img {
        color: #888;
        .dark & {
          color: $white;
        }
      }
    }
  }
}

/* Feature Box - Border - Dark
-----------------------------------------------------------------*/

.feature-box {
  &.fbox-border {
    &.fbox-dark {
      .fbox-icon {
        border-color: #333;
        .dark & {
          border-color: rgba($white, 0.15);
        }
        i,
        img {
          color: $text-color;
          .dark & {
            color: $white;
          }
        }
      }
    }
  }
}

/* Feature Box - Plain
-----------------------------------------------------------------*/

.feature-box {
  &.fbox-plain {
    .fbox-icon {
      border: none !important;
      height: auto !important;
      i,
      img {
        border: none !important;
        background-color: transparent !important;
        color: $theme-color;
        @include border-radius(0);
      }
      i {
        font-size: $featured-box-plain-font-size;
        line-height: 1 !important;
      }
      img {
        height: $featured-box-icon;
      }
    }
    &.fbox-image {
      .fbox-icon {
        width: auto;
      }
      img {
        width: auto;
        display: inline-block;
      }
    }
    &.fbox-small {
      padding-left: round($featured-box-plain-font-size * 1.5);
      .fbox-icon {
        width: $featured-box-plain-font-size;
        i {
          font-size: $featured-box-plain-font-size;
        }
        img {
          height: $featured-box-plain-font-size;
        }
      }
      h3 {
        font-size: $featured-box-font-size;
        line-height: ($featured-box-plain-font-size - 2);
        margin-bottom: 10px;
      }
      p {
        margin-left: -round($featured-box-plain-font-size * 1.5);
      }
    }
    &.fbox-large {
      .fbox-icon {
        i {
          font-size: round($featured-box-plain-font-size * 1.5);
        }
        img {
          height: $featured-box-lg-icon;
        }
      }
    }
    &.fbox-light {
      .fbox-icon {
        i,
        img {
          color: #888;
          .dark & {
            color: $white;
          }
        }
      }
    }
    &.fbox-dark {
      .fbox-icon {
        i,
        img {
          color: $text-color;
          .dark & {
            color: $white;
          }
        }
      }
    }
  }
}

/* Feature Box - Center
-----------------------------------------------------------------*/

.feature-box {
  &.fbox-center {
    padding: 0;
    text-align: center;
    &.fbox-small {
      padding-left: 0 !important;
    }
    .fbox-icon {
      position: relative;
      width: $featured-box-center-icon;
      height: $featured-box-center-icon;
      margin: 0 auto 30px;
      i {
        font-size: round($featured-box-center-icon / 2.285);
        line-height: $featured-box-center-icon;
      }
    }
    &.fbox-outline {
      .fbox-icon {
        i {
          line-height: $featured-box-center-icon -
            ($featured-box-outline-padding * 2) -
            ($featured-box-outline-border * 2);
        }
      }
    }
    p {
      font-size: 14px;
      margin-top: 15px;
    }
    .dark & {
      border-top: rgba($white, 0.15);
    }
  }
}

/* Feature Box - Center & Large Icons
-----------------------------------------------------------------*/

.feature-box {
  &.fbox-center {
    &.fbox-large {
      .fbox-icon {
        width: $featured-box-center-lg-icon; //128px
        height: $featured-box-center-lg-icon;
        i {
          font-size: round($featured-box-center-lg-icon / 1.83);
          line-height: $featured-box-center-lg-icon;
        }
      }
      &.fbox-outline {
        .fbox-icon {
          i {
            line-height: $featured-box-center-lg-icon -
              ($featured-box-outline-padding * 2) -
              ($featured-box-outline-border * 2);
          }
        }
      }
    }
    &.fbox-light {
      .fbox-icon i {
        line-height: $featured-box-center-icon - 2;
      }
      .fbox-outline {
        .fbox-icon i {
          line-height: $featured-box-center-icon -
            ($featured-box-outline-padding * 2) -
            ($featured-box-outline-border * 2);
        }
        .fbox-large {
          .fbox-icon {
            i {
              line-height: $featured-box-center-lg-icon -
                ($featured-box-outline-padding * 2) -
                ($featured-box-outline-border * 2);
            }
          }
        }
      }
    }
    &.fbox-border {
      .fbox-icon {
        i {
          line-height: $featured-box-center-icon -
            ($featured-box-outline-padding * 2) !important;
        }
      }
    }
    &.fbox-large {
      &.fbox-border {
        .fbox-icon i {
          line-height: ($featured-box-center-lg-icon - 2) !important;
        }
      }
      .fbox-plain {
        .fbox-icon {
          i {
            font-size: 112px;
          }
          img {
            height: $featured-box-center-lg-icon;
          }
        }
      }
    }
    &.fbox-plain {
      .fbox-icon {
        i {
          font-size: round($featured-box-plain-font-size * 3);
        }
        img {
          height: $featured-box-center-icon;
        }
      }
    }
  }
}

/* Feature Box - Center & Large Icons
-----------------------------------------------------------------*/

.feature-box {
  &.fbox-center {
    &.fbox-small {
      .fbox-icon {
        width: $featured-box-icon;
        height: $featured-box-icon;
        i {
          font-size: 28px;
          line-height: $featured-box-icon;
        }
      }
      &.fbox-outline {
        .fbox-icon {
          i {
            line-height: $featured-box-center-lg-icon -
              ($featured-box-outline-padding * 2);
          }
        }
      }
    }
    .fbox-light {
      &.fbox-outline {
        &.fbox-small {
          .fbox-icon i {
            line-height: $featured-box-center-lg-icon -
              ($featured-box-outline-padding * 2) -
              ($featured-box-outline-border * 2);
          }
        }
      }
    }
    &.fbox-small {
      &.fbox-border {
        .fbox-icon {
          i {
            line-height: $featured-box-icon -
              ($featured-box-outline-padding * 2) -
              ($featured-box-outline-border * 2) !important;
          }
        }
      }
      .fbox-plain {
        .fbox-icon {
          i {
            font-size: round(
              $featured-box-center-icon - ($featured-box-outline-padding * 2)
            );
          }
          img {
            height: $featured-box-icon;
          }
        }
      }
      p {
        font-size: 14px;
        margin-top: 10px;
      }
    }
    .fbox-plain {
      &.fbox-small {
        p {
          margin-left: 0;
        }
      }
    }
  }
}

/* Feature Box - Right
-----------------------------------------------------------------*/

.feature-box {
  &.fbox-right {
    padding: 0 round($featured-box-icon * 1.25) 0 0;
    text-align: right;
    &.fbox-large {
      padding-right: round($featured-box-lg-icon * 1.2);
    }
    .fbox-icon {
      left: auto;
      right: 0;
    }
    &.fbox-plain {
      &.fbox-small {
        padding: 0 round($featured-box-plain-font-size * 1.5) 0 0;
        p {
          margin: 0 - round($featured-box-plain-font-size * 1.5) 0 0;
        }
      }
    }
  }
}

/* Feature Box - Subtitle
-----------------------------------------------------------------*/

.feature-box {
  h3 + .fbox-icon {
    margin-top: 20px !important;
  }

  h3 span.subtitle {
    display: block;
    margin-top: 5px;
    color: $text-color;
    font-weight: 300;
    text-transform: none;
    .dark & {
      color: darken($text-color-dark, 26.67%);
    }
  }

  &.fbox-center:not(.fbox-bg) h3:after {
    content: '';
    display: block;
    margin: 20px auto;
    width: 30px;
    border-top: 2px solid #555;
    @include transition(width 0.3s ease);
    .dark & {
      border-top: rgba($white, 0.15);
    }
  }

  &.fbox-center:not(.fbox-bg):hover h3:after {
    width: 50px;
  }

  &.fbox-center.noborder:not(.fbox-bg) h3:after,
  &.fbox-center.nobottomborder:not(.fbox-bg) h3:after {
    display: none;
  }

  &.fbox-center.fbox-italic p {
    font-style: italic;
    font-family: $body-font;
  }
}

/* Feature Box - Background
-----------------------------------------------------------------*/

.feature-box {
  &.fbox-bg {
    &.fbox-center {
      margin-top: 68px;
      padding: 68px 30px 30px;
      background-color: $body-bg;
      border: 1px solid darken($white, 10.2%);
      .dark & {
        background-color: $body-bg-dark;
        border-color: rgba($white, 0.1);
      }
      @include border-radius(5px);
      &:first-child {
        margin-top: 48px;
      }
      &.fbox-large {
        margin-top: 84px;
        padding-top: 84px;
        &:first-child {
          margin-top: 64px;
        }
        .fbox-icon {
          top: -64px;
          left: 50%;
          margin-left: -64px;
        }
      }
      .fbox-icon {
        position: absolute;
        top: -($featured-box-center-icon / 2);
        left: 50%;
        margin: 0 0 0 (-($featured-box-center-icon / 2));
      }
      &.fbox-plain,
      &.fbox-border,
      &.fbox-outline {
        .fbox-icon {
          background-color: $body-bg;
          .dark & {
            background-color: $body-bg-dark !important;
          }
        }
      }

      @at-root .dark #{&}.fbox-light .fbox-icon i {
        background-color: lighten($body-bg-dark, 4.71) !important;
      }
    }
  }
}

/* Animated Icon Hovers
-----------------------------------------------------------------*/

.fbox-effect .fbox-icon i {
  z-index: 1;
  @include transition(background-color 0.3s, color 0.3s);
}

.fbox-effect .fbox-icon i:after {
  top: -$featured-box-outline-padding;
  left: -$featured-box-outline-padding;
  padding: $featured-box-outline-padding;
  box-shadow: 0 0 0 2px darken($body-bg, 80);
  @include transition(-webkit-transform 0.3s, opacity 0.3s);
  @include scale(0.8);
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  @include border-radius(50%);
  content: '';
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  .dark & {
    box-shadow: 0 0 0 2px lighten($body-bg-dark, 6.67);
  }
}

.fbox-rounded.fbox-effect .fbox-icon i:after {
  @include border-radius(3px);
}

.fbox-effect .fbox-icon i:hover,
.fbox-effect:hover .fbox-icon i {
  background-color: #333;
  color: #fff;
  .dark & {
    background-color: lighten($body-bg-dark, 6.67);
  }
}

.fbox-effect.fbox-dark .fbox-icon i:after,
.dark .fbox-effect.fbox-dark .fbox-icon i:after {
  box-shadow: 0 0 0 2px $theme-color;
}

.fbox-effect.fbox-dark .fbox-icon i:hover,
.fbox-effect.fbox-dark:hover .fbox-icon i {
  background-color: $theme-color;
}

.fbox-effect .fbox-icon i:hover:after,
.fbox-effect:hover .fbox-icon i:after {
  @include scale(1);
  opacity: 1;
}

/* Icon Effects - Bordered
-----------------------------------------------------------------*/

.fbox-border.fbox-effect .fbox-icon i {
  @include transition(color 0.5s, box-shadow 0.5s, background-color 0.5s);
}

.fbox-border.fbox-effect .fbox-icon i:after {
  top: -2px;
  left: -2px;
  padding: 2px;
  z-index: -1;
  box-shadow: none;
  background-image: url('image/icons/iconalt.svg');
  background-position: center center;
  background-size: 100% 100%;
  @include transition(
    -webkit-transform 0.5s,
    opacity 0.5s,
    background-color 0.5s
  );
  .dark & {
    box-shadow: 0 0 0 1px lighten($body-bg-dark, 6.67);
  }
}

.fbox-border.fbox-rounded.fbox-effect .fbox-icon i:after {
  @include border-radius(3px);
}

.fbox-border.fbox-effect .fbox-icon i:hover,
.fbox-border.fbox-effect:hover .fbox-icon i {
  color: #fff;
  box-shadow: 0 0 0 1px #333;
}

.fbox-border.fbox-effect .fbox-icon i:after {
  @include scale(1.3);
  opacity: 0;
  background-color: #333;
}

.fbox-border.fbox-effect.fbox-dark .fbox-icon i:hover,
.fbox-border.fbox-effect.fbox-dark:hover .fbox-icon i,
.dark .fbox-border.fbox-effect.fbox-dark .fbox-icon i:hover,
.dark .fbox-border.fbox-effect.fbox-dark:hover .fbox-icon i {
  box-shadow: 0 0 0 1px $theme-color;
}

.fbox-border.fbox-effect.fbox-dark .fbox-icon i:after,
.dark .fbox-border.fbox-effect.fbox-dark .fbox-icon i:after {
  background-color: $theme-color;
}

.fbox-border.fbox-effect .fbox-icon i:hover:after,
.fbox-border.fbox-effect:hover .fbox-icon i:after {
  @include scale(1);
  opacity: 1;
}

/* Media Featured Box
-----------------------------------------------------------------*/

.feature-box.media-box {
  padding: 0;
}

.feature-box.media-box .fbox-media {
  margin: 0 0 25px;
}

.feature-box.media-box .fbox-media,
.feature-box.media-box .fbox-media a,
.feature-box.media-box .fbox-media img {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}

.feature-box.media-box .fbox-media iframe {
  display: block;
}

.feature-box.media-box p {
  margin-top: 17px;
}

.feature-box.media-box.fbox-bg .fbox-media {
  margin: 0;
}

.feature-box.media-box.fbox-bg .fbox-desc {
  padding: 25px;
  background-color: $body-bg;
  border: 1px solid darken($white, 10.2%);
  border-top: 0;
  @include border-radius(0 0 5px 5px);
  .dark & {
    background-color: $body-bg-dark;
    border-color: rgba($white, 0.1);
  }
}

.feature-box.media-box.fbox-bg .fbox-media img {
  @include border-radius(5px 5px 0 0);
}

/* ----------------------------------------------------------------
	Flipbox
-----------------------------------------------------------------*/

.flipbox {
  perspective: 1000px;
}

.flipbox-inner {
  transition: 0.5s;
  transition-timing-function: ease;
  position: relative;
  transform-style: preserve-3d;
}

.flipbox-front,
.flipbox-back {
  -webkit-backface-visibility: hidden;
}

.flipbox-front {
  z-index: 2;
}

.flipbox-back {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.flipbox:not(.flipbox-vertical) .flipbox-front {
  transform: rotateY(0deg);
}
.flipbox:not(.flipbox-vertical) .flipbox-back {
  transform: rotateY(-180deg);
}

.flipbox.flipbox-vertical .flipbox-front {
  transform: rotateX(0deg);
}
.flipbox.flipbox-vertical .flipbox-back {
  transform: rotateX(-180deg);
}

.flipbox:not(.flipbox-vertical):hover .flipbox-inner,
.flipbox:not(.flipbox-vertical).hover .flipbox-inner {
  transform: rotateY(180deg);
}

.flipbox.flipbox-vertical:hover .flipbox-inner,
.flipbox.flipbox-vertical.hover .flipbox-inner {
  transform: rotateX(180deg);
}

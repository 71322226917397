
/*-----------------------------------------------------------------------------------

	Shortcodes: team.scss

-----------------------------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Team
-----------------------------------------------------------------*/


.team-image {
	position: relative;
	> a,
	img {
		display: block;
		width: 100%;
	}
}

.team-desc {
	text-align: $team-decs-align;
	&.team-desc-bg {
		background-color: $team-decs-background;
		padding: $team-decs-padding;
		.dark & { background-color: rgba($white,0.05); }
	}
}
.team {
	position: relative;
	.si-share {
		margin-top: 30px;
		text-align: left;
		.social-icon {
			font-size: $team-title-designation-font-size;
			width: $team-title-designation-icon-size;
		}
	}
}
.team-title {
	position: relative;
	padding-top: 20px;
	h4 {
		display: block;
		margin-bottom: 0;
		font-size: $team-title-font-size;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 700;
		color: $team-title-color;
	}
	span {
		display: block;
		color: $team-title-designation-font-color;
		font-weight: $team-title-designation-font-weight;
		font-family: $team-title-designation-font-family;
		font-style: $team-title-designation-font-style;
		font-size: $team-title-designation-font-size;
		margin-top: 5px;
	}
}

.team-content {
	margin-top: 15px;
	font-size: $team-content-font-size;
	color: $team-content-font-color;
	.dark & { color: darken($text-color-dark, 33.33%); }
	p {
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.team-overlay,
.team-overlay:before {
	opacity: 0;
	position: absolute;
	z-index: 2;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	text-align: center;
}
.team-overlay {
	&:before {
		content: '';
		z-index: auto;
		background-color: $team-overlay-color;
		opacity: $team-overlay-opacity;
	}
	.social-icon {
		float: none;
		position: relative;
		z-index: 3;
		margin: 0 3px !important;
		display: inline-block;
	}
	body:not(.device-touch) & {
		@include transition(opacity .3s ease);
	}
}
.team {
	&:hover {
		.team-overlay {
			opacity: 1;
		}
	}
}

/* Team Center
-----------------------------------------------------------------*/

.team.center {
	.team-title {
		&:after {
			left: 50%;
			margin-left: -40px;
		}
	}
}

/* Team List
-----------------------------------------------------------------*/

.team {
	&.team-list {
		.team-image {
			float: left;
			width: $team-list-image-width;
			@include media-breakpoint-only(lg) {
				width: round($team-list-image-width / 1.25);
			}
			@include media-breakpoint-down(sm) {
				float: none;
				width: 100%;
				margin-right: 0;
			}
			margin-right: 30px;
		}
		.team-desc {
			position: relative;
			overflow: hidden;
			text-align: left;
		}
		.team-title {
			padding-top: 0;
			@include media-breakpoint-only(sm) {
				margin-top: 15px;
			}
		}
	}
	.team-desc {
		& > .social-icon {
			margin-top: 20px;
		}
	}
	.team-content {
		& ~ .social-icon {
			margin-top: 30px;
		}
	}
	&:not(.team-list) {
		.social-icon {
			display: inline-block;
			float: none;
			margin-left: 6px;
			margin-right: 6px;
		}
	}
}
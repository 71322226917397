
/*-----------------------------------------------------------------------------------

	Shortcodes: faqs.scss

-----------------------------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Toggle - FAQs
-----------------------------------------------------------------*/

.faqs {
	.toggle {
		border-bottom: $toggle-border-size solid lighten($toggle-border-color, 13.33%);
		padding-bottom: $toggle-font-size * .75;
		margin-bottom: $toggle-font-size * .75;
		.dark & { border-bottom-color: rgba($white,0.1); }
		.togglet,
		.toggleta {
			font-size: $toggle-font-size;
		}
		.togglec {
			padding-bottom: 10px;
		}
	}
	i {
		top: 1px;
		font-size: $toggle-font-size * .875;
	}
}


/* FAQ List
-----------------------------------------------------------------*/

.faqlist {
	li {
		margin-bottom: 5px;
		font-size: $toggle-font-size;
		a {
			color: $tab-base-color;
			.dark & { color: darken($text-color-dark, 13.33%); }
			&:hover {
				color: $theme-color !important;
			}
		}
	}
}
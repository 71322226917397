/*-----------------------------------------------------------------------------------
	shortcodes
-----------------------------------------------------------------------------------*/


@import "shortcodes/countdown";
@import "shortcodes/buttons";
@import "shortcodes/promo";
@import "shortcodes/feature-box";
@import "shortcodes/process-steps";
@import "shortcodes/alerts";
@import "shortcodes/styled-icons";
@import "shortcodes/social-icons";
@import "shortcodes/toggles-accordions";
@import "shortcodes/tabs";
@import "shortcodes/faqs";
@import "shortcodes/clients";
@import "shortcodes/testimonials";
@import "shortcodes/team";
@import "shortcodes/pricing";
@import "shortcodes/counter-skills";
@import "shortcodes/carousel";
@import "shortcodes/flipcard";
@import "shortcodes/overlay";
@import "shortcodes/headings";
@import "shortcodes/dividers";
@import "shortcodes/youtubebg";
@import "shortcodes/misc";


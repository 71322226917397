/* ----------------------------------------------------------------
	Flip Cards
-----------------------------------------------------------------*/


.flip-card-front,
.flip-card-back {
	background-size: cover;
	background-position: center;
	@include transition(transform $flipcard-transition);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	width: 100%;
	height: $flipcard-height;
	@include border-radius($flipcard-border-radius);
	color: $flipcard-color;
	font-size: $flipcard-font-size;

	&.no-after:after,
	&.no-after:after { content: none }

	&:after {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		content: "";
		display: block;
		background-color: $flipcard-bg-overlay;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		@include border-radius($flipcard-border-radius);
	}
}

.flip-card {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-perspective: 1000px;
	perspective: 1000px;
	&:hover {
		.flip-card-front,
		.flip-card-back {
			@include transition(transform $flipcard-transition);
		}
	}
}

.flip-card-back {
	background-color: #666;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.flip-card-inner {
	-webkit-transform: $flipcard-inner-tranform;
	transform: $flipcard-inner-tranform;
	top: 50%;
	position: absolute;
	left: 0;
	width: 100%;
	padding: $flipcard-inner-padding;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	outline: 1px solid transparent;
	-webkit-perspective: inherit;
	perspective: inherit;
	z-index: 2;
	span {
		font-size: 18px;
		line-height: 20px;
		font-weight: 300;
	}
	p {
		position: relative;
		font-size: 16px;
		margin-bottom: 0;
		color: rgba(255, 255, 255, 0.7);
	}
}

.flip-card-front,
.flip-card:hover .flip-card-back {
	-webkit-transform: rotateY(0deg);
	transform: rotateY(0deg);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.flip-card:hover .flip-card-front {
	-webkit-transform: rotateY(-180deg);
	transform: rotateY(-180deg);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.top-to-bottom {
	.flip-card-back {
		-webkit-transform: rotateX(180deg);
		transform: rotateX(180deg);
		-webkit-transform-style: preserve-3d;
		transform-style: preserve-3d;
	}
	&:hover .flip-card-front {
		-webkit-transform: rotateX(-180deg);
		transform: rotateX(-180deg);
		-webkit-transform-style: preserve-3d;
		transform-style: preserve-3d;
	}
}

.top-to-bottom .flip-card-front,
.top-to-bottom:hover .flip-card-back {
	-webkit-transform: rotateX(0deg);
	transform: rotateX(0deg);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

/* ----------------------------------------------------------------

	event.scss

-----------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Events
-----------------------------------------------------------------*/


.events .entry,
.ievent {
	padding: 20px;
	background-color: darken($body-bg, 2.35);
	border-bottom: 3px solid darken($body-bg, 6.67);
	.dark & {
		background-color: rgba($black,0.2);
		border-bottom-color: rgba($black,0.1);
	}
}

.events .entry:after { display: none !important; }

.events,
.ievent {
	.entry-image {
		.entry-date {
			position: absolute;
			top: 10px;
			left: 10px;
			background-color: rgba($black-color,0.7);
			color: #FFF;
			text-shadow: 1px 1px 1px rgba($black-color,0.2);
			width: 60px;
			height: 64px;
			text-align: center;
			padding-top: 9px;
			font-size: 26px;
			line-height: 1;
			span {
				display: block;
				font-size: 12px;
				margin-top: 7px;
			}
		}
	}
	.entry-c {
		padding: 5px 0;
	}
}

.single-event {
	.entry-image {
		img {
			@include border-radius(0);
		}
	}
	.events-meta {
		font-size: 14px;
	}
}

.single-event .events-meta,
.parallax {
	 .iconlist {
		li {
			margin: 5px 0;
		}
	}
}

.single-event .entry-overlay,
.parallax .entry-overlay-meta {
	position: absolute;
	top: auto;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 80px;
	background-color: rgba($black-color,0.85);
	text-align: center;
	line-height: 80px;
	font-size: 22px;
	color: #FFF;
	text-shadow: 1px 1px 1px rgba($black-color,0.3);
	z-index: 5;
}

.single-event .col_full .entry-overlay,
.single-event .col_three_fourth .entry-overlay { position: relative; }

.single-event {
	.countdown {
		display: inline-block;
		position: relative;
		top: 7px;
	}
	.countdown-section {
		padding: 0 15px;
		border-left-color: rgba(255,255,255,0.3);
		color: #CCC;
	}
	.countdown-amount {
		color: #FFF;
	}
}

.parallax {
	.entry-overlay,
	.entry-overlay-meta {
		background: transparent;
		width: auto;
		text-align: right;
		bottom: 30px;
		left: auto;
		right: 30px;
		@include media-breakpoint-down(xs) {
			left: 50% !important;
			right: auto !important;
			width: 280px !important;
			transform: translateX(-50%);
		}
	}
	@include media-breakpoint-down(xs) { .entry-overlay { width: 288px !important; } }
	.entry-overlay-meta {
		padding: 20px;
		font-size: 14px;
		text-align: left;
		right: 38px;
		bottom: 130px;
		width: 368px;
		height: auto;
		line-height: inherit;
		background-color: rgba($black-color,0.7);
		@include border-radius(3px);
	}
}

.parallax {
	&.overlay-left {
		.entry-overlay,
		.entry-overlay-meta {
			right: auto;
			left: 30px;
		}
		.entry-overlay-meta {
			left: 38px;
		}
	}
	&.overlay-center {
		.entry-overlay,
		.entry-overlay-meta {
			bottom: 100px;
			right: auto;
			left: 50%;
			margin-left: -192px;
		}
		.entry-overlay-meta {
			top: 100px;
			bottom: auto;
			margin-left: -184px;
		}
	}
	.entry-overlay-meta {
		h2 {
			font-size: 20px;
			text-transform: uppercase;
			border-bottom: 1px dashed rgba(255,255,255,0.2);
			padding-bottom: 17px;
			a {
				color: #FFF;
				&:hover {
					color: #DDD;
				}
			}
		}
	}
}

.parallax {
	.countdown {
		top: 0;
	}
	.countdown-section {
		border-left: 0;
		width: 80px;
		height: 80px;
		margin: 0 8px;
		font-size: 13px;
		background-color: rgba($black-color,0.7);
		@include border-radius(3px);
		padding-top: 14px;

		@include media-breakpoint-down(xs) {
			width: 64px;
			height: 64px;
			margin: 0 8px 0 0;
			font-size: 11px;
			padding: 0;
			padding-top: 12px;
		}
	}
	.countdown-amount {
		font-size: 28px;
		margin-bottom: 6px;
		@include media-breakpoint-down(xs) { font-size: 20px; }
	}
}

/* Individual Events
-----------------------------------------------------------------*/

.ievent {
	position: relative;
	margin-top: 20px;
	&:first-child {
		margin-top: 0;
	}
	.entry-image {
		width: 30%;
		margin-right: 20px;
	}
	.entry-content {
		margin-top: 20px;
	}
	.entry-title {
		h2 {
			font-size: 18px;
		}
	}
	.entry-meta {
		margin-right: -10px;
		li {
			font-size: 13px;
			margin-right: 10px;
			padding-left: 10px;
			&:first-child {
				padding-left: 0;
			}
		}
	}
}

/* ----------------------------------------------------------------

	pagetitle.scss

-----------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Page Title
-----------------------------------------------------------------*/

#page-title {
  position: relative;
  padding: $page-title-padding 0;
  background-color: $page-title-bg;
  border-bottom: $page-title-border;
  .dark & {
    background-color: darken($body-bg-dark, 6.27);
    border-bottom-color: lighten($header-bg-dark, 2.75);
  }
  @include media-breakpoint-down(sm) {
    padding: round($page-title-padding / 2.5) 0 round($page-title-padding / 2);
    text-align: center;
  }

  .container {
    position: relative;
  }

  h1 {
    padding: 0;
    margin: 0;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 1px;
    color: $page-title-color;
    font-size: $page-title-size;
    text-transform: uppercase;
    .dark & {
      color: rgba($white, 0.9) !important;
    }
    @include media-breakpoint-down(sm) {
      line-height: 1.5;
      font-size: round($page-title-size / 1.17);
    }
  }

  span {
    display: block;
    margin-top: 10px;
    font-weight: 300;
    color: lighten($page-title-color, 26.67%);
    .dark & {
      color: rgba($white, 0.7);
    }
    font-size: $page-title-subtitle-size;
  }
}

.breadcrumb {
  position: absolute !important;
  width: auto !important;
  top: 50% !important;
  left: auto !important;
  right: 15px !important;
  margin: 0 !important;
  transform: translateY(-50%);
  background-color: transparent !important;
  padding: 0 !important;
  font-size: $page-title-breadcumb;
  @include media-breakpoint-down(sm) {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin: 20px 0 0 !important;
    justify-content: center;
    transform: none;
  }
}

body.stretched .container-fullwidth .breadcrumb {
  right: $section-padding !important;
}

.breadcrumb {
  a {
    color: lighten($page-title-color, 13.33%);
    .dark & {
      color: darken($text-color-dark, 20%);
    }
    &:hover {
      color: $theme-color;
      .dark & {
        color: $text-color-dark;
      }
    }
  }
  i {
    width: auto !important;
  }
}

/* Page Title - Dark
-----------------------------------------------------------------*/

#page-title {
  &.page-title-dark {
    background-color: $page-title-bg-dark;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15) !important;
    border-bottom: none;

    h1 {
      color: rgba($white, 0.9) !important;
    }

    span {
      color: rgba($white, 0.7);
    }

    .breadcrumb > .active {
      color: rgba($white, 0.6);
    }

    .breadcrumb a {
      color: rgba($white, 0.8);
    }

    .breadcrumb a:hover {
      color: rgba($white, 0.95);
    }
  }

  /* Page Title - Right Aligned
	-----------------------------------------------------------------*/

  &.page-title-right {
    text-align: right;

    .breadcrumb {
      left: 15px !important;
      right: auto !important;
    }
  }

  /* Page Title - Center Aligned
	-----------------------------------------------------------------*/

  &.page-title-center {
    text-align: center;

    span {
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
    }

    .breadcrumb {
      position: relative !important;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      margin: 20px 0 0 !important;
      justify-content: center;
    }
  }

  /* Page Title - Background Pattern
	-----------------------------------------------------------------*/

  &.page-title-pattern {
    background-image: url('image/pattern.png');
    background-repeat: repeat;
    background-attachment: fixed;
    .dark & {
      background-image: url('../image/pattern2.png');
    }
  }

  /* Page Title - Parallax Background
	-----------------------------------------------------------------*/

  &.page-title-parallax {
    padding: $page-title-parallax-padding 0;
    text-shadow: none;
    border-bottom: none;
    background-color: transparent;
    background-image: url('image/parallax/parallax-bg.jpg');
    background-attachment: fixed;
    background-position: 50% 0;
    background-repeat: no-repeat;

    h1 {
      font-size: $page-title-parallax-size;
      font-weight: 600;
      letter-spacing: 2px;
    }

    span {
      font-size: $page-title-parallax-subtitle-size;
    }

    .breadcrumb {
      font-size: round($page-title-breadcumb * 1.08);
    }
  }

  /* Page Title - Mini
	-----------------------------------------------------------------*/

  &.page-title-mini {
    padding: $page-title-mini-padding 0;

    h1 {
      font-weight: 600;
      font-size: $page-title-mini-size;
      color: $page-title-base-color;
    }

    span {
      display: none;
    }
  }

  /* Page Title - No Background
	-----------------------------------------------------------------*/

  &.page-title-nobg {
    background: transparent !important;
    border-bottom: $page-title-border;
    border-bottom-color: darken($body-bg, 10.2%);
  }

  /* Page Title - Video
	-----------------------------------------------------------------*/

  &.page-title-video {
    background: none;
    position: relative;
    overflow: hidden;

    .container {
      z-index: 3;
    }

    .video-wrap {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      video {
        width: 100%;
      }
    }
  }
}

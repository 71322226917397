//-------------------- Base Colors --------------------//

$theme-color: #1abc9c;
$body-color: #555;

$body-bg: white;
$body-bg-dark: #383838; // Dark -  .dark classes

$white: white; // #FFF
$black-color: black; // #000

$text-color: darken($body-bg, 73.33%); // #444
$text-color-dark: lighten($body-bg-dark, 71.37%); // #EEE

$heading-color: $text-color;

$section-bg: darken($body-bg, 2.35%); // #f9f9f9
$section-bg-dark: darken(
  $body-bg-dark,
  6.27%
); // #282828 // Dark -  .dark classes

//-------------------- Line Height Variables --------------------//

$line-height-base: 1.5;
$line-height-content: 1.8;

//-------------------- Margins --------------------//

$margin-big: 80px;
$margin-base: 50px;
$margin-small: 30px;

$content-padding: 80px;

// line
$line-size: 1px;
$line-color: darken($body-bg, 6.67%);
$line-dark: rgba(white, 0.1);

//-------------------- Grid Break Points --------------------//

$grid-gutter-width: 30px !default;
$grid-gutter-xs-width: 80px !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

// Container Break Points
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
) !default;

//-------------------- Font Families --------------------//

$body-font: 'Lato', sans-serif;
$heading-font: 'Raleway', sans-serif;
$secondary-font: 'Crete Round', serif;

//-------------------- Page Loader Spinners --------------------//

$page-loader-spinners: darken($body-bg, 13.33%);
$page-loader-spinners-dark: lighten($body-bg-dark, 27.06%);

//-------------------- Font Sizes --------------------//

$font-size-base: 1rem;
$font-size-h1: 36px;
$font-size-h2: 30px;
$font-size-h3: 24px;
$font-size-h4: 18px;
$font-size-h5: 14px;
$font-size-h6: 12px;
$font-size-body: 14px;

$letter-spacing: 5;
$font-weight: 700;

//-------------------- Bootstrap colors --------------------//

$primary: #007bff;
$secondary: #6c757d;
$dark: #343a40;
$light: #f8f9fa;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;

//-------------------- Layouts --------------------//

$postcontent: 74%;
$postcontent-margin: 4%;

$sidebar: round(100% - ($postcontent + $postcontent-margin));
$bothsidebar: round(100% - (($sidebar * 2) + ($postcontent-margin * 2)));

$section-margin: 60px;
$section-padding: $section-margin;
$col-padding: $section-padding;

// Top Bar

$topbar-bg: $body-bg;
$topbar-height: 45px;
$topbar-line-height: $topbar-height - 1;
$topbar-font-size: 12px;
$topbar-font-weight: 700;
$topbar-font-transform: uppercase;
$topbar-font-color: lighten($text-color, 13.33%);

$top-social-hidden-sm: true;

//-------------------- Header --------------------//

$header-height: 100px; //Default
$header-bg: $body-bg;
$header-bg-dark: $body-bg-dark;

// Sticky Header
$sticky-header-height: 60px; //Default

// Floating Header Offset
$header-floating-top-offset: 60px; //Default

// Side Header
$side-header-width: 260px;
$side-header-padding-gutters: 30px;
$side-header-bg: $header-bg;

// From sm
$one-page-arrow-hidden-down-sm: true;
$top-cart-hidden-xs: true;

// Side Panel
$side-panel-bg: darken($header-bg, 3.92%);
$side-panel-size: 300px;
$side-panel-padding-gutters: 50px 40px;
$side-header-overlay: rgba(black, 0.6);

// Preloader
$preloader-image-path: 'image';
$preloader-image: 'preloader';
$preloader-fallback-extension: 'gif';

//-------------------- Primary Menu --------------------//

$primary-menu-padding: round($header-height - 22) / 2;
$sticky-menu-padding: round($sticky-header-height - 22) / 2;

$primary-menu-font: $heading-font;
$primary-menu-font-weight: bold;
$primary-menu-font-size: 13px;
$primary-menu-font-tt: uppercase;
$primary-menu-font-color: $text-color; // Leave as a "$text-color" for auto bg contrast
$primary-menu-font-spacing: 1px;

// Primary Menu Sub Menu
$primary-menu-submenu-width: 220px;
$primary-menu-submenu-font: $body-font;
$primary-menu-submenu-font-size: 12px;
$primary-menu-submenu-font-weight: 700;

$primary-menu-active-color: $theme-color;

// Primary Mega menu
$mega-menu-title: $heading-font;

//-------------------- Page Title -------------------- //

$page-title-base-color: $text-color;
$page-title-padding: 50px;
$page-title-parallax-padding: round($page-title-padding * 2);
$page-title-mini-padding: 20px;

$page-title-bg: darken($body-bg, 3.92%);
$page-title-bg-dark: darken($page-title-base-color, 6.67%);
$page-title-color: $page-title-bg-dark;

$page-title-size: 28px;
$page-title-subtitle-size: 18px;

$page-title-parallax-size: 40px;
$page-title-parallax-subtitle-size: 22px;

$page-title-mini-size: 18px;

$page-title-border: 1px solid darken($page-title-bg, 2.75%);

$page-title-breadcumb: 12px;

//-------------------- Sliders --------------------//

$slider-height: 500px;
$slider-md-height: 400px !important;
$slider-sm-height: 300px !important;
$slider-xs-height: 180px !important;

// Flex Slider
$fslider-dots-position: top 14px right 10px; // Eg:top, right, bottom, left  ;
$fslider-dots-size: 10px;
$fslider-dots-border: 1px solid $body-bg;
$fslider-dots-border-radius: 50%;
$fslider-dots-transition: 0.3s ease-in-out;

$fslider-thumbs-grids: 12; // $fslider-thumbs-grids Untill 12 Eg: grid-1, grid-2
$fslider-thumbs-gutters: 2px;
$fslider-thumbs-width: 100px; // for .flex-thumb-grid

// Canvas Slider
$canvas-slider-dots-size: $fslider-dots-size;
$swiper-dots-position-bottom: 20px !important;

$slider-caption-width: 550px;
$slider-caption-position: top 0 left 15px;
$slider-caption-right-position: top 0 right 15px;
$slider-caption-font-size: 1.375rem; //h3  (h2*3)
$slider-caption-font-weight: 300;
$slider-caption-transition: top 0.3s ease;
$slider-caption-para-size: 24px;
$slider-caption-offset: 50px;
$slider-caption-bg-light: rgba($white, 0.8);
$slider-caption-bg-dark: rgba($black-color, 0.6);
$slider-caption-bg-radius: 2px;

// slider-caption-hidden-down-sm --  #slider:not(.swiper_wrapper) .slider-caption, .flex-caption, .slider-caption p, .slider-caption .button, .slider-arrow-top-sm, .slider-arrow-bottom-sm,
$slider-caption-hidden: false; // slider-caption-hidden-down-sm

// Slider Arrows

$slider-arrows-size: 52px;
$slider-arrows-position-top: 50%;
$slider-arrows-position-left: 0;
$slider-arrows-position-right: $slider-arrows-position-left;

$slider-arrows-bg-color: rgba($black-color, 0.3);
$slider-arrows-bg-hover-color: rgba($black-color, 0.6) !important;
$slider-arrows-border-radius: 3px;
$slider-arrow-sm-right: 20px;
$slider-arrow-sm-bottom: $slider-arrow-sm-right;

$slider-arrow-colors: $white;

// Owl Dots

$owl-dots-slider-color: $white;
$owl-dots-slider-position: top auto bottom 25px;

//-------------------- Button --------------------//

$button-color: $theme-color;
$button-light: darken($white, 6.67%);
$button-dark: $text-color;
$button-padding-width: 22px;
$button-height: 40px;
$button-font-size: 14px;
$button-icon-margin: 5px;
$button-text-shadow: rgba($black-color, 0.2);

// Button Mini
$button-mini-height: round($button-height - 12);
$button-mini-padding-width: round($button-padding-width - 8);
$button-mini-font-size: round($button-font-size - 3);

// Button Small
$button-small-height: round($button-height - 6);
$button-small-padding-width: round($button-padding-width - 5);
$button-small-font-size: round($button-font-size - 2);

// Button Large
$button-large-height: round($button-height + 6);
$button-large-padding-width: round($button-padding-width + 4);
$button-large-font-size: round($button-font-size + 2);

// Button xLarge
$button-xlarge-height: round($button-height + 12);
$button-xlarge-padding-width: round($button-padding-width + 10);
$button-xlarge-font-size: round($button-font-size + 4);

// Button Border
$button-border-width: 2px;
$button-border-thin-width: 1px;

// Button 3D / Radius
$button-3d-border: 3px;
$button-rounded: 3px;

$button-full-padding: 30px;
$button-full-font-size: round($button-font-size * 2.14);

// Button Reveal Icon
$button-reveal-icon: ($button-padding-width + 10);

$button-transition-speed: 0.2s ease-in-out;
$button-reveal-transition-speed: 0.3s ease;
$button-fill-transition-speed: 0.4s ease;

// Button Desc in Device-sm
$button-desc-sm: $button-mini-padding-width, $button-small-height,
  ($button-font-size + 1), ($button-font-size + 1), $button-rounded;

$buttons-color: true; // colors are inside of ( $colors ). eg: button-red;
$buttons-social-colors: false; // Find below of this page( $si-colors ). class name eg: button-facebook;

// Colors - Button
$colors: (
  red: #c02942,
  teal: #53777a,
  yellow: #ecd078,
  green: #59ba41,
  brown: #774f38,
  aqua: #40c0cb,
  lime: #aee239,
  purple: #5d4157,
  leaf: #a8caba,
  pink: #f89fa1,
  dirtygreen: #1693a5,
  blue: #1265a8,
  amber: #eb9c4d,
  black: lighten($black-color, 6.67%),
  white: darken($white, 2.35%),
  // Just add variables and color-codes and it will be create a button class of that variables name;
) !default;

//-------------------- Countdown -------------------- //

$countdown-size: 20px;
$countdown-section: 11px;

$countdown-md-size: round($countdown-size * 1.5);
$countdown-md-section: round($countdown-section * 1.3);

$countdown-lg-size: round($countdown-size * 2.2);
$countdown-lg-section: round($countdown-section * 1.6);

$countdown-border: 1px dotted darken($body-bg, 26.67%);

$countdown-inline-space: 7px;

$countdown-large: 140px;

//-------------------- Styled Icons -------------------- //

$styled-icons-bg-color: #444;
$styled-icons-color: $body-bg;

$styled-icons-size: 52px;
$styled-icons-border-width: 1px;
$styled-icons-border-color: lighten($text-color, 6.67%);

$styled-icons-plain-size: 36px;

$i-overlay: rgba($black-color, 0.2);

//-------------------- Social Icons -------------------- //

$social-margins: 5px;
$social-icon-size: 40px;
$social-icon-font-size: 20px;

$social-icon-lg-size: 56px;
$social-icon-lg-font-size: 30px;

$social-icon-sm-size: 32px;
$social-icon-sm-font-size: 14px;

$social-icon-color: lighten($text-color, 6.67%);
$social-icon-border: 1px;
$social-icon-border-color: #aaa;
$social-icon-rounded: 3px;

$social-icon-dark-color: $text-color;
$social-icon-light-color: 0.05;

//-------------------- Promo Box -------------------- //

$promo-padding-width: 30px;
$promo-padding-height: 200px;
$promo-font-size: $font-size-h3;
$promo-span-font-size: 16px;
$promo-span-bg-opacity: 0.9;
$promo-span-dark-bg-opacity: 0.15;
$promo-uppercase: true;
$promo-border: 1px solid darken($body-bg, 10.2%);
$promo-light: darken($body-bg, 4%);
$promo-light-text-color: $text-color;
$promo-dark: darken($text-color, 6.67%);
$promo-dark-text-color: $white;

//-------------------- Featured Box -------------------- //

$featured-box-icon: 64px;
$featured-box-font-size: 16px;
$featured-box-font-color: darken($text-color, 6.67%);

$featured-box-outline-border: 1px;
$featured-box-outline-padding: 3px;

$featured-box-plain-font-size: 28px;

$featured-box-lg-icon: 96px;

$featured-box-center-icon: 96px;
$featured-box-center-lg-icon: 128px;

//-------------------- Toggles - Accordions -------------------- //

$toggle-base-color: $text-color;
$toggle-font-size: 16px; // FAQs Toggles
$toggle-padding-left: 24px;

$toggle-bg-padding-left: round($toggle-padding-left * 1.5);
$toggle-border-size: 1px;
$toggle-border-color: darken($body-bg, 20%);
$toggle-border-radius: 4px; // Same Accordion Radius
$toggle-lg-padding-left: round(
  $toggle-padding-left * 1.25
); // Toggle Large Padding Left

$toggle-bg-color: darken($body-bg, 6.67%); // Same Accordion Color
$toggle-title-color: $toggle-base-color; // Same Accordion Title Color
$toggle-icon-color-open: $toggle-base-color; // Same Accordion Icon Color
$toggle-icon-color-closed: $toggle-base-color; // Same Accordion Icon Color

// Accordion
$accordion-font-size: 14px;
$accordion-title-color: $toggle-base-color;
$accordion-border-size: 1px;
$accordion-border-color: darken($body-bg, 13.33%);
$accordion-padding-height: 10px;
$accordion-padding-left: 20px;

$accordion-bg-padding-left: 36px;

$accordion-lg-font-size: 23px; // Accordion Large

$toggle-accordion-icons-ff: 'font-icons'; //font-icons.css
$toggle-accordion-icons-open: '\e713'; //font-icons.css
$toggle-accordion-icons-closed: '\e714'; //font-icons.css

//-------------------- Tabs -------------------- //

$tab-base-color: $text-color; //Text
$tab-height: 40px;
$tab-padding-width: round($tab-height * 0.375);
$tabs-font-size: 14px;
$tabs-border-width: 1px;
$tabs-border-bottom: $tabs-border-width;
$tabs-border-color: darken($body-bg, 13.33%);
$tabs-bg-color: darken($body-bg, 5.1%);
$tabs-active-bg-color: $body-bg;

$tabs-tb-border-width: $tabs-border-width;

$side-tab-width: 200px;
$side-tab-padding-left: 20px;

$sidenav-font-size: $tabs-font-size;
$sidenav-border: 1px;
$sidenav-radius: 4px;
$sidenav-icon-margin: 6px;
$sidenav-padding-height: 11px;
$sidenav-padding-width: round($sidenav-padding-height * 1.28);

$sidenav-border-color: darken($body-bg, 10.2%);
$sidenav-font-color: lighten($tab-base-color, 13.33%);

//-------------------- Clients -------------------- //

$clients-grid-padding: 20px;
$clients-grid-deafult-grid: 5;
$clients-grid-border-size: 1px;
$clients-grid-border-style: dotted;
$clients-grid-border-color: lighten($text-color, 60%);
$clients-img-opacity: 0.6;
$clients-grid-total-columns: 6; // grid added upto 6

$clients-grid-display-sm: 3; // grid added upto $clients-grid-total-columns

//-------------------- Testimonials - Twitter --------------------//

$testimonials-background: $body-bg;
$testimonials-base-color: $text-color;
$testimonials-padding: 25px;
$testimonials-full-padding: round($testimonials-padding * 1.2); //30px
$testimonials-font-size: $font-size-body;
$testimonials-full-font-size: round($font-size-body * 1.286);
$testimonials-border: 1px solid rgba(0, 0, 0, 0.1);
$testimonials-border-radius: 5px;
$testimonials-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
$testimonials-avatar-size: 64px;
$testimonials-full-avatar-size: round($testimonials-avatar-size * 1.125);
$testimonials-avatar-rounded: 50%;
$testimonials-avatar-icon: 28px;
$testimonials-icon-color: lighten($testimonials-base-color, 26.67%);
$testimonials-font: $secondary-font !important;
$testimonials-font-style: italic;
$testimonials-meta-size: 13px;
$testimonials-full-meta-size: 15px;
$testimonials-columns: 3;

$testimonials-quote-content: ' " ';

$testimonials-carousel-dots: 6px;
$testimonials-carousel-dots-color: $theme-color;
$testimonials-carousel-dots-opacity: 0.5;

// Responsive - under md, sm
$testimonials-md-grid: 2;
$testimonials-sm-grid: 1;

//-------------------- Team --------------------//

$team-base-color: $text-color;
$team-decs-align: center;
$team-decs-background: $body-bg;
$team-decs-padding: 10px 0 30px;
$team-title-font-size: 19px;
$team-title-color: $text-color;

$team-title-designation-font-size: 15px;
$team-title-designation-font-color: $theme-color;
$team-title-designation-font-family: $secondary-font;
$team-title-designation-font-weight: 400;
$team-title-designation-font-style: italic;

$team-title-designation-icon-size: $social-icon-sm-size;
$team-title-designation-icon-size: $social-icon-sm-font-size;

$team-overlay-color: $white;
$team-overlay-opacity: 0.75;

$team-list-image-width: 250px;

$team-content-font-size: $font-size-body;
$team-content-font-color: lighten($team-base-color, 20%);

//-------------------- Pricing --------------------//

$pricing-base-color: $text-color;
$pricing-background: darken($body-bg, 3.92%);

$pricing-border-size: 1px;
$pricing-border-color: rgba($black-color, 0.075);
$pricing-border-radius: 3px;
$pricing-box-shadow: 0 1px 1px rgba($black-color, 0.1);

$pricing-title-padding: 15px;
$pricing-title-bg: lighten($pricing-background, 1.57%);
$pricing-title-border: $pricing-border-size solid rgba($black-color, 0.05);
$pricing-title-font-size: 20px;
$pricing-title-font-weight: bold;
$pricing-title-font-spacing: 1px;
$pricing-title-font-transform: uppercase;
$pricing-title-font-color: lighten($pricing-base-color, 6.67%);

$pricing-price-padding: round($pricing-title-padding * 1.67);
$pricing-price-color: darken($pricing-base-color, 6.67%);
$pricing-price-font-size: 64px;
$pricing-price-bottom-border-width: 100px;
$pricing-price-bottom-border-size: $pricing-border-size solid
  rgba($black-color, 0.1);
$pricing-price-currency-icon: round($pricing-price-font-size * 0.435);
$pricing-price-month: round($pricing-price-currency-icon * 0.571);

$pricing-features-font-size: 14px;
$pricing-features-font-padding: 6px;

$pricing-popular-bg: $body-bg;
$pricing-popular-title-padding: round($pricing-title-padding * 1.33);
$pricing-popular-font-color: $theme-color;
$pricing-popular-title-font-size: round($pricing-title-font-size * 1.1);
$pricing-popular-pricing-font-size: round($pricing-price-font-size * 1.125);
$pricing-popular-box-shadow: 0 0 8px rgba($black-color, 0.1);

$pricing-minimal-bg: $body-bg;
$pricing-5-lg-col: 5; // Default
$pricing-5-md-col: $pricing-5-lg-col; //col
$pricing-5-sm-col: 6; //col
$pricing-5-xs-col: 12; //col

$pricing-extended-desc-width: 75%;
$pricing-extended-padding: $pricing-price-padding;
$pricing-extended-features-col: 6; //col

//-------------------- Counter Skills --------------------//

$counter-font-size: 42px;
$counter-font-wight: 700;
$counter-font-family: $heading-font;
$counter-caption-size: $font-size-h5;

$counter-sm-font-size: round($counter-font-size / 1.5);
$counter-sm-caption-size: round($counter-caption-size * 0.93);

$counter-lg-font-size: round($counter-font-size * 1.33);
$counter-lg-caption-size: round($counter-caption-size / 0.93); // same also xl

$counter-xl-font-size: round($counter-font-size * 1.52);
$counter-lined-size: 2px solid $text-color;
$counter-lined-width: 15%;

// Rounded Skills

$rounded-skills-font-size: 22px;
$rounded-skills-font-color: darken($text-color, 6.67);
$rounded-skills-icon-size: 42px;

// Progress Skills

$progress-skills-height: 4px;
$progress-skills-margin-gutters: 38px;
$progress-skills-Font-size: 12px;
$progress-skills-empty-color: darken($body-bg, 3.92);
$progress-skills-radius: 2px;
$progress-skills-progress-color: $theme-color;
$progress-skills-transition: 1.2s ease-in-out;

$progress-skills-percent-padding: 5px;
$progress-skills-percent-bgcolor: #333;
$progress-skills-percent-color: darken($body-bg, 2.39);
$progress-skills-percent-size: 11px;
$progress-skills-counter-font: $body-font;
$progress-skills-counter-font-color: darken($text-color, 13.33);

//-------------------- Owl Carousel --------------------//

$carousel-animation-transition: 1000ms; // For animate.css

$owl-loading-preload-img: $preloader-image;
$owl-video-play-icon-size: 64px;
$owl-video-play-icon: 'image/icons/play.png';
$owl-video-play-icon-hover: scale(1.3);

// auto-height Transitio
$owl-autoHeight-transition: 500ms ease-in-out;
// Owl Na
$owl-nav-hidden: true;
$owl-nav-size: 18px;
$owl-nav-border: 1px solid rgba($black-color, 0.2);
$owl-nav-background: #fff;
$owl-nav-color: #666;
$owl-nav-border-radius: 50%;

// Owl Full Na
$owl-full-nav-size: 28px;
$owl-full-nav-border-radius: 3px;
$owl-full-nav-bgcolor: rgba($black-color, 0.4);
$owl-full-nav-color: #eee;
$owl-full-nav-hover-bgcolor: $theme-color;
$owl-full-nav-hover-color: #fff;

$owl-nav-disabled: false; // Enable/Disable Inactive Prev or Next Nav

// Owl Dots
$owl-dots-size: 8px;
$owl-dots-background: $theme-color;
$owl-dots-opacity: 0.5;

//-------------------- Flip Card --------------------//

$flipcard-transition: 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
$flipcard-height: 280px;
$flipcard-border-radius: 6px;
$flipcard-color: #fff;
$flipcard-font-size: 1.5rem;
$flipcard-bg-overlay: rgba($black-color, 0.6);

$flipcard-inner-tranform: translateY(-50%) translateZ(60px) scale(0.94);
$flipcard-inner-padding: 2rem;

//-------------------- Overlay --------------------//

$overlay-bg: rgba($black-color, 0.6);
$overlay-icon: 40px;
$overlay-icon-color: rgba($white, 0.8);

$overlay-text-opacity: 0.85;
$overlay-text-padding: 40px 15px 15px;
$overlay-text-bg-start-color: rgba($black-color, 0);
$overlay-text-bg-end-color: rgba($black-color, 0.85);

$overlay-text-title-color: darken($white, 3.92%);
$overlay-text-meta-color: darken($white, 20%);
$overlay-text-font-size: 28px;

//-------------------- Title/Heading Bloks --------------------//

// Heading Title Block
$heading-block-font-size-h1: 32px;
$heading-block-font-size-h2: 30px;
$heading-block-font-size-h3: 26px;
$heading-block-font-size-h4: 20px;
$heading-block-font-weight: 700;
$heading-block-font-spacing: 1px;
$heading-block-font-color: darken($text-color, 6.67%);
$heading-block-font-transform: uppercase;
$heading-block-center-container: 700px;
$title-block-border-size: 40px;
$heading-block-border: 2px solid $text-color;

// Title Block
$title-block-padding: 20px;
$title-block-color: $text-color;
$title-block-border: 7px;
$title-block-border-sm: round($title-block-border/1.4); // 5px Under device-sm
$title-block-border-color: $theme-color;

$title-block-highlighted-color: $title-block-border-color;
$title-block-subtitle-color: lighten($title-block-color, 6.67%);

// Emphasis Title Block
$emphasis-title-font-size: 64px;

// Fancy Title Block
$fancy-title-bg: $body-bg;
$fancy-title-padding: round($title-block-padding / 1.33);
$fancy-title-border-size: 1px;
$fancy-title-border-color: darken($fancy-title-bg, 6.67);
$fancy-title-colored-border: rgba($title-block-border-color, 0.6);
$fancy-title-dotted-border: 'image/icons/dotted.png';

$before-heading-font: normal 400 16px $secondary-font;

//-------------------- Dividers --------------------//

$divider-colors: darken($body-bg, 10.2%);
$divider-margins: 35px;
$divider-border-size: 1px;
$divider-border-color: lighten($divider-colors, 3.53);

$divider-icon-size: 18px;
$divider-icon-gutter: $divider-icon-size + 12;
$divider-icon-color: $divider-colors;
$divider-icon-hover-color: darken($divider-colors, 36.47%);

$divider-short-width: 30%;

$divider-rounded-size: round($divider-icon-size * 2.22);
$divider-rounded-color: darken($divider-colors, 16.47%);
$divider-rounded-background: lighten($divider-colors, 6.27%);

//-------------------- SM-Form Control --------------------//

$sm-form-background: $body-bg;
$sm-form-border: 2px;
$sm-form-border-color: darken($body-bg, 13.33);
$sm-form-border-radius: 0;
$sm-form-color: darken($text-color, 6.67);
$sm-form-font-size: 15px;
$sm-form-padding: 8px 14px;
$sm-form-focus-color: darken($body-bg, 33.33);
$sm-form-placeholder: darken($body-bg, 40);

$sm-form-label-color: $sm-form-color;
$sm-form-label-size: 13px;
$sm-form-label-font: $heading-font;
$sm-form-label-transform: uppercase;
$sm-form-label-spacing: 1px;
$sm-form-label-weight: 700;
$sm-form-label-mb: 10px;

$sm-form-label-error: $danger;

$bs-form-control-radius: 3px;

$form-processor-bg: rgba($body-bg, 0.7);

// Goto Top
$gotoTop-size: 40px;
$gotoTop-bg: rgba($black-color, 0.3);
$gotoTop-icon-size: 20px;
$gotoTop-icon-color: $white;
$gotoTop-position: right 30px bottom 30px;
$gotoTop-position-boxed: right 30px bottom 50px;
$gotoTop-border-radius: 2px;
$gotoTop-hover-color: $theme-color;

$gotoTop-hidden-sm: true;

// Toastr
$toastr-position-gutter: 12px;

//-------------------- Portfolio --------------------//

$portfolio-filter-grid-sm: 3;
$portfolio-filter-grid-xs: 2;
$portfolio-filter-border: 1px;
$portfolio-filter-border-color: rgba($black-color, 0.07);

$portfolio-shuffle-hidden-sm: true;

//Columns Margin
$portfolio-2-margin: 20px;
$portfolio-3-margin: 15px;
$portfolio-4-margin: 12px;
$portfolio-5-margin: 10px;
$portfolio-6-margin: 6px;

//Columns Margin for Sidebar
$portfolio-2-margin-sb: 20px;
$portfolio-3-margin-sb: 15px;
$portfolio-4-margin-sb: 12px;
$portfolio-5-margin-sb: 0;

//Columns Margin for Both Sidebar
$portfolio-2-margin-bs: 20px;
$portfolio-3-margin-bs: 11px;
$portfolio-4-margin-bs: 0;

$lightbox-width: 800px;

//Responsive Down md
$portfolio-item-4-md-grid: 3;
$portfolio-item-4-md-margin: $portfolio-4-margin;

$portfolio-item-3-md-grid: 3;
$portfolio-item-3-md-margin: $portfolio-3-margin;

$portfolio-item-2-md-grid: 2;
$portfolio-item-2-md-margin: $portfolio-2-margin;

//Responsive Down sm
$portfolio-item-4-sm-grid: 2;
$portfolio-item-4-sm-margin: round($portfolio-4-margin / 2);

//-------------------- Shop --------------------//

//Columns Margin
$shop-3-margin: 30px;
$shop-4-margin: 20px;

//Columns Margin for Sidebar
$shop-2-margin-sb: 40px;
$shop-3-margin-sb: 25px;

//Columns Margin for Both Sidebar
$shop-2-margin-bs: 40px;

// Responsive Down md,sm
$shop-item-4-md-grid: 3;
$shop-item-4-md-margin: $shop-4-margin;

$shop-item-4-sm-grid: 2;
$shop-item-4-sm-margin: round($shop-4-margin / 1.67);

$shop-item-3-md-grid: 3;
$shop-item-3-md-margin: $shop-3-margin;

$shop-item-3-sm-grid: $shop-item-4-sm-grid;
$shop-item-3-sm-margin: $shop-item-4-sm-margin;

//-------------------- Blog --------------------//

//Columns Margin
$blog-2-margin: 40px;
$blog-3-margin: 30px;
$blog-4-margin: 28px;

//Columns Margin for Sidebar
$blog-2-margin-sb: 40px;
$blog-3-margin-sb: 25px;

//Columns Margin for Both Sidebar
$blog-2-margin-bs: 40px;

// Responsive Down md
$blog-item-4-md-grid: 3;
$blog-item-4-md-margin: round($blog-4-margin/ 1.4);

$blog-item-3-md-grid: 2;
$blog-item-3-md-margin: $blog-3-margin;

$blog-item-2-md-grid: $blog-item-3-md-grid;
$blog-item-2-md-margin: $blog-item-3-md-margin;

// Responsive Down sm
$blog-item-4-sm-grid: 1;
$blog-item-4-sm-margin: 0px;

$blog-item-3-sm-grid: $blog-item-4-sm-grid;
$blog-item-3-sm-margin: $blog-item-4-sm-margin;

$blog-item-2-sm-grid: $blog-item-4-sm-grid;
$blog-item-2-sm-margin: $blog-item-4-sm-margin;

//-------------------- Footers --------------------//

$footer-bg: darken($body-bg, 6.67%);
$footer-bg-dark: darken($body-bg-dark, 1.96%);

$widget-border: 1px solid #eee;

$footer-widgets-path: 'image/icons';
$footer-widgets-nav-icons: 'widget-link';
$footer-widgets-comments-icons: 'widget-comment';
$footer-widgets-img-extension: 'png';

//-------------------- Social Icons Colors --------------------//

$si-colors: (
  facebook: #3b5998,
  whatsapp: #25d366,
  delicious: #205cc0,
  paypal: #00588b,
  flattr: #f67c1a,
  android: #a4c639,
  smashmag: #e53b2c,
  gplus: #dd4b39,
  wikipedia: #333,
  stumbleupon: #f74425,
  foursquare: #25a0ca,
  call: #444,
  ninetyninedesigns: #f26739,
  forrst: #5b9a68,
  digg: #191919,
  spotify: #81b71a,
  reddit: #c6c6c6,
  blogger: #fc4f08,
  cc: #688527,
  dribbble: #ea4c89,
  evernote: #5ba525,
  flickr: #ff0084,
  google: #dd4b39,
  instapaper: #333,
  klout: #ff5f52,
  linkedin: #0e76a8,
  vk: #2b587a,
  rss: #ee802f,
  skype: #00aff0,
  twitter: #00acee,
  youtube: #c4302b,
  vimeo: #86c9ef,
  aim: #fcd20b,
  yahoo: #720e9e,
  email3: #6567a5,
  macstore: #333333,
  myspace: #666666,
  podcast: #e4b21b,
  cloudapp: #525557,
  dropbox: #3d9ae8,
  ebay: #89c507,
  github: #171515,
  googleplay: #dd4b39,
  itunes: #222,
  plurk: #cf5a00,
  pinboard: #0000e6,
  soundcloud: #ff7700,
  tumblr: #34526f,
  wordpress: #1e8cbe,
  yelp: #c41200,
  intensedebate: #009ee4,
  eventbrite: #f16924,
  scribd: #666666,
  stripe: #008cdd,
  print: #111,
  dwolla: #ff5c03,
  statusnet: #131a30,
  acrobat: #d3222a,
  drupal: #27537a,
  buffer: #333333,
  pocket: #ee4056,
  bitbucket: #0e4984,
  stackoverflow: #ef8236,
  hackernews: #ff6600,
  xing: #126567,
  instagram: #3f729b,
  quora: #a82400,
  openid: #e16309,
  steam: #111,
  amazon: #e47911,
  disqus: #e4e7ee,
  plancast: #222,
  appstore: #000,
  pinterest: #c8232c,
  fivehundredpx: #111,
) !default;

$si-text-hover-color: $white;

@import 'bootstrap/_functions';
@import 'bootstrap/_variables';
@import 'bootstrap/_mixins';

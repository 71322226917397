@charset "UTF-8";
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]), a:not([href]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 87.5%; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: textfield; }

textarea {
  overflow: auto;
  resize: vertical;
  -webkit-appearance: textfield; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

progress {
  vertical-align: baseline; }

::-webkit-datetime-edit {
  overflow: visible;
  line-height: 0; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

main {
  display: block; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.3333333333%; }
  .offset-xl-2 {
    margin-left: 16.6666666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.3333333333%; }
  .offset-xl-5 {
    margin-left: 41.6666666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.3333333333%; }
  .offset-xl-8 {
    margin-left: 66.6666666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.3333333333%; }
  .offset-xl-11 {
    margin-left: 91.6666666667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top; }
  .table th,
  .table td {
    padding: 0.5rem;
    border-bottom: 1px solid #dee2e6; }
  .table tbody {
    vertical-align: inherit; }
  .table td {
    border-bottom: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom-color: #495057; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.25rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control {
  display: block;
  width: 100%;
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.form-control-color {
  max-width: 3rem;
  padding: 0.375rem; }

.form-control-color::-moz-color-swatch {
  border-radius: 0.25rem; }

.form-control-color::-webkit-color-swatch {
  border-radius: 0.25rem; }

.form-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") no-repeat right 0.75rem center/16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .form-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .form-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .form-select::-ms-expand {
    display: none; }

.form-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.form-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.75em;
  margin-bottom: 0.125rem; }

.form-check-input {
  float: left;
  width: 1.25em;
  height: 1.25em;
  margin-top: calc((1.5em - 1.25em) / 2);
  margin-left: -1.75em;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-check-input:checked {
    background-color: #007bff;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1em;
    border-color: #007bff; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M4 8.5L6.5 11l6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #007bff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M5 8h6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1em;
    border-color: #007bff; }
  .form-check-input[disabled] {
    pointer-events: none;
    filter: none;
    opacity: .5; }
    .form-check-input[disabled] ~ .form-check-label {
      opacity: .5; }

.form-check-label {
  margin-bottom: 0; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: calc(1.25em - 2px);
    border-radius: 2em; }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2380bdff'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
      background-position: right center; }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.form-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.form-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .form-file-input:focus ~ .form-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-file-input:focus-within ~ .form-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-file-input[disabled] ~ .form-file-label .form-file-text {
    background-color: #e9ecef; }

.form-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  height: calc(1.5em + 0.75rem + 2px);
  border-color: #ced4da;
  border-radius: 0.25rem; }

.form-file-text {
  display: block;
  flex-grow: 1;
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fff;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit; }

.form-file-button {
  display: block;
  flex-shrink: 0;
  padding: 0.375rem 0.75rem;
  margin-left: -1px;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit; }

.form-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: none; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .form-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-ms-thumb {
        transition: none; } }
    .form-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .form-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .form-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .form-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .form-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .form-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .form-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .form-range:disabled::-moz-range-track {
    cursor: default; }
  .form-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .form-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; } }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-file {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .form-select,
    .input-group > .form-control + .form-file,
    .input-group > .form-select + .form-control,
    .input-group > .form-select + .form-select,
    .input-group > .form-select + .form-file,
    .input-group > .form-file + .form-control,
    .input-group > .form-file + .form-select,
    .input-group > .form-file + .form-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus,
  .input-group > .form-file .form-file-input:focus ~ .form-file-label {
    z-index: 3; }
  .input-group > .form-file .form-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .form-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .form-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .form-file {
    display: flex;
    align-items: center; }
    .input-group > .form-file:not(:last-child) .form-file-label {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .form-file:not(:first-child) .form-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control {
  min-height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control {
  min-height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") no-repeat right 0.75rem center/16px 12px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-select:valid ~ .valid-feedback,
  .was-validated .form-select:valid ~ .valid-tooltip, .form-select.is-valid ~ .valid-feedback,
  .form-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #28a745; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #34ce57; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #28a745; }
  .was-validated .form-check-input:valid ~ .valid-feedback,
  .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
  .form-check-input.is-valid ~ .valid-tooltip {
    display: block; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .form-file-input:valid ~ .form-file-label, .form-file-input.is-valid ~ .form-file-label {
  border-color: #28a745; }

.was-validated .form-file-input:valid ~ .valid-feedback,
.was-validated .form-file-input:valid ~ .valid-tooltip, .form-file-input.is-valid ~ .valid-feedback,
.form-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-file-input:valid:focus ~ .form-file-label, .form-file-input.is-valid:focus ~ .form-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") no-repeat right 0.75rem center/16px 12px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-select:invalid ~ .invalid-feedback,
  .was-validated .form-select:invalid ~ .invalid-tooltip, .form-select.is-invalid ~ .invalid-feedback,
  .form-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #e4606d; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545; }
  .was-validated .form-check-input:invalid ~ .invalid-feedback,
  .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
  .form-check-input.is-invalid ~ .invalid-tooltip {
    display: block; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .form-file-input:invalid ~ .form-file-label, .form-file-input.is-invalid ~ .form-file-label {
  border-color: #dc3545; }

.was-validated .form-file-input:invalid ~ .invalid-feedback,
.was-validated .form-file-input:invalid ~ .invalid-tooltip, .form-file-input.is-invalid ~ .invalid-feedback,
.form-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-file-input:invalid:focus ~ .form-file-label, .form-file-input.is-invalid:focus ~ .form-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:hover,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container, .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .container-sm,
    .navbar-expand-md > .container-md,
    .navbar-expand-md > .container-lg,
    .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid,
  .navbar-expand > .container-sm,
  .navbar-expand > .container-md,
  .navbar-expand > .container-lg,
  .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid,
  .navbar-expand > .container-sm,
  .navbar-expand > .container-md,
  .navbar-expand > .container-lg,
  .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.page-link {
  padding: 0.5rem 0.75rem;
  line-height: 1.25; }

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.5em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:first-child {
    border-top-width: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[x-placement^="top"] .tooltip-arrow {
    bottom: 0; }
    .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="top"] .tooltip-arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .tooltip-arrow, .bs-tooltip-auto[x-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="right"] .tooltip-arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow {
    top: 0; }
    .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .tooltip-arrow, .bs-tooltip-auto[x-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="left"] .tooltip-arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .popover-arrow, .bs-popover-auto[x-placement^="top"] > .popover-arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .popover-arrow::before, .bs-popover-auto[x-placement^="top"] > .popover-arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .popover-arrow::after, .bs-popover-auto[x-placement^="top"] > .popover-arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .popover-arrow, .bs-popover-auto[x-placement^="right"] > .popover-arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .popover-arrow::before, .bs-popover-auto[x-placement^="right"] > .popover-arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .popover-arrow::after, .bs-popover-auto[x-placement^="right"] > .popover-arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .popover-arrow, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .popover-arrow, .bs-popover-auto[x-placement^="left"] > .popover-arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .popover-arrow::before, .bs-popover-auto[x-placement^="left"] > .popover-arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .popover-arrow::after, .bs-popover-auto[x-placement^="left"] > .popover-arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #007bff; }
  .link-primary:hover, .link-primary:focus {
    color: #0056b3; }

.link-secondary {
  color: #6c757d; }
  .link-secondary:hover, .link-secondary:focus {
    color: #494f54; }

.link-success {
  color: #28a745; }
  .link-success:hover, .link-success:focus {
    color: #19692c; }

.link-info {
  color: #17a2b8; }
  .link-info:hover, .link-info:focus {
    color: #0f6674; }

.link-warning {
  color: #ffc107; }
  .link-warning:hover, .link-warning:focus {
    color: #ba8b00; }

.link-danger {
  color: #dc3545; }
  .link-danger:hover, .link-danger:focus {
    color: #a71d2a; }

.link-light {
  color: #f8f9fa; }
  .link-light:hover, .link-light:focus {
    color: #cbd3da; }

.link-dark {
  color: #343a40; }
  .link-dark:hover, .link-dark:focus {
    color: #121416; }

.embed-responsive {
  position: relative;
  width: 100%; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only,
.sr-only-focusable:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.mr-0 {
  margin-right: 0 !important; }

.mr-1 {
  margin-right: 0.25rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mr-3 {
  margin-right: 1rem !important; }

.mr-4 {
  margin-right: 1.5rem !important; }

.mr-5 {
  margin-right: 3rem !important; }

.mr-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ml-0 {
  margin-left: 0 !important; }

.ml-1 {
  margin-left: 0.25rem !important; }

.ml-2 {
  margin-left: 0.5rem !important; }

.ml-3 {
  margin-left: 1rem !important; }

.ml-4 {
  margin-left: 1.5rem !important; }

.ml-5 {
  margin-left: 3rem !important; }

.ml-auto {
  margin-left: auto !important; }

.m-n1 {
  margin: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important; }

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important; }

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important; }

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important; }

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important; }

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important; }

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important; }

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important; }

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important; }

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important; }

.mt-n1 {
  margin-top: -0.25rem !important; }

.mt-n2 {
  margin-top: -0.5rem !important; }

.mt-n3 {
  margin-top: -1rem !important; }

.mt-n4 {
  margin-top: -1.5rem !important; }

.mt-n5 {
  margin-top: -3rem !important; }

.mr-n1 {
  margin-right: -0.25rem !important; }

.mr-n2 {
  margin-right: -0.5rem !important; }

.mr-n3 {
  margin-right: -1rem !important; }

.mr-n4 {
  margin-right: -1.5rem !important; }

.mr-n5 {
  margin-right: -3rem !important; }

.mb-n1 {
  margin-bottom: -0.25rem !important; }

.mb-n2 {
  margin-bottom: -0.5rem !important; }

.mb-n3 {
  margin-bottom: -1rem !important; }

.mb-n4 {
  margin-bottom: -1.5rem !important; }

.mb-n5 {
  margin-bottom: -3rem !important; }

.ml-n1 {
  margin-left: -0.25rem !important; }

.ml-n2 {
  margin-left: -0.5rem !important; }

.ml-n3 {
  margin-left: -1rem !important; }

.ml-n4 {
  margin-left: -1.5rem !important; }

.ml-n5 {
  margin-left: -3rem !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pr-0 {
  padding-right: 0 !important; }

.pr-1 {
  padding-right: 0.25rem !important; }

.pr-2 {
  padding-right: 0.5rem !important; }

.pr-3 {
  padding-right: 1rem !important; }

.pr-4 {
  padding-right: 1.5rem !important; }

.pr-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.pl-0 {
  padding-left: 0 !important; }

.pl-1 {
  padding-left: 0.25rem !important; }

.pl-2 {
  padding-left: 0.5rem !important; }

.pl-3 {
  padding-left: 1rem !important; }

.pl-4 {
  padding-left: 1.5rem !important; }

.pl-5 {
  padding-left: 3rem !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-justify {
  text-align: justify !important; }

.text-primary {
  color: #007bff !important; }

.text-secondary {
  color: #6c757d !important; }

.text-success {
  color: #28a745 !important; }

.text-info {
  color: #17a2b8 !important; }

.text-warning {
  color: #ffc107 !important; }

.text-danger {
  color: #dc3545 !important; }

.text-light {
  color: #f8f9fa !important; }

.text-dark {
  color: #343a40 !important; }

.text-white {
  color: #fff !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  color: inherit !important; }

.bg-primary {
  background-color: #007bff !important; }

.bg-secondary {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #28a745 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

.bg-warning {
  background-color: #ffc107 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

.bg-dark {
  background-color: #343a40 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-decoration-none {
  text-decoration: none !important; }

.font-italic {
  font-style: italic !important; }

.text-break {
  overflow-wrap: break-word !important;
  word-break: break-word !important; }

.font-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .mr-sm-0 {
    margin-right: 0 !important; }
  .mr-sm-1 {
    margin-right: 0.25rem !important; }
  .mr-sm-2 {
    margin-right: 0.5rem !important; }
  .mr-sm-3 {
    margin-right: 1rem !important; }
  .mr-sm-4 {
    margin-right: 1.5rem !important; }
  .mr-sm-5 {
    margin-right: 3rem !important; }
  .mr-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-0 {
    margin-left: 0 !important; }
  .ml-sm-1 {
    margin-left: 0.25rem !important; }
  .ml-sm-2 {
    margin-left: 0.5rem !important; }
  .ml-sm-3 {
    margin-left: 1rem !important; }
  .ml-sm-4 {
    margin-left: 1.5rem !important; }
  .ml-sm-5 {
    margin-left: 3rem !important; }
  .ml-sm-auto {
    margin-left: auto !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-sm-n1 {
    margin-top: -0.25rem !important; }
  .mt-sm-n2 {
    margin-top: -0.5rem !important; }
  .mt-sm-n3 {
    margin-top: -1rem !important; }
  .mt-sm-n4 {
    margin-top: -1.5rem !important; }
  .mt-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n1 {
    margin-right: -0.25rem !important; }
  .mr-sm-n2 {
    margin-right: -0.5rem !important; }
  .mr-sm-n3 {
    margin-right: -1rem !important; }
  .mr-sm-n4 {
    margin-right: -1.5rem !important; }
  .mr-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-sm-n3 {
    margin-bottom: -1rem !important; }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n1 {
    margin-left: -0.25rem !important; }
  .ml-sm-n2 {
    margin-left: -0.5rem !important; }
  .ml-sm-n3 {
    margin-left: -1rem !important; }
  .ml-sm-n4 {
    margin-left: -1.5rem !important; }
  .ml-sm-n5 {
    margin-left: -3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pr-sm-1 {
    padding-right: 0.25rem !important; }
  .pr-sm-2 {
    padding-right: 0.5rem !important; }
  .pr-sm-3 {
    padding-right: 1rem !important; }
  .pr-sm-4 {
    padding-right: 1.5rem !important; }
  .pr-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-0 {
    padding-left: 0 !important; }
  .pl-sm-1 {
    padding-left: 0.25rem !important; }
  .pl-sm-2 {
    padding-left: 0.5rem !important; }
  .pl-sm-3 {
    padding-left: 1rem !important; }
  .pl-sm-4 {
    padding-left: 1.5rem !important; }
  .pl-sm-5 {
    padding-left: 3rem !important; }
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; }
  .text-sm-justify {
    text-align: justify !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .mr-md-0 {
    margin-right: 0 !important; }
  .mr-md-1 {
    margin-right: 0.25rem !important; }
  .mr-md-2 {
    margin-right: 0.5rem !important; }
  .mr-md-3 {
    margin-right: 1rem !important; }
  .mr-md-4 {
    margin-right: 1.5rem !important; }
  .mr-md-5 {
    margin-right: 3rem !important; }
  .mr-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ml-md-0 {
    margin-left: 0 !important; }
  .ml-md-1 {
    margin-left: 0.25rem !important; }
  .ml-md-2 {
    margin-left: 0.5rem !important; }
  .ml-md-3 {
    margin-left: 1rem !important; }
  .ml-md-4 {
    margin-left: 1.5rem !important; }
  .ml-md-5 {
    margin-left: 3rem !important; }
  .ml-md-auto {
    margin-left: auto !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-md-n1 {
    margin-top: -0.25rem !important; }
  .mt-md-n2 {
    margin-top: -0.5rem !important; }
  .mt-md-n3 {
    margin-top: -1rem !important; }
  .mt-md-n4 {
    margin-top: -1.5rem !important; }
  .mt-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n1 {
    margin-right: -0.25rem !important; }
  .mr-md-n2 {
    margin-right: -0.5rem !important; }
  .mr-md-n3 {
    margin-right: -1rem !important; }
  .mr-md-n4 {
    margin-right: -1.5rem !important; }
  .mr-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-md-n3 {
    margin-bottom: -1rem !important; }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n1 {
    margin-left: -0.25rem !important; }
  .ml-md-n2 {
    margin-left: -0.5rem !important; }
  .ml-md-n3 {
    margin-left: -1rem !important; }
  .ml-md-n4 {
    margin-left: -1.5rem !important; }
  .ml-md-n5 {
    margin-left: -3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pr-md-0 {
    padding-right: 0 !important; }
  .pr-md-1 {
    padding-right: 0.25rem !important; }
  .pr-md-2 {
    padding-right: 0.5rem !important; }
  .pr-md-3 {
    padding-right: 1rem !important; }
  .pr-md-4 {
    padding-right: 1.5rem !important; }
  .pr-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-0 {
    padding-left: 0 !important; }
  .pl-md-1 {
    padding-left: 0.25rem !important; }
  .pl-md-2 {
    padding-left: 0.5rem !important; }
  .pl-md-3 {
    padding-left: 1rem !important; }
  .pl-md-4 {
    padding-left: 1.5rem !important; }
  .pl-md-5 {
    padding-left: 3rem !important; }
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; }
  .text-md-justify {
    text-align: justify !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .mr-lg-0 {
    margin-right: 0 !important; }
  .mr-lg-1 {
    margin-right: 0.25rem !important; }
  .mr-lg-2 {
    margin-right: 0.5rem !important; }
  .mr-lg-3 {
    margin-right: 1rem !important; }
  .mr-lg-4 {
    margin-right: 1.5rem !important; }
  .mr-lg-5 {
    margin-right: 3rem !important; }
  .mr-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-0 {
    margin-left: 0 !important; }
  .ml-lg-1 {
    margin-left: 0.25rem !important; }
  .ml-lg-2 {
    margin-left: 0.5rem !important; }
  .ml-lg-3 {
    margin-left: 1rem !important; }
  .ml-lg-4 {
    margin-left: 1.5rem !important; }
  .ml-lg-5 {
    margin-left: 3rem !important; }
  .ml-lg-auto {
    margin-left: auto !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-lg-n1 {
    margin-top: -0.25rem !important; }
  .mt-lg-n2 {
    margin-top: -0.5rem !important; }
  .mt-lg-n3 {
    margin-top: -1rem !important; }
  .mt-lg-n4 {
    margin-top: -1.5rem !important; }
  .mt-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n1 {
    margin-right: -0.25rem !important; }
  .mr-lg-n2 {
    margin-right: -0.5rem !important; }
  .mr-lg-n3 {
    margin-right: -1rem !important; }
  .mr-lg-n4 {
    margin-right: -1.5rem !important; }
  .mr-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-lg-n3 {
    margin-bottom: -1rem !important; }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n1 {
    margin-left: -0.25rem !important; }
  .ml-lg-n2 {
    margin-left: -0.5rem !important; }
  .ml-lg-n3 {
    margin-left: -1rem !important; }
  .ml-lg-n4 {
    margin-left: -1.5rem !important; }
  .ml-lg-n5 {
    margin-left: -3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pr-lg-1 {
    padding-right: 0.25rem !important; }
  .pr-lg-2 {
    padding-right: 0.5rem !important; }
  .pr-lg-3 {
    padding-right: 1rem !important; }
  .pr-lg-4 {
    padding-right: 1.5rem !important; }
  .pr-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-0 {
    padding-left: 0 !important; }
  .pl-lg-1 {
    padding-left: 0.25rem !important; }
  .pl-lg-2 {
    padding-left: 0.5rem !important; }
  .pl-lg-3 {
    padding-left: 1rem !important; }
  .pl-lg-4 {
    padding-left: 1.5rem !important; }
  .pl-lg-5 {
    padding-left: 3rem !important; }
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; }
  .text-lg-justify {
    text-align: justify !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .mr-xl-0 {
    margin-right: 0 !important; }
  .mr-xl-1 {
    margin-right: 0.25rem !important; }
  .mr-xl-2 {
    margin-right: 0.5rem !important; }
  .mr-xl-3 {
    margin-right: 1rem !important; }
  .mr-xl-4 {
    margin-right: 1.5rem !important; }
  .mr-xl-5 {
    margin-right: 3rem !important; }
  .mr-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-0 {
    margin-left: 0 !important; }
  .ml-xl-1 {
    margin-left: 0.25rem !important; }
  .ml-xl-2 {
    margin-left: 0.5rem !important; }
  .ml-xl-3 {
    margin-left: 1rem !important; }
  .ml-xl-4 {
    margin-left: 1.5rem !important; }
  .ml-xl-5 {
    margin-left: 3rem !important; }
  .ml-xl-auto {
    margin-left: auto !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-xl-n1 {
    margin-top: -0.25rem !important; }
  .mt-xl-n2 {
    margin-top: -0.5rem !important; }
  .mt-xl-n3 {
    margin-top: -1rem !important; }
  .mt-xl-n4 {
    margin-top: -1.5rem !important; }
  .mt-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n1 {
    margin-right: -0.25rem !important; }
  .mr-xl-n2 {
    margin-right: -0.5rem !important; }
  .mr-xl-n3 {
    margin-right: -1rem !important; }
  .mr-xl-n4 {
    margin-right: -1.5rem !important; }
  .mr-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-xl-n3 {
    margin-bottom: -1rem !important; }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n1 {
    margin-left: -0.25rem !important; }
  .ml-xl-n2 {
    margin-left: -0.5rem !important; }
  .ml-xl-n3 {
    margin-left: -1rem !important; }
  .ml-xl-n4 {
    margin-left: -1.5rem !important; }
  .ml-xl-n5 {
    margin-left: -3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-0 {
    padding-right: 0 !important; }
  .pr-xl-1 {
    padding-right: 0.25rem !important; }
  .pr-xl-2 {
    padding-right: 0.5rem !important; }
  .pr-xl-3 {
    padding-right: 1rem !important; }
  .pr-xl-4 {
    padding-right: 1.5rem !important; }
  .pr-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-0 {
    padding-left: 0 !important; }
  .pl-xl-1 {
    padding-left: 0.25rem !important; }
  .pl-xl-2 {
    padding-left: 0.5rem !important; }
  .pl-xl-3 {
    padding-left: 1rem !important; }
  .pl-xl-4 {
    padding-left: 1.5rem !important; }
  .pl-xl-5 {
    padding-left: 3rem !important; }
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; }
  .text-xl-justify {
    text-align: justify !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

/* ----------------------------------------------------------------

	Layouts.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Bootstrap Adjustments
-----------------------------------------------------------------*/
@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 750px; } }

@media (min-width: 992px) {
  .container {
    max-width: 970px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1170px; } }

@media (max-width: 575.98px) {
  .container {
    width: 100% !important;
    padding-left: 40px !important;
    padding-right: 40px !important; } }

body,
.dropdown-menu {
  font-size: 14px; }

body.dark {
  background-color: #383838; }

/* ----------------------------------------------------------------
	Sections
-----------------------------------------------------------------*/
.section {
  position: relative;
  margin: 60px 0;
  padding: 60px 0;
  background-color: #f9f9f9;
  overflow: hidden; }
  .section.dark,
  .dark .section {
    background-color: #282828;
    border-color: rgba(255, 255, 255, 0.1); }
  .section .container {
    z-index: 2; }
  .section .container + .video-wrap {
    z-index: 1; }

.parallax {
  background-color: transparent;
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: no-repeat;
  overflow: hidden; }

.mobile-parallax,
.video-placeholder {
  background-size: cover !important;
  background-attachment: scroll !important;
  background-position: center center; }

.revealer-image {
  position: relative;
  bottom: -120px;
  transition: bottom 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .revealer-image {
      transition: none; } }

.section:hover .revealer-image {
  bottom: -60px; }

/* ----------------------------------------------------------------
	Columns & Grids
-----------------------------------------------------------------*/
.postcontent {
  width: 74%; }
  .postcontent.bothsidebar {
    width: 48%; }
    @media (max-width: 991.98px) {
      .postcontent.bothsidebar {
        width: 100%;
        margin-right: 0;
        float: none !important;
        margin-bottom: 40px !important;
        padding-bottom: 40px;
        border-bottom: 1px solid #eeeeee; }
        .dark .postcontent.bothsidebar {
          border-bottom-color: rgba(255, 255, 255, 0.1); } }

.sidebar {
  width: 22%; }

.col_full {
  width: 100%; }

.col_half {
  width: 48%; }

.col_one_third {
  width: 30.63%; }

.col_two_third {
  width: 65.33%; }

.col_one_fourth {
  width: 22%; }

.col_three_fourth {
  width: 74%; }

.col_one_fifth {
  width: 16.8%; }

.col_two_fifth {
  width: 37.6%; }

.col_three_fifth {
  width: 58.4%; }

.col_four_fifth {
  width: 79.2%; }

.col_one_sixth {
  width: 13.33%; }

.col_five_sixth {
  width: 82.67%; }

body.stretched .container-fullwidth {
  position: relative;
  padding: 0 60px;
  width: 100%; }

@media (max-width: 991.98px) {
  .container-fullwidth {
    padding: 0 15px !important;
    margin: 0 auto;
    width: 750px; } }

@media (max-width: 767.98px) {
  .container-fullwidth {
    margin: 0 auto; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .container-fullwidth {
    width: 540px !important; } }

@media (max-width: 575.98px) {
  .container-fullwidth {
    width: 100% !important;
    padding-left: 40px !important;
    padding-right: 40px !important; } }

.postcontent,
.sidebar,
.col_full,
.col_half,
.col_one_third,
.col_two_third,
.col_three_fourth,
.col_one_fourth,
.col_one_fifth,
.col_two_fifth,
.col_three_fifth,
.col_four_fifth,
.col_one_sixth,
.col_five_sixth {
  display: block;
  position: relative;
  margin-right: 4%;
  margin-bottom: 50px;
  float: left; }
  @media (max-width: 767.98px) {
    .postcontent,
    .sidebar,
    .col_full,
    .col_half,
    .col_one_third,
    .col_two_third,
    .col_three_fourth,
    .col_one_fourth,
    .col_one_fifth,
    .col_two_fifth,
    .col_three_fifth,
    .col_four_fifth,
    .col_one_sixth,
    .col_five_sixth {
      width: 100%;
      margin-right: 0;
      float: none;
      margin-bottom: 50px !important; } }

.postcontent,
.sidebar {
  margin-right: 4%; }
  @media (max-width: 991.98px) {
    .postcontent,
    .sidebar {
      width: 100%;
      margin-right: 0;
      float: none !important;
      margin-bottom: 40px !important;
      padding-bottom: 40px;
      border-bottom: 1px solid #eeeeee; }
      .dark .postcontent, .dark
      .sidebar {
        border-bottom-color: rgba(255, 255, 255, 0.1); } }

.col_full {
  clear: both;
  float: none;
  margin-right: 0; }

.col_last {
  margin-right: 0 !important;
  float: right;
  clear: right; }
  @media (max-width: 991.98px) {
    .sidebar.col_last {
      float: none;
      margin-bottom: 0 !important;
      padding-bottom: 0;
      border-bottom: 0; } }

/*-----------------------------------------------------------------------------------
	typography.scss
-----------------------------------------------------------------------------------*/
dl, dt, dd, ol, ul, li {
  margin: 0;
  padding: 0; }

.clear {
  clear: both;
  display: block;
  font-size: 0px;
  height: 0px;
  line-height: 0;
  width: 100%;
  overflow: hidden; }

::selection {
  background: #1abc9c;
  color: #FFF;
  text-shadow: none; }

::-moz-selection {
  background: #1abc9c;
  /* Firefox */
  color: #FFF;
  text-shadow: none; }

::-webkit-selection {
  background: #1abc9c;
  /* Safari */
  color: #FFF;
  text-shadow: none; }

:active,
:focus {
  outline: none !important; }

/* ----------------------------------------------------------------
	Typography
-----------------------------------------------------------------*/
body {
  line-height: 1.5;
  color: #555;
  font-size: 1rem;
  font-family: "Lato", sans-serif; }
  @media (max-width: 767.98px) {
    body {
      padding: 0 !important; } }

a {
  text-decoration: none !important;
  color: #1abc9c; }
  a:hover {
    color: #222; }
    .dark a:hover {
      color: #eeeeee; }
  a img {
    border: none; }

img {
  max-width: 100%; }

iframe {
  border: none !important; }

/* ----------------------------------------------------------------
	Basic Layout Styles
-----------------------------------------------------------------*/
h1, .h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: #444444;
  font-weight: 600;
  line-height: 1.5;
  margin: 0 0 30px 0;
  font-family: "Raleway", sans-serif; }
  .dark h1, .dark .h1,
  .dark
  h2,
  .dark .h2,
  .dark
  h3,
  .dark .h3,
  .dark
  h4,
  .dark .h4,
  .dark
  h5,
  .dark .h5,
  .dark
  h6,
  .dark .h6 {
    color: #eeeeee; }

.dark {
  color: #eeeeee; }

h5, .h5,
h6,
.h6 {
  margin-bottom: 20px; }

h1, .h1 {
  font-size: 36px; }
  @media (max-width: 767.98px) {
    h1, .h1 {
      font-size: 28px; } }

h2, .h2 {
  font-size: 30px; }
  @media (max-width: 767.98px) {
    h2, .h2 {
      font-size: 24px; } }

h3, .h3 {
  font-size: 24px; }
  @media (max-width: 767.98px) {
    h3, .h3 {
      font-size: 20px; } }

h4, .h4 {
  font-size: 18px; }
  @media (max-width: 767.98px) {
    h4, .h4 {
      font-size: 16px; } }

h4, .h4 {
  font-weight: 600; }

h5, .h5,
h6,
.h6 {
  font-weight: bold; }

h1 > span:not(.nocolor), .h1 > span:not(.nocolor),
h2 > span:not(.nocolor), .h2 > span:not(.nocolor),
h3 > span:not(.nocolor), .h3 > span:not(.nocolor),
h4 > span:not(.nocolor), .h4 > span:not(.nocolor),
h5 > span:not(.nocolor), .h5 > span:not(.nocolor),
h6 > span:not(.nocolor), .h6 > span:not(.nocolor) {
  color: #1abc9c; }

p,
pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset,
form {
  margin-bottom: 30px; }

small, .small {
  font-family: "Lato", sans-serif; }

.dark fieldset {
  border-color: rgba(255, 255, 255, 0.1); }

#wrapper {
  position: relative;
  float: none;
  margin: 0 auto;
  width: 1220px;
  background-color: white; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    #wrapper {
      width: 1000px; } }
  @media (max-width: 991.98px) {
    #wrapper {
      width: 100%;
      margin: 0;
      box-shadow: none; } }
  @media (max-width: 767.98px) {
    #wrapper {
      width: 100% !important; } }
  .dark #wrapper {
    background-color: #383838; }

/* ----------------------------------------------------------------

	content.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Content
-----------------------------------------------------------------*/
#content {
  position: relative;
  overflow: hidden;
  background-color: white; }
  .dark #content {
    background-color: #383838; }
  #content p {
    line-height: 1.8; }
  #content .content-wrap {
    position: relative;
    padding: 80px 0; }
  #content .container {
    position: relative; }

.grid-container {
  position: relative;
  overflow: hidden; }

body:not(.device-touch) .grid-container {
  transition: height 0.4s linear; }
  @media (prefers-reduced-motion: reduce) {
    body:not(.device-touch) .grid-container {
      transition: none; } }

/* ----------------------------------------------------------------

	blog.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Blog
-----------------------------------------------------------------*/
#posts {
  position: relative; }
  #posts .entry-content {
    margin-top: 30px; }

.entry {
  position: relative;
  margin: 0 0 50px; }
  .entry:after {
    content: '';
    position: relative;
    height: 2px;
    margin-top: 50px;
    background-color: whitesmoke; }
    .dark .entry:after {
      border-bottom-color: rgba(255, 255, 255, 0.08); }
  .entry blockquote p {
    font-weight: 400;
    font-family: "Crete Round", serif;
    font-style: italic; }

.entry-image,
.entry-image > a,
.entry-image .slide a,
.entry-image img {
  display: block;
  position: relative;
  width: 100%;
  height: auto; }

.entry-image {
  margin-bottom: 30px; }
  .entry-image iframe {
    display: block; }
    @media (min-width: 576px) and (max-width: 767.98px) {
      .post-grid:not(.post-masonry) .entry-image iframe {
        height: 246px; } }
    @media (max-width: 575.98px) {
      .post-grid:not(.post-masonry) .entry-image iframe {
        height: 157px; } }
  .entry-image img {
    border-radius: 3px; }
  .entry-image video {
    display: block;
    width: 100%; }

.entry-title h2, .entry-title .h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px; }
  @media (max-width: 767.98px) {
    .entry-title h2, .entry-title .h2 {
      font-size: 18px !important;
      font-weight: 600; } }
  .entry-title h2 a, .entry-title .h2 a {
    color: #333; }
    .dark .entry-title h2 a, .dark .entry-title .h2 a {
      color: #eeeeee; }
    .entry-title h2 a:hover, .entry-title .h2 a:hover {
      color: #1abc9c; }
      .dark .entry-title h2 a:hover, .dark .entry-title .h2 a:hover {
        color: #999999; }

.entry-meta {
  margin: 10px -10px -15px 0;
  list-style: none; }
  .entry-meta li {
    float: left;
    font-size: 13px;
    line-height: 14px;
    margin: 0 10px 15px 0;
    color: #999999;
    font-family: "Crete Round", serif;
    font-style: italic; }
    .dark .entry-meta li {
      color: #888888;
      border-left-color: rgba(255, 255, 255, 0.08); }
    @media (max-width: 991.98px) {
      .entry-meta li {
        font-size: 12px;
        margin: 0 10px 10px 0; } }
    @media (max-width: 767.98px) {
      .entry-meta li {
        font-size: 11px;
        margin: 0 10px 10px 0; } }
    .entry-meta li:before {
      content: '/';
      display: inline-block;
      margin-right: 10px;
      opacity: 0.5; }
    .entry-meta li i {
      position: relative;
      top: 1px;
      font-size: 14px;
      margin-right: 3px; }
      @media (max-width: 767.98px) {
        .entry-meta li i {
          display: none; } }
    .entry-meta li a {
      color: #999; }
      .dark .entry-meta li a {
        color: #888888; }
      .entry-meta li a:hover {
        color: #1abc9c; }
        .dark .entry-meta li a:hover {
          color: #cccccc; }
    .entry-meta li:first-child:before {
      display: none; }
    .entry-meta li ins {
      font-weight: 700;
      text-decoration: none; }

.single-post .entry-meta {
  margin-bottom: 20px; }

.single-post .entry-content .entry-image {
  max-width: 350px; }

.entry-c {
  position: relative;
  overflow: hidden; }

.entry-content {
  position: relative; }

.entry-link {
  display: block;
  width: 100%;
  background-color: #F5F5F5;
  padding: 30px 0;
  text-align: center;
  color: #444444;
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 24px;
  font-weight: 700;
  border-radius: 3px; }
  .dark .entry-link {
    background-color: rgba(0, 0, 0, 0.2); }
  .entry-link:hover {
    color: #FFF;
    background-color: #1abc9c;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }
  .entry-link span {
    display: block;
    margin-top: 5px;
    font-family: "Crete Round", serif;
    font-style: italic;
    font-weight: normal;
    text-transform: none;
    letter-spacing: 0;
    font-size: 14px;
    color: #AAA; }
  .entry-link:hover span {
    color: #EEE; }

body:not(.device-touch) .entry-link {
  transition: background-color 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    body:not(.device-touch) .entry-link {
      transition: none; } }

/* Small Thumbs
-----------------------------------------------------------------*/
.small-thumbs .entry-image,
.ievent .entry-image {
  float: left;
  width: 300px;
  margin: 0 30px 0 0; }
  @media (max-width: 767.98px) {
    .small-thumbs .entry-image,
    .ievent .entry-image {
      float: none !important;
      width: 100% !important;
      margin: 0 0 20px !important; } }
  .small-thumbs .entry-image img,
  .ievent .entry-image img {
    border-radius: 0; }

.small-thumbs .entry-c .entry-image,
.ievent .entry-c .entry-image {
  float: none;
  width: 100%;
  margin: 0 0 20px 0; }
  .small-thumbs .entry-c .entry-image img,
  .ievent .entry-c .entry-image img {
    border-radius: 0; }

.small-thumbs .entry-title h2, .small-thumbs .entry-title .h2,
.ievent .entry-title h2,
.ievent .entry-title .h2 {
  font-size: 20px;
  font-weight: 600; }
  @media (max-width: 991.98px) {
    .small-thumbs .entry-title h2, .small-thumbs .entry-title .h2,
    .ievent .entry-title h2,
    .ievent .entry-title .h2 {
      font-size: 18px; } }

/* Small Thumbs - Right
-----------------------------------------------------------------*/
.small-thumbs.alt .entry-image {
  float: right;
  margin: 0 0 0 30px; }

.small-thumbs.alt .entry-c .entry-image {
  float: none;
  margin: 0 0 20px 0; }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .postcontent .small-thumbs .entry-image {
    width: 200px; } }

/* Small Thumbs - Both Sidebar
-----------------------------------------------------------------*/
.bothsidebar .small-thumbs .entry-image {
  width: 200px;
  margin: 0 25px 0 0; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .bothsidebar .small-thumbs .entry-image {
      width: 120px; } }

.bothsidebar .small-thumbs.alt .entry-image {
  margin: 0 0 0 25px; }

.bothsidebar .small-thumbs .entry-c .entry-image {
  width: 100%;
  margin: 0 0 20px 0; }

.bothsidebar .small-thumbs .entry-title h2, .bothsidebar .small-thumbs .entry-title .h2 {
  font-size: 18px; }

/* Blog - Grid
-----------------------------------------------------------------*/
.post-grid {
  margin-right: -28px; }
  @media (max-width: 991.98px) {
    .post-grid {
      margin-right: -20px; } }
  @media (max-width: 767.98px) {
    .post-grid {
      margin-right: 0px;
      margin-right: 0; } }
  .post-grid .entry {
    float: left;
    width: 25%;
    padding-right: 28px; }
    @media (max-width: 991.98px) {
      .post-grid .entry {
        width: 33.3333333333%;
        padding-right: 20px; } }
    @media (max-width: 767.98px) {
      .post-grid .entry {
        float: none !important;
        width: 100% !important;
        margin-right: 0 !important;
        border-right: 0 !important;
        padding-right: 0; } }
  .post-grid .entry-image img {
    border-radius: 0; }
  .post-grid .entry-title h2, .post-grid .entry-title .h2 {
    font-size: 17px;
    font-weight: 600; }
    @media (max-width: 767.98px) {
      .post-grid .entry-title h2, .post-grid .entry-title .h2 {
        font-size: 18px !important;
        font-weight: 600; } }
  .post-grid .entry-link {
    font-size: 24px; }
    .post-grid .entry-link span {
      font-size: 13px; }
  @media (max-width: 767.98px) {
    .post-grid .entry-meta li i .entry-meta li:last-child i {
      display: inline-block; } }

/* Blog - Grid - 3 Columns
-----------------------------------------------------------------*/
.post-grid.grid-3 {
  margin-right: -30px; }
  @media (max-width: 991.98px) {
    .post-grid.grid-3 {
      margin-right: -30px; } }
  @media (max-width: 767.98px) {
    .post-grid.grid-3 {
      margin-right: 0px;
      margin-right: 0; } }
  .post-grid.grid-3 .entry {
    width: 33.33333333%;
    padding-right: 30px; }
    @media (max-width: 991.98px) {
      .post-grid.grid-3 .entry {
        width: 50%;
        padding-right: 30px; } }
    @media (max-width: 767.98px) {
      .post-grid.grid-3 .entry {
        float: none !important;
        width: 100% !important;
        margin-right: 0 !important;
        border-right: 0 !important;
        padding-right: 0; } }
  .post-grid.grid-3 .entry-title h2, .post-grid.grid-3 .entry-title .h2 {
    font-size: 18px; }

/* Blog - Grid - 3 Columns - Sidebar
-----------------------------------------------------------------*/
.postcontent .post-grid.grid-3 {
  margin-right: -25px; }
  @media (max-width: 991.98px) {
    .postcontent .post-grid.grid-3 {
      margin-right: -30px; } }
  @media (max-width: 767.98px) {
    .postcontent .post-grid.grid-3 {
      margin-right: 0px;
      margin-right: 0; } }
  .postcontent .post-grid.grid-3 .entry {
    padding-right: 25px; }
    @media (max-width: 991.98px) {
      .postcontent .post-grid.grid-3 .entry {
        width: 50%;
        padding-right: 30px; } }
    @media (max-width: 767.98px) {
      .postcontent .post-grid.grid-3 .entry {
        float: none !important;
        width: 100% !important;
        margin-right: 0 !important;
        border-right: 0 !important;
        padding-right: 0; } }
  .postcontent .post-grid.grid-3 .entry-title h2, .postcontent .post-grid.grid-3 .entry-title .h2 {
    font-size: 16px; }

/* Blog - Grid - 2 Columns
-----------------------------------------------------------------*/
.post-grid.grid-2 {
  margin-right: -40px; }
  @media (max-width: 991.98px) {
    .post-grid.grid-2 {
      margin-right: -30px; } }
  @media (max-width: 767.98px) {
    .post-grid.grid-2 {
      margin-right: 0px;
      margin-right: 0; } }
  .post-grid.grid-2 .entry {
    width: 50%;
    padding-right: 40px; }
    @media (max-width: 991.98px) {
      .post-grid.grid-2 .entry {
        width: 50%;
        padding-right: 30px; } }
    @media (max-width: 767.98px) {
      .post-grid.grid-2 .entry {
        float: none !important;
        width: 100% !important;
        margin-right: 0 !important;
        border-right: 0 !important;
        padding-right: 0; } }
  .post-grid.grid-2 .entry-title h2, .post-grid.grid-2 .entry-title .h2 {
    font-size: 20px; }

/* Blog - Grid - 2 Columns - Sidebar
-----------------------------------------------------------------*/
.postcontent .post-grid.grid-2 .entry-title h2, .postcontent .post-grid.grid-2 .entry-title .h2 {
  font-size: 18px; }

/* Blog - Grid - 2 Columns - Both Sidebar
-----------------------------------------------------------------*/
.bothsidebar .post-grid.grid-2 .entry-title h2, .bothsidebar .post-grid.grid-2 .entry-title .h2 {
  font-size: 16px; }

/* Blog - Masonry
-----------------------------------------------------------------*/
.post-masonry .entry-image,
.post-masonry .entry-image img {
  height: auto !important; }

/* Blog - Masonry Full
-----------------------------------------------------------------*/
.post-masonry-full {
  margin: -80px 0 -80px -1px !important; }
  .post-masonry-full .entry {
    width: 24.9% !important;
    margin: 0 !important;
    padding: 40px 30px;
    border-bottom: 1px dashed;
    border-left: 1px dashed;
    border-color: #e5e5e5; }
    .dark .post-masonry-full .entry {
      border-color: rgba(255, 255, 255, 0.08); }
    @media (max-width: 991.98px) {
      .post-masonry-full .entry {
        width: 33.3333333333% !important; } }
    @media (max-width: 767.98px) {
      .post-masonry-full .entry {
        float: none !important;
        width: 100% !important;
        margin-right: 0 !important;
        border-right: 0 !important;
        padding: 40px; } }
    .post-masonry-full .entry:after {
      display: none; }
  .post-masonry-full.grid-3 .entry {
    width: 33.30% !important; }
    @media (max-width: 991.98px) {
      .post-masonry-full.grid-3 .entry {
        width: 50% !important; } }
    @media (max-width: 767.98px) {
      .post-masonry-full.grid-3 .entry {
        float: none !important;
        width: 100% !important;
        margin-right: 0 !important;
        border-right: 0 !important;
        padding-right: 0; } }

/* Blog - Timeline
-----------------------------------------------------------------*/
.timeline-border {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -71px;
  width: 0;
  border-left: 1px dashed #cccccc;
  height: 100%; }
  .dark .timeline-border {
    border-left-color: rgba(255, 255, 255, 0.1); }
  @media (max-width: 767.98px) {
    .timeline-border {
      display: none !important; } }

.post-timeline {
  margin-right: -140px !important; }
  .post-timeline .entry {
    width: 500px !important;
    margin-right: 140px !important;
    padding-right: 0 !important; }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .post-timeline .entry {
        width: 400px !important; } }
    @media (max-width: 991.98px) {
      .post-timeline .entry {
        width: 290px !important; } }
  .post-timeline .entry-timeline {
    display: none;
    position: absolute;
    border: 2px solid #CCC;
    background-color: #FFF;
    padding-top: 0;
    text-indent: -9999px;
    top: 40px;
    left: auto;
    right: -76px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    font-size: 26px;
    font-weight: bold;
    color: #AAA;
    text-align: center;
    line-height: 1;
    transition: all 0.3s ease-in-out; }
    .dark .post-timeline .entry-timeline {
      border-color: rgba(255, 255, 255, 0.1);
      background-color: rgba(255, 255, 255, 0.08);
      color: #aaaaaa; }
    @media (prefers-reduced-motion: reduce) {
      .post-timeline .entry-timeline {
        transition: none; } }
    @media (max-width: 767.98px) {
      .post-timeline .entry-timeline {
        display: none !important; } }
    .post-timeline .entry-timeline div.timeline-divider {
      position: absolute;
      top: 4px;
      left: -58px;
      width: 50px;
      height: 0;
      border-top: 1px dashed #cccccc;
      transition: all 0.3s ease-in-out; }
      .dark .post-timeline .entry-timeline div.timeline-divider {
        border-top-color: rgba(255, 255, 255, 0.1); }
      @media (prefers-reduced-motion: reduce) {
        .post-timeline .entry-timeline div.timeline-divider {
          transition: none; } }
    .post-timeline .entry-timeline span {
      display: block;
      margin-top: 3px;
      font-size: 13px;
      font-weight: normal; }
  .post-timeline .alt .entry-timeline {
    right: auto;
    left: -77px; }
    .post-timeline .alt .entry-timeline div.timeline-divider {
      left: auto;
      right: -58px; }

.entry.entry-date-section {
  width: 100% !important;
  margin: 50px -70px 80px;
  padding: 0;
  border: 0;
  text-align: center; }
  @media (max-width: 991.98px) {
    .entry.entry-date-section {
      width: 100% !important; } }
  @media (max-width: 767.98px) {
    .entry.entry-date-section {
      margin-left: 0; } }
  .entry.entry-date-section span {
    display: inline-block;
    padding: 10px 15px;
    background-color: #FFF;
    border: 2px solid #EEE;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: "Raleway", sans-serif; }
    .dark .entry.entry-date-section span {
      border-color: rgba(255, 255, 255, 0.1);
      background-color: #333333; }
  .entry.entry-date-section + .entry .entry-timeline {
    top: 70px; }

.post-timeline .entry:hover .entry-timeline,
.post-timeline .entry:hover .timeline-divider {
  border-color: #1abc9c;
  color: #1abc9c; }

/* Blog - Timeline - Sidebar
-----------------------------------------------------------------*/
.postcontent .timeline-border {
  left: -68px;
  margin-left: 0; }

.postcontent .post-timeline {
  padding-left: 0;
  margin-left: 100px;
  margin-right: 0 !important;
  overflow: visible; }
  @media (max-width: 767.98px) {
    .postcontent .post-timeline {
      margin: 0;
      padding-left: 0; } }
  .postcontent .post-timeline .entry {
    width: 100% !important;
    margin-right: 0 !important; }
  .postcontent .post-timeline .entry-timeline {
    display: block;
    border: 3px solid #CCC;
    background-color: #FFF;
    padding-top: 10px;
    text-indent: 0;
    top: 20px;
    left: -100px;
    right: auto;
    width: 64px;
    height: 64px;
    font-size: 24px; }
  .postcontent .post-timeline .entry-timeline div.timeline-divider {
    top: 29px;
    left: 64px;
    width: 32px; }

/* Individual Post
-----------------------------------------------------------------*/
.ipost .entry-image img {
  border-radius: 0; }

.ipost .entry-title h3, .ipost .entry-title .h3,
.ipost .entry-title h4,
.ipost .entry-title .h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 600; }
  .ipost .entry-title h3 a, .ipost .entry-title .h3 a,
  .ipost .entry-title h4 a,
  .ipost .entry-title .h4 a {
    color: #333; }
    .dark .ipost .entry-title h3 a, .dark .ipost .entry-title .h3 a, .dark
    .ipost .entry-title h4 a, .dark
    .ipost .entry-title .h4 a {
      color: #eeeeee; }
    .ipost .entry-title h3 a:hover, .ipost .entry-title .h3 a:hover,
    .ipost .entry-title h4 a:hover,
    .ipost .entry-title .h4 a:hover {
      color: #1abc9c; }
      .dark .ipost .entry-title h3 a:hover, .dark .ipost .entry-title .h3 a:hover, .dark
      .ipost .entry-title h4 a:hover, .dark
      .ipost .entry-title .h4 a:hover {
        color: #999999; }

.ipost .entry-title h4, .ipost .entry-title .h4 {
  font-size: 15px; }

.ipost .entry-meta {
  margin-right: -10px; }
  .ipost .entry-meta li {
    font-size: 13px;
    margin-right: 10px; }

.ipost .entry-content {
  margin-top: 20px; }
  .ipost .entry-content p {
    margin-bottom: 0; }

.more-link {
  display: inline-block;
  border-bottom: 1px solid #1abc9c;
  padding: 0 2px;
  font-family: "Crete Round", serif;
  font-style: italic; }
  .more-link:hover {
    border-bottom-color: #555; }

/* Related Posts
-----------------------------------------------------------------*/
.overlay-icon {
  position: absolute;
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
  width: 48px;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center; }
  .overlay-icon i {
    line-height: 48px;
    font-size: 28px;
    color: #FFF;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }

/* Small Post
-----------------------------------------------------------------*/
.spost,
.mpost {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px dashed #e5e5e5; }
  .dark .spost, .dark
  .mpost {
    border-top-color: rgba(255, 255, 255, 0.1); }

.mpost {
  margin-top: 25px;
  padding-top: 25px; }

.spost:first-child,
.mpost:first-child {
  margin-top: 0;
  padding-top: 0;
  border-top: 0; }

.spost .entry-image,
.mpost .entry-image {
  float: left;
  margin: 0 15px 0 0;
  text-align: center; }

.spost .entry-image,
.spost .entry-image a,
.spost .entry-image img,
.spost .entry-image i {
  width: 48px;
  height: 48px; }

.spost .entry-image a i,
.mpost .entry-image a i {
  font-size: 28px;
  line-height: 48px;
  color: #666;
  background-color: #EEE; }
  .dark .spost .entry-image a i, .dark
  .mpost .entry-image a i {
    color: #cccccc;
    background-color: rgba(0, 0, 0, 0.2); }

.spost .entry-title h4, .spost .entry-title .h4,
.mpost .entry-title h4,
.mpost .entry-title .h4 {
  margin: 0;
  font-size: 14px;
  font-weight: 600; }
  .spost .entry-title h4 a, .spost .entry-title .h4 a,
  .mpost .entry-title h4 a,
  .mpost .entry-title .h4 a {
    color: #333; }
    .dark .spost .entry-title h4 a, .dark .spost .entry-title .h4 a, .dark
    .mpost .entry-title h4 a, .dark
    .mpost .entry-title .h4 a {
      color: #bbbbbb; }
    .spost .entry-title h4 a:hover, .spost .entry-title .h4 a:hover,
    .mpost .entry-title h4 a:hover,
    .mpost .entry-title .h4 a:hover {
      color: #1abc9c; }
      .dark .spost .entry-title h4 a:hover, .dark .spost .entry-title .h4 a:hover, .dark
      .mpost .entry-title h4 a:hover, .dark
      .mpost .entry-title .h4 a:hover {
        color: #999999; }

.spost .entry-meta {
  margin: 8px -10px 0 0; }

.spost .entry-meta li,
.mpost .entry-meta li {
  font-size: 13px;
  margin: 0 10px 0 0; }
  .dark .spost .entry-meta li a, .dark
  .mpost .entry-meta li a {
    color: #aaaaaa; }
    .dark .dark .spost .entry-meta li a:hover, .dark .dark
    .mpost .entry-meta li a:hover {
      color: #888888; }

/* Medium Post
-----------------------------------------------------------------*/
.mpost .entry-image {
  margin-right: 20px; }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .mpost .entry-image {
      float: none;
      margin: 0 0 20px 0; } }
  .mpost .entry-image a i {
    font-size: 42px;
    line-height: 128px; }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .mpost .entry-image a i {
        line-height: 90px; } }
    @media (min-width: 576px) and (max-width: 767.98px) {
      .mpost .entry-image a i {
        height: 150px;
        line-height: 150px; } }

.mpost .entry-title h4, .mpost .entry-title .h4 {
  margin: 0;
  font-size: 17px;
  font-weight: 600; }

.mpost .entry-meta {
  margin-top: 5px; }
  .mpost .entry-meta li i {
    margin-right: 2px; }

.mpost .entry-content {
  margin-top: 15px; }

.mpost .entry-image,
.mpost .entry-image a,
.mpost .entry-image img,
.mpost .entry-image i {
  width: 170px;
  height: 128px; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .mpost .entry-image,
    .mpost .entry-image a,
    .mpost .entry-image img,
    .mpost .entry-image i {
      width: 120px;
      height: 90px; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .mpost .entry-image,
    .mpost .entry-image a,
    .mpost .entry-image img,
    .mpost .entry-image i {
      width: 100%;
      height: auto; } }

.mpost .entry-image a,
.mpost .entry-image img {
  border-radius: 2px; }

.post-navigation + .line {
  margin: 40px 0 50px; }

/* ----------------------------------------------------------------
	Blog - Author
-----------------------------------------------------------------*/
.author-image {
  float: left;
  width: 64px;
  height: 64px;
  margin-right: 15px; }
  .author-image img {
    width: 64px;
    height: 64px; }

.author-desc {
  position: relative;
  overflow: hidden; }

/* ----------------------------------------------------------------
	Comments List
-----------------------------------------------------------------*/
#comments {
  position: relative;
  margin-top: 50px;
  padding-top: 50px;
  border-top: 1px solid #EEE; }
  .dark #comments {
    border-top-color: rgba(255, 255, 255, 0.08); }

.commentlist {
  list-style: none;
  padding-bottom: 50px;
  margin: 0 0 50px;
  border-bottom: 1px solid #EEE; }
  .dark .commentlist {
    border-bottom-color: rgba(255, 255, 255, 0.08); }
  .commentlist ul {
    list-style: none; }
    .commentlist ul:first-child {
      margin-top: 0; }
    .commentlist ul .comment-wrap {
      margin-left: 25px;
      padding-left: 20px; }
  .commentlist li {
    position: relative;
    margin: 30px 0 0 30px; }
    .commentlist li .children {
      margin-top: 0; }
    .commentlist li li .children {
      margin-left: 30px; }
    .commentlist li .comment-content {
      padding: 0 0 0 15px; }
    .commentlist li .comment-meta {
      float: left;
      margin-right: 0;
      line-height: 1; }

#reviews .commentlist {
  padding-bottom: 30px;
  margin: 0 0 20px; }
  #reviews .commentlist li {
    margin-top: 20px; }

#reviews .comment-wrap {
  border: 0;
  padding: 10px 0 0 35px; }

.commentlist li,
.commentlist li ul,
.commentlist li ul li {
  margin: 30px 0 0 0; }

.comment-wrap {
  position: relative;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  padding: 20px 20px 20px 35px; }
  .dark .comment-wrap {
    border-bottom-color: rgba(255, 255, 255, 0.08); }

.commentlist > li:first-child,
#reviews .commentlist > li:first-child {
  padding-top: 0;
  margin-top: 0; }

.commentlist li .comment-content,
.pingback {
  position: relative;
  overflow: hidden; }
  .commentlist li .comment-content p,
  .pingback p {
    margin: 20px 0 0 0; }

.comment-avatar {
  position: absolute;
  top: 15px;
  left: -35px;
  padding: 4px;
  background: #FFF;
  border: 1px solid #E5E5E5;
  border-radius: 50%; }
  .dark .comment-avatar {
    background: rgba(255, 255, 255, 0.08);
    border-color: rgba(255, 255, 255, 0.1); }
  .comment-avatar img {
    display: block;
    border-radius: 50%; }

.commentlist li .children .comment-avatar {
  left: -25px; }

.comment-content .comment-author {
  margin-bottom: -10px;
  font-size: 16px;
  font-weight: bold;
  color: #555; }
  .dark .comment-content .comment-author {
    color: #cccccc; }
  .comment-content .comment-author a {
    border: none;
    color: #333; }
    .dark .comment-content .comment-author a {
      color: #bbbbbb; }
    .comment-content .comment-author a:hover {
      color: #1abc9c; }
      .dark .comment-content .comment-author a:hover {
        color: #eeeeee; }
  .comment-content .comment-author span {
    display: block; }
    .comment-content .comment-author span:hover {
      color: #888; }
  .comment-content .comment-author span,
  .comment-content .comment-author a {
    font-size: 12px;
    font-weight: normal;
    font-family: "Crete Round", serif;
    font-style: italic;
    color: #AAA; }

.comment-reply-link,
.review-comment-ratings {
  display: block;
  position: absolute;
  top: 4px;
  left: auto;
  text-align: center;
  right: 0px;
  width: 14px;
  height: 14px;
  color: #CCC;
  font-size: 14px;
  line-height: 1; }

.review-comment-ratings {
  width: auto;
  color: #333; }
  .dark .review-comment-ratings {
    color: #cccccc; }

.comment-reply-link:hover {
  color: #888; }
  .dark .comment-reply-link:hover {
    color: #bbbbbb; }

/* ----------------------------------------------------------------
	Comment Form
-----------------------------------------------------------------*/
#respond,
#respond form {
  margin-bottom: 0; }

.commentlist li #respond {
  margin: 30px 0 0; }

.commentlist li li #respond {
  margin-left: 30px; }

#respond p {
  margin: 10px 0 0 0; }
  #respond p:first-child {
    margin-top: 0; }

#respond label small, #respond label .small {
  color: #999999;
  font-weight: normal; }

#respond input[type="text"],
#respond textarea {
  margin-bottom: 0; }

#respond .col_one_third,
#respond .col_full {
  margin-bottom: 20px; }

.fb-comments,
.fb_iframe_widget,
.fb-comments > span,
.fb_iframe_widget > span,
.fb-comments > span > iframe,
.fb_iframe_widget > span > iframe {
  display: block !important;
  width: 100% !important;
  margin: 0; }

/* Post Elements
-----------------------------------------------------------------*/
img.alignleft,
div.alignleft {
  float: left;
  margin: 5px 20px 13px 0;
  max-width: 100%; }

div.alignleft > img, div.alignnone > img, div.aligncenter > img, div.alignright > img {
  display: block;
  float: none; }

img.alignnone,
img.aligncenter,
div.alignnone,
div.aligncenter {
  display: block;
  margin: 10px 0;
  float: none; }

img.aligncenter,
div.aligncenter,
div.aligncenter img {
  margin-left: auto;
  margin-right: auto;
  clear: both; }

img.alignright,
div.alignright {
  float: right;
  margin: 5px 0 13px 20px; }

.wp-caption {
  text-align: center;
  margin: 10px 20px 13px 20px;
  font-family: "Lato", sans-serif;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: italic; }
  .wp-caption img,
  .wp-caption img a {
    display: block;
    margin: 0; }

p.wp-caption-text {
  display: inline-block;
  margin: 10px 0 0 0;
  padding: 5px 10px;
  background-color: #EEE;
  border-radius: 50px; }
  .dark p.wp-caption-text {
    background-color: rgba(0, 0, 0, 0.2); }

.wp-smiley {
  max-height: 13px;
  margin: 0 !important; }

/*-----------------------------------------------------------------------------------
	Dark Scheme - sass/dark.scss
-----------------------------------------------------------------------------------*/
/* Dark Scheme - Bootstrap
-----------------------------------------------------------------*/
.dark .img-thumbnail {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.1); }

.dark hr {
  border-top-color: rgba(255, 255, 255, 0.1); }

.dark .page-header {
  border-bottom-color: rgba(255, 255, 255, 0.1); }

.dark blockquote {
  border-left-color: rgba(255, 255, 255, 0.2); }

.dark .blockquote-reverse,
.dark blockquote.pull-right {
  border-right-color: rgba(255, 255, 255, 0.2); }

.dark code {
  background-color: rgba(0, 0, 0, 0.3); }

.dark kbd {
  background-color: rgba(255, 255, 255, 0.1); }

.dark pre {
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.3);
  border-color: rgba(255, 255, 255, 0.15); }

.dark .table > thead > tr > th,
.dark .table > tbody > tr > th,
.dark .table > tfoot > tr > th,
.dark .table > thead > tr > td,
.dark .table > tbody > tr > td,
.dark .table > tfoot > tr > td {
  border-top-color: rgba(255, 255, 255, 0.1);
  color: #eeeeee; }

.dark .table > thead > tr > th {
  border-bottom-color: rgba(255, 255, 255, 0.1); }

.dark .table > tbody + tbody {
  border-top-color: rgba(255, 255, 255, 0.1); }

.dark .table .table {
  background-color: rgba(0, 0, 0, 0.2); }

.dark .table-bordered {
  border-color: rgba(255, 255, 255, 0.1); }

.dark .table-bordered > thead > tr > th,
.dark .table-bordered > tbody > tr > th,
.dark .table-bordered > tfoot > tr > th,
.dark .table-bordered > thead > tr > td,
.dark .table-bordered > tbody > tr > td,
.dark .table-bordered > tfoot > tr > td {
  border-color: rgba(255, 255, 255, 0.1); }

.table-striped > tbody > tr:nth-child(odd),
.dark .table-striped > tbody > tr:nth-child(odd) > td,
.dark .table-striped > tbody > tr:nth-child(odd) > th {
  background-color: rgba(0, 0, 0, 0.05); }

.dark .table-hover > tbody > tr:hover > td,
.dark .table-hover > tbody > tr:hover > th {
  background-color: rgba(0, 0, 0, 0.1); }

.dark .table > thead > tr > td.active,
.dark .table > tbody > tr > td.active,
.dark .table > tfoot > tr > td.active,
.dark .table > thead > tr > th.active,
.dark .table > tbody > tr > th.active,
.dark .table > tfoot > tr > th.active,
.dark .table > thead > tr.active > td,
.dark .table > tbody > tr.active > td,
.dark .table > tfoot > tr.active > td,
.dark .table > thead > tr.active > th,
.dark .table > tbody > tr.active > th,
.dark .table > tfoot > tr.active > th {
  background-color: rgba(0, 0, 0, 0.1); }

.dark .table-hover > tbody > tr > td.active:hover,
.dark .table-hover > tbody > tr > th.active:hover,
.dark .table-hover > tbody > tr.active:hover > td,
.dark .table-hover > tbody > tr.active:hover > th {
  background-color: rgba(255, 255, 255, 0.1); }

.dark legend {
  color: #dddddd;
  border-bottom-color: rgba(255, 255, 255, 0.1); }

.dark output {
  color: #999999; }

.dark .form-control:not(.not-dark),
.dark .sm-form-control:not(.not-dark) {
  color: #999999;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.25); }
  .dark .form-control:not(.not-dark) option,
  .dark .sm-form-control:not(.not-dark) option {
    background-color: #383838; }
  .dark .form-control:not(.not-dark):active, .dark .form-control:not(.not-dark):focus,
  .dark .sm-form-control:not(.not-dark):active,
  .dark .sm-form-control:not(.not-dark):focus {
    background-color: rgba(0, 0, 0, 0.3);
    border-color: rgba(0, 0, 0, 0.25) !important; }
  .dark .form-control:not(.not-dark)::-webkit-input-placeholder,
  .dark .sm-form-control:not(.not-dark)::-webkit-input-placeholder {
    color: #666666; }
  .dark .form-control:not(.not-dark)::-moz-placeholder,
  .dark .sm-form-control:not(.not-dark)::-moz-placeholder {
    color: #666666; }
  .dark .form-control:not(.not-dark):-ms-input-placeholder,
  .dark .sm-form-control:not(.not-dark):-ms-input-placeholder {
    color: #666666; }
  .dark .form-control:not(.not-dark)[disabled], .dark .form-control:not(.not-dark)[readonly],
  .dark .sm-form-control:not(.not-dark)[disabled],
  .dark .sm-form-control:not(.not-dark)[readonly] {
    background-color: rgba(255, 255, 255, 0.1); }

.dark fieldset[disabled] .form-control:not(.not-dark),
.dark fieldset[disabled] .sm-form-control:not(.not-dark) {
  background-color: rgba(255, 255, 255, 0.1); }

.dark fieldset[disabled] .btn-default, .dark fieldset[disabled] .btn-default:hover, .dark fieldset[disabled] .open .dropdown-toggle.btn-default, .dark .open fieldset[disabled] .dropdown-toggle.btn-default, .dark fieldset[disabled] .btn-default:focus, .dark fieldset[disabled] .btn-default:active, .dark fieldset[disabled] .btn-default.active {
  color: #BBB;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1); }

.dark .sm-form-control.error:not(.not-dark) {
  border-color: #E42C3E !important; }
  .dark .sm-form-control.error:not(.not-dark)::-webkit-input-placeholder {
    color: rgba(228, 44, 62, 0.6); }
  .dark .sm-form-control.error:not(.not-dark)::-moz-placeholder {
    color: rgba(228, 44, 62, 0.6); }
  .dark .sm-form-control.error:not(.not-dark):-ms-input-placeholder {
    color: rgba(228, 44, 62, 0.6); }
  .dark .sm-form-control.error:not(.not-dark) .input-group {
    color: #E42C3E; }

.dark .btn:hover, .dark .btn:focus {
  color: #FFF; }

.dark .btn-default, .dark fieldset[disabled] .btn-default:hover, .dark fieldset[disabled] .open .dropdown-toggle.btn-default, .dark .open fieldset[disabled] .dropdown-toggle.btn-default, .dark fieldset[disabled] .btn-default:focus, .dark fieldset[disabled] .btn-default:active, .dark fieldset[disabled] .btn-default.active {
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.15); }
  .dark .btn-default:hover, .dark fieldset[disabled] .btn-default:hover, .dark fieldset[disabled] .open .dropdown-toggle.btn-default, .dark .open fieldset[disabled] .dropdown-toggle.btn-default, .dark .open .dropdown-toggle.btn-default, .dark .btn-default:focus, .dark fieldset[disabled] .btn-default:focus, .dark .btn-default:active, .dark fieldset[disabled] .btn-default:active {
    color: #FFF;
    background-color: rgba(0, 0, 0, 0.3);
    border-color: rgba(255, 255, 255, 0.2); }
  .dark .btn-default.disabled, .dark fieldset[disabled] .disabled.btn-default:hover, .dark fieldset[disabled] .open .disabled.dropdown-toggle.btn-default, .dark .open fieldset[disabled] .disabled.dropdown-toggle.btn-default, .dark fieldset[disabled] .disabled.btn-default:focus, .dark fieldset[disabled] .disabled.btn-default:active, .dark fieldset[disabled] .disabled.btn-default.active, .dark .btn-default[disabled], .dark fieldset[disabled] [disabled].btn-default:hover, .dark fieldset[disabled] .open [disabled].dropdown-toggle.btn-default, .dark .open fieldset[disabled] [disabled].dropdown-toggle.btn-default, .dark fieldset[disabled] [disabled].btn-default:focus, .dark fieldset[disabled] [disabled].btn-default:active, .dark fieldset[disabled] [disabled].btn-default.active {
    color: #BBB;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.1); }
  .dark .btn-default .badge, .dark fieldset[disabled] .btn-default:hover .badge, .dark fieldset[disabled] .open .dropdown-toggle.btn-default .badge, .dark .open fieldset[disabled] .dropdown-toggle.btn-default .badge, .dark fieldset[disabled] .btn-default:focus .badge, .dark fieldset[disabled] .btn-default:active .badge, .dark fieldset[disabled] .btn-default.active .badge {
    background-color: rgba(255, 255, 255, 0.1); }

.dark .btn-link:hover, .dark .btn-link:focus {
  color: #eeeeee; }

.dark .dropdown-menu {
  background-color: #333333;
  border-color: #3f3f3f; }
  .dark .dropdown-menu .divider {
    background-color: rgba(255, 255, 255, 0.1); }
  .dark .dropdown-menu > li > a {
    color: #dddddd; }
    .dark .dropdown-menu > li > a:hover, .dark .dropdown-menu > li > a:focus {
      color: #eeeeee;
      background-color: rgba(255, 255, 255, 0.1); }

.dark .input-group-text,
.dark .input-group-btn .btn-default, .dark .input-group-btn fieldset[disabled] .btn-default:hover, .dark fieldset[disabled] .input-group-btn .btn-default:hover, .dark .input-group-btn fieldset[disabled] .open .dropdown-toggle.btn-default, .dark fieldset[disabled] .open .input-group-btn .dropdown-toggle.btn-default, .dark .input-group-btn .open fieldset[disabled] .dropdown-toggle.btn-default, .dark .open fieldset[disabled] .input-group-btn .dropdown-toggle.btn-default, .dark .input-group-btn fieldset[disabled] .btn-default:focus, .dark fieldset[disabled] .input-group-btn .btn-default:focus, .dark .input-group-btn fieldset[disabled] .btn-default:active, .dark fieldset[disabled] .input-group-btn .btn-default:active, .dark .input-group-btn fieldset[disabled] .btn-default.active, .dark fieldset[disabled] .input-group-btn .btn-default.active {
  color: #999999;
  background-color: rgba(0, 0, 0, 0.35);
  border-color: rgba(0, 0, 0, 0.25); }

.dark .input-group-btn .btn-default, .dark .input-group-btn fieldset[disabled] .btn-default:hover, .dark fieldset[disabled] .input-group-btn .btn-default:hover, .dark .input-group-btn fieldset[disabled] .open .dropdown-toggle.btn-default, .dark fieldset[disabled] .open .input-group-btn .dropdown-toggle.btn-default, .dark .input-group-btn .open fieldset[disabled] .dropdown-toggle.btn-default, .dark .open fieldset[disabled] .input-group-btn .dropdown-toggle.btn-default, .dark .input-group-btn fieldset[disabled] .btn-default:focus, .dark fieldset[disabled] .input-group-btn .btn-default:focus, .dark .input-group-btn fieldset[disabled] .btn-default:active, .dark fieldset[disabled] .input-group-btn .btn-default:active, .dark .input-group-btn fieldset[disabled] .btn-default.active, .dark fieldset[disabled] .input-group-btn .btn-default.active {
  color: #FFF; }
  .dark .input-group-btn .btn-default:hover, .dark .input-group-btn fieldset[disabled] .btn-default:hover, .dark fieldset[disabled] .input-group-btn .btn-default:hover, .dark .input-group-btn .open .dropdown-toggle.btn-default, .dark .open .input-group-btn .dropdown-toggle.btn-default {
    background-color: rgba(0, 0, 0, 0.3); }

.dark .nav > li > a:hover,
.dark .nav > li > a:focus,
.dark .nav .open > a,
.dark .nav .open > a:hover,
.dark .nav .open > a:focus {
  background-color: rgba(0, 0, 0, 0.2); }

.dark .nav .nav-divider {
  background-color: rgba(255, 255, 255, 0.1); }

.dark .nav-tabs {
  border-bottom-color: rgba(255, 255, 255, 0.15); }

.dark .nav-tabs > li > a:hover {
  border-color: rgba(255, 255, 255, 0.2);
  border-bottom: none; }

.dark .nav-tabs.nav-justified > li > a:hover,
.dark .nav-tabs-justified > li > a:hover {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }

.dark .nav-tabs > li.active > a,
.dark .nav-tabs > li.active > a:hover,
.dark .nav-tabs > li.active > a:focus {
  color: #dddddd;
  background-color: #383838;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom-color: #383838; }

.dark .nav-tabs.nav-justified > .active > a,
.dark .nav-tabs.nav-justified > .active > a:hover,
.dark .nav-tabs.nav-justified > .active > a:focus {
  border-color: rgba(255, 255, 255, 0.15); }

@media (min-width: 768px) {
  .dark .nav-tabs.nav-justified > .active > a,
  .dark .nav-tabs.nav-justified > .active > a:hover,
  .dark .nav-tabs.nav-justified > .active > a:focus,
  .dark .nav-tabs-justified > .active > a,
  .dark .nav-tabs-justified > .active > a:hover,
  .dark .nav-tabs-justified > .active > a:focus {
    border-bottom-color: #383838; }
  .dark .nav-tabs-justified > li > a,
  .dark .nav-tabs.nav-justified > li > a {
    border-bottom-color: rgba(255, 255, 255, 0.15); } }

.dark .nav-tabs-justified > .active > a,
.dark .nav-tabs-justified > .active > a:hover,
.dark .nav-tabs-justified > .active > a:focus {
  border-color: rgba(255, 255, 255, 0.15); }

.dark .navbar-default {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1); }
  .dark .navbar-default .navbar-brand {
    color: #eeeeee; }
    .dark .navbar-default .navbar-brand:hover, .dark .navbar-default .navbar-brand:focus {
      color: #bbbbbb; }
  .dark .navbar-default .navbar-text {
    color: #999999; }
  .dark .navbar-default .navbar-nav > li > a {
    color: #bbbbbb; }
    .dark .navbar-default .navbar-nav > li > a:hover, .dark .navbar-default .navbar-nav > li > a:focus {
      color: #eeeeee;
      background-color: transparent; }
  .dark .navbar-default .navbar-nav > .active > a,
  .dark .navbar-default .navbar-nav > .active > a:hover,
  .dark .navbar-default .navbar-nav > .active > a:focus {
    color: #eeeeee;
    background-color: rgba(0, 0, 0, 0.25); }
  .dark .navbar-default .navbar-nav > .disabled > a,
  .dark .navbar-default .navbar-nav > .disabled > a:hover,
  .dark .navbar-default .navbar-nav > .disabled > a:focus {
    color: #999999; }
  .dark .navbar-default .navbar-nav > .open > a,
  .dark .navbar-default .navbar-nav > .open > a:hover,
  .dark .navbar-default .navbar-nav > .open > a:focus {
    background-color: rgba(0, 0, 0, 0.25);
    color: #eeeeee; }
  .dark .navbar-default .navbar-toggle {
    border-color: rgba(255, 255, 255, 0.15); }
    .dark .navbar-default .navbar-toggle:hover, .dark .navbar-default .navbar-toggle:focus {
      background-color: rgba(255, 255, 255, 0.15); }
    .dark .navbar-default .navbar-toggle .icon-bar {
      background-color: #BBB; }
  .dark .navbar-default .navbar-collapse,
  .dark .navbar-default .navbar-form {
    border-color: rgba(255, 255, 255, 0.15); }

@media (max-width: 767.98px) {
  .dark .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #bbbbbb; }
  .dark .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .dark .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #eeeeee; }
  .dark .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
  .dark .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
  .dark .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    background-color: rgba(0, 0, 0, 0.25);
    color: #eeeeee; }
  .dark .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
  .dark .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .dark .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #999999; } }

.dark .navbar-default .navbar-link {
  color: #bbbbbb; }
  .dark .navbar-default .navbar-link:hover {
    color: #eeeeee; }

.dark .pagination > li > a,
.dark .pagination > li > span {
  color: #BBB;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: #666; }
  .dark .pagination > li > a:hover, .dark .pagination > li > a:focus,
  .dark .pagination > li > span:hover,
  .dark .pagination > li > span:focus {
    color: #eeeeee;
    background-color: rgba(0, 0, 0, 0.1); }

.dark .pagination > .disabled > span,
.dark .pagination > .disabled > span:hover,
.dark .pagination > .disabled > span:focus,
.dark .pagination > .disabled > a,
.dark .pagination > .disabled > a:hover,
.dark .pagination > .disabled > a:focus,
.dark .pager .disabled > a,
.dark .pager .disabled > a:hover,
.dark .pager .disabled > a:focus,
.dark .pager .disabled > span {
  color: #999999;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: #777777; }

.dark .label-default {
  background-color: rgba(255, 255, 255, 0.2); }
  .dark .label-default[href]:hover, .dark .label-default[href]:focus {
    background-color: rgba(0, 0, 0, 0.2); }

.dark .badge {
  background-color: rgba(255, 255, 255, 0.2); }

.dark .jumbotron {
  background-color: rgba(255, 255, 255, 0.15); }

.dark .thumbnail {
  background-color: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.1); }
  .dark .thumbnail .caption {
    color: #eeeeee; }

.dark .progress {
  background-color: rgba(255, 255, 255, 0.15); }

.dark .list-group-item {
  text-shadow: none; }
  .dark .list-group-item:not(.list-group-item-primary):not(.list-group-item-secondary):not(.list-group-item-success):not(.list-group-item-info):not(.list-group-item-warning):not(.list-group-item-danger) {
    background-color: rgba(0, 0, 0, 0.2);
    border-color: #444;
    color: #bbbbbb; }
    .dark .list-group-item:not(.list-group-item-primary):not(.list-group-item-secondary):not(.list-group-item-success):not(.list-group-item-info):not(.list-group-item-warning):not(.list-group-item-danger) .list-group-item-heading {
      color: #dddddd; }

.dark a.list-group-item:not(.list-group-item-primary):not(.list-group-item-secondary):not(.list-group-item-success):not(.list-group-item-info):not(.list-group-item-warning):not(.list-group-item-danger):hover, .dark a.list-group-item:not(.list-group-item-primary):not(.list-group-item-secondary):not(.list-group-item-success):not(.list-group-item-info):not(.list-group-item-warning):not(.list-group-item-danger):focus {
  background-color: rgba(255, 255, 255, 0.05); }

.dark a.list-group-item:not(.list-group-item-primary):not(.list-group-item-secondary):not(.list-group-item-success):not(.list-group-item-info):not(.list-group-item-warning):not(.list-group-item-danger).active, .dark a.list-group-item:not(.list-group-item-primary):not(.list-group-item-secondary):not(.list-group-item-success):not(.list-group-item-info):not(.list-group-item-warning):not(.list-group-item-danger).active:hover, .dark a.list-group-item:not(.list-group-item-primary):not(.list-group-item-secondary):not(.list-group-item-success):not(.list-group-item-info):not(.list-group-item-warning):not(.list-group-item-danger).active:focus {
  color: #ffffff;
  background-color: #428bca;
  border-color: #428bca; }

.dark .card {
  background-color: rgba(0, 0, 0, 0.2); }
  .dark .card-footer {
    background-color: rgba(255, 255, 255, 0.1);
    border-top-color: rgba(255, 255, 255, 0.1); }
  .dark .card > .card-body + .table,
  .dark .card > .card-body + .table-responsive {
    border-top-color: rgba(255, 255, 255, 0.1); }
  .dark .card-group .card-heading + .collapse .card-body {
    border-top-color: rgba(255, 255, 255, 0.1); }
  .dark .card-group .card-footer + .collapse .card-body {
    border-bottom-color: rgba(255, 255, 255, 0.1); }
  .dark .card-default {
    border-color: rgba(255, 255, 255, 0.15); }
  .dark .card-default > .card-heading {
    color: #DDD;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.1); }
  .dark .card-default > .card-heading + .collapse .card-body {
    border-top-color: rgba(255, 255, 255, 0.15); }
  .dark .card-default > .card-footer + .collapse .card-body {
    border-bottom-color: rgba(255, 255, 255, 0.15); }

.dark .close {
  opacity: 1;
  color: rgba(0, 0, 0, 0.15);
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2); }
  .dark .close:hover, .dark .close:focus {
    color: rgba(0, 0, 0, 0.4); }

.dark .modal-content {
  border-color: #555555; }

.dark .modal-header {
  border-bottom-color: rgba(255, 255, 255, 0.15); }

.dark .modal-footer {
  border-top-color: rgba(255, 255, 255, 0.15); }

.dark .popover {
  background-color: #383838;
  border-color: #494949; }
  .dark .popover-title {
    background-color: rgba(255, 255, 255, 0.1);
    border-bottom-color: rgba(255, 255, 255, 0.075); }
  .dark .popover.top > .arrow {
    border-top-color: #494949; }
    .dark .popover.top > .arrow:after {
      border-top-color: #383838; }
  .dark .popover.right > .arrow {
    border-right-color: #494949; }
    .dark .popover.right > .arrow:after {
      border-right-color: #383838; }
  .dark .popover.bottom > .arrow {
    border-bottom-color: #494949; }
    .dark .popover.bottom > .arrow:after {
      border-bottom-color: #383838; }
  .dark .popover.left > .arrow {
    border-left-color: #494949; }
    .dark .popover.left > .arrow:after {
      border-left-color: #383838; }

/* ----------------------------------------------------------------

	event.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Events
-----------------------------------------------------------------*/
.events .entry,
.ievent {
  padding: 20px;
  background-color: #f9f9f9;
  border-bottom: 3px solid #eeeeee; }
  .dark .events .entry, .dark
  .ievent {
    background-color: rgba(0, 0, 0, 0.2);
    border-bottom-color: rgba(0, 0, 0, 0.1); }

.events .entry:after {
  display: none !important; }

.events .entry-image .entry-date,
.ievent .entry-image .entry-date {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #FFF;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  width: 60px;
  height: 64px;
  text-align: center;
  padding-top: 9px;
  font-size: 26px;
  line-height: 1; }
  .events .entry-image .entry-date span,
  .ievent .entry-image .entry-date span {
    display: block;
    font-size: 12px;
    margin-top: 7px; }

.events .entry-c,
.ievent .entry-c {
  padding: 5px 0; }

.single-event .entry-image img {
  border-radius: 0; }

.single-event .events-meta {
  font-size: 14px; }

.single-event .events-meta .iconlist li,
.parallax .iconlist li {
  margin: 5px 0; }

.single-event .entry-overlay,
.parallax .entry-overlay-meta {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.85);
  text-align: center;
  line-height: 80px;
  font-size: 22px;
  color: #FFF;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  z-index: 5; }

.single-event .col_full .entry-overlay,
.single-event .col_three_fourth .entry-overlay {
  position: relative; }

.single-event .countdown {
  display: inline-block;
  position: relative;
  top: 7px; }

.single-event .countdown-section {
  padding: 0 15px;
  border-left-color: rgba(255, 255, 255, 0.3);
  color: #CCC; }

.single-event .countdown-amount {
  color: #FFF; }

.parallax .entry-overlay,
.parallax .entry-overlay-meta {
  background: transparent;
  width: auto;
  text-align: right;
  bottom: 30px;
  left: auto;
  right: 30px; }
  @media (max-width: 575.98px) {
    .parallax .entry-overlay,
    .parallax .entry-overlay-meta {
      left: 50% !important;
      right: auto !important;
      width: 280px !important;
      transform: translateX(-50%); } }

@media (max-width: 575.98px) {
  .parallax .entry-overlay {
    width: 288px !important; } }

.parallax .entry-overlay-meta {
  padding: 20px;
  font-size: 14px;
  text-align: left;
  right: 38px;
  bottom: 130px;
  width: 368px;
  height: auto;
  line-height: inherit;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px; }

.parallax.overlay-left .entry-overlay,
.parallax.overlay-left .entry-overlay-meta {
  right: auto;
  left: 30px; }

.parallax.overlay-left .entry-overlay-meta {
  left: 38px; }

.parallax.overlay-center .entry-overlay,
.parallax.overlay-center .entry-overlay-meta {
  bottom: 100px;
  right: auto;
  left: 50%;
  margin-left: -192px; }

.parallax.overlay-center .entry-overlay-meta {
  top: 100px;
  bottom: auto;
  margin-left: -184px; }

.parallax .entry-overlay-meta h2, .parallax .entry-overlay-meta .h2 {
  font-size: 20px;
  text-transform: uppercase;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
  padding-bottom: 17px; }
  .parallax .entry-overlay-meta h2 a, .parallax .entry-overlay-meta .h2 a {
    color: #FFF; }
    .parallax .entry-overlay-meta h2 a:hover, .parallax .entry-overlay-meta .h2 a:hover {
      color: #DDD; }

.parallax .countdown {
  top: 0; }

.parallax .countdown-section {
  border-left: 0;
  width: 80px;
  height: 80px;
  margin: 0 8px;
  font-size: 13px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  padding-top: 14px; }
  @media (max-width: 575.98px) {
    .parallax .countdown-section {
      width: 64px;
      height: 64px;
      margin: 0 8px 0 0;
      font-size: 11px;
      padding: 0;
      padding-top: 12px; } }

.parallax .countdown-amount {
  font-size: 28px;
  margin-bottom: 6px; }
  @media (max-width: 575.98px) {
    .parallax .countdown-amount {
      font-size: 20px; } }

/* Individual Events
-----------------------------------------------------------------*/
.ievent {
  position: relative;
  margin-top: 20px; }
  .ievent:first-child {
    margin-top: 0; }
  .ievent .entry-image {
    width: 30%;
    margin-right: 20px; }
  .ievent .entry-content {
    margin-top: 20px; }
  .ievent .entry-title h2, .ievent .entry-title .h2 {
    font-size: 18px; }
  .ievent .entry-meta {
    margin-right: -10px; }
    .ievent .entry-meta li {
      font-size: 13px;
      margin-right: 10px;
      padding-left: 10px; }
      .ievent .entry-meta li:first-child {
        padding-left: 0; }

/* ----------------------------------------------------------------

	extras.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Styled Paginations
-----------------------------------------------------------------*/
.pagination {
  /* Pagination Circle */
  /* Pagination Rounded */
  /* Pagination 3d */
  /* Pagination Transparent */
  /* Pagination Inside Transparent */
  /* Pagination Pill */
  /* Pagination primary */
  /* Pagination secondary */
  /* Pagination success */
  /* Pagination danger */
  /* Pagination info */
  /* Pagination dark */
  /* Pagination light */ }
  .pagination .page-link {
    color: #1abc9c; }
  .pagination.pagination-circle .page-item .page-link {
    width: 35px;
    height: 35px;
    line-height: 33px;
    text-align: center;
    padding: 0;
    border-radius: 50%; }
  .pagination.pagination-circle .page-item:not(:first-child) .page-link {
    margin-left: 5px; }
  .pagination.pagination-circle.pagination-lg .page-item .page-link {
    width: 56px;
    height: 56px;
    line-height: 54px; }
  .pagination.pagination-circle.pagination-lg .page-item:not(:first-child) .page-link {
    margin-left: 8px; }
  .pagination.pagination-circle.pagination-sm .page-item .page-link {
    width: 26px;
    height: 26px;
    line-height: 24px; }
  .pagination.pagination-circle.pagination-sm .page-item:not(:first-child) .page-link {
    margin-left: 3px; }
  .pagination.pagination-rounded .page-item .page-link {
    border-radius: 4px; }
  .pagination.pagination-rounded .page-item:not(:first-child) .page-link {
    margin-left: 5px; }
  .pagination.pagination-rounded.pagination-lg .page-item:not(:first-child) .page-link {
    margin-left: 8px; }
  .pagination.pagination-rounded.pagination-sm .page-item:not(:first-child) .page-link {
    margin-left: 3px; }
  .pagination.pagination-3d .page-item .page-link {
    border-bottom: 3px solid rgba(0, 0, 0, 0.15) !important; }
  .pagination.pagination-transparent .page-item .page-link {
    border: 0; }
  .pagination.pagination-inside-transparent .page-item .page-link:not(:hover) {
    color: #222; }
  .pagination.pagination-inside-transparent .page-item:not(:first-child):not(:nth-child(2)):not(:nth-last-child(1)) .page-link {
    border-left: 0; }
  .pagination.pagination-inside-transparent .page-item.active .page-link,
  .pagination.pagination-inside-transparent .page-item .page-link:hover,
  .pagination.pagination-inside-transparent .page-item .page-link:focus {
    border-color: transparent !important; }
  .pagination.pagination-inside-transparent.pagination-button .page-item:not(:first-child):not(:nth-last-child(1)) .page-link {
    border: 0; }
  .pagination.pagination-pill .page-item:first-child .page-link {
    border-top-left-radius: 10rem;
    border-bottom-left-radius: 10rem; }
  .pagination.pagination-pill .page-item:last-child .page-link {
    border-top-right-radius: 10rem;
    border-bottom-right-radius: 10rem; }
  .pagination.pagination-primary .page-item .page-link {
    background-color: #007bff;
    border-color: #007bff;
    color: #FFF; }
  .pagination.pagination-primary .page-item.active .page-link,
  .pagination.pagination-primary .page-link:hover,
  .pagination.pagination-primary .page-link:focus {
    background-color: #222 !important;
    border-color: #222 !important;
    color: #FFF; }
  .pagination.pagination-secondary .page-item .page-link {
    background-color: #6c757d;
    border-color: #6c757d;
    color: #FFF; }
  .pagination.pagination-secondary .page-item.active .page-link,
  .pagination.pagination-secondary .page-link:hover,
  .pagination.pagination-secondary .page-link:focus {
    background-color: #222 !important;
    border-color: #222 !important;
    color: #FFF; }
  .pagination.pagination-success .page-item .page-link {
    background-color: #28a745;
    border-color: #28a745;
    color: #FFF; }
  .pagination.pagination-success .page-item.active .page-link,
  .pagination.pagination-success .page-link:hover,
  .pagination.pagination-success .page-link:focus {
    background-color: #222 !important;
    border-color: #222 !important;
    color: #FFF; }
  .pagination.pagination-danger .page-item .page-link {
    background-color: #dc3545;
    border-color: #dc3545;
    color: #FFF; }
  .pagination.pagination-danger .page-item.active .page-link,
  .pagination.pagination-danger .page-link:hover,
  .pagination.pagination-danger .page-link:focus {
    background-color: #222 !important;
    border-color: #222 !important;
    color: #FFF; }
  .pagination.pagination-info .page-item .page-link {
    background-color: #17a2b8;
    border-color: #17a2b8;
    color: #FFF; }
  .pagination.pagination-info .page-item.active .page-link,
  .pagination.pagination-info .page-link:hover,
  .pagination.pagination-info .page-link:focus {
    background-color: #222 !important;
    border-color: #222 !important;
    color: #FFF; }
  .pagination.pagination-dark .page-item .page-link {
    background-color: #343a40;
    border-color: #343a40;
    color: #FFF; }
  .pagination.pagination-light .page-item .page-link {
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    color: #222; }
  .pagination.pagination-light .page-item.active .page-link,
  .pagination.pagination-light .page-link:hover,
  .pagination.pagination-light .page-link:focus {
    background-color: #222 !important;
    border-color: #222 !important;
    color: #FFF; }

.table .primary {
  color: #007bff; }

.table .secondary {
  color: #6c757d; }

.table .success {
  color: #28a745; }

.table .info {
  color: #17a2b8; }

.table .warning {
  color: #ffc107; }

.table .danger {
  color: #dc3545; }

.table .dark {
  color: #343a40; }

.table .light {
  color: #f8f9fa; }

.btn-link {
  color: #1abc9c; }
  .btn-link:hover, .btn-link:focus {
    color: #222; }

.boot-tabs {
  margin-bottom: 20px !important; }

.carousel-control .icon-chevron-left,
.carousel-control .icon-chevron-right {
  position: absolute;
  top: 47.5%;
  z-index: 5;
  display: inline-block; }

.carousel-control .icon-chevron-left {
  left: 50%; }

.carousel-control .icon-chevron-right {
  right: 50%; }

.responsive-utilities th small, .responsive-utilities th .small {
  display: block;
  font-weight: 400;
  color: #999; }

.show-grid [class^=col-] {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #eeeeee;
  border: 1px solid #dddddd; }
  .dark .show-grid [class^=col-] {
    background-color: rgba(255, 255, 255, 0.05);
    border-color: rgba(255, 255, 255, 0.1); }

.img-circle {
  border-radius: 50% !important; }

.page-item.active .page-link,
.page-link:hover,
.page-link:focus {
  color: #FFF !important;
  background-color: #1abc9c !important;
  border-color: #1abc9c !important; }

label.radio, label.checkbox {
  padding-left: 20px; }

.input-group-lg > .input-group-addon {
  line-height: 1; }

/* ----------------------------------------------------------------
	Cookie Notification
-----------------------------------------------------------------*/
#cookie-notification {
  position: fixed;
  opacity: 0;
  top: auto;
  bottom: -70px;
  left: 0;
  width: 100%;
  padding: 20px 0;
  line-height: 24px;
  background-color: #EEE;
  color: #444;
  z-index: 99;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease; }
  .dark #cookie-notification, #cookie-notification.dark {
    background-color: rgba(0, 0, 0, 0.85);
    color: #eeeeee; }
  #cookie-notification .container {
    padding-right: 100px;
    position: relative; }
    @media (max-width: 767.98px) {
      #cookie-notification .container {
        padding-right: 15px; } }
  #cookie-notification .cookie-noti-btn {
    position: absolute;
    top: 0;
    left: auto;
    right: 0; }
    @media (max-width: 767.98px) {
      #cookie-notification .cookie-noti-btn {
        position: relative;
        left: 0;
        display: block;
        margin-top: 20px; } }

/* ----------------------------------------------------------------
	Stretched Layout
-----------------------------------------------------------------*/
.stretched #wrapper {
  width: 100%;
  margin: 0;
  box-shadow: none; }

/* ----------------------------------------------------------------
	Page Transitions
-----------------------------------------------------------------*/
body:not(.no-transition) #wrapper,
.animsition-overlay {
  position: relative;
  opacity: 0;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.css3-spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  text-align: center;
  background-color: white;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-perspective: 1000; }
  .dark .css3-spinner {
    background-color: #383838; }

.css3-spinner > div {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -9px;
  margin-left: 13px;
  width: 18px;
  height: 18px;
  background-color: #dddddd;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
  animation: bouncedelay 1.4s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }
  .dark .css3-spinner > div {
    background-color: #7d7d7d; }

.css3-spinner .css3-spinner-bounce1 {
  margin-left: -31px;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.css3-spinner .css3-spinner-bounce2 {
  margin-left: -9px;
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

@-webkit-keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

.css3-spinner > .css3-spinner-flipper {
  width: 32px;
  height: 32px;
  margin-top: -16px;
  margin-left: -16px;
  border-radius: 0;
  -webkit-animation: rotateplane 1.2s infinite ease-in-out;
  animation: rotateplane 1.2s infinite ease-in-out; }

@-webkit-keyframes rotateplane {
  0% {
    -webkit-transform: perspective(120px); }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg); }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg); } }

@keyframes rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg); }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg); }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }

.css3-spinner > .css3-spinner-double-bounce1,
.css3-spinner > .css3-spinner-double-bounce2 {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 50%;
  opacity: 0.6;
  -webkit-animation: cssspinnerbounce 2.0s infinite ease-in-out;
  animation: cssspinnerbounce 2.0s infinite ease-in-out; }

.css3-spinner > .css3-spinner-double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s; }

@-webkit-keyframes cssspinnerbounce {
  0%, 100% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1); } }

@keyframes cssspinnerbounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

.css3-spinner > .css3-spinner-rect1,
.css3-spinner > .css3-spinner-rect2,
.css3-spinner > .css3-spinner-rect3,
.css3-spinner > .css3-spinner-rect4,
.css3-spinner > .css3-spinner-rect5 {
  height: 30px;
  width: 6px;
  margin-top: -15px;
  margin-left: -21px;
  border-radius: 0;
  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
  animation: stretchdelay 1.2s infinite ease-in-out; }

.css3-spinner > .css3-spinner-rect2 {
  margin-left: -12px;
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s; }

.css3-spinner > .css3-spinner-rect3 {
  margin-left: -3px;
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s; }

.css3-spinner > .css3-spinner-rect4 {
  margin-left: 6px;
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.css3-spinner > .css3-spinner-rect5 {
  margin-left: 15px;
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

@-webkit-keyframes stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1); } }

@keyframes stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4); }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1); } }

.css3-spinner > .css3-spinner-cube1,
.css3-spinner > .css3-spinner-cube2 {
  width: 16px;
  height: 16px;
  border-radius: 0;
  margin-top: -20px;
  margin-left: -20px;
  -webkit-animation: cubemove 1.8s infinite ease-in-out;
  animation: cubemove 1.8s infinite ease-in-out; }

.css3-spinner > .css3-spinner-cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

@-webkit-keyframes cubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5); }
  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg); }
  75% {
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5); }
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5); }
  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg); }
  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg); }
  75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5); }
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg); } }

.css3-spinner > .css3-spinner-scaler {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  -webkit-animation: scaleout 1.0s infinite ease-in-out;
  animation: scaleout 1.0s infinite ease-in-out; }

@-webkit-keyframes scaleout {
  0% {
    -webkit-transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    opacity: 0; } }

@keyframes scaleout {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 0; } }

.css3-spinner > .css3-spinner-grid-pulse {
  width: 48px;
  height: 48px;
  margin-top: -24px;
  margin-left: -24px;
  background-color: transparent !important;
  -webkit-animation: none;
  animation: none; }

.css3-spinner-grid-pulse > div {
  background-color: #dddddd;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  margin: 2px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
  float: left;
  -webkit-animation-name: ball-grid-pulse;
  animation-name: ball-grid-pulse;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-delay: 0;
  animation-delay: 0; }
  .dark .css3-spinner-grid-pulse > div {
    background-color: #7d7d7d; }

.css3-spinner-grid-pulse > div:nth-child(1) {
  -webkit-animation-delay: 0.73s;
  animation-delay: 0.73s;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s; }

.css3-spinner-grid-pulse > div:nth-child(2) {
  -webkit-animation-delay: 0.32s;
  animation-delay: 0.32s;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s; }

.css3-spinner-grid-pulse > div:nth-child(3) {
  -webkit-animation-delay: 0.71s;
  animation-delay: 0.71s;
  -webkit-animation-duration: 0.88s;
  animation-duration: 0.88s; }

.css3-spinner-grid-pulse > div:nth-child(4) {
  -webkit-animation-delay: 0.62s;
  animation-delay: 0.62s;
  -webkit-animation-duration: 1.06s;
  animation-duration: 1.06s; }

.css3-spinner-grid-pulse > div:nth-child(5) {
  -webkit-animation-delay: 0.31s;
  animation-delay: 0.31s;
  -webkit-animation-duration: 0.62s;
  animation-duration: 0.62s; }

.css3-spinner-grid-pulse > div:nth-child(6) {
  -webkit-animation-delay: -0.14s;
  animation-delay: -0.14s;
  -webkit-animation-duration: 1.48s;
  animation-duration: 1.48s; }

.css3-spinner-grid-pulse > div:nth-child(7) {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
  -webkit-animation-duration: 1.47s;
  animation-duration: 1.47s; }

.css3-spinner-grid-pulse > div:nth-child(8) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  -webkit-animation-duration: 1.49s;
  animation-duration: 1.49s; }

.css3-spinner-grid-pulse > div:nth-child(9) {
  -webkit-animation-delay: 0.73s;
  animation-delay: 0.73s;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s; }

@-webkit-keyframes ball-grid-pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.7; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@keyframes ball-grid-pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.7; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

.css3-spinner > .css3-spinner-clip-rotate {
  width: 33px;
  height: 33px;
  margin-top: -17px;
  margin-left: -17px;
  background-color: transparent !important;
  -webkit-animation: none;
  animation: none; }

.css3-spinner-clip-rotate > div {
  border-radius: 100%;
  border: 2px solid #dddddd;
  border-bottom-color: transparent !important;
  height: 33px;
  width: 33px;
  background: transparent !important;
  -webkit-animation: rotate 1s 0s linear infinite;
  animation: rotate 1s 0s linear infinite; }
  .dark .css3-spinner-clip-rotate > div {
    border-color: #7d7d7d; }

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1); }
  50% {
    -webkit-transform: rotate(180deg) scale(0.6);
    transform: rotate(180deg) scale(0.6); }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1); } }

.css3-spinner > .css3-spinner-ball-rotate {
  width: 12px;
  height: 12px;
  margin-top: -6px;
  margin-left: -6px;
  background-color: transparent !important;
  -webkit-animation: ballrotate 1s 0s cubic-bezier(0.7, -0.13, 0.22, 0.86) infinite;
  animation: ballrotate 1s 0s cubic-bezier(0.7, -0.13, 0.22, 0.86) infinite; }

.css3-spinner-ball-rotate > div {
  background-color: #dddddd;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  position: relative; }
  .dark .css3-spinner-ball-rotate > div {
    background-color: #7d7d7d; }

.css3-spinner-ball-rotate > div:nth-child(1), .css3-spinner-ball-rotate > div:nth-child(3) {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  content: "";
  position: absolute;
  opacity: 0.7; }

.css3-spinner-ball-rotate > div:nth-child(1) {
  top: 0px;
  left: -22px; }

.css3-spinner-ball-rotate > div:nth-child(3) {
  top: 0px;
  left: 22px; }

@-webkit-keyframes ballrotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes ballrotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.css3-spinner > .css3-spinner-zig-zag {
  background-color: transparent !important;
  -webkit-transform: translate(-15px, -15px);
  -ms-transform: translate(-15px, -15px);
  transform: translate(-15px, -15px);
  -webkit-animation: none;
  animation: none; }

.css3-spinner-zig-zag > div {
  background-color: #dddddd;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  position: absolute;
  margin-left: 15px;
  top: 4px;
  left: -7px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }
  .dark .css3-spinner-zig-zag > div {
    background-color: #7d7d7d; }

.css3-spinner-zig-zag > div:first-child {
  -webkit-animation: ball-zig-deflect 1.5s 0s infinite linear;
  animation: ball-zig-deflect 1.5s 0s infinite linear; }

.css3-spinner-zig-zag > div:last-child {
  -webkit-animation: ball-zag-deflect 1.5s 0s infinite linear;
  animation: ball-zag-deflect 1.5s 0s infinite linear; }

@-webkit-keyframes ball-zig-deflect {
  17% {
    -webkit-transform: translate(-15px, -30px);
    transform: translate(-15px, -30px); }
  34% {
    -webkit-transform: translate(15px, -30px);
    transform: translate(15px, -30px); }
  50% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  67% {
    -webkit-transform: translate(15px, -30px);
    transform: translate(15px, -30px); }
  84% {
    -webkit-transform: translate(-15px, -30px);
    transform: translate(-15px, -30px); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@keyframes ball-zig-deflect {
  17% {
    -webkit-transform: translate(-15px, -30px);
    transform: translate(-15px, -30px); }
  34% {
    -webkit-transform: translate(15px, -30px);
    transform: translate(15px, -30px); }
  50% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  67% {
    -webkit-transform: translate(15px, -30px);
    transform: translate(15px, -30px); }
  84% {
    -webkit-transform: translate(-15px, -30px);
    transform: translate(-15px, -30px); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@-webkit-keyframes ball-zag-deflect {
  17% {
    -webkit-transform: translate(15px, 30px);
    transform: translate(15px, 30px); }
  34% {
    -webkit-transform: translate(-15px, 30px);
    transform: translate(-15px, 30px); }
  50% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  67% {
    -webkit-transform: translate(-15px, 30px);
    transform: translate(-15px, 30px); }
  84% {
    -webkit-transform: translate(15px, 30px);
    transform: translate(15px, 30px); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@keyframes ball-zag-deflect {
  17% {
    -webkit-transform: translate(15px, 30px);
    transform: translate(15px, 30px); }
  34% {
    -webkit-transform: translate(-15px, 30px);
    transform: translate(-15px, 30px); }
  50% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  67% {
    -webkit-transform: translate(-15px, 30px);
    transform: translate(-15px, 30px); }
  84% {
    -webkit-transform: translate(15px, 30px);
    transform: translate(15px, 30px); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

.css3-spinner > .css3-spinner-ball-scale-multiple {
  width: 60px;
  height: 60px;
  margin-left: -30px;
  margin-top: -30px;
  background-color: transparent !important;
  -webkit-animation: none;
  animation: none; }

.css3-spinner-ball-scale-multiple > div {
  background-color: #dddddd;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 60px;
  height: 60px;
  -webkit-animation: ball-scale-multiple 1.25s 0s linear infinite;
  animation: ball-scale-multiple 1.25s 0s linear infinite; }
  .dark .css3-spinner-ball-scale-multiple > div {
    background-color: #7d7d7d; }

.css3-spinner-ball-scale-multiple > div:nth-child(2) {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s; }

.css3-spinner-ball-scale-multiple > div:nth-child(3) {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s; }

@-webkit-keyframes ball-scale-multiple {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0; }
  5% {
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0; } }

@keyframes ball-scale-multiple {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0; }
  5% {
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0; } }

.css3-spinner > .css3-spinner-triangle-path {
  background-color: transparent !important;
  -webkit-transform: translate(-29.994px, -37.50938px);
  -ms-transform: translate(-29.994px, -37.50938px);
  transform: translate(-29.994px, -37.50938px);
  -webkit-animation: none;
  animation: none; }

.css3-spinner-triangle-path > div {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #dddddd; }
  .dark .css3-spinner-triangle-path > div {
    background-color: #7d7d7d; }

.css3-spinner-triangle-path > div:nth-child(1) {
  -webkit-animation: ball-triangle-path-1 2s 0s ease-in-out infinite;
  animation: ball-triangle-path-1 2s 0s ease-in-out infinite; }

.css3-spinner-triangle-path > div:nth-child(2) {
  -webkit-animation: ball-triangle-path-2 2s 0s ease-in-out infinite;
  animation: ball-triangle-path-2 2s 0s ease-in-out infinite; }

.css3-spinner-triangle-path > div:nth-child(3) {
  -webkit-animation: ball-triangle-path-3 2s 0s ease-in-out infinite;
  animation: ball-triangle-path-3 2s 0s ease-in-out infinite; }

.css3-spinner-triangle-path > div:nth-of-type(1) {
  top: 50px; }

.css3-spinner-triangle-path > div:nth-of-type(2) {
  left: 25px; }

.css3-spinner-triangle-path > div:nth-of-type(3) {
  top: 50px;
  left: 50px; }

@-webkit-keyframes ball-triangle-path-1 {
  33% {
    -webkit-transform: translate(25px, -50px);
    transform: translate(25px, -50px); }
  66% {
    -webkit-transform: translate(50px, 0px);
    transform: translate(50px, 0px); }
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px); } }

@keyframes ball-triangle-path-1 {
  33% {
    -webkit-transform: translate(25px, -50px);
    transform: translate(25px, -50px); }
  66% {
    -webkit-transform: translate(50px, 0px);
    transform: translate(50px, 0px); }
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px); } }

@-webkit-keyframes ball-triangle-path-2 {
  33% {
    -webkit-transform: translate(25px, 50px);
    transform: translate(25px, 50px); }
  66% {
    -webkit-transform: translate(-25px, 50px);
    transform: translate(-25px, 50px); }
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px); } }

@keyframes ball-triangle-path-2 {
  33% {
    -webkit-transform: translate(25px, 50px);
    transform: translate(25px, 50px); }
  66% {
    -webkit-transform: translate(-25px, 50px);
    transform: translate(-25px, 50px); }
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px); } }

@-webkit-keyframes ball-triangle-path-3 {
  33% {
    -webkit-transform: translate(-50px, 0px);
    transform: translate(-50px, 0px); }
  66% {
    -webkit-transform: translate(-25px, -50px);
    transform: translate(-25px, -50px); }
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px); } }

@keyframes ball-triangle-path-3 {
  33% {
    -webkit-transform: translate(-50px, 0px);
    transform: translate(-50px, 0px); }
  66% {
    -webkit-transform: translate(-25px, -50px);
    transform: translate(-25px, -50px); }
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px); } }

.css3-spinner > .css3-spinner-ball-pulse-sync {
  width: 70px;
  height: 34px;
  margin-left: -35px;
  margin-top: -17px;
  background-color: transparent !important;
  -webkit-animation: none;
  animation: none; }

.css3-spinner-ball-pulse-sync > div {
  display: inline-block;
  background-color: #dddddd;
  width: 14px;
  height: 14px;
  margin: 10px 3px 0;
  border-radius: 100%;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }
  .dark .css3-spinner-ball-pulse-sync > div {
    background-color: #7d7d7d; }

.css3-spinner-ball-pulse-sync > div:nth-child(0) {
  -webkit-animation: ball-pulse-sync 0.7s -0.21s infinite ease-in-out;
  animation: ball-pulse-sync 0.7s -0.21s infinite ease-in-out; }

.css3-spinner-ball-pulse-sync > div:nth-child(1) {
  -webkit-animation: ball-pulse-sync 0.7s -0.14s infinite ease-in-out;
  animation: ball-pulse-sync 0.7s -0.14s infinite ease-in-out; }

.css3-spinner-ball-pulse-sync > div:nth-child(2) {
  -webkit-animation: ball-pulse-sync 0.7s -0.07s infinite ease-in-out;
  animation: ball-pulse-sync 0.7s -0.07s infinite ease-in-out; }

.css3-spinner-ball-pulse-sync > div:nth-child(3) {
  -webkit-animation: ball-pulse-sync 0.7s 0s infinite ease-in-out;
  animation: ball-pulse-sync 0.7s 0s infinite ease-in-out; }

@-webkit-keyframes ball-pulse-sync {
  33% {
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 0.85; }
  66% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0.7; }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes ball-pulse-sync {
  33% {
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 0.85; }
  66% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0.7; }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

.css3-spinner > .css3-spinner-scale-ripple {
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
  background-color: transparent !important;
  -webkit-animation: none;
  animation: none; }

.css3-spinner-scale-ripple > div {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  top: 0;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 2px solid #dddddd;
  -webkit-animation: ball-scale-ripple-multiple 1.4s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
  animation: ball-scale-ripple-multiple 1.4s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8); }
  .dark .css3-spinner-scale-ripple > div {
    border-color: #7d7d7d; }

.css3-spinner-scale-ripple > div:nth-child(0) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

.css3-spinner-scale-ripple > div:nth-child(1) {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s; }

.css3-spinner-scale-ripple > div:nth-child(2) {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s; }

.css3-spinner-scale-ripple > div:nth-child(3) {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s; }

@-webkit-keyframes ball-scale-ripple-multiple {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 1; }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.7; }
  100% {
    opacity: 0.0; } }

@keyframes ball-scale-ripple-multiple {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 1; }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.7; }
  100% {
    opacity: 0.0; } }

/* ----------------------------------------------------------------
	Datepicker Component
-----------------------------------------------------------------*/
.datepicker td,
.datepicker th {
  padding: 5px; }

/* ----------------------------------------------------------------
	Star Ratings Component
-----------------------------------------------------------------*/
.rating-xl {
  font-size: 3rem !important; }

.rating-lg {
  font-size: 2.5rem !important; }

.rating-md {
  font-size: 2rem !important; }

.rating-sm {
  font-size: 1.75rem !important; }

.rating-xs {
  font-size: 1.25rem !important; }

.rating-container .star {
  margin: 0 2px !important; }

.rating-container .filled-stars {
  -webkit-text-stroke: 0px !important;
  text-shadow: none !important; }

/* ----------------------------------------------------------------
	Bootrstap Overwrite Component
-----------------------------------------------------------------*/
.list-group-item,
.card,
.card-body {
  background-color: white; }

.modal-content {
  background-color: white; }
  .dark .modal-content {
    background-color: #383838; }

.table th,
.table td {
  padding: 0.75rem;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 0; }

.table thead th {
  border-bottom: 2px solid rgba(0, 0, 0, 0.125); }

@media (max-width: 767.98px) {
  .table-responsive {
    border-color: rgba(255, 255, 255, 0.1); } }

.dark mark, .dark .mark {
  background: rgba(0, 0, 0, 0.5);
  color: #FFF; }

/* ----------------------------------------------------------------

	footer.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Footer
-----------------------------------------------------------------*/
#footer {
  position: relative;
  background-color: #eeeeee;
  border-top: 5px solid rgba(0, 0, 0, 0.2); }
  #footer .footer-widgets-wrap {
    position: relative;
    padding: 80px 0; }
  #footer.dark,
  .dark #footer {
    background-color: #333333;
    color: #cccccc;
    border-top-color: rgba(0, 0, 0, 0.2); }

.device-xl.sticky-footer #top-bar,
.device-lg.sticky-footer #top-bar {
  z-index: 299; }

.device-xl.sticky-footer #header,
.device-lg.sticky-footer #header {
  z-index: 199; }

.device-xl.sticky-footer #slider,
.device-xl.sticky-footer #page-submenu,
.device-xl.sticky-footer #page-title,
.device-xl.sticky-footer #content,
.device-xl.sticky-footer #slider,
.device-xl.sticky-footer #page-submenu,
.device-xl.sticky-footer #page-title,
.device-xl.sticky-footer #content,
.device-lg.sticky-footer #slider,
.device-lg.sticky-footer #page-submenu,
.device-lg.sticky-footer #page-title,
.device-lg.sticky-footer #content,
.device-lg.sticky-footer #slider,
.device-lg.sticky-footer #page-submenu,
.device-lg.sticky-footer #page-title,
.device-lg.sticky-footer #content {
  z-index: 2; }

.device-xl.sticky-footer #footer,
.device-lg.sticky-footer #footer {
  position: fixed;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1; }

.device-xl.sticky-footer:not(.stretched) #footer,
.device-lg.sticky-footer:not(.stretched) #footer {
  left: 50%;
  transform: translateX(-50%);
  width: 1220px; }

.device-lg.sticky-footer:not(.stretched) #footer {
  width: 1000px; }

.footer-widgets-wrap .col_full,
.footer-widgets-wrap .col_half,
.footer-widgets-wrap .col_one_third,
.footer-widgets-wrap .col_two_third,
.footer-widgets-wrap .col_three_fourth,
.footer-widgets-wrap .col_one_fourth,
.footer-widgets-wrap .col_one_fifth,
.footer-widgets-wrap .col_two_fifth,
.footer-widgets-wrap .col_three_fifth,
.footer-widgets-wrap .col_four_fifth,
.footer-widgets-wrap .col_one_sixth,
.footer-widgets-wrap .col_five_sixth {
  margin-bottom: 0; }

.dark .footer-widgets-wrap a {
  color: #cccccc; }
  .dark .footer-widgets-wrap a:hover {
    color: #999999; }

#copyrights {
  padding: 40px 0;
  background-color: #dddddd;
  font-size: 14px;
  line-height: 1.8; }
  .dark #copyrights {
    background-color: rgba(0, 0, 0, 0.2);
    color: rgba(255, 255, 255, 0.25);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); }
  #copyrights i.footer-icon {
    position: relative;
    top: 1px;
    font-size: 14px;
    width: 14px;
    text-align: center;
    margin-right: 3px; }
    .dark #copyrights i.footer-icon {
      color: rgba(255, 255, 255, 0.3); }
  #copyrights .col_half {
    margin-bottom: 0 !important; }
    @media (max-width: 767.98px) {
      #copyrights .col_half {
        text-align: center; } }
  #copyrights a:first-child {
    margin-left: 0; }
  #copyrights .tright a:last-child {
    margin-right: 0; }
    @media (max-width: 767.98px) {
      #copyrights .tright a:last-child {
        display: none; } }

.copyright-links a {
  display: inline-block;
  margin: 0 3px;
  color: #333333;
  border-bottom: 1px dotted #444444; }
  .dark .copyright-links a {
    color: rgba(255, 255, 255, 0.25);
    border-bottom-color: rgba(255, 255, 255, 0.25); }
  .copyright-links a:hover {
    color: #555555;
    border-bottom: 1px solid #666666; }
    .dark .copyright-links a:hover {
      color: rgba(255, 255, 255, 0.35);
      border-bottom-color: rgba(255, 255, 255, 0.35); }

.copyrights-menu {
  margin-bottom: 10px; }
  .copyrights-menu a {
    font-size: 14px;
    margin: 0 10px;
    border-bottom: 0 !important; }

.footer-logo {
  display: block;
  margin-bottom: 30px; }
  @media (max-width: 767.98px) {
    .footer-logo {
      margin-left: auto;
      margin-right: auto; } }

/* ----------------------------------------------------------------

	header.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	10. Header
-----------------------------------------------------------------*/
#header {
  position: relative;
  border-bottom: 1px solid #eeeeee; }
  #header:not(.transparent-header) {
    background-color: white; }
  .dark #header:not(.transparent-header), #header.dark:not(.transparent-header) {
    background-color: #333333;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05); }
  #header .container {
    position: relative; }
  #header.transparent-header {
    background: transparent;
    border-bottom: none;
    z-index: 199; }
    @media (max-width: 991.98px) {
      .dark #header.transparent-header, #header.transparent-header.dark {
        background-color: #333333;
        border-bottom-color: rgba(255, 255, 255, 0.1); } }
  #header.semi-transparent {
    background-color: rgba(255, 255, 255, 0.8); }
    .dark #header.semi-transparent.floating-header .container,
    #header.semi-transparent.dark.floating-header .container,
    .dark #header.semi-transparent, #header.semi-transparent.dark {
      background-color: rgba(56, 56, 56, 0.6); }
    @media (max-width: 991.98px) {
      .dark #header.semi-transparent, #header.semi-transparent.dark {
        background-color: #333333;
        border-bottom-color: rgba(255, 255, 255, 0.1); } }
  #header.transparent-header.floating-header {
    margin-top: 60px; }
    #header.transparent-header.floating-header .container {
      width: 1190px;
      max-width: 1190px;
      background-color: white;
      padding: 0 40px;
      border-radius: 2px; }
      @media (min-width: 992px) and (max-width: 1199.98px) {
        #header.transparent-header.floating-header .container {
          width: 1030px;
          padding: 0 30px; } }
      @media (max-width: 991.98px) {
        #header.transparent-header.floating-header .container {
          width: 750px;
          padding: 15px;
          border-radius: 0; } }
      @media (min-width: 576px) and (max-width: 767.98px) {
        #header.transparent-header.floating-header .container {
          width: 540px;
          padding: 0 15px; } }
      @media (max-width: 575.98px) {
        #header.transparent-header.floating-header .container {
          width: 310px;
          padding: 0 15px; } }
    #header.transparent-header.floating-header.sticky-header .container {
      width: 1140px;
      max-width: 1140px;
      padding: 0 15px; }
      @media (min-width: 992px) and (max-width: 1199.98px) {
        #header.transparent-header.floating-header.sticky-header .container {
          width: 970px;
          padding: 0 30px; } }
    #header.transparent-header.floating-header + #slider,
    #header.transparent-header.floating-header + #google-map {
      top: -160px;
      margin-bottom: -160px; }
  #header.transparent-header + #page-title.page-title-parallax .container {
    z-index: 5;
    padding-top: 100px; }
    @media (max-width: 991.98px) {
      #header.transparent-header + #page-title.page-title-parallax .container {
        padding-top: 0; } }

@media (max-width: 991.98px) {
  body:not(.primary-menu-open) #header.dark.semi-transparent.transparent-header-responsive {
    background-color: rgba(0, 0, 0, 0.6); } }

#header.transparent-header + #slider,
#header.transparent-header + #page-title.page-title-parallax,
#header.transparent-header + #google-map,
#slider + #header.transparent-header {
  top: -100px;
  margin-bottom: -100px; }

#header.full-header {
  border-bottom-color: #eeeeee; }
  .dark #header.full-header #header-wrap:not(.not-dark), #header.full-header.dark,
  #header.full-header.dark #header-wrap:not(.not-dark) {
    border-bottom-color: rgba(255, 255, 255, 0.15); }

body.stretched #header.full-header .container {
  width: 100%;
  max-width: none;
  padding: 0 30px; }
  @media (max-width: 991.98px) {
    #header.full-header .container {
      padding: 0 15px !important;
      margin: 0 auto;
      width: 750px !important; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    #header.full-header .container {
      width: 540px !important; } }
  @media (max-width: 575.98px) {
    #header.full-header .container {
      width: 100% !important;
      padding-left: 40px !important;
      padding-right: 40px !important; } }

#header.transparent-header.full-header #header-wrap {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

#slider + #header.transparent-header.full-header #header-wrap {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: none; }

.dark #slider + #header.full-header #header-wrap:not(.not-dark),
#slider + #header.full-header.dark #header-wrap:not(.not-dark) {
  border-top-color: rgba(255, 255, 255, 0.15); }

.dark #header.transparent-header.full-header:not(.sticky-header) #header-wrap:not(.not-dark),
#header.transparent-header.full-header.dark:not(.sticky-header) #header-wrap:not(.not-dark) {
  border-bottom-color: rgba(255, 255, 255, 0.2); }

.dark #slider + #header.transparent-header.full-header:not(.sticky-header) #header-wrap:not(.not-dark),
#slider + #header.transparent-header.full-header.dark:not(.sticky-header) #header-wrap:not(.not-dark) {
  border-top-color: rgba(255, 255, 255, 0.2); }

/* ----------------------------------------------------------------
	Logo
-----------------------------------------------------------------*/
#logo {
  position: relative;
  float: left;
  font-family: "Raleway", sans-serif;
  font-size: 36px;
  line-height: 100%;
  margin-right: 40px; }
  #header.full-header #logo {
    padding-right: 30px;
    margin-right: 30px;
    border-right: 1px solid #eeeeee; }
  #logo a {
    display: block;
    color: #444444; }
    #logo a.standard-logo {
      display: block; }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        #logo a.standard-logo {
          display: none; } }
    #logo a.retina-logo {
      display: none; }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        #logo a.retina-logo {
          display: block; } }
    .dark #logo a {
      color: white; }
  #logo img {
    display: block;
    max-width: 100%; }

#header.transparent-header.full-header #logo {
  border-right-color: rgba(0, 0, 0, 0.1); }

.dark #header.full-header #header-wrap:not(.not-dark) #logo,
#header.full-header.dark #header-wrap:not(.not-dark) #logo {
  border-right-color: rgba(255, 255, 255, 0.15); }

.dark #header.transparent-header.full-header:not(.sticky-header) #header-wrap:not(.not-dark) #logo,
#header.transparent-header.full-header.dark:not(.sticky-header) #header-wrap:not(.not-dark) #logo {
  border-right-color: rgba(255, 255, 255, 0.2); }

/* ----------------------------------------------------------------
	Header Right Area
-----------------------------------------------------------------*/
.header-extras {
  float: right;
  margin: 30px 0 0; }
  .header-extras li {
    float: left;
    margin-left: 20px;
    height: 40px;
    overflow: hidden;
    list-style: none; }
    .header-extras li i {
      margin-top: 3px !important; }
    .header-extras li:first-child {
      margin-left: 0; }
    .header-extras li .he-text {
      float: left;
      padding-left: 10px;
      font-weight: bold;
      font-size: 14px;
      line-height: 1.43; }
      .header-extras li .he-text span {
        display: block;
        font-weight: 400;
        color: #1abc9c; }

.dark #header-wrap:not(.not-dark) #primary-menu > ul > li > a,
.dark #header-wrap:not(.not-dark) #primary-menu > .container > ul > li > a,
.dark.overlay-menu #header-wrap:not(.not-dark) #primary-menu > ul > li > a,
.overlay-menu #primary-menu.dark > ul > li > a,
.dark.overlay-menu #header-wrap:not(.not-dark) #primary-menu > #overlay-menu-close,
.overlay-menu #primary-menu.dark > #overlay-menu-close,
.dark #header-wrap:not(.not-dark) #top-search a,
.dark #header-wrap:not(.not-dark) #top-cart a,
.dark #header-wrap:not(.not-dark) #side-panel-trigger a,
.dark #top-cart .top-cart-item-desc a,
body.top-search-open .dark #header-wrap:not(.not-dark) #top-search a,
.dark #header-wrap:not(.not-dark) #top-search a:hover,
.dark #header-wrap:not(.not-dark) #top-cart a:hover,
.dark #header-wrap:not(.not-dark) #side-panel-trigger a:hover,
.dark #header-wrap:not(.not-dark) #top-search form input {
  color: #eeeeee;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); }

.dark.overlay-menu #primary-menu:not(.not-dark) ul,
.dark.overlay-menu #primary-menu:not(.not-dark) ul,
.overlay-menu #primary-menu.dark ul,
.overlay-menu #primary-menu.dark ul {
  background-color: rgba(0, 0, 0, 0.9); }

/* ----------------------------------------------------------------
	Primary Menu
-----------------------------------------------------------------*/
#primary-menu {
  float: right; }
  #primary-menu ul.mobile-primary-menu {
    display: none; }

#header.full-header #primary-menu > ul {
  float: left;
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid #eeeeee; }

#header.transparent-header.full-header #primary-menu > ul {
  border-right-color: rgba(0, 0, 0, 0.1); }

.dark #header.full-header #header-wrap:not(.not-dark) #primary-menu > ul,
#header.full-header.dark #header-wrap:not(.not-dark) #primary-menu > ul {
  border-right-color: rgba(255, 255, 255, 0.15); }

.dark #header.transparent-header.full-header:not(.sticky-header) #header-wrap:not(.not-dark) #primary-menu > ul,
#header.transparent-header.full-header.dark:not(.sticky-header) #header-wrap:not(.not-dark) #primary-menu > ul {
  border-right-color: rgba(255, 255, 255, 0.2); }

#primary-menu-trigger,
#page-submenu-trigger {
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }

@media (max-width: 991.98px) {
  #primary-menu-trigger {
    opacity: 1;
    pointer-events: auto;
    top: 25px;
    margin-top: 0;
    left: 0;
    z-index: 1; }
    #primary-menu .container #primary-menu-trigger {
      top: 5px;
      left: 0; } }

@media (max-width: 575.98px) {
  #primary-menu-trigger {
    left: 20px; } }

#primary-menu ul {
  list-style: none;
  margin: 0; }
  #primary-menu ul li {
    position: relative; }
    #primary-menu ul li.mega-menu {
      position: inherit; }
    #primary-menu ul li > a {
      display: block;
      line-height: 22px;
      padding: 39px 15px;
      color: #444444;
      font-weight: bold;
      font-size: 13px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-family: "Raleway", sans-serif;
      -webkit-transition: margin 0.4s ease, padding 0.4s ease;
      -o-transition: margin 0.4s ease, padding 0.4s ease;
      transition: margin 0.4s ease, padding 0.4s ease; }
      #primary-menu ul li > a span {
        display: none; }
      #primary-menu ul li > a i {
        position: relative;
        top: -1px;
        font-size: 14px;
        width: 16px;
        text-align: center;
        margin-right: 6px;
        vertical-align: top; }
        #primary-menu ul li > a i.icon-angle-down:last-child {
          font-size: 12px;
          margin: 0 0 0 5px; }
  #primary-menu ul > li {
    float: left;
    margin-left: 2px; }

#primary-menu > ul {
  float: left; }
  #primary-menu > ul > li:first-child {
    margin-left: 0; }

#primary-menu ul li:hover > a,
#primary-menu ul li.current > a {
  color: #1abc9c; }

body.no-superfish #primary-menu li:hover > ul:not(.mega-menu-column),
body.no-superfish #primary-menu li:hover > .mega-menu-content {
  display: block; }

.dark #header-wrap:not(.not-dark) #primary-menu > ul > li:hover a,
.dark #header-wrap:not(.not-dark) #primary-menu > ul > li.current a,
.dark #header-wrap:not(.not-dark) #primary-menu > .container > ul > li:hover a,
.dark #header-wrap:not(.not-dark) #primary-menu > .container > ul > li.current a {
  color: #eeeeee; }

.dark.overlay-menu #header-wrap:not(.not-dark) #primary-menu > ul > li:hover > a,
.dark.overlay-menu #header-wrap:not(.not-dark) #primary-menu > ul > li.current > a,
.overlay-menu #primary-menu.dark > ul > li:hover > a,
.overlay-menu #primary-menu.dark > ul > li.current > a {
  color: #1abc9c; }

#primary-menu ul ul:not(.mega-menu-column),
#primary-menu ul li .mega-menu-content {
  display: none;
  position: absolute;
  width: 220px;
  background-color: white;
  box-shadow: 0px 13px 42px 11px rgba(0, 0, 0, 0.05);
  border: 1px solid #eeeeee;
  border-top: 2px solid #1abc9c;
  height: auto;
  z-index: 199;
  top: 100%;
  left: 0;
  margin: 0; }

#primary-menu ul li .mega-menu-content {
  display: -ms-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch; }

#primary-menu.on-click ul li .mega-menu-content {
  display: none; }

#primary-menu.on-click ul li .mega-menu-content.d-block {
  display: -ms-flex !important;
  display: flex !important; }

#primary-menu ul ul:not(.mega-menu-column) ul {
  top: -2px !important;
  left: 218px; }

#primary-menu ul ul.menu-pos-invert:not(.mega-menu-column),
#primary-menu ul li .mega-menu-content.menu-pos-invert {
  left: auto;
  right: 0; }

#primary-menu ul ul:not(.mega-menu-column) ul.menu-pos-invert {
  right: 218px; }

#primary-menu ul ul li {
  float: none;
  margin: 0; }

#primary-menu ul ul li:first-child {
  border-top: 0; }

#primary-menu ul ul li > a {
  font-size: 12px;
  font-weight: 700;
  color: #666;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 0;
  letter-spacing: 0;
  font-family: "Lato", sans-serif;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

#primary-menu ul ul li > a i {
  vertical-align: middle; }

#primary-menu ul ul li:hover > a {
  background-color: #f9f9f9;
  padding-left: 18px;
  color: #1abc9c; }

#primary-menu ul ul > li.sub-menu > a,
#primary-menu ul ul > li.sub-menu:hover > a {
  background-image: url("image/icons/submenu.png");
  background-position: right center;
  background-repeat: no-repeat; }
  .dark #primary-menu ul ul > li.sub-menu > a, .dark
  #primary-menu ul ul > li.sub-menu:hover > a {
    background-image: url("image/icons/submenu-dark.png"); }

@media (max-width: 991.98px) {
  .dark #primary-menu:not(.not-dark) > ul > li.sub-menu > a, .dark
  #primary-menu:not(.not-dark) > .container > ul > li.sub-menu > a {
    background-image: url("image/icons/submenu-dark.png"); } }

/* ----------------------------------------------------------------
	Mega Menu
-----------------------------------------------------------------*/
#primary-menu ul li .mega-menu-content,
#primary-menu ul li.mega-menu-small .mega-menu-content {
  width: 720px;
  max-width: 400px; }

#primary-menu ul li.mega-menu .mega-menu-content,
.floating-header.sticky-header #primary-menu ul li.mega-menu .mega-menu-content {
  margin: 0 15px;
  width: 1140px;
  max-width: none; }

.floating-header #primary-menu ul li.mega-menu .mega-menu-content {
  margin: 0;
  width: 1220px; }

body.stretched .container-fullwidth #primary-menu ul li.mega-menu .mega-menu-content {
  margin: 0 60px; }

body.stretched #header.full-header #primary-menu ul li.mega-menu .mega-menu-content {
  margin: 0 30px; }

#primary-menu ul li.mega-menu .mega-menu-content.style-2 {
  padding: 0 10px; }

#primary-menu ul li .mega-menu-content ul {
  display: block;
  position: relative;
  top: 0;
  min-width: inherit;
  border: 0;
  box-shadow: none;
  background-color: transparent; }

#primary-menu ul li .mega-menu-content ul:not(.megamenu-dropdown) {
  display: block !important;
  opacity: 1 !important;
  top: 0;
  left: 0; }

#primary-menu ul li .mega-menu-content ul.mega-menu-column {
  float: left;
  margin: 0;
  padding-left: 0;
  padding-right: 0; }

#primary-menu ul li .mega-menu-content ul.mega-menu-column.col-5 {
  width: 20%;
  flex: 0 0 20%;
  max-width: 20%;
  max-width: none; }

#primary-menu ul li .mega-menu-content ul:not(.mega-menu-column) {
  width: 100%; }

#primary-menu ul li .mega-menu-content ul.mega-menu-column:not(:first-child) {
  border-left: 1px solid #f2f2f2; }

#primary-menu ul li .mega-menu-content.style-2 ul.mega-menu-column {
  padding: 30px 20px; }

#primary-menu ul li .mega-menu-content.style-2 ul.mega-menu-column > li.mega-menu-title {
  margin-top: 20px; }

#primary-menu ul li .mega-menu-content.style-2 ul.mega-menu-column > li.mega-menu-title:first-child {
  margin-top: 0; }

#primary-menu ul li .mega-menu-content.style-2 ul.mega-menu-column > li.mega-menu-title > a {
  font-size: 13px;
  font-weight: bold;
  font-family: "Raleway", sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase !important;
  margin-bottom: 15px;
  color: #444444;
  padding: 0 !important;
  line-height: 1.3 !important; }

#primary-menu ul li .mega-menu-content.style-2 ul.mega-menu-column > li.mega-menu-title:hover > a {
  background-color: transparent; }

#primary-menu ul li .mega-menu-content.style-2 ul.mega-menu-column > li.mega-menu-title > a:hover {
  color: #1abc9c; }

#primary-menu ul li .mega-menu-content.style-2 ul.mega-menu-column > li.mega-menu-title.sub-menu > a,
#primary-menu ul li .mega-menu-content.style-2 ul.mega-menu-column > li.mega-menu-title.sub-menu:hover > a {
  background: none; }

#primary-menu ul li .mega-menu-content.style-2 li {
  border: 0; }

#primary-menu ul li .mega-menu-content.style-2 ul li > a {
  padding-left: 5px;
  padding-top: 7px;
  padding-bottom: 7px; }

#primary-menu ul li .mega-menu-content.style-2 ul li > a:hover {
  padding-left: 12px; }

.dark #primary-menu:not(.not-dark) ul li .mega-menu-content.style-2,
#primary-menu.dark ul li .mega-menu-content.style-2 {
  border-color: transparent;
  border-top-color: #1abc9c; }

.mega-menu-column .entry-meta {
  margin: 8px -10px 0 0 !important;
  border: none !important;
  padding: 0 !important; }

.mega-menu-column .entry-meta li {
  float: left !important;
  border: none !important;
  margin: 0 10px 0 0 !important; }

.dark #primary-menu:not(.not-dark) ul ul,
.dark #primary-menu:not(.not-dark) ul li .mega-menu-content,
#primary-menu.dark ul ul,
#primary-menu.dark ul li .mega-menu-content {
  background-color: #333333;
  border-color: #3f3f3f;
  border-top-color: #1abc9c; }

/* Primary Menu - Style 2
-----------------------------------------------------------------*/
#primary-menu.style-2 {
  float: none;
  max-width: none;
  border-top: 1px solid whitesmoke; }

#primary-menu.style-2 > div > ul {
  float: left; }

#primary-menu.style-2 > div > ul > li > a {
  padding-top: 19px;
  padding-bottom: 19px; }

#logo + #primary-menu.style-2 {
  border-top: 0; }

#primary-menu.style-2 > div #top-search,
#primary-menu.style-2 > div #top-cart,
#primary-menu.style-2 > div #side-panel-trigger {
  margin-top: 20px;
  margin-bottom: 20px; }

body:not(.device-md):not(.device-sm):not(.device-xs) #primary-menu.style-2.center > ul,
body:not(.device-md):not(.device-sm):not(.device-xs) #primary-menu.style-2.center > div {
  float: none;
  display: inline-block !important;
  width: auto;
  text-align: left; }

/* Primary Menu - Style 3
-----------------------------------------------------------------*/
#primary-menu.style-3 > ul > li > a {
  margin: 28px 0;
  padding-top: 11px;
  padding-bottom: 11px;
  border-radius: 2px; }

#primary-menu.style-3 > ul > li:hover > a {
  color: #444444;
  background-color: whitesmoke; }

#primary-menu.style-3 > ul > li.current > a {
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  background-color: #1abc9c; }

/* Primary Menu - Style 4
-----------------------------------------------------------------*/
#primary-menu.style-4 > ul > li > a {
  margin: 28px 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 2px;
  border: 1px solid transparent; }

#primary-menu.style-4 > ul > li:hover > a,
#primary-menu.style-4 > ul > li.current > a {
  border-color: #1abc9c; }

/* Primary Menu - Style 5
-----------------------------------------------------------------*/
#primary-menu.style-5 > ul {
  padding-right: 10px;
  margin-right: 5px;
  border-right: 1px solid #eeeeee; }

#primary-menu.style-5 > ul > li:not(:first-child) {
  margin-left: 15px; }

#primary-menu.style-5 > ul > li > a {
  padding-top: 25px;
  padding-bottom: 25px;
  line-height: 14px; }

#primary-menu.style-5 > ul > li > a i {
  display: block;
  width: auto;
  margin: 0 0 8px;
  font-size: 28px;
  line-height: 1; }

/* Primary Menu - Style 6
-----------------------------------------------------------------*/
#primary-menu.style-6 > ul > li > a {
  position: relative; }

#primary-menu.style-6 > ul > li > a:after,
#primary-menu.style-6 > ul > li.current > a:after,
#primary-menu.style-6 > div > ul > li > a:after,
#primary-menu.style-6 > div > ul > li.current > a:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  width: 0;
  border-top: 2px solid #1abc9c;
  -webkit-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease; }

#primary-menu.style-6 > ul > li.current > a:after,
#primary-menu.style-6 > ul > li:hover > a:after,
#primary-menu.style-6 > div > ul > li.current > a:after,
#primary-menu.style-6 > ul > li:hover > a:after {
  width: 100%; }

/* Primary Menu - Sub Title
-----------------------------------------------------------------*/
#primary-menu.sub-title > ul > li,
#primary-menu.sub-title.style-2 > div > ul > li {
  background-image: url("image/icons/menu-divider.png");
  background-position: right center;
  background-repeat: no-repeat;
  margin-left: 1px; }

#primary-menu.sub-title ul li:first-child {
  padding-left: 0;
  margin-left: 0; }

#primary-menu.sub-title > ul > li > a,
#primary-menu.sub-title > div > ul > li > a {
  line-height: 14px;
  padding: 27px 20px 32px;
  text-transform: uppercase;
  border-top: 5px solid transparent; }

#primary-menu.sub-title > ul > li > a span,
#primary-menu.sub-title > div > ul > li > a span {
  display: block;
  margin-top: 10px;
  line-height: 12px;
  font-size: 11px;
  font-weight: 400;
  color: #000;
  opacity: 0.7;
  text-transform: capitalize; }

#primary-menu.sub-title > ul > li:hover > a,
#primary-menu.sub-title > ul > li.current > a,
#primary-menu.sub-title > div > ul > li:hover > a,
#primary-menu.sub-title > div > ul > li.current > a {
  background-color: #1abc9c;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  border-top-color: rgba(0, 0, 0, 0.1); }

#primary-menu.sub-title > ul > li:hover > a span,
#primary-menu.sub-title > ul > li.current > a span,
#primary-menu.sub-title.style-2 > div > ul > li:hover > a span,
#primary-menu.sub-title.style-2 > div > ul > li.current > a span {
  color: #eeeeee; }

#primary-menu.sub-title.style-2 ul ul span {
  display: none; }

/* Primary Menu - Style 2 with Sub Title
-----------------------------------------------------------------*/
#primary-menu.sub-title.style-2 > div > ul > li {
  background-position: left center; }

#primary-menu.sub-title.style-2 > div #top-search,
#primary-menu.sub-title.style-2 > div #top-cart,
#primary-menu.sub-title.style-2 > div #side-panel-trigger {
  float: right;
  margin-top: 30px;
  margin-bottom: 30px; }

#primary-menu.sub-title.style-2 div ul li:first-child,
#primary-menu.sub-title.style-2 > div > ul > li:hover + li,
#primary-menu.sub-title.style-2 > div > ul > li.current + li {
  background-image: none; }

#primary-menu.sub-title.style-2 > div > ul > li > a {
  padding-top: 17px;
  padding-bottom: 22px; }

/* Primary Menu - Split Menu
-----------------------------------------------------------------*/
@media (min-width: 992px) {
  #header.split-menu #logo {
    position: absolute;
    width: 100%;
    text-align: center;
    margin: 0;
    float: none;
    height: 100px; }
  #header.split-menu #logo a.standard-logo {
    display: inline-block; } }
  @media only screen and (min-width: 992px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 992px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 992px) and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-width: 992px) and (min-device-pixel-ratio: 2), only screen and (min-width: 992px) and (min-resolution: 192dpi), only screen and (min-width: 992px) and (min-resolution: 2dppx) {
    #header.split-menu #logo a.standard-logo {
      display: none; } }

@media only screen and (min-width: 992px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 992px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 992px) and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-width: 992px) and (min-device-pixel-ratio: 2), only screen and (min-width: 992px) and (min-resolution: 192dpi), only screen and (min-width: 992px) and (min-resolution: 2dppx) {
  #header.split-menu #logo a.retina-logo {
    display: inline-block; } }

#header.split-menu #primary-menu {
  float: none;
  margin: 0; }

#header.split-menu #primary-menu > ul {
  z-index: 199; }

#header.split-menu #primary-menu > ul:nth-child(1) {
  float: left; }

#header.split-menu #primary-menu > ul:nth-child(2) {
  float: right; }

/* Primary Menu - Overlay Menu
-----------------------------------------------------------------*/
@media (min-width: 992px) {
  .overlay-menu:not(.top-search-open) #primary-menu-trigger {
    opacity: 1;
    pointer-events: auto;
    left: auto;
    right: 75px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin-top: -10px; }
  .overlay-menu:not(.top-search-open) .full-header #primary-menu-trigger {
    right: 90px; }
  .overlay-menu #primary-menu > #overlay-menu-close {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 25px;
    left: auto;
    right: 25px;
    width: 48px;
    height: 48px;
    line-height: 48px;
    font-size: 24px;
    text-align: center;
    color: #444;
    z-index: 300;
    -webkit-transform: translateY(-80px);
    -ms-transform: translateY(-80px);
    -o-transform: translateY(-80px);
    transform: translateY(-80px);
    -webkit-transition: opacity 0.4s ease, transform 0.45s 0.15s ease;
    -o-transition: opacity 0.4s ease, transform 0.45s 0.15s ease;
    transition: opacity 0.4s ease, transform 0.45s 0.15s ease; }
  .overlay-menu #primary-menu > ul {
    opacity: 0 !important;
    pointer-events: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100% !important;
    z-index: 299;
    background: rgba(255, 255, 255, 0.95);
    -webkit-transition: opacity 0.7s ease;
    -o-transition: opacity 0.7s ease;
    transition: opacity 0.7s ease; }
  .overlay-menu #primary-menu > ul.d-block {
    opacity: 1 !important;
    display: inherit !important;
    pointer-events: auto; }
  .overlay-menu #primary-menu > ul.d-block ~ #overlay-menu-close {
    opacity: 1;
    -webkit-transition: opacity 0.7s 0.4s ease, transform 0.45s 0.15s ease;
    -o-transition: opacity 0.7s 0.4s ease, transform 0.45s 0.15s ease;
    transition: opacity 0.7s 0.4s ease, transform 0.45s 0.15s ease;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    pointer-events: auto; }
  .overlay-menu #primary-menu > ul > li {
    float: none;
    text-align: center;
    max-width: 400px;
    margin: 0 auto; }
  .overlay-menu #primary-menu > ul > li > a {
    font-size: 24px;
    padding-top: 19px;
    padding-bottom: 19px;
    letter-spacing: 2px;
    text-transform: none;
    opacity: 0;
    -webkit-transform: translateY(-80px);
    -ms-transform: translateY(-80px);
    -o-transform: translateY(-80px);
    transform: translateY(-80px);
    -webkit-transition: opacity 0.7s 0.15s ease, transform 0.45s 0.15s ease, color 0.2s linear;
    -o-transition: opacity 0.7s 0.15s ease, transform 0.45s 0.15s ease, color 0.2s linear;
    transition: opacity 0.7s 0.15s ease, transform 0.45s 0.15s ease, color 0.2s linear; }
  .overlay-menu #primary-menu > ul.d-block > li > a {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

/* ----------------------------------------------------------------
	Top Search
-----------------------------------------------------------------*/
#top-search,
#top-cart,
#side-panel-trigger,
#top-account {
  float: right;
  margin: 40px 0 40px 15px; }

#top-search a,
#top-cart a,
#side-panel-trigger a,
#top-account a {
  display: block;
  position: relative;
  width: 14px;
  height: 14px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #444444;
  -webkit-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out; }

@media (max-width: 991.98px) {
  #top-search {
    margin: 0; } }

#top-search a {
  z-index: 11; }
  @media (max-width: 991.98px) {
    #top-search a {
      position: absolute;
      top: 42.5px;
      left: auto;
      right: 43px;
      margin: 0;
      -webkit-transition: margin 0.4s ease;
      -o-transition: margin 0.4s ease;
      transition: margin 0.4s ease; } }
  @media (max-width: 575.98px) {
    #top-search a {
      right: 40px; } }
  #top-search a i {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease; }

body.top-search-open #top-search a i.icon-search3,
#top-search a i.icon-line-cross {
  opacity: 0; }

body.top-search-open #top-search a i.icon-line-cross {
  opacity: 1;
  z-index: 11;
  font-size: 16px; }

#top-cart a:hover {
  color: #1abc9c; }

#top-search form {
  opacity: 0;
  z-index: -2;
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  padding: 0 15px;
  margin: 0;
  top: 0;
  left: 0;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }
  @media (max-width: 991.98px) {
    #top-search form {
      height: 100px !important; } }

body.top-search-open #top-search form {
  opacity: 1;
  z-index: 10; }

#top-search form input {
  box-shadow: none !important;
  pointer-events: none;
  border-radius: 0;
  border: 0;
  outline: 0 !important;
  font-size: 32px;
  padding: 10px 80px 10px 0;
  height: 100%;
  background-color: transparent;
  color: #333333;
  font-weight: 700;
  margin-top: 0 !important;
  font-family: "Raleway", sans-serif;
  letter-spacing: 2px; }
  @media (max-width: 991.98px) {
    #top-search form input {
      font-size: 24px; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    #top-search form input {
      padding-right: 60px; } }
  @media (max-width: 575.98px) {
    #top-search form input {
      padding-right: 95px; } }

.dark #header.transparent-header:not(.sticky-header) #header-wrap:not(.not-dark) form input,
#header.dark.transparent-header:not(.sticky-header) #header-wrap:not(.not-dark) form input {
  border-bottom-color: rgba(255, 255, 255, 0.15) !important; }

body:not(.device-md):not(.device-sm):not(.device-xs) #header.full-header #top-search form input {
  padding-left: 40px; }

body:not(.device-md):not(.device-sm):not(.device-xs) .container-fullwidth #top-search form input {
  padding-left: 60px; }

body.top-search-open #top-search form input {
  pointer-events: auto; }

body:not(.device-md):not(.device-sm):not(.device-xs) #header.transparent-header:not(.sticky-header):not(.full-header):not(
.floating-header) #top-search form input {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1); }

#top-search form input::-moz-placeholder {
  color: #555555;
  text-transform: uppercase; }
  .dark #header-wrap:not(.not-dark) #top-search form input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.6); }

#top-search form input:-ms-input-placeholder {
  color: #555555;
  text-transform: uppercase; }
  .dark #header-wrap:not(.not-dark) #top-search form input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6); }

#top-search form input::-webkit-input-placeholder {
  color: #555555;
  text-transform: uppercase; }
  .dark #header-wrap:not(.not-dark) #top-search form input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.6); }

#primary-menu .container #top-search form input,
.sticky-header #top-search form input {
  border: none !important; }

/* ----------------------------------------------------------------
	Top Cart
-----------------------------------------------------------------*/
#top-cart {
  position: relative;
  margin-right: 0; }
  @media (max-width: 991.98px) {
    #top-cart {
      position: absolute;
      top: 42.5px;
      left: auto;
      right: 15px;
      margin: 0;
      -webkit-transition: margin 0.4s ease;
      -o-transition: margin 0.4s ease;
      transition: margin 0.4s ease; } }
  @media (max-width: 575.98px) {
    #top-cart {
      display: none; } }

#top-cart > a > span {
  display: block;
  position: absolute;
  top: -7px;
  left: auto;
  right: -14px;
  font-size: 10px;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  background-color: #1abc9c;
  border-radius: 50%; }

#top-cart .top-cart-content {
  opacity: 0;
  z-index: -2;
  position: absolute;
  width: 280px;
  background-color: white;
  box-shadow: 0px 20px 50px 10px rgba(0, 0, 0, 0.05);
  border: 1px solid #eeeeee;
  border-top: 2px solid #1abc9c;
  top: 60px;
  right: -15px;
  left: auto;
  margin: -10000px 0 0;
  -webkit-transition: opacity 0.5s ease, top 0.4s ease;
  -o-transition: opacity 0.5s ease, top 0.4s ease;
  transition: opacity 0.5s ease, top 0.4s ease; }

.style-2 .container #top-cart .top-cart-content {
  top: 39px; }

.style-2.sub-title .container #top-cart .top-cart-content {
  top: 50px; }

#top-cart.top-cart-open .top-cart-content {
  opacity: 1;
  z-index: 11;
  margin-top: 0; }

.top-cart-title {
  padding: 12px 15px;
  border-bottom: 1px solid #eeeeee; }

.top-cart-title h4, .top-cart-title .h4 {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px; }

.top-cart-items {
  padding: 15px; }

.top-cart-item {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid whitesmoke; }

.top-cart-item:first-child {
  padding-top: 0;
  margin-top: 0;
  border-top: 0; }

.top-cart-item-image {
  float: left;
  margin-right: 15px;
  width: 48px !important;
  height: 48px !important;
  border: 2px solid #eeeeee;
  -webkit-transition: border-color 0.2s linear;
  -o-transition: border-color 0.2s linear;
  transition: border-color 0.2s linear; }

.top-cart-item-image a,
.top-cart-item-image img {
  display: block;
  width: 44px !important;
  height: 44px !important; }

.top-cart-item-image:hover {
  border-color: #1abc9c; }

.top-cart-item-desc {
  position: relative;
  overflow: hidden; }

.top-cart-item-desc a {
  max-width: 85%;
  display: block;
  text-align: left !important;
  font-size: 13px !important;
  width: auto !important;
  height: auto !important;
  color: #333 !important;
  font-weight: 700; }

.top-cart-item-desc a:hover {
  color: #1abc9c !important; }

.top-cart-item-desc span.top-cart-item-price {
  display: block;
  line-height: 20px;
  font-size: 12px !important;
  color: #999; }

.top-cart-item-desc span.top-cart-item-quantity {
  display: block;
  position: absolute;
  font-size: 12px !important;
  right: 0;
  top: 2px;
  color: #444; }

.top-cart-action {
  padding: 15px;
  border-top: 1px solid #eeeeee; }

.top-cart-action span.top-checkout-price {
  font-size: 20px;
  color: #1abc9c; }

/* ----------------------------------------------------------------
	Top Account
-----------------------------------------------------------------*/
#top-account {
  margin: 32px 0 33px 20px; }
  @media (max-width: 991.98px) {
    #top-account {
      position: absolute;
      top: 0;
      left: auto;
      right: 75px;
      margin: 33px 0 32px;
      -webkit-transition: margin 0.4s ease;
      -o-transition: margin 0.4s ease;
      transition: margin 0.4s ease; } }
  @media (max-width: 575.98px) {
    #top-account {
      right: 68px; } }
  #top-account a {
    width: auto;
    height: auto; }
    #top-account a span {
      margin-left: 5px;
      font-weight: 700; }
      @media (max-width: 991.98px) {
        #top-account a span {
          display: none; } }
    #top-account a i.icon-angle-down {
      margin-left: 5px;
      font-size: 12px; }
    @media (max-width: 575.98px) {
      #top-account a.btn {
        padding-left: 8px;
        padding-right: 8px; }
        #top-account a.btn:last-child {
          display: none; } }
  #top-account .dropdown-menu {
    padding: 10px 0; }
    #top-account .dropdown-menu li a {
      font-family: "Lato", sans-serif;
      font-weight: 400;
      text-align: left;
      line-height: 22px; }
      #top-account .dropdown-menu li a .badge {
        display: block;
        float: right;
        margin: 2px 0 0 0; }
      #top-account .dropdown-menu li a i:last-child {
        position: relative;
        top: 1px;
        margin-left: 5px; }

/* ----------------------------------------------------------------
	Sticky Header
-----------------------------------------------------------------*/
#header,
#header-wrap,
#logo img {
  height: 100px;
  -webkit-transition: height 0.4s ease, opacity 0.3s ease;
  -o-transition: height 0.4s ease, opacity 0.3s ease;
  transition: height 0.4s ease, opacity 0.3s ease; }

#header.sticky-style-2 {
  height: 161px; }

#header.sticky-style-3 {
  height: 181px; }

#header.sticky-style-2 #header-wrap {
  height: 60px; }

#header.sticky-style-3 #header-wrap {
  height: 80px; }

#primary-menu > ul > li > ul,
#primary-menu > ul > li > .mega-menu-content,
#primary-menu > ul > li.mega-menu > .mega-menu-content {
  -webkit-transition: top 0.4s ease;
  -o-transition: top 0.4s ease;
  transition: top 0.4s ease; }

#top-search,
#top-cart {
  -webkit-transition: margin 0.4s ease, opacity 0.3s ease;
  -o-transition: margin 0.4s ease, opacity 0.3s ease;
  transition: margin 0.4s ease, opacity 0.3s ease; }

body:not(.top-search-open) #header-wrap #logo,
body:not(.top-search-open) #primary-menu > ul,
body:not(.top-search-open) #primary-menu > .container > ul,
body:not(.top-search-open) #top-cart,
body:not(.top-search-open) #side-panel-trigger,
body:not(.top-search-open) #top-account {
  opacity: 1;
  -webkit-transition: height 0.4s ease, margin 0.4s ease, opacity 0.2s 0.2s ease;
  -o-transition: height 0.4s ease, margin 0.4s ease, opacity 0.2s 0.2s ease;
  transition: height 0.4s ease, margin 0.4s ease, opacity 0.2s 0.2s ease; }

body.top-search-open #header-wrap #logo,
body.top-search-open #primary-menu > ul,
body.top-search-open #primary-menu > .container > ul,
body.top-search-open #top-cart,
body.top-search-open #side-panel-trigger,
body.top-search-open #top-account {
  opacity: 0; }

#header.sticky-header.semi-transparent,
.dark #header.sticky-header.semi-transparent {
  background-color: transparent; }

#header-wrap {
  position: relative;
  z-index: 199;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000px;
  -webkit-backface-visibility: hidden; }

#header.sticky-header #header-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

#header.semi-transparent.sticky-header #header-wrap {
  background-color: rgba(255, 255, 255, 0.8); }
  .dark #header.semi-transparent.sticky-header #header-wrap, #header.semi-transparent.sticky-header #header-wrap.dark {
    background-color: rgba(56, 56, 56, 0.6); }

.dark #header.sticky-header:not(.transparent-header) #header-wrap:not(.not-dark),
.dark #header.sticky-header.transparent-header #header-wrap:not(.not-dark),
.dark #header.transparent-header.floating-header .container,
.dark #header.transparent-header.floating-header.sticky-header .container,
.dark#header.sticky-header:not(.transparent-header) #header-wrap:not(.not-dark),
.dark#header.sticky-header.transparent-header #header-wrap:not(.not-dark),
.dark#header.transparent-header.floating-header .container,
.dark#header.transparent-header.floating-header.sticky-header .container {
  background-color: #333333; }

.dark #header.semi-transparent.floating-header,
.dark #header.semi-transparent.floating-header.sticky-header .container,
#header.dark.sticky-header.semi-transparent,
#header.dark.semi-transparent.floating-header,
#header.dark.semi-transparent.floating-header.sticky-header .container {
  background-color: transparent; }

#header.sticky-header:not(.static-sticky),
#header.sticky-header:not(.static-sticky) #header-wrap,
#header.sticky-header:not(.static-sticky):not(.sticky-style-2):not(
.sticky-style-3) #logo img {
  height: 60px; }

#header.sticky-header:not(.static-sticky) #primary-menu > ul > li > a {
  padding-top: 19px;
  padding-bottom: 19px; }

#header.sticky-header:not(.static-sticky).sticky-style-2,
#header.sticky-header:not(.static-sticky).sticky-style-3 {
  height: 161px; }

#header.sticky-header:not(.static-sticky).sticky-style-2 #primary-menu,
#header.sticky-header:not(.static-sticky).sticky-style-3 #primary-menu {
  border-top: 0; }

#header.sticky-header:not(.static-sticky) #top-search,
#header.sticky-header:not(.static-sticky) #top-cart,
#header.sticky-header:not(.static-sticky) #side-panel-trigger {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

#header.sticky-header:not(.static-sticky) #top-account {
  margin-top: 12px !important;
  margin-bottom: 13px !important; }

#header.sticky-header:not(.static-sticky) #top-cart .top-cart-content {
  top: 39px; }

#header.sticky-header.sticky-style-2 #primary-menu {
  border-top: 0; }

/* Primary Menu - Style 3
-----------------------------------------------------------------*/
#header.sticky-header:not(.static-sticky) #primary-menu.style-3 > ul > li > a {
  margin: 0;
  border-radius: 0; }

/* Primary Menu - Style 4
-----------------------------------------------------------------*/
#header.sticky-header:not(.static-sticky) #primary-menu.style-4 > ul > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 10px 0; }

/* Primary Menu - Style 5
-----------------------------------------------------------------*/
#header.sticky-header:not(.static-sticky) #primary-menu.style-5 > ul > li {
  margin-left: 2px; }

#header.sticky-header:not(.static-sticky) #primary-menu.style-5 > ul > li:first-child {
  margin-left: 0; }

#header.sticky-header:not(.static-sticky) #primary-menu.style-5 > ul > li > a {
  line-height: 22px;
  padding-top: 19px;
  padding-bottom: 19px; }

#header.sticky-header:not(.static-sticky) #primary-menu.style-5 > ul > li > a > div {
  padding: 0; }

#header.sticky-header:not(.static-sticky) #primary-menu.style-5 > ul > li > a i {
  display: inline-block;
  width: 16px;
  top: -1px;
  margin: 0 6px 0 0;
  font-size: 14px;
  line-height: 22px; }

/* Primary Menu - Sub Title
-----------------------------------------------------------------*/
#header.sticky-header:not(.static-sticky) #primary-menu.sub-title > ul > li,
#header.sticky-header:not(.static-sticky) #primary-menu.sub-title.style-2 > div > ul > li {
  background: none !important;
  margin-left: 2px; }

#header.sticky-header:not(.static-sticky) #primary-menu.sub-title > ul > li:first-child,
#header.sticky-header:not(.static-sticky) #primary-menu.sub-title.style-2 > div > ul > li:first-child {
  margin-left: 0; }

#header.sticky-header:not(.static-sticky) #primary-menu.sub-title > ul > li > a,
#header.sticky-header:not(.static-sticky) #primary-menu.sub-title > div > ul > li > a {
  line-height: 22px;
  padding: 19px 15px;
  text-transform: none;
  font-size: 14px;
  border-top: 0; }

#header.sticky-header:not(.static-sticky) #primary-menu.sub-title ul li span {
  display: none; }

/* ----------------------------------------------------------------
	Page Menu
-----------------------------------------------------------------*/
#page-menu,
#page-menu-wrap {
  position: relative;
  height: 44px;
  line-height: 44px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); }

#page-menu-wrap {
  z-index: 99;
  background-color: #1abc9c;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden; }

#page-menu.sticky-page-menu #page-menu-wrap {
  position: fixed;
  z-index: 99;
  left: 0;
  width: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); }

#header.static-sticky ~ #page-menu.sticky-page-menu #page-menu-wrap,
#header.static-sticky ~ #content #page-menu.sticky-page-menu #page-menu-wrap {
  top: 100px; }

#header ~ #page-menu.sticky-page-menu #page-menu-wrap,
#header.no-sticky ~ #page-menu.sticky-page-menu #page-menu-wrap,
#header ~ #content #page-menu.sticky-page-menu #page-menu-wrap,
#header.no-sticky ~ #content #page-menu.sticky-page-menu #page-menu-wrap {
  top: 0; }

#header.sticky-header ~ #page-menu.sticky-page-menu #page-menu-wrap,
#header.sticky-header ~ #content #page-menu.sticky-page-menu #page-menu-wrap {
  top: 60px; }

#page-submenu-trigger {
  top: 0;
  margin-top: 0;
  left: auto;
  right: 15px;
  width: 40px;
  height: 44px;
  line-height: 44px; }

#page-menu .menu-title {
  float: left;
  color: #fff;
  font-size: 20px;
  font-weight: 300; }

#page-menu .menu-title span {
  font-weight: 700; }

#page-menu nav {
  position: relative;
  float: right; }

#page-menu nav ul {
  margin-bottom: 0;
  height: 44px; }

#page-menu nav li {
  position: relative;
  float: left;
  list-style: none; }

#page-menu nav li a {
  display: block;
  height: 28px;
  line-height: 28px;
  margin: 8px 4px;
  padding: 0 14px;
  color: #fff;
  font-size: 14px;
  border-radius: 14px; }

#page-menu nav li a .icon-angle-down {
  margin-left: 5px; }

body:not(.device-touch) #page-menu nav li a {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

#page-menu nav li:hover a,
#page-menu nav li.current a {
  background-color: rgba(0, 0, 0, 0.15); }

#page-menu li:hover ul {
  display: block; }

#page-menu ul ul {
  display: none;
  position: absolute;
  width: 150px;
  top: 44px;
  left: 0;
  z-index: 149;
  height: auto;
  background-color: #1abc9c; }

#page-menu ul ul ul {
  display: none !important; }

/* Disable Multi-Level Links */
#page-menu ul ul li {
  float: none; }

#page-menu ul ul a {
  height: auto;
  line-height: 22px;
  margin: 0;
  padding: 8px 14px;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.15); }

#page-menu ul ul li:hover a,
#page-menu ul ul li.current a {
  background-color: rgba(0, 0, 0, 0.3); }

/* Page Menu - Dots Style
-----------------------------------------------------------------*/
@media (min-width: 992px) {
  #page-menu.dots-menu,
  #page-menu.dots-menu #page-menu-wrap {
    position: fixed;
    z-index: 99;
    top: 50%;
    left: auto;
    right: 20px;
    width: 24px;
    height: auto;
    line-height: 1.5;
    background-color: transparent;
    text-shadow: none; }
  #page-menu.dots-menu #page-menu-wrap {
    position: relative;
    box-shadow: none; }
  #page-menu.dots-menu .menu-title {
    display: none; }
  #page-menu.dots-menu nav {
    float: none;
    width: 24px; }
  #page-menu.dots-menu nav ul {
    height: auto; }
  #page-menu.dots-menu nav ul li {
    float: none;
    width: 14px;
    height: 14px;
    margin: 10px 6px; }
  #page-menu.dots-menu nav li a {
    position: relative;
    width: 10px;
    height: 10px;
    line-height: 1.5;
    padding: 0;
    margin: 0 2px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    color: #777; }
  #page-menu.dots-menu nav li a:hover {
    background-color: rgba(0, 0, 0, 0.7); }
  #page-menu.dots-menu nav li.current a {
    background-color: #1abc9c; }
  #page-menu.dots-menu nav li div {
    position: absolute;
    width: auto;
    top: -11px;
    right: 25px;
    background-color: #1abc9c;
    color: #fff;
    padding: 5px 14px;
    white-space: nowrap;
    pointer-events: none;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    opacity: 0; }
  body:not(.device-touch) #page-menu.dots-menu nav li div {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
  #page-menu.dots-menu nav li div:after {
    position: absolute;
    content: '';
    top: 50%;
    margin-top: -6px;
    left: auto;
    right: -5px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 6px solid #1abc9c; }
  #page-menu.dots-menu nav li a:hover div {
    opacity: 1;
    right: 30px; } }

/* ----------------------------------------------------------------
	One Page
-----------------------------------------------------------------*/
.one-page-arrow {
  position: absolute;
  z-index: 3;
  bottom: 20px;
  left: 50%;
  margin-left: -16px;
  width: 32px;
  height: 32px;
  font-size: 32px;
  text-align: center;
  color: #222; }
  @media (max-width: 767.98px) {
    .one-page-arrow {
      display: none !important; } }

/* ----------------------------------------------------------------
	Side Header
-----------------------------------------------------------------*/
@media (min-width: 992px) {
  .side-header #header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 260px;
    height: 100vh;
    height: calc(100vh);
    background-color: white;
    border: none;
    border-right: 1px solid #eeeeee;
    overflow: hidden;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.065);
    -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.065);
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.065); }
  .side-header #header-wrap {
    width: 100%;
    height: 100%;
    overflow: auto; }
  .side-header #header .container {
    width: 100%;
    padding: 0 30px !important;
    margin: 0 !important; }
  .side-header #logo {
    float: none;
    margin: 60px 0 40px; }
  .side-header #logo img {
    height: auto !important; }
  .side-header #logo:not(.nobottomborder):after,
  .side-header #primary-menu:not(.nobottomborder):after {
    display: block;
    content: '';
    width: 20%;
    border-bottom: 1px solid #e5e5e5; }
  .side-header .center #logo:after {
    margin: 0 auto; }
  .side-header #primary-menu {
    margin-bottom: 30px; }
  .side-header #primary-menu:not(.nobottomborder):after {
    margin-top: 40px; }
  .side-header #primary-menu,
  .side-header #primary-menu ul {
    float: none;
    height: auto; }
  .side-header #primary-menu ul li {
    float: none;
    margin: 15px 0 0; }
  .side-header #primary-menu ul li:first-child {
    margin-top: 0; }
  .side-header #primary-menu ul li a {
    height: 25px;
    line-height: 25px;
    padding: 0;
    text-transform: uppercase; }
  .side-header #primary-menu ul li i {
    line-height: 24px; }
  .side-header #primary-menu ul ul {
    position: relative;
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    border: none;
    height: auto;
    z-index: 1;
    top: 0;
    left: 0;
    margin: 5px 0;
    padding-left: 12px; }
  .side-header #primary-menu ul ul ul {
    top: 0 !important;
    left: 0; }
  .side-header #primary-menu ul ul.menu-pos-invert {
    left: 0;
    right: auto; }
  .side-header #primary-menu ul ul ul.menu-pos-invert {
    right: auto; }
  .side-header #primary-menu ul ul li {
    margin: 0;
    border: 0; }
  .side-header #primary-menu ul ul li:first-child {
    border-top: 0; }
  .side-header #primary-menu ul ul a {
    font-size: 12px;
    height: auto !important;
    line-height: 24px !important;
    padding: 2px 0 !important; }
  .side-header #primary-menu ul ul li:hover > a {
    background-color: transparent !important;
    color: #444 !important; }
  .side-header #primary-menu ul > li.sub-menu > a,
  .side-header #primary-menu ul > li.sub-menu:hover > a {
    background-image: none !important; }
  .side-header #primary-menu ul > li.sub-menu > a:after {
    position: absolute;
    content: '\e7a5';
    top: 1px;
    right: 0;
    -webkit-transition: transform 0.2s linear;
    -o-transition: transform 0.2s linear;
    transition: transform 0.2s linear; }
  .side-header #primary-menu ul > li.sub-menu:hover > a:after {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg); }
  .side-header:not(.open-header) #wrapper {
    margin: 0 0 0 260px !important;
    width: auto !important; }
  .side-header:not(.open-header) #wrapper .container {
    width: auto;
    max-width: none;
    margin: 0 40px; }
  /* Side Header - Push Header
	-----------------------------------------------------------------*/
  .side-header.open-header.push-wrapper {
    overflow-x: hidden; }
  .side-header.open-header #header {
    left: -260px; }
  .side-header.open-header.side-header-open #header {
    left: 0; }
  .side-header.open-header #wrapper {
    left: 0; }
  .side-header.open-header.push-wrapper.side-header-open #wrapper {
    left: 260px; }
  #header-trigger {
    display: none; }
  .side-header.open-header #header-trigger {
    display: block;
    position: fixed;
    cursor: pointer;
    top: 20px;
    left: 20px;
    z-index: 11;
    font-size: 16px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background-color: #fff;
    text-align: center;
    border-radius: 2px; }
  .side-header.open-header.side-header-open #header-trigger {
    left: 280px; }
  .side-header.open-header #header-trigger i:nth-child(1),
  .side-header.open-header.side-header-open #header-trigger i:nth-child(2) {
    display: block; }
  .side-header.open-header.side-header-open #header-trigger i:nth-child(1),
  .side-header.open-header #header-trigger i:nth-child(2) {
    display: none; }
  .side-header.open-header #header,
  .side-header.open-header #header-trigger,
  .side-header.open-header.push-wrapper #wrapper {
    -webkit-transition: left 0.4s ease;
    -o-transition: left 0.4s ease;
    transition: left 0.4s ease; }
  /* Side Header - Right Aligned
	-----------------------------------------------------------------*/
  .side-header.side-header-right #header {
    left: auto;
    right: 0;
    border: none;
    border-left: 1px solid #eeeeee; }
  .side-header.side-header-right:not(.open-header) #wrapper {
    margin: 0 260px 0 0 !important; }
  .side-header.side-header-right.open-header #header {
    left: auto;
    right: -260px; }
  .side-header.side-header-right.open-header.side-header-open #header {
    left: auto;
    right: 0; }
  .side-header.side-header-right.open-header.push-wrapper.side-header-open #wrapper {
    left: -260px; }
  .side-header.side-header-right.open-header #header-trigger {
    left: auto;
    right: 20px; }
  .side-header.side-header-right.open-header.side-header-open #header-trigger {
    left: auto;
    right: 280px; }
  .side-header.side-header-right.open-header #header,
  .side-header.side-header-right.open-header #header-trigger {
    -webkit-transition: right 0.4s ease;
    -o-transition: right 0.4s ease;
    transition: right 0.4s ease; } }

/* ----------------------------------------------------------------
	Side Push Panel
-----------------------------------------------------------------*/
body.side-push-panel {
  overflow-x: hidden; }

#side-panel {
  position: fixed;
  top: 0;
  right: -300px;
  z-index: 399;
  width: 300px;
  height: 100%;
  background-color: whitesmoke;
  overflow: hidden;
  -webkit-backface-visibility: hidden; }
  body.dark #side-panel,
  body #side-panel.dark {
    background-color: #383838; }

body.side-panel-left #side-panel {
  left: -300px;
  right: auto; }

body.side-push-panel.stretched #wrapper,
body.side-push-panel.stretched #header.sticky-header .container {
  right: 0; }

body.side-panel-left.side-push-panel.stretched #wrapper,
body.side-panel-left.side-push-panel.stretched #header.sticky-header .container {
  left: 0;
  right: auto; }

#side-panel .side-panel-wrap {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 50px 40px; }

body.device-touch #side-panel .side-panel-wrap {
  overflow-y: scroll; }

#side-panel .widget {
  margin-top: 40px;
  padding-top: 40px;
  width: 100%;
  border-top: 1px solid #e5e5e5; }
  body .dark#side-panel .widget,
  body.dark #side-panel .widget {
    border-top-color: rgba(255, 255, 255, 0.1); }

#side-panel .widget:first-child {
  padding-top: 0;
  border-top: 0;
  margin: 0; }

body.side-panel-open #side-panel {
  right: 0; }

body.side-panel-left.side-panel-open #side-panel {
  left: 0;
  right: auto; }

body.side-push-panel.side-panel-open.stretched #wrapper,
body.side-push-panel.side-panel-open.stretched #header.sticky-header .container {
  right: 300px; }

body.side-push-panel.side-panel-open.stretched.device-xl .slider-parallax-inner,
body.side-push-panel.side-panel-open.stretched.device-lg .slider-parallax-inner {
  left: -300px; }

body.side-panel-left.side-push-panel.side-panel-open.stretched #wrapper,
body.side-panel-left.side-push-panel.side-panel-open.stretched #header.sticky-header .container,
body.side-panel-left.side-push-panel.side-panel-open.stretched .slider-parallax-inner {
  left: 300px;
  right: auto; }

#side-panel-trigger {
  display: block;
  cursor: pointer;
  z-index: 11;
  margin-right: 5px; }
  @media (max-width: 991.98px) {
    #side-panel-trigger {
      position: absolute;
      top: 0;
      left: auto;
      right: 71px;
      margin: 40px 0;
      -webkit-transition: margin 0.4s ease;
      -o-transition: margin 0.4s ease;
      transition: margin 0.4s ease; } }
  @media (max-width: 575.98px) {
    #side-panel-trigger {
      right: 68px; } }

#side-panel-trigger-close a {
  display: none; }
  body #side-panel.dark #side-panel-trigger-close a,
  body.dark #side-panel #side-panel-trigger-close a {
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff; }

#side-panel,
body.side-push-panel.stretched #wrapper,
body.side-push-panel.stretched #header .container {
  -webkit-transition: right 0.4s ease;
  -o-transition: right 0.4s ease;
  transition: right 0.4s ease; }

body.side-panel-left #side-panel,
body.side-panel-left.side-push-panel.stretched #wrapper,
body.side-panel-left.side-push-panel.stretched #header .container,
body.side-push-panel.stretched .slider-parallax-inner {
  -webkit-transition: left 0.4s ease;
  -o-transition: left 0.4s ease;
  transition: left 0.4s ease; }

.body-overlay {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }

body.side-panel-open .body-overlay {
  opacity: 1;
  z-index: 298;
  pointer-events: auto; }

body.side-panel-open:not(.device-xs):not(.device-sm):not(.device-md) .body-overlay:hover {
  cursor: url("image/icons/close.png") 15 15, default; }

.dark #primary-menu:not(.not-dark) ul ul li,
#primary-menu.dark ul ul li {
  border-top-color: #3f3f3f; }

.dark #primary-menu:not(.not-dark) ul ul li:first-child,
#primary-menu.dark ul ul li:first-child {
  border-top: 0; }

.dark #primary-menu:not(.not-dark) ul ul a,
#primary-menu.dark ul ul a {
  color: #999 !important; }

.dark #primary-menu:not(.not-dark) ul ul li:hover > a,
#primary-menu.dark ul ul li:hover > a {
  background-color: rgba(0, 0, 0, 0.1);
  color: #ccc !important; }

.dark #primary-menu ul li .mega-menu-content ul.mega-menu-column:not(:first-child),
#primary-menu.dark ul li .mega-menu-content ul.mega-menu-column:not(:first-child) {
  border-left-color: #3f3f3f; }

.dark #primary-menu:not(.not-dark) ul li .mega-menu-content.style-2 > ul > li.mega-menu-title > a,
.dark #primary-menu:not(.not-dark) ul li .mega-menu-content.style-2 > ul > li.mega-menu-title:hover > a,
#primary-menu.dark ul li .mega-menu-content.style-2 > ul > li.mega-menu-title > a,
#primary-menu.dark ul li .mega-menu-content.style-2 > ul > li.mega-menu-title:hover > a {
  color: #bbb !important;
  background: transparent; }

.dark #primary-menu:not(.not-dark) ul li .mega-menu-content.style-2 > ul > li.mega-menu-title > a:hover,
#primary-menu.dark ul li .mega-menu-content.style-2 > ul > li.mega-menu-title > a:hover {
  color: #ddd !important; }

.dark #primary-menu.style-2:not(.not-dark) {
  border-top-color: rgba(255, 255, 255, 0.1); }

.dark #primary-menu.style-3:not(.not-dark) > ul > li:hover > a,
#primary-menu.dark.style-3 > ul > li:hover > a {
  color: #eeeeee;
  background-color: rgba(255, 255, 255, 0.05); }

.dark #primary-menu.style-3:not(.not-dark) > ul > li.current > a,
#primary-menu.dark.style-3 > ul > li.current > a {
  color: #eeeeee; }

.dark #primary-menu.style-5:not(.not-dark) > ul,
#primary-menu.dark.style-5 > ul {
  border-right-color: rgba(255, 255, 255, 0.1); }

.dark #primary-menu.sub-title:not(.not-dark) ul li span,
#primary-menu.dark.sub-title ul li span {
  color: #999; }

.dark #primary-menu.sub-title:not(.not-dark) > ul > li:hover span,
.dark #primary-menu.sub-title:not(.not-dark) > ul > li.current span,
.dark #primary-menu.sub-title.style-2:not(.not-dark) > div > ul > li:hover span,
.dark #primary-menu.sub-title.style-2:not(.not-dark) > div > ul > li.current span,
#primary-menu.dark.sub-title > ul > li:hover span,
#primary-menu.dark.sub-title > ul > li.current span,
#primary-menu.dark.sub-title.style-2 > div > ul > li:hover span,
#primary-menu.dark.sub-title.style-2 > div > ul > li.current span {
  color: #eeeeee; }

.dark #top-cart .top-cart-content {
  background-color: #333333;
  border-color: #3f3f3f;
  border-top-color: #1abc9c; }

.dark .top-cart-title {
  border-bottom-color: #3f3f3f; }

.dark .top-cart-item {
  border-top-color: #3f3f3f; }

.dark .top-cart-item-image {
  border-color: #444; }

.dark .top-cart-item-image:hover {
  border-color: #1abc9c; }

.dark .top-cart-item-desc a:hover {
  color: #1abc9c !important; }

.dark .top-cart-item-desc span.top-cart-item-price {
  color: #aaa; }

.dark .top-cart-item-desc span.top-cart-item-quantity {
  color: #888; }

.dark .top-cart-action {
  border-top-color: #3f3f3f; }

.dark .one-page-arrow,
.one-page-arrow.dark {
  color: white; }

.dark.side-header #header {
  background-color: #333333;
  border-right-color: #3f3f3f; }

.dark.side-header #logo:not(.nobottomborder):after,
.dark.side-header #primary-menu:not(.nobottomborder):after {
  border-bottom-color: #3f3f3f; }

.dark.side-header #primary-menu:not(.not-dark) > ul > li > a,
.dark.side-header #primary-menu ul ul li:hover > a {
  color: #ddd !important; }

.dark.side-header.open-header #header-trigger {
  background-color: rgba(0, 0, 0, 0.3);
  color: #eeeeee;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); }

.dark.side-header.side-header-right #header {
  border-left-color: #3f3f3f; }

.dark.side-header #primary-menu ul ul a {
  color: #aaa !important; }

@media (min-width: 992px) and (max-width: 1199.98px) {
  #primary-menu.sub-title ul > li > a {
    padding-left: 15px;
    padding-right: 15px; }
  #primary-menu ul li.mega-menu .mega-menu-content,
  .floating-header.sticky-header #primary-menu ul li.mega-menu .mega-menu-content {
    width: 940px; }
  .floating-header #primary-menu ul li.mega-menu .mega-menu-content {
    width: 1030px; } }

@media (max-width: 991.98px) {
  .dark #primary-menu:not(.not-dark) ul li,
  .dark #primary-menu:not(.not-dark) ul ul,
  .dark #primary-menu:not(.not-dark) ul li .mega-menu-content,
  .dark #primary-menu:not(.not-dark) ul ul li,
  .dark #primary-menu:not(.not-dark) ul li .mega-menu-content ul ul,
  .dark #primary-menu:not(.not-dark) ul li .mega-menu-content.col-2 > ul,
  .dark #primary-menu:not(.not-dark) ul li .mega-menu-content.col-3 > ul,
  .dark #primary-menu:not(.not-dark) ul li .mega-menu-content.col-4 > ul,
  .dark #primary-menu:not(.not-dark) ul li .mega-menu-content.col-5 > ul,
  .dark #primary-menu:not(.not-dark) ul li .mega-menu-content.style-2 li {
    border-top-color: rgba(255, 255, 255, 0.1) !important; }
  body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu ul li,
  body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu ul ul,
  body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu ul li .mega-menu-content,
  body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu ul ul li,
  body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu ul li .mega-menu-content ul ul,
  body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu ul li .mega-menu-content.col-2 > ul,
  body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu ul li .mega-menu-content.col-3 > ul,
  body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu ul li .mega-menu-content.col-4 > ul,
  body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu ul li .mega-menu-content.col-5 > ul,
  body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu ul li .mega-menu-content.style-2 li {
    border-top-color: #eeeeee !important; }
  body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu > ul > li.current a,
  body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu > div > ul > li:hover a {
    color: #1abc9c !important; }
  body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu ul ul a,
  body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu > ul > li:hover ul a,
  body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu > ul > li.current ul a,
  body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu > div > ul > li:hover ul a,
  body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu > div > ul > li.current ul a {
    color: #444444 !important; }
  /* ----------------------------------------------------------------
		Header
	-----------------------------------------------------------------*/
  #header,
  #header-wrap,
  #header.sticky-style-2,
  #header.sticky-style-3,
  #header.sticky-style-2 #header-wrap,
  #header.sticky-style-3 #header-wrap {
    height: auto !important; }
  #header.sticky-style-2 #header-wrap,
  #header.sticky-style-3 #header-wrap {
    min-height: 60px; }
  #header.sticky-header #header-wrap {
    position: relative; }
  #header.transparent-header.floating-header {
    margin-top: 0; }
  #header.transparent-header + #page-title.page-title-parallax .container {
    padding-top: 0; }
  #header.transparent-header,
  #header.semi-transparent {
    background: #fff;
    border-bottom: 1px solid #f5f5f5;
    z-index: auto; }
  #header.dark.transparent-header,
  #header.dark.semi-transparent {
    background-color: #333;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  #header.transparent-header + #slider,
  #header.transparent-header + #page-title.page-title-parallax,
  #slider + #header.transparent-header,
  #header.transparent-header.floating-header + #slider,
  #header.transparent-header + #google-map {
    top: 0;
    margin-bottom: 0; }
  body:not(.primary-menu-open) #header.dark.transparent-header-responsive,
  body:not(.primary-menu-open) #header.transparent-header-responsive {
    background-color: transparent;
    border: none; }
  body:not(.primary-menu-open) #header.semi-transparent.transparent-header-responsive {
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1; }
  body:not(.primary-menu-open) #header.transparent-header-responsive + #slider,
  body:not(.primary-menu-open) #header.transparent-header-responsive + #page-title.page-title-parallax,
  body:not(.primary-menu-open) #slider + #header.transparent-header-responsive,
  body:not(.primary-menu-open) #header.transparent-header-responsive.floating-header + #slider,
  body:not(.primary-menu-open) #header.transparent-header-responsive + #google-map {
    top: -101px;
    margin-bottom: -101px; }
  #header-trigger,
  .top-advert,
  .header-extras {
    display: none; }
  #logo {
    display: block;
    height: 100px;
    float: none;
    margin: 0 auto 0 !important;
    max-width: none;
    text-align: center;
    border: 0 !important;
    padding: 0 !important; }
  /* ----------------------------------------------------------------
		Primary Menu
	-----------------------------------------------------------------*/
  #primary-menu {
    display: block;
    float: none; }
  #primary-menu > ul,
  #primary-menu > div > ul {
    display: none;
    float: none !important;
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    -webkit-transition: none;
    -o-transition: none;
    transition: none; }
  #primary-menu > div > ul {
    padding-top: 60px !important; }
  body:not(.top-search-open) #primary-menu-trigger {
    opacity: 1;
    -webkit-transition: opacity 0.2s 0.2s ease, top 0.4s ease;
    -o-transition: opacity 0.2s 0.2s ease, top 0.4s ease;
    transition: opacity 0.2s 0.2s ease, top 0.4s ease; }
  body.top-search-open #primary-menu-trigger {
    opacity: 0; }
  body.side-push-panel #side-panel-trigger-close a {
    display: block;
    position: absolute;
    z-index: 12;
    top: 0;
    left: auto;
    right: 0;
    width: 40px;
    height: 40px;
    font-size: 18px;
    line-height: 40px;
    color: #444;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 0 0 0 2px; }
  #primary-menu.style-5 > ul {
    padding-right: 0;
    margin-right: 0;
    border-right: 0; }
  #primary-menu ul li {
    float: none;
    margin: 0 !important;
    text-align: left !important;
    border-top: 1px solid #eee; }
  #header.split-menu #primary-menu:not(.mobile-menu-off-canvas) > ul:not(:first-child) > li:first-child {
    border-top: 1px solid #eee; }
  #primary-menu ul li:first-child {
    border-top: 0; }
  #primary-menu ul > li > a,
  #primary-menu.style-2 > div > ul > li > a {
    padding: 14px 5px !important;
    border: none !important;
    margin: 0 !important; }
  #primary-menu.style-5 > ul > li > a > div {
    line-height: 22px; }
  #primary-menu.style-5 > ul > li > a > div i {
    display: inline-block;
    margin: 0 6px 0 0;
    top: -1px;
    width: 16px;
    font-size: 14px;
    line-height: inherit; }
  #primary-menu.style-6 > ul > li > a:after,
  #primary-menu.style-6 > ul > li.current > a:after {
    display: none; }
  #primary-menu.sub-title > ul > li,
  #primary-menu.sub-title.style-2 > div > ul > li {
    background: none !important;
    margin-left: 0; }
  #primary-menu.sub-title > ul > li > a,
  #primary-menu.sub-title > div > ul > li > a {
    line-height: 22px;
    font-size: 13px;
    border-top: none; }
  #primary-menu.sub-title ul li > a span {
    display: none !important; }
  #primary-menu.sub-title ul li:hover > a,
  #primary-menu.sub-title ul li.current > a,
  #primary-menu.sub-title div ul > li:hover > a,
  #primary-menu.sub-title div ul > li.current > a,
  #primary-menu.style-3 ul li:hover > a,
  #primary-menu.style-3 ul li.current > a {
    background-color: transparent !important;
    color: #444;
    text-shadow: none; }
  #primary-menu ul:not(.windows-mobile-menu) li > a i.icon-angle-down:last-child {
    display: none; }
  #primary-menu > ul > li.sub-menu > a,
  #primary-menu > .container > ul > li.sub-menu > a {
    background-image: url("../image/icons/submenu.png");
    background-position: right center;
    background-repeat: no-repeat; }
  #primary-menu ul li.mega-menu {
    position: relative; }
  #primary-menu ul ul:not(.mega-menu-column),
  #primary-menu ul li .mega-menu-content {
    position: relative;
    width: auto;
    max-width: none;
    background: transparent !important;
    box-shadow: none;
    border: 0;
    border-top: 1px solid #eee;
    z-index: 1;
    top: 0;
    padding-left: 15px; }
  #primary-menu.on-click ul li .mega-menu-content.d-block {
    display: block !important; }
  #primary-menu ul li .mega-menu-content {
    padding-left: 0; }
  #primary-menu ul ul:not(.mega-menu-column) ul {
    top: 0 !important;
    left: 0; }
  #primary-menu ul ul.menu-pos-invert:not(.mega-menu-column),
  #primary-menu ul li .mega-menu-content.menu-pos-invert {
    left: 0;
    right: 0; }
  #primary-menu ul ul:not(.mega-menu-column) ul.menu-pos-invert {
    right: 0; }
  #primary-menu ul ul li {
    float: none;
    margin: 0;
    border-top: 1px solid #eee; }
  #primary-menu ul ul li > a {
    padding: 11px 5px !important;
    font-size: 13px;
    text-transform: none;
    font-weight: 400; }
  #primary-menu ul ul li:hover > a {
    background-color: transparent !important;
    font-weight: 700;
    padding-left: 5px;
    color: #1abc9c; }
  #primary-menu ul li .mega-menu-content,
  #primary-menu ul li.mega-menu-small .mega-menu-content,
  #primary-menu ul li.mega-menu .mega-menu-content,
  .floating-header.sticky-header #primary-menu ul li.mega-menu .mega-menu-content,
  .floating-header #primary-menu ul li.mega-menu .mega-menu-content {
    width: auto;
    margin: 0;
    top: 0; }
  .container-fullwidth #primary-menu ul li.mega-menu .mega-menu-content,
  #header.full-header #primary-menu ul li.mega-menu .mega-menu-content {
    width: auto !important;
    margin: 0 !important; }
  #primary-menu ul li.mega-menu .mega-menu-content.style-2 {
    padding: 0; }
  #primary-menu ul li .mega-menu-content ul {
    display: block !important;
    position: relative;
    opacity: 1 !important;
    top: 0;
    left: 0;
    min-width: inherit; }
  #primary-menu ul li .mega-menu-content ul ul {
    border-top: 1px solid #eee; }
  #primary-menu ul li .mega-menu-content ul.mega-menu-column {
    float: none;
    width: auto;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0;
    border-left: 0 !important;
    padding-left: 15px;
    border-top: 1px solid #eee; }
  #primary-menu ul li .mega-menu-content ul.mega-menu-column:first-child {
    border: 0; }
  #primary-menu ul li .mega-menu-content ul {
    width: 100% !important; }
  #primary-menu ul li .mega-menu-content.style-2 ul.mega-menu-column {
    padding: 0 0 0 15px; }
  #primary-menu ul li .mega-menu-content.style-2 ul.mega-menu-column > li.mega-menu-title {
    margin: 0; }
  #primary-menu ul li .mega-menu-content.style-2 ul.mega-menu-column > li.mega-menu-title > a {
    font-size: 13px;
    font-weight: 700;
    text-transform: none !important;
    padding: 11px 5px !important;
    line-height: 22px !important;
    color: #444 !important; }
  #primary-menu ul li .mega-menu-content.style-2 ul.mega-menu-column > li.mega-menu-title:hover > a {
    background-color: transparent;
    color: #1abc9c !important; }
  #primary-menu ul li .mega-menu-content.style-2 ul.mega-menu-column > li.mega-menu-title > a:hover {
    color: #1abc9c !important; }
  #primary-menu ul li .mega-menu-content.style-2 li {
    border-top: 1px solid #eee; }
  #primary-menu ul li .mega-menu-content.style-2 li:first-child {
    border-top: 0; }
  #primary-menu ul li .mega-menu-content.style-2 ul > li > a:hover {
    padding-left: 5px; }
  .mega-menu-column .widget {
    padding: 30px 0; }
  /* Top Search
	-----------------------------------------------------------------*/
  #primary-menu > div > #top-search a,
  #primary-menu > div > #top-cart,
  #primary-menu > div > #side-panel-trigger {
    margin: 20px 0 !important; }
  #primary-menu ul.windows-mobile-menu li.sub-menu {
    position: relative; }
  #primary-menu ul.windows-mobile-menu li.sub-menu a {
    background-image: none !important; }
  #primary-menu ul.windows-mobile-menu li.sub-menu a.wn-submenu-trigger {
    position: absolute;
    cursor: pointer;
    width: 32px;
    top: 0;
    right: 0;
    z-index: 1;
    padding: 0 !important;
    text-align: center !important; }
  #primary-menu ul.windows-mobile-menu .mega-menu-content a.wn-submenu-trigger {
    display: none; }
  #primary-menu ul.windows-mobile-menu li.sub-menu a.wn-submenu-trigger i {
    margin: 0 !important; }
  #header.sticky-style-3 #top-cart .top-cart-content {
    top: 40px; }
  /* Page Menu
	-----------------------------------------------------------------*/
  body:not(.sticky-responsive-pagemenu) #page-menu #page-menu-wrap {
    position: relative !important;
    top: 0 !important; }
  #page-menu.pagemenu-active #page-submenu-trigger {
    background-color: rgba(0, 0, 0, 0.2); }
  #page-menu.pagemenu-active nav {
    display: block; }
  #page-menu nav {
    display: none;
    position: absolute;
    float: none;
    width: 200px;
    top: 43px;
    left: auto;
    right: 15px;
    height: auto;
    background-color: #1abc9c;
    z-index: 11; }
    #page-menu nav ul {
      height: auto;
      background-color: rgba(0, 0, 0, 0.2); }
    #page-menu nav li {
      float: none; }
      #page-menu nav li a {
        height: 40px;
        line-height: 40px;
        margin: 0;
        padding: 0 15px;
        border-radius: 0; }
  #page-submenu-trigger {
    opacity: 1;
    pointer-events: auto;
    color: #fff; }
  /* Responsive Sticky Header
	-----------------------------------------------------------------*/
  .sticky-responsive-menu #header {
    min-height: 100px;
    -webkit-transition: min-height 0.4s ease;
    -o-transition: min-height 0.4s ease;
    transition: min-height 0.4s ease; }
  .sticky-responsive-menu #header-wrap {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .sticky-responsive-menu.device-touch #header,
  .sticky-responsive-menu.device-touch #header-wrap,
  .sticky-responsive-menu.device-touch #logo,
  .sticky-responsive-menu.device-touch #logo img,
  .sticky-responsive-menu.device-touch #top-search a,
  .sticky-responsive-menu.device-touch #primary-menu-trigger,
  .sticky-responsive-menu.device-touch #top-cart,
  .sticky-responsive-menu.device-touch #side-panel-trigger,
  .sticky-responsive-menu.device-touch #top-account {
    -webkit-transition: none !important;
    -o-transition: none !important;
    transition: none !important; }
  .sticky-responsive-menu #header.sticky-style-2,
  .sticky-responsive-menu #header.sticky-style-3 {
    min-height: 160px; }
  #header.responsive-sticky-header:not(.sticky-style-2):not(
  .sticky-style-3):not(.static-sticky) {
    min-height: 60px; }
  .responsive-sticky-header #header-wrap {
    z-index: 199;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    -webkit-backface-visibility: hidden; }
  .responsive-sticky-header:not(.static-sticky) #logo,
  .responsive-sticky-header:not(.static-sticky) #logo img {
    height: 60px; }
  .responsive-sticky-header:not(.static-sticky) #primary-menu-trigger {
    top: 5px; }
  .responsive-sticky-header #primary-menu > ul,
  .responsive-sticky-header #primary-menu > .container > ul {
    max-height: 300px;
    overflow-y: scroll;
    margin-bottom: 20px; }
  .responsive-sticky-header #primary-menu > ul,
  .responsive-sticky-header #primary-menu > .container > ul {
    margin-bottom: 20px !important; }
  .responsive-sticky-header:not(.static-sticky) #top-search a,
  .responsive-sticky-header:not(.static-sticky) #top-cart,
  .responsive-sticky-header:not(.static-sticky) #side-panel-trigger {
    margin: 0;
    top: 22.5px; }
  .responsive-sticky-header:not(.static-sticky) #top-search form {
    height: 60px !important; }
  .responsive-sticky-header:not(.static-sticky) #top-cart .top-cart-content {
    top: 39px; }
  /* Off Canvas Menu
	-----------------------------------------------------------------*/
  body:not(.sticky-responsive-menu) #primary-menu.mobile-menu-off-canvas > ul,
  body:not(.sticky-responsive-menu) #primary-menu.mobile-menu-off-canvas > div > ul {
    position: fixed;
    display: block;
    z-index: 499;
    width: 280px;
    height: 100%;
    height: calc(100vh);
    max-height: none !important;
    margin: 0 !important;
    left: 0 !important;
    top: 0 !important;
    padding: 35px 25px 60px !important;
    background-color: #fff;
    border-right: 1px solid #eee !important;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translate3d(-280px, 0, 0);
    -ms-transform: translate3d(-280px, 0, 0);
    -o-transform: translate3d(-280px, 0, 0);
    transform: translate3d(-280px, 0, 0);
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d; }
  body:not(.sticky-responsive-menu) #primary-menu.mobile-menu-off-canvas.from-right > ul,
  body:not(.sticky-responsive-menu) #primary-menu.mobile-menu-off-canvas.from-right > div > ul {
    left: auto !important;
    right: 0;
    border-right: 0 !important;
    border-left: 1px solid #eee !important;
    -webkit-transform: translate3d(280px, 0, 0);
    -ms-transform: translate3d(280px, 0, 0);
    -o-transform: translate3d(280px, 0, 0);
    transform: translate3d(280px, 0, 0); }
  body:not(.sticky-responsive-menu) #primary-menu.mobile-menu-off-canvas > ul,
  body:not(.sticky-responsive-menu) #primary-menu.mobile-menu-off-canvas > div > ul {
    -webkit-transition: -webkit-transform 0.3s ease-in-out, opacity 0.2s ease !important;
    -o-transition: -webkit-transform 0.3s ease-in-out, opacity 0.2s ease !important;
    transition: -webkit-transform 0.3s ease-in-out, opacity 0.2s ease !important; }
    body:not(.sticky-responsive-menu) #primary-menu.mobile-menu-off-canvas > ul.d-block,
    body:not(.sticky-responsive-menu) #primary-menu.mobile-menu-off-canvas > div > ul.d-block {
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0); } }

/* ----------------------------------------------------------------
	Retina CSS
-----------------------------------------------------------------*/
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 991.98px), only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 991.98px), only screen and (-o-min-device-pixel-ratio: 2 / 1) and (max-width: 991.98px), only screen and (min-device-pixel-ratio: 2) and (max-width: 991.98px), only screen and (min-resolution: 192dpi) and (max-width: 991.98px), only screen and (min-resolution: 2dppx) and (max-width: 991.98px) {
  #logo a.standard-logo {
    display: none; }
  #logo a.retina-logo {
    display: inline-block; } }

/*-----------------------------------------------------------------------------------
	helpers.scss
-----------------------------------------------------------------------------------*/
.uppercase {
  text-transform: uppercase !important; }

.lowercase {
  text-transform: lowercase !important; }

.capitalize {
  text-transform: capitalize !important; }

.nott {
  text-transform: none !important; }

.tright {
  text-align: right !important; }

.tleft {
  text-align: left !important; }

.fright {
  float: right !important; }

.fleft {
  float: left !important; }

.fnone {
  float: none !important; }

.ohidden {
  position: relative;
  overflow: hidden !important; }

.line,
.double-line {
  clear: both;
  position: relative;
  width: 100%;
  margin: 60px 0;
  border-top: 1px solid #eeeeee; }
  .line.dark,
  .dark .line,
  .double-line.dark,
  .dark
  .double-line {
    border-top-color: rgba(255, 255, 255, 0.1); }
  .line.line-sm,
  .double-line.line-sm {
    margin: 30px 0; }

span.middot {
  display: inline-block;
  margin: 0 5px; }

.double-line {
  border-top: 3px double #e5e5e5; }

.emptydiv {
  display: block !important;
  position: relative !important; }

.allmargin {
  margin: 50px !important; }

.leftmargin {
  margin-left: 50px !important; }

.rightmargin {
  margin-right: 50px !important; }

.topmargin {
  margin-top: 50px !important; }

.bottommargin {
  margin-bottom: 50px !important; }

.clear-bottommargin {
  margin-bottom: -50px !important; }

.allmargin-sm {
  margin: 30px !important; }

.leftmargin-sm {
  margin-left: 30px !important; }

.rightmargin-sm {
  margin-right: 30px !important; }

.topmargin-sm {
  margin-top: 30px !important; }

.bottommargin-sm {
  margin-bottom: 30px !important; }

.clear-bottommargin-sm {
  margin-bottom: -30px !important; }

.allmargin-lg {
  margin: 80px !important; }

.leftmargin-lg {
  margin-left: 80px !important; }

.rightmargin-lg {
  margin-right: 80px !important; }

.topmargin-lg {
  margin-top: 80px !important; }

.bottommargin-lg {
  margin-bottom: 80px !important; }

.clear-bottommargin-lg {
  margin-bottom: -80px !important; }

.nomargin {
  margin: 0 !important; }

.noleftmargin {
  margin-left: 0 !important; }

.norightmargin {
  margin-right: 0 !important; }

.notopmargin {
  margin-top: 0 !important; }

.nobottommargin {
  margin-bottom: 0 !important; }

.header-stick {
  margin-top: -50px !important; }

.content-wrap .header-stick {
  margin-top: -80px !important; }

.footer-stick {
  margin-bottom: -50px !important; }

.content-wrap .footer-stick {
  margin-bottom: -80px !important; }

.noborder {
  border: none !important; }

.noleftborder {
  border-left: none !important; }

.norightborder {
  border-right: none !important; }

.notopborder {
  border-top: none !important; }

.nobottomborder {
  border-bottom: none !important; }

.noradius {
  border-radius: 0; }

.col-padding {
  padding: 60px; }

.nopadding {
  padding: 0 !important; }

.noleftpadding {
  padding-left: 0 !important; }

.norightpadding {
  padding-right: 0 !important; }

.notoppadding {
  padding-top: 0 !important; }

.nobottompadding {
  padding-bottom: 0 !important; }

.noabsolute {
  position: relative !important; }

.noshadow {
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.notextshadow {
  text-shadow: none !important; }

.hidden {
  display: none !important; }

.nothidden {
  display: block !important; }

.inline-block {
  float: none !important;
  display: inline-block !important; }

.center {
  text-align: center !important; }

.divcenter {
  position: relative !important;
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important; }

.bgcolor,
.bgcolor #header-wrap {
  background-color: #1abc9c !important; }

.color {
  color: #1abc9c !important; }

.border-color {
  border-color: #1abc9c !important; }

.nobg {
  background: none !important; }

.nobgcolor {
  background-color: transparent !important; }

.t100 {
  font-weight: 100 !important; }

.t200 {
  font-weight: 200 !important; }

.t300 {
  font-weight: 300 !important; }

.t400 {
  font-weight: 400 !important; }

.t500 {
  font-weight: 500 !important; }

.t600 {
  font-weight: 600 !important; }

.t700 {
  font-weight: 700 !important; }

.ls-1 {
  letter-spacing: 1px !important; }

.ls-2 {
  letter-spacing: 2px !important; }

.ls-3 {
  letter-spacing: 3px !important; }

.ls-4 {
  letter-spacing: 4px !important; }

.ls-5 {
  letter-spacing: 5px !important; }

.noheight {
  height: 0 !important; }

.nolineheight {
  line-height: 0 !important; }

.font-body {
  font-family: "Lato", sans-serif; }

.font-primary {
  font-family: "Raleway", sans-serif; }

.font-secondary {
  font-family: "Crete Round", serif; }

.bgicon {
  display: block;
  position: absolute;
  bottom: -60px;
  right: -50px;
  font-size: 210px;
  color: rgba(0, 0, 0, 0.1); }

/* MOVING BG -  TESTIMONIALS */
.bganimate {
  -webkit-animation: BgAnimated 30s infinite linear;
  -moz-animation: BgAnimated 30s infinite linear;
  -ms-animation: BgAnimated 30s infinite linear;
  -o-animation: BgAnimated 30s infinite linear;
  animation: BgAnimated 30s infinite linear; }

@-webkit-keyframes BgAnimated {
  from {
    background-position: 0 0; }
  to {
    background-position: 0 400px; } }

@-moz-keyframes BgAnimated {
  from {
    background-position: 0 0; }
  to {
    background-position: 0 400px; } }

@-ms-keyframes BgAnimated {
  from {
    background-position: 0 0; }
  to {
    background-position: 0 400px; } }

@-o-keyframes BgAnimated {
  from {
    background-position: 0 0; }
  to {
    background-position: 0 400px; } }

@keyframes BgAnimated {
  from {
    background-position: 0 0; }
  to {
    background-position: 0 400px; } }

.input-block-level {
  display: block;
  width: 100% !important;
  min-height: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.vertical-middle {
  height: auto !important;
  left: 0;
  max-width: none !important;
  z-index: 2; }
  .vertical-middle + .video-wrap {
    z-index: 1 !important; }
    @media (max-width: 991.98px) {
      .vertical-middle + .video-wrap {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; } }
  @media (max-width: 991.98px) {
    .container.vertical-middle {
      width: 100% !important; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .container.vertical-middle {
      width: 100% !important; }
      .full-screen:not(.force-full-screen) .container.vertical-middle {
        width: 540px !important; } }

.magnific-max-width .mfp-content {
  max-width: 800px; }

/* ----------------------------------------------------------------

	pagetitle.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Page Title
-----------------------------------------------------------------*/
#page-title {
  position: relative;
  padding: 50px 0;
  background-color: whitesmoke;
  border-bottom: 1px solid #eeeeee; }
  .dark #page-title {
    background-color: #282828;
    border-bottom-color: #3f3f3f; }
  @media (max-width: 767.98px) {
    #page-title {
      padding: 20px 0 25px;
      text-align: center; } }
  #page-title .container {
    position: relative; }
  #page-title h1, #page-title .h1 {
    padding: 0;
    margin: 0;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 1px;
    color: #333333;
    font-size: 28px;
    text-transform: uppercase; }
    .dark #page-title h1, .dark #page-title .h1 {
      color: rgba(255, 255, 255, 0.9) !important; }
    @media (max-width: 767.98px) {
      #page-title h1, #page-title .h1 {
        line-height: 1.5;
        font-size: 24px; } }
  #page-title span {
    display: block;
    margin-top: 10px;
    font-weight: 300;
    color: #777777;
    font-size: 18px; }
    .dark #page-title span {
      color: rgba(255, 255, 255, 0.7); }

.breadcrumb {
  position: absolute !important;
  width: auto !important;
  top: 50% !important;
  left: auto !important;
  right: 15px !important;
  margin: 0 !important;
  transform: translateY(-50%);
  background-color: transparent !important;
  padding: 0 !important;
  font-size: 12px; }
  @media (max-width: 767.98px) {
    .breadcrumb {
      position: relative !important;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      margin: 20px 0 0 !important;
      justify-content: center;
      transform: none; } }

body.stretched .container-fullwidth .breadcrumb {
  right: 60px !important; }

.breadcrumb a {
  color: #555555; }
  .dark .breadcrumb a {
    color: #bbbbbb; }
  .breadcrumb a:hover {
    color: #1abc9c; }
    .dark .breadcrumb a:hover {
      color: #eeeeee; }

.breadcrumb i {
  width: auto !important; }

/* Page Title - Dark
-----------------------------------------------------------------*/
#page-title {
  /* Page Title - Right Aligned
	-----------------------------------------------------------------*/
  /* Page Title - Center Aligned
	-----------------------------------------------------------------*/
  /* Page Title - Background Pattern
	-----------------------------------------------------------------*/
  /* Page Title - Parallax Background
	-----------------------------------------------------------------*/
  /* Page Title - Mini
	-----------------------------------------------------------------*/
  /* Page Title - No Background
	-----------------------------------------------------------------*/
  /* Page Title - Video
	-----------------------------------------------------------------*/ }
  #page-title.page-title-dark {
    background-color: #333333;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15) !important;
    border-bottom: none; }
    #page-title.page-title-dark h1, #page-title.page-title-dark .h1 {
      color: rgba(255, 255, 255, 0.9) !important; }
    #page-title.page-title-dark span {
      color: rgba(255, 255, 255, 0.7); }
    #page-title.page-title-dark .breadcrumb > .active {
      color: rgba(255, 255, 255, 0.6); }
    #page-title.page-title-dark .breadcrumb a {
      color: rgba(255, 255, 255, 0.8); }
    #page-title.page-title-dark .breadcrumb a:hover {
      color: rgba(255, 255, 255, 0.95); }
  #page-title.page-title-right {
    text-align: right; }
    #page-title.page-title-right .breadcrumb {
      left: 15px !important;
      right: auto !important; }
  #page-title.page-title-center {
    text-align: center; }
    #page-title.page-title-center span {
      max-width: 700px;
      margin-left: auto;
      margin-right: auto; }
    #page-title.page-title-center .breadcrumb {
      position: relative !important;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      margin: 20px 0 0 !important;
      justify-content: center; }
  #page-title.page-title-pattern {
    background-image: url("image/pattern.png");
    background-repeat: repeat;
    background-attachment: fixed; }
    .dark #page-title.page-title-pattern {
      background-image: url("../image/pattern2.png"); }
  #page-title.page-title-parallax {
    padding: 100px 0;
    text-shadow: none;
    border-bottom: none;
    background-color: transparent;
    background-image: url("image/parallax/parallax-bg.jpg");
    background-attachment: fixed;
    background-position: 50% 0;
    background-repeat: no-repeat; }
    #page-title.page-title-parallax h1, #page-title.page-title-parallax .h1 {
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 2px; }
    #page-title.page-title-parallax span {
      font-size: 22px; }
    #page-title.page-title-parallax .breadcrumb {
      font-size: 13px; }
  #page-title.page-title-mini {
    padding: 20px 0; }
    #page-title.page-title-mini h1, #page-title.page-title-mini .h1 {
      font-weight: 600;
      font-size: 18px;
      color: #444444; }
    #page-title.page-title-mini span {
      display: none; }
  #page-title.page-title-nobg {
    background: transparent !important;
    border-bottom: 1px solid #eeeeee;
    border-bottom-color: #e5e5e5; }
  #page-title.page-title-video {
    background: none;
    position: relative;
    overflow: hidden; }
    #page-title.page-title-video .container {
      z-index: 3; }
    #page-title.page-title-video .video-wrap {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
      #page-title.page-title-video .video-wrap video {
        width: 100%; }

/* ----------------------------------------------------------------

	portfolio.scss

-----------------------------------------------------------------*/
/* Portfolio - Filter
-----------------------------------------------------------------*/
.portfolio-filter {
  position: relative;
  margin: 0 0 40px 0;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.07);
  float: left;
  border-radius: 4px; }
  .dark .portfolio-filter {
    border-color: rgba(255, 255, 255, 0.08); }
  @media (max-width: 767.98px) {
    .portfolio-filter {
      border: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.07);
      border-left: 1px solid rgba(0, 0, 0, 0.07);
      border-radius: 0; } }
  .portfolio-filter li {
    float: left;
    position: relative; }
    @media (max-width: 767.98px) {
      .portfolio-filter li {
        width: 33.3333333333%;
        border-right: 1px solid rgba(0, 0, 0, 0.07);
        border-bottom: 1px solid rgba(0, 0, 0, 0.07); } }
    @media (max-width: 575.98px) {
      .portfolio-filter li {
        width: 50%; } }
    .portfolio-filter li a {
      display: block;
      position: relative;
      padding: 12px 18px;
      font-size: 13px;
      line-height: 15px;
      color: #666;
      border-left: 1px solid rgba(0, 0, 0, 0.07); }
      .dark .portfolio-filter li a {
        color: #999999;
        border-left-color: rgba(255, 255, 255, 0.08); }
      @media (max-width: 767.98px) {
        .portfolio-filter li a {
          text-align: center;
          border-left: none;
          padding: 12px 18px !important;
          border-radius: 0 !important; } }
      .portfolio-filter li a:hover {
        color: #1abc9c; }
        .dark .portfolio-filter li a:hover {
          color: #eeeeee; }
    .portfolio-filter li:first-child a {
      border-left: none;
      border-radius: 4px 0 0 4px; }
    .portfolio-filter li:last-child a {
      border-radius: 0 4px 4px 0; }
    .portfolio-filter li.activeFilter a {
      color: #FFF !important;
      background-color: #1abc9c;
      margin: -1px 0;
      padding: 13px 18px;
      font-weight: bold; }
      @media (max-width: 767.98px) {
        .portfolio-filter li.activeFilter a {
          margin: 0; } }
      .dark .portfolio-filter li.activeFilter a {
        color: white; }

.bothsidebar .portfolio-filter li a {
  padding: 12px 14px; }

.bothsidebar .portfolio-filter li.activeFilter a {
  padding: 13px 18px; }

/* Portfolio - Filter: Style 2
-----------------------------------------------------------------*/
.portfolio-filter.style-2,
.portfolio-filter.style-3,
.portfolio-filter.style-4 {
  border: none;
  border-radius: 0; }

.portfolio-filter.style-2 li,
.portfolio-filter.style-3 li,
.portfolio-filter.style-4 li {
  margin-bottom: 10px; }

.portfolio-filter.style-2 li:not(:first-child),
.portfolio-filter.style-3 li:not(:first-child) {
  margin-left: 10px; }

.portfolio-filter.style-2 li a,
.portfolio-filter.style-3 li a,
.portfolio-filter.style-4 li a {
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 14px;
  line-height: 16px;
  border-radius: 22px;
  border: none; }

.portfolio-filter.style-2 li.activeFilter a {
  color: #FFF !important;
  margin: 0; }

/* Portfolio - Filter: Style 3
-----------------------------------------------------------------*/
.portfolio-filter.style-3 li a {
  border: 1px solid transparent; }

.portfolio-filter.style-3 li.activeFilter a {
  color: #1abc9c !important;
  border-color: #1abc9c;
  background-color: transparent;
  margin: 0; }

/* Portfolio - Filter: Style 4
-----------------------------------------------------------------*/
.portfolio-filter.style-4 li:not(:first-child) {
  margin-left: 30px; }

.portfolio-filter.style-4 li a {
  padding: 13px 5px;
  font-size: 15px;
  border-radius: 0; }

.portfolio-filter.style-4 li a:after {
  content: '';
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.portfolio-filter.style-4 li.activeFilter a {
  color: #444 !important;
  background-color: transparent;
  margin: 0; }

.portfolio-filter.style-4 li.activeFilter a:after {
  width: 100%;
  left: 0%;
  background-color: #1abc9c; }

.portfolio-filter.center.style-2 li, .portfolio-filter.center.style-3 li, .portfolio-filter.center.style-4 li {
  display: inline-block;
  float: none; }

/* Portfolio - Shuffle Icon
-----------------------------------------------------------------*/
.portfolio-shuffle {
  float: right;
  width: 41px;
  height: 41px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  font-size: 14px;
  text-align: center;
  line-height: 39px;
  color: #333;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear; }
  .dark .portfolio-shuffle {
    color: #999999;
    border-left-color: rgba(255, 255, 255, 0.08); }
  @media (max-width: 767.98px) {
    .portfolio-shuffle {
      display: none; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .bothsidebar .portfolio-shuffle {
      display: none; } }
  .portfolio-shuffle:hover {
    background-color: #1abc9c;
    color: white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }
    .dark .portfolio-shuffle:hover {
      color: white; }

/* Portfolio - Items
-----------------------------------------------------------------*/
.portfolio {
  position: relative;
  margin: 0 -12px -12px 0; }
  @media (max-width: 991.98px) {
    .portfolio {
      margin: 0 -12px -12px 0; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .portfolio:not(.portfolio-1) {
      margin: 0 -6px -6px 0; } }
  @media (max-width: 575.98px) {
    .portfolio:not(.portfolio-1) {
      margin: 0; } }

body:not(.device-touch) .portfolio {
  -webkit-transition: height 0.4s linear;
  -o-transition: height 0.4s linear;
  transition: height 0.4s linear; }

body:not(.device-touch):not(.device-sm):not(.device-xs) .portfolio-notitle .portfolio-image img {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.portfolio-item {
  position: relative;
  float: left;
  width: 25%;
  height: auto;
  padding: 0 12px 12px 0; }
  @media (max-width: 991.98px) {
    .portfolio-item {
      width: 33.3333333333%;
      padding: 0 12px 12px 0; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .portfolio-item:not(.portfolio-1) {
      width: 50%;
      padding: 0 6px 6px 0; } }
  @media (max-width: 575.98px) {
    .portfolio-item {
      width: 100%;
      padding: 0; } }
  .portfolio-item .portfolio-image {
    position: relative;
    overflow: hidden; }

.portfolio-notitle .portfolio-item,
.portfolio-notitle .iportfolio {
  overflow: hidden; }

.portfolio-item .portfolio-image,
.portfolio-item .portfolio-image a,
.portfolio-item .portfolio-image img {
  display: block;
  width: 100%;
  height: auto; }

/* Portfolio - Item Title
-----------------------------------------------------------------*/
.portfolio-desc {
  z-index: 3;
  padding: 15px 5px 10px; }
  .portfolio-desc h3, .portfolio-desc .h3 {
    margin: 0;
    padding: 0;
    font-size: 19px; }
    @media (min-width: 576px) and (max-width: 767.98px) {
      .portfolio:not(.portfolio-1) .portfolio-desc h3, .portfolio:not(.portfolio-1) .portfolio-desc .h3 {
        font-size: 16px; } }
    .portfolio-desc h3 a, .portfolio-desc .h3 a {
      color: #222; }
      .dark .portfolio-desc h3 a, .dark .portfolio-desc .h3 a {
        color: #eeeeee; }
      .portfolio-desc h3 a:hover, .portfolio-desc .h3 a:hover {
        color: #1abc9c; }
        .dark .portfolio-desc h3 a:hover, .dark .portfolio-desc .h3 a:hover {
          color: #bbbbbb; }
  .portfolio-desc span {
    display: block;
    margin-top: 3px;
    color: #888; }
    @media (min-width: 576px) and (max-width: 767.98px) {
      .portfolio:not(.portfolio-1) .portfolio-desc span {
        font-size: 14px; } }
    .portfolio-desc span a {
      color: #888; }
      .portfolio-desc span a:hover {
        color: #000; }
        .dark .portfolio-desc span a:hover {
          color: #cccccc; }

.portfolio-notitle .portfolio-desc {
  position: absolute;
  display: block !important;
  width: 100%;
  height: 78px;
  padding: 15px 5px;
  top: auto;
  bottom: -79px;
  left: 0;
  background-color: white;
  border-bottom: 1px solid #eeeeee; }
  .dark .portfolio-notitle .portfolio-desc {
    background-color: #333333;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08); }

body:not(.device-touch):not(.device-sm):not(.device-xs) .portfolio-notitle .portfolio-desc {
  -webkit-transition: bottom 0.2s ease-in-out;
  -o-transition: bottom 0.2s ease-in-out;
  transition: bottom 0.2s ease-in-out; }

.portfolio-full.portfolio-notitle .portfolio-desc,
.portfolio-nomargin.portfolio-notitle .portfolio-desc {
  bottom: -78px;
  border-bottom: 0; }

.portfolio-notitle .portfolio-item:hover .portfolio-desc,
.portfolio-notitle .iportfolio:hover .portfolio-desc {
  bottom: 0 !important; }

.bothsidebar .portfolio-notitle .portfolio-item:hover .portfolio-image img {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0); }

body:not(.device-touch):not(.device-sm):not(.device-xs) .portfolio-notitle .portfolio-item:hover .portfolio-image img,
body:not(.device-touch):not(.device-sm):not(.device-xs) .bothsidebar .portfolio-3.portfolio-notitle .portfolio-item:hover .portfolio-image img,
body:not(.device-touch):not(.device-sm):not(.device-xs) .bothsidebar .portfolio-2.portfolio-notitle .portfolio-item:hover .portfolio-image img,
body:not(.device-touch):not(.device-sm):not(.device-xs) .portfolio-notitle .iportfolio:hover .portfolio-image img {
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  -o-transform: translateY(-20px);
  transform: translateY(-20px); }

body.device-touch.device-sm .portfolio-notitle .portfolio-desc,
body.device-touch.device-xs .portfolio-notitle .portfolio-desc {
  display: none !important; }

/* Portfolio - No Margin
-----------------------------------------------------------------*/
.portfolio.portfolio-nomargin, .portfolio.portfolio-full {
  margin: 0 !important; }

.portfolio-nomargin .portfolio-item,
.portfolio-full .portfolio-item {
  padding: 0 !important; }

.portfolio-nomargin .portfolio-desc {
  padding: 15px 10px 15px; }

/* Portfolio - 100% Full Width
-----------------------------------------------------------------*/
.portfolio.portfolio-full {
  width: 100.4%; }

.portfolio-full .portfolio-item {
  overflow: hidden !important; }

.portfolio-full .portfolio-desc {
  padding: 15px; }

/* Portfolio - Sidebar
-----------------------------------------------------------------*/
.postcontent .portfolio-desc h3, .postcontent .portfolio-desc .h3 {
  font-size: 17px; }

.postcontent .portfolio-desc span {
  font-size: 12px; }

/* Portfolio - Sidebar - No Title
-----------------------------------------------------------------*/
.postcontent .portfolio-notitle .portfolio-desc {
  height: 70px;
  bottom: -71px; }

.postcontent .portfolio-notitle .portfolio-item:hover .portfolio-overlay a {
  margin-top: -50px !important; }

.postcontent .portfolio-full.portfolio-notitle .portfolio-desc,
.postcontent .portfolio-nomargin.portfolio-notitle .portfolio-desc {
  bottom: -70px; }

/* Portfolio - Both Sidebars
-----------------------------------------------------------------*/
.bothsidebar .portfolio {
  margin: 0; }

.bothsidebar .portfolio-item {
  padding: 0; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .bothsidebar .portfolio-item {
      width: 50%; } }

.bothsidebar .portfolio-desc {
  display: none !important; }

/* Portfolio - Items - 3 Columns
-----------------------------------------------------------------*/
.portfolio.portfolio-3 {
  margin: 0 -15px -15px 0; }
  @media (max-width: 991.98px) {
    .portfolio.portfolio-3 {
      margin: 0 -15px -15px 0; } }

.portfolio-3 .portfolio-item {
  width: 33.33333333%;
  padding: 0 15px 15px 0; }
  @media (max-width: 991.98px) {
    .portfolio-3 .portfolio-item {
      width: 33.3333333333%;
      padding: 0 15px 15px 0; } }

/* Portfolio - Sidebar - Items - 3 Columns
-----------------------------------------------------------------*/
.postcontent .portfolio-3 .portfolio-desc h3, .postcontent .portfolio-3 .portfolio-desc .h3 {
  font-size: 18px; }

.postcontent .portfolio-3 .portfolio-desc span {
  font-size: 13px; }

/* Portfolio - Sidebar - Items - 3 Columns - No Title
-----------------------------------------------------------------*/
.postcontent .portfolio-3.portfolio-notitle .portfolio-desc {
  height: 72px;
  bottom: -73px; }

.postcontent .portfolio-3.portfolio-notitle .portfolio-item:hover .portfolio-overlay a {
  margin-top: -40px !important; }

.postcontent .portfolio-3.portfolio-full.portfolio-notitle .portfolio-desc,
.postcontent .portfolio-3.portfolio-nomargin.portfolio-notitle .portfolio-desc {
  bottom: -72px; }

/* Portfolio - Both Sidebars - Items - 3 Columns
-----------------------------------------------------------------*/
.bothsidebar .portfolio.portfolio-3 {
  margin: 0 -11px -11px 0; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .bothsidebar .portfolio.portfolio-3 {
      margin: 0 -20px -20px 0; } }

.bothsidebar .portfolio-3 .portfolio-item {
  padding: 0 11px 11px 0; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .bothsidebar .portfolio-3 .portfolio-item {
      padding: 0 20px 20px 0; } }

.bothsidebar .portfolio-3 .portfolio-desc {
  display: block !important; }
  .bothsidebar .portfolio-3 .portfolio-desc h3, .bothsidebar .portfolio-3 .portfolio-desc .h3 {
    font-size: 15px; }
  .bothsidebar .portfolio-3 .portfolio-desc span {
    font-size: 12px; }

/* Portfolio - Both Sidebars - Items - 3 Columns - No Title
-----------------------------------------------------------------*/
.bothsidebar .portfolio-3.portfolio-notitle .portfolio-desc {
  height: 68px;
  bottom: -69px; }

.bothsidebar .portfolio-3.portfolio-notitle .portfolio-item:hover .portfolio-overlay a {
  margin-top: -50px !important; }

.bothsidebar .portfolio-3.portfolio-full.portfolio-notitle .portfolio-desc,
.bothsidebar .portfolio-3.portfolio-nomargin.portfolio-notitle .portfolio-desc {
  bottom: -68px; }

/* Portfolio - Items - 2 Columns
-----------------------------------------------------------------*/
.portfolio.portfolio-2 {
  margin: 0 -20px -20px 0; }
  @media (max-width: 991.98px) {
    .portfolio.portfolio-2 {
      margin: 0 -20px -20px 0; } }

.portfolio-2 .portfolio-item {
  width: 50%;
  padding: 0 20px 20px 0; }
  @media (max-width: 991.98px) {
    .portfolio-2 .portfolio-item {
      width: 50%;
      padding: 0 20px 20px 0; } }

.portfolio-2 .portfolio-desc {
  padding: 20px 5px 10px; }
  .portfolio-2 .portfolio-desc h3, .portfolio-2 .portfolio-desc .h3 {
    font-size: 21px; }
  .portfolio-2 .portfolio-desc span {
    margin-top: 4px;
    font-size: 14px; }

/* Portfolio - Items - 2 Columns - No Margin
-----------------------------------------------------------------*/
.portfolio-2.portfolio-nomargin .portfolio-desc {
  padding: 20px 15px 20px; }

/* Portfolio - Items - 2 Columns - No Title
-----------------------------------------------------------------*/
.portfolio-2.portfolio-notitle .portfolio-desc {
  height: 90px;
  bottom: -91px; }

.portfolio-2.portfolio-full.portfolio-notitle .portfolio-desc,
.portfolio-2.portfolio-nomargin.portfolio-notitle .portfolio-desc {
  bottom: -90px; }

/* Portfolio - Sidebar - Items - 2 Columns
-----------------------------------------------------------------*/
.postcontent .portfolio-2 .portfolio-desc h3, .postcontent .portfolio-2 .portfolio-desc .h3 {
  font-size: 21px; }

.postcontent .portfolio-2 .portfolio-desc span {
  font-size: 14px; }

/* Portfolio - Sidebar - Items - 2 Columns - No Margin
-----------------------------------------------------------------*/
.postcontent .portfolio-2.portfolio-nomargin .portfolio-desc {
  padding-bottom: 20px; }

/* Portfolio - Sidebar - Items - 2 Columns - No Title
-----------------------------------------------------------------*/
.postcontent .portfolio-2.portfolio-notitle .portfolio-desc {
  height: 90px;
  bottom: -91px; }

.postcontent .portfolio-2.portfolio-notitle .portfolio-item:hover .portfolio-overlay a {
  margin-top: -40px !important; }

.postcontent .portfolio-2.portfolio-full.portfolio-notitle .portfolio-desc,
.postcontent .portfolio-2.portfolio-nomargin.portfolio-notitle .portfolio-desc {
  bottom: -90px; }

/* Portfolio - Both Sidebars - Items - 2 Columns
-----------------------------------------------------------------*/
.bothsidebar .portfolio-2 .portfolio-desc {
  display: block !important;
  padding-top: 15px;
  padding-bottom: 0; }
  .bothsidebar .portfolio-2 .portfolio-desc h3, .bothsidebar .portfolio-2 .portfolio-desc .h3 {
    font-size: 19px; }
  .bothsidebar .portfolio-2 .portfolio-desc span {
    font-size: 13px; }

/* Portfolio - Both Sidebars - Items - 2 Columns - No Margin
-----------------------------------------------------------------*/
.bothsidebar .portfolio-2.portfolio-nomargin .portfolio-desc {
  padding-bottom: 15px; }

/* Portfolio - Both Sidebars - Items - 2 Columns - No Title
-----------------------------------------------------------------*/
.bothsidebar .portfolio-2.portfolio-notitle .portfolio-desc {
  height: 75px;
  bottom: -76px; }

.bothsidebar .portfolio-2.portfolio-notitle .portfolio-item:hover .portfolio-overlay a {
  margin-top: -40px !important; }

.bothsidebar .portfolio-2.portfolio-full.portfolio-notitle .portfolio-desc,
.bothsidebar .portfolio-2.portfolio-nomargin.portfolio-notitle .portfolio-desc {
  bottom: -75px; }

/* Portfolio - Items - 5 Columns
-----------------------------------------------------------------*/
.portfolio.portfolio-5 {
  margin: 0 -10px -10px 0; }

.portfolio-5 .portfolio-item {
  width: 50%;
  padding: 0 10px 10px 0; }

.portfolio-5 .portfolio-desc h3, .portfolio-5 .portfolio-desc .h3 {
  font-size: 17px; }

/* Portfolio - Items - 5 Columns - No Title
-----------------------------------------------------------------*/
.portfolio-5.portfolio-notitle .portfolio-desc {
  height: 71px;
  bottom: -72px; }

.portfolio-5.portfolio-notitle .portfolio-item:hover .portfolio-overlay a {
  margin-top: -30px !important; }

.portfolio-5.portfolio-full.portfolio-notitle .portfolio-desc,
.portfolio-5.portfolio-nomargin.portfolio-notitle .portfolio-desc {
  bottom: -71px; }

/* Portfolio - Sidebar - Items - 5 Columns
-----------------------------------------------------------------*/
.postcontent .portfolio.portfolio-5 {
  margin: 0; }

.postcontent .portfolio-5 .portfolio-item {
  padding: 0; }

.postcontent .portfolio-5 .portfolio-desc {
  padding: 15px 10px; }
  .postcontent .portfolio-5 .portfolio-desc h3, .postcontent .portfolio-5 .portfolio-desc .h3 {
    font-size: 15px; }
  .postcontent .portfolio-5 .portfolio-desc span {
    font-size: 12px; }

/* Portfolio - Sidebar - Items - 5 Columns - No Title
-----------------------------------------------------------------*/
.postcontent .portfolio-5.portfolio-notitle .portfolio-desc {
  height: 68px;
  bottom: -69px; }

.postcontent .portfolio-5.portfolio-notitle .portfolio-item:hover .portfolio-overlay a {
  margin-top: -50px !important; }

.postcontent .portfolio-5.portfolio-full.portfolio-notitle .portfolio-desc,
.postcontent .portfolio-5.portfolio-nomargin.portfolio-notitle .portfolio-desc {
  bottom: -68px; }

/* Portfolio - Items - 6 Columns
-----------------------------------------------------------------*/
.portfolio.portfolio-6 {
  margin: 0 -6px -6px 0; }

.portfolio-6 .portfolio-item {
  width: 16.66666667%;
  padding: 0 6px 6px 0; }

.portfolio-6 .portfolio-desc h3, .portfolio-6 .portfolio-desc .h3 {
  font-size: 15px; }

.portfolio-6 .portfolio-desc span {
  font-size: 12px; }

/* Portfolio - Items - 6 Columns - No Title
-----------------------------------------------------------------*/
.portfolio-6.portfolio-notitle .portfolio-desc {
  height: 68px;
  bottom: -69px; }

.portfolio-6.portfolio-notitle .portfolio-item:hover .portfolio-overlay a {
  margin-top: -40px !important; }

.portfolio-6.portfolio-full.portfolio-notitle .portfolio-desc,
.portfolio-6.portfolio-nomargin.portfolio-notitle .portfolio-desc {
  bottom: -68px; }

/* Portfolio - Items - 1 Column
-----------------------------------------------------------------*/
.portfolio.portfolio-1 {
  margin: 0 0px -40px 0; }

.portfolio-1 .portfolio-item {
  float: none;
  width: 100%;
  margin: 0 0 40px 0;
  padding-bottom: 40px;
  padding-right: 0;
  border-bottom: 1px solid #eeeeee; }
  .dark .portfolio-1 .portfolio-item {
    border-bottom-color: rgba(255, 255, 255, 0.08); }
  @media (max-width: 991.98px) {
    .portfolio-1 .portfolio-item {
      width: 100%; } }
  .portfolio-1 .portfolio-item .portfolio-image {
    float: left;
    margin-right: 0;
    width: 65%;
    height: auto; }
    @media (max-width: 991.98px) {
      .portfolio-1 .portfolio-item .portfolio-image {
        float: none !important;
        margin: 0 !important;
        margin-bottom: 20px !important; } }
  .portfolio-1 .portfolio-item.alt .portfolio-image {
    float: right;
    margin: 0; }

.portfolio-1 .portfolio-desc {
  float: left;
  width: 35%;
  padding: 10px 0 10px 40px; }
  @media (max-width: 991.98px) {
    .portfolio-1 .portfolio-desc {
      width: 100% !important;
      padding: 0 !important; } }
  .portfolio-1 .portfolio-desc h3, .portfolio-1 .portfolio-desc .h3 {
    font-size: 22px; }
  .portfolio-1 .portfolio-desc span {
    margin-top: 6px;
    font-size: 14px; }
  .portfolio-1 .portfolio-desc p {
    margin: 20px 0 25px;
    font-size: 14px; }
  .portfolio-1 .portfolio-desc li {
    margin: 3px 0; }

.portfolio-1 .alt .portfolio-desc {
  float: right;
  padding-left: 0;
  padding-right: 40px; }

/* Portfolio - Items - 1 Column - Both Sidebar
-----------------------------------------------------------------*/
.bothsidebar .portfolio-1 .iconlist {
  display: block !important; }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .postcontent .portfolio-1 .iconlist {
    display: none; } }

.bothsidebar .portfolio-1 .portfolio-item {
  width: 100% !important; }

.bothsidebar .portfolio-1 .portfolio-item .portfolio-image,
.bothsidebar .portfolio-1 .portfolio-item .portfolio-desc {
  float: none;
  margin: 0 !important; }

.bothsidebar .portfolio-1 .portfolio-item .portfolio-image {
  margin-bottom: 20px !important; }

.bothsidebar .portfolio-1 .portfolio-item .portfolio-image,
.bothsidebar .portfolio-1 .portfolio-item .portfolio-image a,
.bothsidebar .portfolio-1 .portfolio-item .portfolio-image img {
  width: 100%;
  height: auto; }

.bothsidebar .portfolio-1 .portfolio-desc {
  display: block !important;
  position: relative !important;
  width: 100% !important;
  padding: 0 !important;
  background-color: transparent !important;
  left: 0 !important;
  right: 0 !important; }

.bothsidebar .portfolio-1.portfolio-fullwidth .portfolio-overlay a {
  left: 50%; }

.bothsidebar .portfolio-1.portfolio-fullwidth .alt .portfolio-overlay a {
  left: auto;
  right: 50%; }

/* Portfolio - Items - 1 Column - Full Width
-----------------------------------------------------------------*/
.portfolio.portfolio-1.portfolio-fullwidth {
  margin: 0 0 -60px 0; }

.portfolio-1.portfolio-fullwidth .portfolio-item {
  margin-bottom: 60px;
  padding-bottom: 0;
  border-bottom: 0;
  overflow: hidden; }
  .portfolio-1.portfolio-fullwidth .portfolio-item .portfolio-image {
    float: none;
    width: 100%;
    margin: 0 !important; }
    @media (max-width: 991.98px) {
      .portfolio-1.portfolio-fullwidth .portfolio-item .portfolio-image {
        margin: 0 0 20px 0 !important; }
        .portfolio-1.portfolio-fullwidth .portfolio-item .portfolio-image img {
          -webkit-transition: none;
          -o-transition: none;
          transition: none; } }
  .portfolio-1.portfolio-fullwidth .portfolio-item:hover .portfolio-image img {
    -webkit-transform: translateX(-60px);
    -ms-transform: translateX(-60px);
    -o-transform: translateX(-60px);
    transform: translateX(-60px); }
    @media (max-width: 991.98px) {
      .portfolio-1.portfolio-fullwidth .portfolio-item:hover .portfolio-image img {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0); } }
  .portfolio-1.portfolio-fullwidth .portfolio-item:hover .portfolio-desc {
    right: 0; }
  .portfolio-1.portfolio-fullwidth .portfolio-item.alt:hover .portfolio-image img {
    -webkit-transform: translateX(60px);
    -ms-transform: translateX(60px);
    -o-transform: translateX(60px);
    transform: translateX(60px); }
    @media (max-width: 991.98px) {
      .portfolio-1.portfolio-fullwidth .portfolio-item.alt:hover .portfolio-image img {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0); } }
  .portfolio-1.portfolio-fullwidth .portfolio-item.alt:hover .portfolio-desc {
    left: 0; }

.portfolio-1.portfolio-fullwidth .portfolio-desc {
  position: absolute;
  float: none;
  width: 35%;
  height: 100%;
  padding: 30px;
  background-color: #f9f9f9;
  top: 0;
  left: auto;
  right: -35%; }
  .dark .portfolio-1.portfolio-fullwidth .portfolio-desc {
    background-color: #333333; }

body:not(.device-touch):not(.device-sm):not(.device-xs) .portfolio-1.portfolio-fullwidth .portfolio-item .portfolio-image img {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

body:not(.device-touch):not(.device-sm):not(.device-xs) .portfolio-1.portfolio-fullwidth .portfolio-desc {
  -webkit-transition: right 0.3s ease-in-out;
  -o-transition: right 0.3s ease-in-out;
  transition: right 0.3s ease-in-out; }

body:not(.device-touch):not(.device-sm):not(.device-xs) .portfolio-1.portfolio-fullwidth .alt .portfolio-desc {
  -webkit-transition: left 0.3s ease-in-out;
  -o-transition: left 0.3s ease-in-out;
  transition: left 0.3s ease-in-out; }

/* Portfolio - Items - 1 Column - Full Width - Sidebar
-----------------------------------------------------------------*/
.postcontent .portfolio.portfolio-1.portfolio-fullwidth {
  margin: 0 0 -40px 0; }

.postcontent .portfolio-1.portfolio-fullwidth .portfolio-item {
  margin-bottom: 40px; }
  .postcontent .portfolio-1.portfolio-fullwidth .portfolio-item:hover .portfolio-image img {
    -webkit-transform: translateX(-40px);
    -ms-transform: translateX(-40px);
    -o-transform: translateX(-40px);
    transform: translateX(-40px); }
  .postcontent .portfolio-1.portfolio-fullwidth .portfolio-item .alt:hover .portfolio-image img {
    -webkit-transform: translateX(40px);
    -ms-transform: translateX(40px);
    -o-transform: translateX(40px);
    transform: translateX(40px); }

.postcontent .portfolio-1.portfolio-fullwidth .portfolio-desc {
  padding: 25px; }

/* Portfolio - Items - 1 Column - Full Width - Both Sidebar
-----------------------------------------------------------------*/
.bothsidebar .portfolio-1.portfolio-fullwidth .portfolio-item {
  margin-bottom: 20px; }
  .bothsidebar .portfolio-1.portfolio-fullwidth .portfolio-item:hover .portfolio-image img {
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px); }
  .bothsidebar .portfolio-1.portfolio-fullwidth .portfolio-item.alt:hover .portfolio-image img {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }

@media (max-width: 991.98px) {
  .portfolio-1 .portfolio-item .portfolio-image,
  .portfolio-1 .portfolio-item .portfolio-image a,
  .portfolio-1 .portfolio-item .portfolio-image img,
  .portfolio-1.portfolio-fullwidth .portfolio-item .portfolio-image,
  .portfolio-1.portfolio-fullwidth .portfolio-item .portfolio-image a,
  .portfolio-1.portfolio-fullwidth .portfolio-item .portfolio-image img {
    width: 100%;
    height: auto; }
  .portfolio-1.portfolio-fullwidth .portfolio-desc,
  .portfolio-1.portfolio-fullwidth .alt .portfolio-desc {
    position: relative;
    height: auto;
    background-color: transparent;
    left: 0 !important;
    right: 0 !important;
    -webkit-transition: none;
    -o-transition: none;
    transition: none; }
  .portfolio-1.portfolio-fullwidth .portfolio-overlay a {
    left: 50% !important; }
  .portfolio-1.portfolio-fullwidth .alt .portfolio-overlay a {
    left: auto;
    right: 50% !important; } }

/* Portfolio - Parallax
-----------------------------------------------------------------*/
.portfolio.portfolio-parallax {
  margin: 0 !important; }
  .portfolio.portfolio-parallax .portfolio-item {
    float: none;
    width: 100% !important;
    height: 500px !important;
    margin: 0 !important;
    padding: 0 !important; }
    .portfolio.portfolio-parallax .portfolio-item .portfolio-image {
      width: 100% !important;
      height: 500px !important;
      background-attachment: fixed; }
    .portfolio.portfolio-parallax .portfolio-item:hover .portfolio-desc {
      opacity: 1; }
      .portfolio.portfolio-parallax .portfolio-item:hover .portfolio-desc .portfolio-divider div {
        width: 120px; }
  .portfolio.portfolio-parallax .portfolio-desc {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -57px;
    width: 100%;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
    padding: 0;
    opacity: 0; }
    @media (max-width: 991.98px) {
      .device-touch .portfolio.portfolio-parallax .portfolio-desc {
        opacity: 1; } }
    .portfolio.portfolio-parallax .portfolio-desc h3, .portfolio.portfolio-parallax .portfolio-desc .h3 {
      font-size: 44px;
      font-weight: 300;
      letter-spacing: -1px; }
      .portfolio.portfolio-parallax .portfolio-desc h3 a, .portfolio.portfolio-parallax .portfolio-desc .h3 a {
        color: #FFF; }
        .portfolio.portfolio-parallax .portfolio-desc h3 a:hover, .portfolio.portfolio-parallax .portfolio-desc .h3 a:hover {
          color: #DDD; }
    .portfolio.portfolio-parallax .portfolio-desc span {
      margin-top: 12px;
      font-size: 16px;
      color: #CCC; }
      .portfolio.portfolio-parallax .portfolio-desc span a {
        color: #DDD; }
        .portfolio.portfolio-parallax .portfolio-desc span a:hover {
          color: #BBB; }
    .portfolio.portfolio-parallax .portfolio-desc .portfolio-divider {
      width: 100%;
      text-align: center;
      margin: 15px 0 0; }
      .portfolio.portfolio-parallax .portfolio-desc .portfolio-divider div {
        display: inline-block;
        width: 50px;
        height: 0;
        border-bottom: 1px solid #FFF; }
  .portfolio.portfolio-parallax .portfolio-overlay {
    background-color: rgba(0, 0, 0, 0.4); }
    @media (max-width: 991.98px) {
      .device-touch .portfolio.portfolio-parallax .portfolio-overlay {
        opacity: 1; } }

body:not(.device-touch):not(.device-sm):not(.device-xs) .portfolio.portfolio-parallax .portfolio-desc {
  -webkit-transition: opacity 0.15s ease-in-out;
  -o-transition: opacity 0.15s ease-in-out;
  transition: opacity 0.15s ease-in-out; }
  body:not(.device-touch):not(.device-sm):not(.device-xs) .portfolio.portfolio-parallax .portfolio-desc .portfolio-divider div {
    -webkit-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out; }

/* Portfolio - Overlay
-----------------------------------------------------------------*/
.portfolio-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5); }
  .portfolio-overlay a {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: whitesmoke;
    width: 40px !important;
    height: 40px !important;
    margin: -28px 0 0 -44px;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    color: #444;
    border-radius: 50%;
    -webkit-backface-visibility: hidden; }
    .dark .portfolio-overlay a {
      background-color: rgba(0, 0, 0, 0.5);
      color: #FFF; }
    .portfolio-overlay a.right-icon {
      left: auto;
      right: 50%;
      margin-left: 0;
      margin-right: -44px; }
    .portfolio-overlay a.center-icon {
      display: block;
      margin: -20px 0 0 -20px;
      opacity: 0; }
    .portfolio-overlay a:hover {
      color: #1abc9c;
      background-color: #EEE; }
      .dark .portfolio-overlay a:hover {
        background-color: rgba(0, 0, 0, 0.7); }
    .portfolio-overlay a i.icon-line-play {
      position: relative;
      left: 2px; }
  .portfolio-overlay .portfolio-desc {
    position: relative;
    padding: 0 !important;
    margin: 0;
    text-align: center; }
    .portfolio-overlay .portfolio-desc a {
      display: inline;
      position: relative;
      top: 0;
      left: 0;
      margin: 0;
      font-size: inherit;
      width: auto !important;
      height: auto !important;
      line-height: 1;
      background-color: transparent !important;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15); }
      .portfolio-overlay .portfolio-desc a:hover {
        background: transparent; }
    .portfolio-overlay .portfolio-desc h3, .portfolio-overlay .portfolio-desc .h3 {
      font-weight: 600;
      color: #F5F5F5 !important;
      line-height: 1; }
      .portfolio-overlay .portfolio-desc h3 a, .portfolio-overlay .portfolio-desc .h3 a {
        color: #F5F5F5 !important; }
    .portfolio-overlay .portfolio-desc span {
      margin-top: 7px;
      color: #DDD !important; }
      .portfolio-overlay .portfolio-desc span a {
        color: #DDD !important;
        font-size: inherit; }
    .portfolio-overlay .portfolio-desc ~ a {
      display: inline-block;
      position: relative;
      top: 0;
      left: 0;
      margin: 20px 0 0 !important; }
      @media (min-width: 576px) and (max-width: 767.98px) {
        .portfolio:not(.portfolio-1) .portfolio-item:hover .portfolio-overlay .portfolio-desc ~ a {
          margin: 20px 0 0 !important; } }
      .portfolio-overlay .portfolio-desc ~ a.right-icon {
        left: 0;
        right: 0;
        margin-left: 5px !important;
        margin-right: 0 !important; }
        @media (min-width: 576px) and (max-width: 767.98px) {
          .portfolio:not(.portfolio-1) .portfolio-item:hover .portfolio-overlay .portfolio-desc ~ a.right-icon {
            margin-left: 5px !important;
            margin-right: 0 !important; } }

body:not(.device-touch):not(.device-sm):not(.device-xs) .portfolio-overlay {
  -webkit-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out; }
  body:not(.device-touch):not(.device-sm):not(.device-xs) .portfolio-overlay a {
    -webkit-transition: color 0.2s linear, background-color 0.2s linear, margin-top 0.2s linear, opacity 0.2s linear;
    -o-transition: color 0.2s linear, background-color 0.2s linear, margin-top 0.2s linear, opacity 0.2s linear;
    transition: color 0.2s linear, background-color 0.2s linear, margin-top 0.2s linear, opacity 0.2s linear; }
  body:not(.device-touch):not(.device-sm):not(.device-xs) .portfolio-overlay .portfolio-desc ~ a {
    -webkit-transition: color 0.2s linear, background-color 0.2s linear, opacity 0.2s linear;
    -o-transition: color 0.2s linear, background-color 0.2s linear, opacity 0.2s linear;
    transition: color 0.2s linear, background-color 0.2s linear, opacity 0.2s linear; }

.portfolio-item:hover .portfolio-overlay,
.iportfolio:hover .portfolio-overlay {
  opacity: 1; }

.portfolio-item:hover a.center-icon,
.iportfolio:hover a.center-icon {
  opacity: 1; }

.portfolio-item:hover .portfolio-overlay a.left-icon,
.portfolio-item:hover .portfolio-overlay a.right-icon,
.iportfolio:hover .portfolio-overlay a.left-icon,
.iportfolio:hover .portfolio-overlay a.right-icon {
  margin-top: -18px; }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .portfolio-notitle:not(.portfolio-1) .portfolio-item:hover .portfolio-overlay a.left-icon,
    .portfolio-notitle .iportfolio:hover .portfolio-item:hover .portfolio-overlay a.left-icon, .portfolio-notitle:not(.portfolio-1)
    .portfolio-item:hover .portfolio-overlay a.right-icon,
    .portfolio-notitle .iportfolio:hover
    .portfolio-item:hover .portfolio-overlay a.right-icon, .portfolio-notitle:not(.portfolio-1)
    .iportfolio:hover .portfolio-overlay a.left-icon,
    .portfolio-notitle .iportfolio:hover
    .iportfolio:hover .portfolio-overlay a.left-icon, .portfolio-notitle:not(.portfolio-1)
    .iportfolio:hover .portfolio-overlay a.right-icon,
    .portfolio-notitle .iportfolio:hover
    .iportfolio:hover .portfolio-overlay a.right-icon {
      margin-top: -55px; } }

body.device-touch.device-sm .portfolio-notitle .portfolio-overlay, body.device-touch.device-xs .portfolio-notitle .portfolio-overlay {
  display: none !important; }

/* Portfolio Single
-----------------------------------------------------------------*/
/* Portfolio Single - Image
-----------------------------------------------------------------*/
.portfolio-single-image > a,
.portfolio-single-image .slide a,
.portfolio-single-image img,
.portfolio-single-image iframe,
.portfolio-single-image video {
  display: block;
  width: 100%; }

.portfolio-single-image-full {
  position: relative;
  height: 600px;
  overflow: hidden;
  margin: -80px 0 80px !important; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .portfolio-single-image-full.portfolio-single-thumbs {
      height: 400px; } }

.portfolio-single-video {
  height: auto !important; }

/* Portfolio Single - Gallery Thumbs
-----------------------------------------------------------------*/
.portfolio-single-image-full .swiper-container {
  width: 100%;
  height: 600px;
  background-color: #333; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .portfolio-single-image-full .swiper-container {
      height: 400px; } }

.portfolio-single-image-full .swiper-slide {
  overflow: hidden; }
  .portfolio-single-image-full .swiper-slide img {
    width: 100%;
    height: auto; }

.portfolio-single-image-full .swiper-nested-1,
.portfolio-single-image-full .swiper-nested-2 {
  width: 100%; }

/* Portfolio & Blog Single - Masonry
-----------------------------------------------------------------*/
.masonry-thumbs {
  position: relative; }
  .masonry-thumbs a {
    position: relative;
    float: left;
    width: 25%;
    overflow: hidden; }
  .masonry-thumbs.grid-2 a {
    width: 50%; }
  .masonry-thumbs.grid-3 a {
    width: 33.30%; }
  .masonry-thumbs.grid-4 a {
    width: 25%; }
  .masonry-thumbs.grid-5 a {
    width: 20%; }
  .masonry-thumbs.grid-6 a {
    width: 16.60%; }
  .masonry-thumbs img {
    width: 100%;
    border-radius: 0 !important;
    padding: 0 1px 1px 0; }
  .masonry-thumbs .overlay {
    padding: 0 1px 1px 0; }
  .masonry-thumbs a,
  .masonry-thumbs img {
    display: block;
    height: auto !important; }

/* Portfolio Single - Content
-----------------------------------------------------------------*/
.portfolio-single-content {
  font-size: 14px; }
  .portfolio-single-content h2, .portfolio-single-content .h2 {
    margin: 0 0 20px;
    padding: 0;
    font-size: 20px;
    font-weight: 600 !important; }

.portfolio-ajax-modal {
  width: 1000px !important; }

.modal-padding {
  padding: 40px; }

.ajax-modal-title {
  background-color: #f9f9f9;
  border-bottom: 1px solid #eeeeee;
  padding: 25px 40px; }
  .dark .ajax-modal-title {
    background-color: #333333;
    border-bottom-color: rgba(255, 255, 255, 0.08); }
  .ajax-modal-title h2, .ajax-modal-title .h2 {
    font-size: 26px;
    margin-bottom: 0; }

#portfolio-ajax-wrap {
  position: relative;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.4s ease;
  -o-transition: max-height 0.4s ease;
  transition: max-height 0.4s ease; }
  #portfolio-ajax-wrap.portfolio-ajax-opened {
    max-height: 1200px; }

#portfolio-ajax-container {
  display: none;
  padding-bottom: 60px; }

/* Portfolio Single - Meta
-----------------------------------------------------------------*/
.portfolio-meta,
.portfolio-share {
  list-style: none;
  font-size: 14px; }

.portfolio-meta li {
  margin: 10px 0;
  color: #666; }
  .dark .portfolio-meta li {
    color: #999999; }
  .portfolio-meta li:first-child {
    margin-top: 0; }
  .portfolio-meta li span {
    display: inline-block;
    width: 150px;
    font-weight: bold;
    color: #333; }
    .dark .portfolio-meta li span {
      color: #bbbbbb; }
    .portfolio-meta li span i {
      position: relative;
      top: 1px;
      width: 14px;
      text-align: center;
      margin-right: 7px; }

.well .line {
  margin: 20px 0;
  border-color: #e5e5e5; }
  .dark .well .line {
    border-color: rgba(255, 255, 255, 0.08); }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .panel .portfolio-meta li {
    padding-left: 22px; }
    .panel .portfolio-meta li span {
      display: block;
      margin-left: -21px; } }

/* Portfolio Single Navigation
-----------------------------------------------------------------*/
#portfolio-navigation {
  position: absolute;
  top: 50%;
  left: auto;
  right: 10px;
  max-width: 96px;
  height: 24px;
  margin-top: -12px; }
  #portfolio-navigation a {
    display: block;
    float: left;
    margin-left: 12px;
    width: 24px;
    height: 24px;
    text-align: center;
    color: #444444;
    -webkit-transition: color 0.3s linear;
    -o-transition: color 0.3s linear;
    transition: color 0.3s linear; }
    .dark #portfolio-navigation a {
      color: #999999; }
    #portfolio-navigation a i {
      position: relative;
      top: -1px;
      font-size: 24px;
      line-height: 1; }
      #portfolio-navigation a i.icon-angle-right {
        left: -1px; }
    #portfolio-navigation a:first-child {
      margin-left: 0; }
    #portfolio-navigation a:hover {
      color: #1abc9c; }
      .dark #portfolio-navigation a:hover {
        color: #eeeeee; }
  @media (max-width: 767.98px) {
    #page-title #portfolio-navigation,
    .portfolio-ajax-title #portfolio-navigation {
      position: relative;
      top: 0;
      left: 0;
      margin: 15px auto 0; } }

@media (max-width: 767.98px) {
  .portfolio-ajax-title {
    text-align: center; } }

.page-title-right #portfolio-navigation {
  left: 10px;
  right: auto; }

.page-title-center #portfolio-navigation {
  position: relative;
  top: 0;
  left: 0;
  margin: 20px auto 0; }

.page-title-dark #portfolio-navigation a {
  color: #eeeeee; }

.page-title-parallax #portfolio-navigation a {
  color: #FFF; }

#portfolio-navigation a i.icon-angle-left, #portfolio-navigation a i.icon-angle-right {
  font-size: 32px;
  top: -6px; }

#portfolio-ajax-show #portfolio-navigation {
  top: 0;
  margin-top: 0; }

/* Individual Portfolio Item
-----------------------------------------------------------------*/
.iportfolio {
  position: relative;
  float: none;
  width: 100%;
  margin: 0;
  padding: 0; }
  .iportfolio .portfolio-image {
    position: relative;
    overflow: hidden; }
  .iportfolio .portfolio-image,
  .iportfolio .portfolio-image a,
  .iportfolio .portfolio-image img {
    display: block;
    width: 100%;
    height: auto; }
  .iportfolio .portfolio-overlay {
    height: 100% !important; }

/* ----------------------------------------------------------------

	shop.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Shop
-----------------------------------------------------------------*/
.shop {
  position: relative;
  margin: 0 -20px -20px 0; }
  @media (max-width: 991.98px) {
    .shop {
      margin: 0 -20px -20px 0; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .shop:not(.product-1) {
      margin: 0 -12px -12px 0; } }
  @media (max-width: 575.98px) {
    .shop:not(.product-1) {
      margin: 0; } }
  .shop .product {
    position: relative;
    float: left;
    padding: 0 20px 20px 0;
    width: 25%; }
    @media (max-width: 991.98px) {
      .shop .product {
        width: 33.3333333333%;
        padding: 0 20px 20px 0; } }
    @media (min-width: 576px) and (max-width: 767.98px) {
      :not {
        width: 50%;
        padding: 0 12px 12px 0; } }
    @media (max-width: 575.98px) {
      :not {
        margin: 0 0 20px 0;
        padding: 0;
        width: 100%; } }

.product {
  position: relative; }

.product-image {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden; }
  .product-image > a,
  .product-image .slide a,
  .product-image img {
    display: block;
    width: 100%;
    height: 100%; }

body:not(.device-touch):not(.device-sm):not(.device-xs) .product-image > a {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease; }

.product-image > a:nth-of-type(2) {
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0; }

.product-image:hover > a:nth-of-type(2) {
  opacity: 1;
  z-index: 2; }

.product-desc {
  padding: 15px 0;
  font-size: 14px; }

.product-title {
  margin-bottom: 7px; }
  .product-title h3, .product-title .h3 {
    margin: 0;
    font-size: 20px; }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .product-title h3, .product-title .h3 {
        font-size: 18px; } }
    @media (max-width: 991.98px) {
      .shop:not(.product-2):not(.product-3) .product-title h3, .shop:not(.product-2):not(.product-3) .product-title .h3 {
        font-size: 18px; } }
    @media (min-width: 576px) and (max-width: 767.98px) {
      .shop:not(.product-1) .product-title h3, .shop:not(.product-1) .product-title .h3 {
        font-size: 17px; } }
    @media (max-width: 575.98px) {
      .shop:not(.product-1) .product-title h3, .shop:not(.product-1) .product-title .h3 {
        font-size: 19px; } }

.product-title h3 a, .product-title .h3 a,
.single-product .product-title h2 a,
.single-product .product-title .h2 a {
  color: #333; }
  .dark .product-title h3 a, .dark .product-title .h3 a, .dark
  .single-product .product-title h2 a, .dark
  .single-product .product-title .h2 a {
    color: #eeeeee; }
  .product-title h3 a:hover, .product-title .h3 a:hover,
  .single-product .product-title h2 a:hover,
  .single-product .product-title .h2 a:hover {
    color: #1abc9c; }
    .dark .product-title h3 a:hover, .dark .product-title .h3 a:hover, .dark
    .single-product .product-title h2 a:hover, .dark
    .single-product .product-title .h2 a:hover {
      color: #bbbbbb; }

.product-price {
  font-size: 18px;
  font-weight: 700;
  color: #444;
  margin-bottom: 4px; }
  .dark .product-price {
    color: #bbbbbb; }
  .product-price del {
    font-weight: 400;
    font-size: 90%;
    color: #888; }
  .product-price ins {
    text-decoration: none;
    color: #1abc9c; }

.product-overlay {
  position: absolute;
  width: 100%;
  height: 44px;
  line-height: 44px;
  top: auto;
  bottom: -44px;
  left: 0;
  z-index: 6; }
  .product-overlay a {
    display: block;
    float: left;
    width: 50%;
    font-size: 13px;
    color: #333;
    background-color: whitesmoke;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1); }
    .dark .product-overlay a {
      color: #eeeeee;
      background-color: rgba(0, 0, 0, 0.8);
      border-right-color: rgba(255, 255, 255, 0.15); }
      .dark .product-overlay a:last-child {
        border-right: 0; }
    .product-overlay a.item-quick-view {
      border-right: 0; }
    .product-overlay a i {
      position: relative;
      top: 1px;
      font-size: 14px;
      margin-right: 3px; }
    .product-overlay a:hover {
      background-color: #333;
      color: #fff;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }
      .dark .product-overlay a:hover {
        background-color: rgba(0, 0, 0, 0.9); }

body:not(.device-touch):not(.device-sm):not(.device-xs) .product-overlay {
  -webkit-transition: bottom 0.3s ease-in-out;
  -o-transition: bottom 0.3s ease-in-out;
  transition: bottom 0.3s ease-in-out; }
  body:not(.device-touch):not(.device-sm):not(.device-xs) .product-overlay a {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }

.product:hover .product-overlay {
  bottom: 0; }

.sale-flash {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 6px 10px;
  background-color: #1abc9c;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  z-index: 5; }
  .sale-flash.out-of-stock {
    background-color: #777; }

/* Shop - 3 Columns
-----------------------------------------------------------------*/
.shop.product-3 {
  margin: 0 -30px -30px 0; }
  @media (max-width: 991.98px) {
    .shop.product-3 {
      margin: 0 -30px -30px 0; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .shop.product-3 {
      margin: 0 -12px -12px 0; } }

.product-3 .product {
  padding: 0 30px 30px 0;
  width: 33.33333333%; }
  @media (max-width: 991.98px) {
    .product-3 .product {
      width: 33.3333333333%;
      padding: 0 30px 30px 0; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .product-3 .product {
      width: 50%;
      padding: 0 12px 12px 0; } }

/* Shop - 3 Columns - Sidebar
-----------------------------------------------------------------*/
.postcontent .shop.product-3 {
  margin: 0 -25px -25px 0; }

.postcontent .product-3 .product {
  padding: 0 25px 25px 0; }

/* Shop - 2 Columns - Sidebar
-----------------------------------------------------------------*/
.postcontent .shop.product-2 {
  margin: 0 -40px -40px 0; }

.postcontent .product-2 .product {
  padding: 0 40px 40px 0;
  width: 50%; }

/* Shop - 2 Columns - Both Sidebar
-----------------------------------------------------------------*/
.bothsidebar .shop.product-2 {
  margin: 0 -40px -20px 0; }

.bothsidebar .product-2 .product {
  padding: 0 40px 20px 0; }

/* Shop - 1 Column
-----------------------------------------------------------------*/
.shop.product-1 {
  margin: 0 0 -40px 0; }

.product-1 .product {
  float: none;
  margin: 0 0 40px 0;
  padding: 0;
  width: 100%; }
  @media (max-width: 767.98px) {
    .product-1 .product {
      width: 100%; } }
  .product-1 .product.alt .product-image {
    float: right;
    margin: 0 0 0 30px; }

.product-1 .product-image {
  float: left;
  width: 300px;
  height: 400px;
  margin-right: 30px; }
  @media (max-width: 767.98px) {
    .product-1 .product-image {
      float: none !important;
      width: 100% !important;
      height: auto !important;
      margin: 0 0 20px !important; } }

.product-1 .product-desc {
  padding: 0;
  position: relative;
  overflow: hidden; }
  .product-1 .product-desc .product-price {
    font-size: 20px; }
  .product-1 .product-desc .product-rating {
    margin-bottom: 15px; }

.product-1 .product-title h3, .product-1 .product-title .h3 {
  font-size: 22px; }
  @media (max-width: 767.98px) {
    .product-1 .product-title h3, .product-1 .product-title .h3 {
      font-size: 19px; } }

@media (max-width: 767.98px) {
  .product-1 .product-title p {
    display: none; } }

/* Shop - 1 Column - Both Sidebar
-----------------------------------------------------------------*/
.bothsidebar .product-1 .product {
  border-top: 1px solid whitesmoke;
  padding-top: 40px; }
  .dark .bothsidebar .product-1 .product {
    border-top-color: rgba(255, 255, 255, 0.08); }
  .bothsidebar .product-1 .product:first-child {
    border-top: 0;
    padding-top: 0; }

.bothsidebar .product-1 .product-image {
  width: 240px;
  height: 320px; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .bothsidebar .product-1 .product-image {
      width: 200px;
      height: 267px; } }

.bothsidebar .product-1 .product-title h3, .bothsidebar .product-1 .product-title .h3 {
  font-size: 20px; }

.bothsidebar .product-1 .product-desc .product-price {
  font-size: 16px; }

/* Shop - Single
-----------------------------------------------------------------*/
.single-product .product {
  width: 100%;
  margin: 0; }

.single-product .product-desc {
  padding: 0; }
  .single-product .product-desc .line {
    margin: 20px 0; }

.single-product .product-title h2, .single-product .product-title .h2 {
  font-size: 28px;
  margin-bottom: 8px; }

.single-product .product-price {
  float: left;
  font-size: 24px;
  color: #1abc9c;
  margin-bottom: 0; }

.single-product .product-rating {
  float: right;
  position: relative;
  top: 9px; }

.single-product .product-image,
.single-product .product-image img {
  height: auto; }

.shop-quick-view-ajax,
.portfolio-ajax-modal {
  position: relative;
  background-color: white;
  width: 800px;
  margin: 0 auto; }
  .dark .shop-quick-view-ajax, .dark
  .portfolio-ajax-modal {
    background-color: #383838; }
  @media (max-width: 991.98px) {
    .shop-quick-view-ajax,
    .portfolio-ajax-modal {
      width: 90% !important; } }

.quantity {
  float: left;
  margin-right: 30px; }
  @media (max-width: 767.98px) {
    .quantity {
      display: block; } }
  .quantity .qty {
    float: left;
    width: 50px;
    height: 40px;
    line-height: 40px;
    border: 0;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    background-color: #eeeeee;
    text-align: center;
    margin-bottom: 0; }
    .dark .quantity .qty {
      border-left-color: rgba(255, 255, 255, 0.08);
      border-right-color: rgba(255, 255, 255, 0.08);
      background-color: rgba(0, 0, 0, 0.2); }
    @media (max-width: 767.98px) {
      .quantity .qty {
        border: 0;
        border-top: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd; } }
  .quantity .plus,
  .quantity .minus {
    display: block;
    float: left;
    cursor: pointer;
    border: 0px transparent;
    padding: 0;
    width: 36px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #eeeeee;
    font-size: 16px;
    font-weight: bold;
    -webkit-transition: background-color 0.2s linear;
    -o-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear; }
    .dark .quantity .plus, .dark
    .quantity .minus {
      background-color: rgba(0, 0, 0, 0.2); }
    .quantity .plus :hover,
    .quantity .minus :hover {
      background-color: #dddddd; }
      .dark .quantity .plus :hover, .dark
      .quantity .minus :hover {
        background-color: rgba(0, 0, 0, 0.4); }
  .dark .quantity .qty, .dark
  .quantity .plus, .dark
  .quantity .minus {
    color: #eeeeee; }
  @media (max-width: 767.98px) {
    .quantity .qty,
    .quantity .plus,
    .quantity .minus {
      float: none;
      width: 100%; } }
  .quantity .qty :focus,
  .quantity .plus :focus,
  .quantity .minus :focus {
    box-shadow: none !important;
    outline: 0 !important; }

.single-product .add-to-cart.button {
  height: 40px;
  line-height: 40px; }

.single-product .feature-box.fbox-plain.fbox-small {
  padding-left: 26px; }
  .single-product .feature-box.fbox-plain.fbox-small .fbox-icon {
    width: 20px; }
    .single-product .feature-box.fbox-plain.fbox-small .fbox-icon i {
      font-size: 18px; }
  .single-product .feature-box.fbox-plain.fbox-small h3, .single-product .feature-box.fbox-plain.fbox-small .h3 {
    font-size: 15px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 7px; }
  .single-product .feature-box.fbox-plain.fbox-small p {
    margin-left: -26px; }

.product-meta {
  font-size: 13px;
  color: #777; }
  .dark .product-meta {
    color: #888888; }
  .product-meta > .panel-body {
    margin-bottom: -5px; }
    .product-meta > .panel-body > span {
      display: block;
      margin-bottom: 5px; }

/* Individual Product
-----------------------------------------------------------------*/
.product.iproduct {
  float: none;
  margin: 0;
  width: 100%; }

.product .product-image,
.product .product-image a,
.product .product-image img {
  height: auto; }

/* ----------------------------------------------------------------
	Cart
-----------------------------------------------------------------*/
.cart .remove {
  font-size: 14px;
  color: #dc3545; }
  .dark .cart .remove {
    padding: 8px 10px;
    background: rgba(0, 0, 0, 0.2); }
    @media (max-width: 767.98px) {
      .dark .cart .remove {
        background: transparent;
        padding: 0; } }
  .cart .remove:hover {
    color: #000; }
    .dark .cart .remove:hover {
      color: #eeeeee; }

.cart th {
  padding: 12px 15px !important;
  font-size: 14px;
  color: #555; }
  .dark .cart th {
    color: #dddddd; }

.cart td {
  padding: 12px 15px !important;
  vertical-align: middle !important;
  border-color: #e5e5e5 !important; }
  .dark .cart td {
    border-color: rgba(255, 255, 255, 0.08) !important; }

.cart-product-thumbnail a {
  display: block;
  width: 68px; }

.cart-product-thumbnail img {
  display: block;
  width: 64px;
  height: 64px;
  border: 2px solid #eeeeee; }
  .dark .cart-product-thumbnail img {
    border-color: rgba(0, 0, 0, 0.2); }
  .cart-product-thumbnail img:hover {
    border-color: #1abc9c; }
    .dark .cart-product-thumbnail img:hover {
      border-color: #1abc9c; }

.cart-product-name a,
.product-name a {
  font-weight: bold;
  font-size: 13px;
  color: #333; }
  .dark .cart-product-name a, .dark
  .product-name a {
    color: #eeeeee; }

.cart-product-name a:hover,
.product-name a:hover {
  color: #666; }
  .dark .cart-product-name a:hover, .dark
  .product-name a:hover {
    color: #bbbbbb; }

.cart-product-price,
.cart-product-quantity,
.cart-product-subtotal {
  text-align: center !important; }

.cart-product-quantity .quantity {
  display: inline-block;
  margin: 0;
  float: none; }

td.actions {
  padding: 20px 15px !important;
  vertical-align: top !important;
  background-color: whitesmoke; }
  .dark td.actions {
    background-color: rgba(0, 0, 0, 0.2); }

.dark .table.cart {
  color: #eeeeee; }

/*-----------------------------------------------------------------------------------
	shortcodes
-----------------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------

	Shortcodes: countdown.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Countdown
-----------------------------------------------------------------*/
.countdown {
  display: block; }
  .countdown-row {
    display: block;
    position: relative;
    text-align: center; }
  .countdown-section {
    display: inline-block;
    font-size: 11px;
    line-height: 1;
    text-align: center;
    width: 25%;
    border-left: 1px dotted #bbbbbb;
    color: #888;
    text-transform: capitalize; }
    .countdown-section:first-child {
      border-left: 0; }
    .dark .countdown-section {
      color: #dddddd;
      border-left-color: rgba(255, 255, 255, 0.2); }
  .countdown-amount {
    display: block;
    font-size: 20px;
    color: #333;
    margin-bottom: 5px; }
  .countdown-descr {
    display: block;
    width: 100%; }

/* Countdown - Medium
-----------------------------------------------------------------*/
.countdown-medium .countdown-section {
  font-size: 14px; }

.countdown-medium .countdown-amount {
  font-size: 30px;
  margin-bottom: 7px; }

/* Countdown - Large
-----------------------------------------------------------------*/
.countdown-large .countdown-section {
  font-size: 18px; }
  @media (max-width: 575.98px) {
    .countdown-large .countdown-section {
      font-size: 14px; } }

.countdown-large .countdown-amount {
  font-size: 44px;
  font-weight: 700;
  margin-bottom: 8px; }
  @media (max-width: 575.98px) {
    .countdown-large .countdown-amount {
      font-size: 30px; } }

/* Countdown - Inline
-----------------------------------------------------------------*/
.countdown.countdown-inline {
  display: inline-block; }
  .countdown.countdown-inline .countdown-row {
    display: inline-block;
    text-align: center; }
  .countdown.countdown-inline .countdown-section {
    display: inline-block;
    font-size: inherit;
    line-height: inherit;
    width: auto;
    border: none;
    color: inherit;
    margin-left: 7px;
    text-transform: inherit; }
    .countdown.countdown-inline .countdown-section:first-child {
      margin-left: 0; }
  .countdown.countdown-inline .countdown-amount {
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: bold;
    margin: 0 3px 0 0; }
    .dark .countdown.countdown-inline .countdown-amount {
      color: #eeeeee; }
  .countdown.countdown-inline .countdown-descr {
    display: inline-block;
    width: auto; }

/* Countdown - Coming Soon
-----------------------------------------------------------------*/
body:not(.device-sm):not(.device-xs) .countdown.countdown-large.coming-soon .countdown-section {
  border: none;
  padding: 15px; }

body:not(.device-sm):not(.device-xs) .countdown.countdown-large.coming-soon .countdown-amount {
  height: 140px;
  line-height: 140px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  margin-bottom: 15px !important; }

/*-----------------------------------------------------------------------------------

	Shortcodes: buttons.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Buttons
-----------------------------------------------------------------*/
.button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  margin: 5px;
  padding: 0 22px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  background-color: #1abc9c;
  color: #FFF;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  /* Buttons - Border
	-----------------------------------------------------------------*/
  /* Buttons - 3D
	-----------------------------------------------------------------*/
  /* Buttons - Icon Reveal
	-----------------------------------------------------------------*/ }
  body:not(.device-touch) .button {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
  .button-dark {
    background-color: #444444; }
    .dark .button-dark:not(.button-border) {
      background-color: rgba(0, 0, 0, 0.3); }
    .button-dark:hover {
      background-color: #1abc9c; }
      .dark .button-dark:hover {
        background-color: #1abc9c; }
  .button-light {
    background-color: #eeeeee;
    color: #444444;
    text-shadow: none !important; }
    .dark .button-light:not(.button-border):hover {
      background-color: #383838; }
  .button:hover {
    background-color: #444444;
    color: white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }
    .dark .button:hover {
      background-color: rgba(0, 0, 0, 0.3); }
  .button-mini {
    padding: 0 14px;
    font-size: 11px;
    height: 28px;
    line-height: 28px; }
  .button-small {
    padding: 0 17px;
    font-size: 12px;
    height: 34px;
    line-height: 34px; }
  .button-large {
    padding: 0 26px;
    height: 46px;
    font-size: 16px;
    line-height: 46px; }
  .button-xlarge {
    padding: 0 32px;
    font-size: 18px;
    letter-spacing: 2px;
    height: 52px;
    line-height: 52px; }
  .button-desc {
    text-align: left;
    padding: 24px 34px;
    font-size: 22px;
    height: auto;
    line-height: 1;
    font-family: "Raleway", sans-serif; }
    @media (max-width: 767.98px) {
      .button-desc {
        max-width: 100%;
        padding: 14px 34px;
        font-size: 15px;
        line-height: 15px;
        border-radius: 3px; } }
    @media (max-width: 575.98px) {
      .button-desc {
        padding: 20px 17px;
        font-size: 15px;
        max-width: 100%; } }
    .button-desc span {
      display: block;
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1px;
      font-family: "Crete Round", serif;
      font-style: italic;
      text-transform: none; }
      @media (max-width: 767.98px) {
        .button-desc span {
          font-size: 11px; } }
  .button i {
    position: relative;
    top: 1px;
    line-height: 1;
    margin-right: 5px;
    width: 16px;
    text-align: center; }
  .button.tright i {
    margin: 0 0 0 5px; }
  .button-mini i {
    margin-right: 3px; }
  .button-mini.tright i {
    margin: 0 0 0 3px; }
  .button-small i {
    margin-right: 4px; }
  .button-small.tright i {
    margin: 0 0 0 4px; }
  .button-xlarge i {
    top: 2px;
    margin-right: 8px; }
  .button-xlarge.tright i {
    margin: 0 0 0 7px; }
  .button-desc i {
    top: 1px;
    font-size: 48px;
    width: 48px;
    text-align: center;
    margin-right: 12px; }
  .button-desc.tright i {
    margin: 0 0 0 12px; }
  .button-desc div {
    display: inline-block; }
  .button-rounded {
    border-radius: 3px; }
  .button.button-border {
    border: 2px solid #444444;
    background-color: transparent;
    color: #444444;
    line-height: 36px;
    font-weight: 700;
    text-shadow: none; }
    .dark .button.button-border:not(.button-light) {
      border-color: rgba(255, 255, 255, 0.4);
      color: rgba(255, 255, 255, 0.5); }
      .dark .button.button-border:not(.button-light):hover {
        color: #eeeeee; }
    .not-dark .button.button-border:not(.button-light) {
      border-color: #444444;
      color: #444444; }
      .not-dark .button.button-border:not(.button-light) :hover {
        background-color: #444444;
        color: white; }
    .button.button-border.button-mini {
      line-height: 24px; }
    .button.button-border.button-small {
      line-height: 30px; }
    .button.button-border.button-large {
      line-height: 42px; }
    .button.button-border.button-xlarge {
      line-height: 48px; }
    .button.button-border.button-desc {
      line-height: 1; }
    .button.button-border.button-border-thin {
      border-width: 1px;
      line-height: 38px; }
      .button.button-border.button-border-thin.button-mini {
        line-height: 26px; }
      .button.button-border.button-border-thin.button-small {
        line-height: 32px; }
      .button.button-border.button-border-thin.button-large {
        line-height: 44px; }
      .button.button-border.button-border-thin.button-xlarge {
        line-height: 50px; }
    .button.button-border:not(.button-fill):hover {
      background-color: #444444;
      color: white;
      border-color: transparent !important;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }
    .button.button-border.button-light {
      border-color: #eeeeee;
      color: #eeeeee; }
      .button.button-border.button-light:hover {
        background-color: #eeeeee;
        color: #444444;
        text-shadow: none; }
  .button.button-3d {
    border-radius: 3px;
    border-bottom: 3px solid rgba(0, 0, 0, 0.15);
    -webkit-transition: none;
    -o-transition: none;
    transition: none; }
    .button.button-3d:hover {
      background-color: #1abc9c !important;
      opacity: 0.9; }
  .button.button-3d.button-light:hover, .button.button-reveal.button-light:hover {
    text-shadow: none;
    color: #444444; }
  .button.button-reveal {
    padding: 0 28px;
    overflow: hidden; }
    .button.button-reveal i {
      display: block;
      position: absolute;
      top: 0;
      left: -32px;
      width: 32px;
      height: 40px;
      line-height: 40px;
      margin: 0;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.1); }
      body:not(.device-touch) .button.button-reveal i {
        -webkit-transition: left 0.3s ease, right 0.3s ease;
        -o-transition: left 0.3s ease, right 0.3s ease;
        transition: left 0.3s ease, right 0.3s ease; }
    .button.button-reveal.button-border i {
      top: -2px; }
    .button.button-reveal.tright:hover span {
      left: -16px; }
    .button.button-reveal.tright i {
      left: auto;
      right: -32px; }
    .button.button-reveal.button-3d i, .button.button-reveal.button-rounded i {
      border-radius: 3px 0 0 3px; }
    .button.button-reveal.button-3d.tright i,
    .button.button-reveal.button-3d.tright i, .button.button-reveal.button-rounded.tright i,
    .button.button-reveal.button-rounded.tright i {
      border-radius: 0 3px 3px 0; }
    .button.button-reveal span {
      display: inline-block;
      position: relative;
      left: 0; }
      body:not(.device-touch) .button.button-reveal span {
        -webkit-transition: left 0.3s ease, right 0.3s ease;
        -o-transition: left 0.3s ease, right 0.3s ease;
        transition: left 0.3s ease, right 0.3s ease; }
    .button.button-reveal.button-mini {
      padding: 0 17px; }
      .button.button-reveal.button-mini i {
        left: -22px;
        width: 22px;
        height: 28px;
        line-height: 28px; }
      .button.button-reveal.button-mini.tright:hover span {
        left: -11px; }
      .button.button-reveal.button-mini.tright i {
        left: auto;
        right: -22px; }
      .button.button-reveal.button-mini:hover span {
        left: 11px; }
    .button.button-reveal.button-small {
      padding: 0 22px; }
      .button.button-reveal.button-small i {
        left: -26px;
        width: 26px;
        height: 34px;
        line-height: 34px; }
      .button.button-reveal.button-small.tright:hover span {
        left: -13px; }
      .button.button-reveal.button-small.tright i {
        left: auto;
        right: -26px; }
      .button.button-reveal.button-small:hover span {
        left: 13px; }
    .button.button-reveal.button-large {
      padding: 0 32px; }
      .button.button-reveal.button-large i {
        left: -38px;
        width: 38px;
        height: 46px;
        line-height: 46px; }
      .button.button-reveal.button-large.tright:hover span {
        left: -19px; }
      .button.button-reveal.button-large.tright i {
        left: auto;
        right: -38px; }
      .button.button-reveal.button-large:hover span {
        left: 19px; }
    .button.button-reveal.button-xlarge {
      padding: 0 40px; }
      .button.button-reveal.button-xlarge i {
        left: -44px;
        width: 44px;
        height: 52px;
        line-height: 52px; }
      .button.button-reveal.button-xlarge.tright:hover span {
        left: -22px; }
      .button.button-reveal.button-xlarge.tright i {
        left: auto;
        right: -44px; }
      .button.button-reveal.button-xlarge:hover span {
        left: 22px; }
    .button.button-reveal:hover i {
      left: 0; }
    .button.button-reveal:hover span {
      left: 16px; }
    .button.button-reveal.tright:hover i {
      left: auto;
      right: 0; }

/* Buttons - Promo 100% Width
-----------------------------------------------------------------*/
.button.button-full {
  display: block;
  width: 100%;
  white-space: normal;
  margin: 0;
  height: auto;
  line-height: 1.6;
  padding: 30px 0;
  font-size: 30px;
  font-weight: 300;
  text-transform: none;
  border-radius: 0;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); }
  @media (max-width: 991.98px) {
    .button.button-full {
      font-size: 22px; }
      .button.button-full i {
        top: 3px !important; } }
  @media (max-width: 767.98px) {
    .button.button-full {
      font-size: 18px; }
      .button.button-full i {
        top: 2px !important;
        margin-left: 3px !important; } }
  @media (max-width: 575.98px) {
    .button.button-full {
      font-size: 16px; } }
  .button.button-full.button-light {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
    .button.button-full.button-light strong {
      border-bottom-color: #444444; }
    .button.button-full.button-light:hover strong {
      border-bottom-color: #eeeeee; }
  .button.button-full strong {
    font-weight: 700;
    border-bottom: 2px solid #eeeeee; }
    body:not(.device-touch) .button.button-full strong {
      -webkit-transition: border 0.2s ease-in-out;
      -o-transition: border 0.2s ease-in-out;
      transition: border 0.2s ease-in-out; }

/* Buttons - Circle
-----------------------------------------------------------------*/
.button.button-circle {
  border-radius: 20px; }
  .button.button-circle.button-mini {
    border-radius: 14px; }
  .button.button-circle.button-small {
    border-radius: 17px; }
  .button.button-circle.button-large {
    border-radius: 23px; }
  .button.button-circle.button-xlarge {
    border-radius: 26px; }

/* Buttons - Fill Effect
-----------------------------------------------------------------*/
.button.button-border.button-fill {
  overflow: hidden;
  transform-style: preserve-3d;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
  -webkit-backface-visibility: hidden; }
  .button.button-border.button-fill span {
    position: relative; }
  .button.button-border.button-fill:hover {
    background-color: transparent !important;
    color: #FFF;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }
    .button.button-border.button-fill:hover:before {
      width: 100%; }
  .button.button-border.button-fill.button-light:hover {
    border-color: #eeeeee !important;
    color: #444444 !important;
    text-shadow: none; }
  .button.button-border.button-fill.button-light:before {
    background-color: #eeeeee; }
  .button.button-border.button-fill:before {
    content: '';
    position: absolute;
    background-color: #444444;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    -webkit-backface-visibility: hidden; }
  .button.button-border.button-fill.fill-from-right:before {
    left: auto;
    right: 0; }
  .button.button-border.button-fill.fill-from-top:before, .button.button-border.button-fill.fill-from-bottom:before {
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease; }
  .button.button-border.button-fill.fill-from-top:before {
    width: 100%;
    height: 0; }
  .button.button-border.button-fill.fill-from-top:hover:before {
    height: 100%; }
  .button.button-border.button-fill.fill-from-bottom:before {
    width: 100%;
    height: 0;
    top: auto;
    bottom: 0; }
  .button.button-border.button-fill.fill-from-bottom:hover:before {
    height: 100%; }

/* Buttons - Colors
-----------------------------------------------------------------*/
.button-blue {
  background-color: #007bff; }
  .button-blue.button-3d:hover, .button-blue.button-reveal:hover, .button-blue.button-border:hover, .button-blue.button-border.button-fill:before {
    background-color: #007bff !important; }

.button-border.button-blue, .button-border.button-light.button-blue {
  color: #007bff;
  border-color: #007bff; }

.button-indigo {
  background-color: #6610f2; }
  .button-indigo.button-3d:hover, .button-indigo.button-reveal:hover, .button-indigo.button-border:hover, .button-indigo.button-border.button-fill:before {
    background-color: #6610f2 !important; }

.button-border.button-indigo, .button-border.button-light.button-indigo {
  color: #6610f2;
  border-color: #6610f2; }

.button-purple {
  background-color: #6f42c1; }
  .button-purple.button-3d:hover, .button-purple.button-reveal:hover, .button-purple.button-border:hover, .button-purple.button-border.button-fill:before {
    background-color: #6f42c1 !important; }

.button-border.button-purple, .button-border.button-light.button-purple {
  color: #6f42c1;
  border-color: #6f42c1; }

.button-pink {
  background-color: #e83e8c; }
  .button-pink.button-3d:hover, .button-pink.button-reveal:hover, .button-pink.button-border:hover, .button-pink.button-border.button-fill:before {
    background-color: #e83e8c !important; }

.button-border.button-pink, .button-border.button-light.button-pink {
  color: #e83e8c;
  border-color: #e83e8c; }

.button-red {
  background-color: #dc3545; }
  .button-red.button-3d:hover, .button-red.button-reveal:hover, .button-red.button-border:hover, .button-red.button-border.button-fill:before {
    background-color: #dc3545 !important; }

.button-border.button-red, .button-border.button-light.button-red {
  color: #dc3545;
  border-color: #dc3545; }

.button-orange {
  background-color: #fd7e14; }
  .button-orange.button-3d:hover, .button-orange.button-reveal:hover, .button-orange.button-border:hover, .button-orange.button-border.button-fill:before {
    background-color: #fd7e14 !important; }

.button-border.button-orange, .button-border.button-light.button-orange {
  color: #fd7e14;
  border-color: #fd7e14; }

.button-yellow {
  background-color: #ffc107; }
  .button-yellow.button-3d:hover, .button-yellow.button-reveal:hover, .button-yellow.button-border:hover, .button-yellow.button-border.button-fill:before {
    background-color: #ffc107 !important; }

.button-border.button-yellow, .button-border.button-light.button-yellow {
  color: #ffc107;
  border-color: #ffc107; }

.button-green {
  background-color: #28a745; }
  .button-green.button-3d:hover, .button-green.button-reveal:hover, .button-green.button-border:hover, .button-green.button-border.button-fill:before {
    background-color: #28a745 !important; }

.button-border.button-green, .button-border.button-light.button-green {
  color: #28a745;
  border-color: #28a745; }

.button-teal {
  background-color: #20c997; }
  .button-teal.button-3d:hover, .button-teal.button-reveal:hover, .button-teal.button-border:hover, .button-teal.button-border.button-fill:before {
    background-color: #20c997 !important; }

.button-border.button-teal, .button-border.button-light.button-teal {
  color: #20c997;
  border-color: #20c997; }

.button-cyan {
  background-color: #17a2b8; }
  .button-cyan.button-3d:hover, .button-cyan.button-reveal:hover, .button-cyan.button-border:hover, .button-cyan.button-border.button-fill:before {
    background-color: #17a2b8 !important; }

.button-border.button-cyan, .button-border.button-light.button-cyan {
  color: #17a2b8;
  border-color: #17a2b8; }

.button-white {
  background-color: #fff; }
  .button-white.button-3d:hover, .button-white.button-reveal:hover, .button-white.button-border:hover, .button-white.button-border.button-fill:before {
    background-color: #fff !important; }

.button-border.button-white, .button-border.button-light.button-white {
  color: #fff;
  border-color: #fff; }

.button-gray {
  background-color: #6c757d; }
  .button-gray.button-3d:hover, .button-gray.button-reveal:hover, .button-gray.button-border:hover, .button-gray.button-border.button-fill:before {
    background-color: #6c757d !important; }

.button-border.button-gray, .button-border.button-light.button-gray {
  color: #6c757d;
  border-color: #6c757d; }

.button-gray-dark {
  background-color: #343a40; }
  .button-gray-dark.button-3d:hover, .button-gray-dark.button-reveal:hover, .button-gray-dark.button-border:hover, .button-gray-dark.button-border.button-fill:before {
    background-color: #343a40 !important; }

.button-border.button-gray-dark, .button-border.button-light.button-gray-dark {
  color: #343a40;
  border-color: #343a40; }

/* Buttons - No Hover
-----------------------------------------------------------------*/
.button.button-nohover:hover {
  opacity: inherit !important;
  background-color: inherit !important;
  color: inherit !important;
  border-color: inherit !important; }

/*-----------------------------------------------------------------------------------

	Shortcodes: promo.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Promo Boxes
-----------------------------------------------------------------*/
.promo {
  position: relative;
  padding: 30px 200px 30px 0; }
  @media (max-width: 991.98px) {
    .promo:not(.promo-full) {
      padding: 30px 20px !important; } }
  .promo h3, .promo .h3 {
    font-weight: bold;
    margin-bottom: 0;
    font-size: 24px;
    color: #444444; }
    @media (max-width: 1199.98px) {
      .promo h3, .promo .h3 {
        font-size: 20px; } }
  .promo a.button {
    position: absolute;
    top: 50%;
    margin: 0;
    margin-top: -18px;
    left: auto;
    right: 0; }
    @media (max-width: 991.98px) {
      .promo a.button {
        position: relative !important;
        top: 0 !important;
        margin: 20px 0 0 !important;
        left: 0 !important;
        right: 0; } }
  .promo > span,
  .promo > .container > span {
    display: block;
    color: #444444;
    font-weight: 300;
    font-size: 16px;
    margin-top: 6px; }
    @media (max-width: 1199.98px) {
      .promo > span,
      .promo > .container > span {
        font-size: 15px; } }
    .dark .promo > span, .dark
    .promo > .container > span {
      color: rgba(255, 255, 255, 0.8); }
  .promo a.button-mini {
    margin-top: -12px; }
  .promo a.button-small {
    margin-top: -15px; }
  .promo a.button-large {
    margin-top: -22px; }
  .promo a.button-xlarge {
    margin-top: -25px; }
  .promo a.button-desc {
    margin-top: -40px; }

/* Promo Uppercase
-----------------------------------------------------------------*/
.promo-uppercase {
  text-transform: uppercase; }
  .promo-uppercase h3, .promo-uppercase .h3 {
    font-size: 22px; }
    @media (max-width: 1199.98px) {
      .promo-uppercase h3, .promo-uppercase .h3 {
        font-size: 19px; } }
  .promo-uppercase > span,
  .promo-uppercase > .container > span {
    font-size: 15px; }
    @media (max-width: 1199.98px) {
      .promo-uppercase > span,
      .promo-uppercase > .container > span {
        font-size: 14px; } }

/* Promo - with Border
-----------------------------------------------------------------*/
.promo.promo-border {
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  padding-left: 30px; }
  .dark .promo.promo-border {
    border-color: rgba(255, 255, 255, 0.08); }
  .promo.promo-border a.button {
    right: 30px; }

/* Promo - Light
-----------------------------------------------------------------*/
.promo.promo-light {
  background-color: whitesmoke;
  border-radius: 3px;
  padding-left: 30px; }
  .dark .promo.promo-light {
    background-color: rgba(255, 255, 255, 0.1); }
  .promo.promo-light a.button {
    right: 30px; }

/* Promo - Dark
-----------------------------------------------------------------*/
.promo.promo-dark {
  border: 0 !important;
  background-color: #333333;
  padding: 30px 200px 30px 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px; }
  .dark .promo.promo-dark:not(.promo-flat) {
    background-color: rgba(0, 0, 0, 0.3); }
  .promo.promo-dark h3, .promo.promo-dark .h3 {
    color: white; }
    @media (max-width: 991.98px) {
      .promo.promo-dark h3, .promo.promo-dark .h3 {
        line-height: 1.6; } }
  .promo.promo-dark a.button {
    right: 30px; }
  .promo.promo-dark > h3 span, .promo.promo-dark > .h3 span,
  .promo.promo-dark > .container > h3 span,
  .promo.promo-dark > .container > .h3 span {
    padding: 2px 8px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.15);
    color: white;
    font-weight: 400; }
  .promo.promo-dark > span,
  .promo.promo-dark > .container > span {
    color: #cccccc;
    margin-top: 8px; }

/* Promo - Flat
-----------------------------------------------------------------*/
.promo.promo-flat {
  background-color: #1abc9c; }
  .promo.promo-flat a.button:not(.button-border) {
    background-color: rgba(0, 0, 0, 0.2);
    color: white; }
  .promo.promo-flat a.button:hover {
    background-color: white;
    border-color: white;
    color: #444444;
    text-shadow: none; }
  .promo.promo-flat > span,
  .promo.promo-flat .promo.promo-flat > .container > span {
    color: rgba(255, 255, 255, 0.9); }

/* Promo - 100% Full Width
-----------------------------------------------------------------*/
.promo-full {
  border-radius: 3px !important;
  border-left: 0 !important;
  border-right: 0 !important;
  padding: 40px 0 !important; }
  .promo-full a.button {
    right: 15px !important; }
  .promo-full.promo-right a.button {
    right: auto !important;
    left: 15px !important; }

/* Promo - Parallax
-----------------------------------------------------------------*/
.promo.parallax {
  padding: 80px 0 !important; }

/* Promo - Right Align
-----------------------------------------------------------------*/
.promo.promo-right {
  text-align: right;
  padding: 30px 0 30px 200px; }
  .promo.promo-right a.button {
    left: 0;
    right: auto; }

/* Promo - with Border & Right Align
-----------------------------------------------------------------*/
.promo.promo-right.promo-border:not(.promo-mini), .promo.promo-dark.promo-right:not(.promo-mini) {
  padding-right: 30px; }

.promo.promo-right.promo-border a.button, .promo.promo-dark.promo-right a.button {
  left: 30px; }

/* Promo - Center Align
-----------------------------------------------------------------*/
.promo.promo-center {
  text-align: center;
  padding: 30px 20px; }
  .promo.promo-center a.button {
    position: relative;
    top: 0;
    margin: 20px 0 0;
    left: 0 !important;
    right: 0; }

/* Promo - Mini
-----------------------------------------------------------------*/
.promo.promo-mini {
  text-align: left;
  padding: 20px 25px; }
  .promo.promo-mini h3, .promo.promo-mini .h3 {
    font-size: 20px;
    line-height: 1.5; }
  .promo.promo-mini.promo-uppercase h3, .promo.promo-mini.promo-uppercase .h3 {
    font-size: 18px; }
  .promo.promo-mini.promo-dark h3, .promo.promo-mini.promo-dark .h3 {
    line-height: 1.7; }
  .promo.promo-mini a.button {
    position: relative;
    top: 0;
    margin: 20px 0 0;
    left: 0 !important;
    right: 0; }

/* Promo - Mini - Center & Right Align
-----------------------------------------------------------------*/
.promo.promo-mini.promo-center {
  text-align: center; }

.promo.promo-mini.promo-right {
  text-align: right; }

/*-----------------------------------------------------------------------------------

	Shortcodes: feature-box.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Featured Boxes
-----------------------------------------------------------------*/
.feature-box {
  position: relative;
  margin-top: 20px;
  padding: 0 0 0 80px; }
  .feature-box:first-child {
    margin-top: 0; }
  .feature-box .fbox-icon {
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    top: 0;
    left: 0; }
    .feature-box .fbox-icon a,
    .feature-box .fbox-icon i,
    .feature-box .fbox-icon img {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      color: #fff; }
    .feature-box .fbox-icon i,
    .feature-box .fbox-icon img {
      border-radius: 50%;
      background-color: #1abc9c; }
    .feature-box .fbox-icon i {
      font-style: normal;
      font-size: 28px;
      text-align: center;
      line-height: 64px; }
  .feature-box h3, .feature-box .h3 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0;
    color: #333333; }
    .dark .feature-box h3, .dark .feature-box .h3 {
      color: #cccccc; }
  .feature-box p {
    margin: 8px 0 0 0;
    color: #999999; }
    .dark .feature-box p {
      color: #888888; }
  .feature-box .before-heading {
    font-size: 14px; }

/* Feature Box - Icon Large
-----------------------------------------------------------------*/
.feature-box.fbox-large {
  padding: 0 0 0 115px; }
  .feature-box.fbox-large .fbox-icon {
    width: 96px;
    height: 96px; }
    .feature-box.fbox-large .fbox-icon i {
      font-size: 42px;
      line-height: 96px; }

/* Feature Box - Icon Outline
-----------------------------------------------------------------*/
.feature-box.fbox-outline .fbox-icon {
  border: 1px solid #1abc9c;
  border-radius: 50%;
  padding: 3px; }
  .feature-box.fbox-outline .fbox-icon i {
    line-height: 56px; }

/* Feature Box - Icon Outline Large
-----------------------------------------------------------------*/
.feature-box.fbox-outline.fbox-large .fbox-icon {
  padding: 4px; }
  .feature-box.fbox-outline.fbox-large .fbox-icon i {
    line-height: 86px; }

/* Feature Box - Icon Rounded
-----------------------------------------------------------------*/
.feature-box.fbox-rounded .fbox-icon {
  border-radius: 3px !important; }
  .feature-box.fbox-rounded .fbox-icon i,
  .feature-box.fbox-rounded .fbox-icon img {
    border-radius: 3px !important; }

/* Feature Box - Icon Rounded & Large
-----------------------------------------------------------------*/
.feature-box.fbox-rounded.fbox-large .fbox-icon {
  border-radius: 4px !important; }
  .feature-box.fbox-rounded.fbox-large .fbox-icon i,
  .feature-box.fbox-rounded.fbox-large .fbox-icon img {
    border-radius: 4px !important; }

/* Feature Box - Light Background
-----------------------------------------------------------------*/
.feature-box.fbox-light.fbox-outline .fbox-icon {
  border-color: #e5e5e5; }
  .dark .feature-box.fbox-light.fbox-outline .fbox-icon {
    border-color: rgba(255, 255, 255, 0.15); }
  .feature-box.fbox-light.fbox-outline .fbox-icon i {
    line-height: 56px; }

.feature-box.fbox-light.fbox-outline.fbox-large .fbox-icon i {
  line-height: 86px; }

.feature-box.fbox-light .fbox-icon i,
.feature-box.fbox-light .fbox-icon img {
  border: 1px solid #e5e5e5;
  background-color: whitesmoke;
  color: #444444; }
  .dark .feature-box.fbox-light .fbox-icon i, .dark
  .feature-box.fbox-light .fbox-icon img {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.1);
    color: white; }

.feature-box.fbox-light .fbox-icon i {
  line-height: 62px; }

.feature-box.fbox-large .fbox-icon i {
  line-height: 96px; }

/* Feature Box - Dark Background
-----------------------------------------------------------------*/
.feature-box.fbox-dark.fbox-outline .fbox-icon {
  border-color: #444444; }
  .dark .feature-box.fbox-dark.fbox-outline .fbox-icon {
    border-color: rgba(255, 255, 255, 0.15); }

.feature-box.fbox-dark .fbox-icon i,
.feature-box.fbox-dark .fbox-icon img {
  background-color: #333; }
  .dark .feature-box.fbox-dark .fbox-icon i, .dark
  .feature-box.fbox-dark .fbox-icon img {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.1);
    color: white; }

/* Feature Box - Border
-----------------------------------------------------------------*/
.feature-box.fbox-border .fbox-icon {
  border: 1px solid #1abc9c;
  border-radius: 50%; }
  .feature-box.fbox-border .fbox-icon i,
  .feature-box.fbox-border .fbox-icon img {
    border: none;
    background-color: transparent !important;
    color: #1abc9c; }

.feature-box.fbox-border .fbox-icon {
  padding: 0; }
  .feature-box.fbox-border .fbox-icon i {
    line-height: 62px !important; }

.feature-box.fbox-border.fbox-large .fbox-icon i {
  line-height: 94px !important; }

/* Feature Box - Border - Light
-----------------------------------------------------------------*/
.feature-box.fbox-border.fbox-light .fbox-icon {
  border-color: #e5e5e5; }
  .dark .feature-box.fbox-border.fbox-light .fbox-icon {
    border-color: rgba(255, 255, 255, 0.15); }

.feature-box.fbox-border.fbox-light i,
.feature-box.fbox-border.fbox-light img {
  color: #888; }
  .dark .feature-box.fbox-border.fbox-light i, .dark
  .feature-box.fbox-border.fbox-light img {
    color: white; }

/* Feature Box - Border - Dark
-----------------------------------------------------------------*/
.feature-box.fbox-border.fbox-dark .fbox-icon {
  border-color: #333; }
  .dark .feature-box.fbox-border.fbox-dark .fbox-icon {
    border-color: rgba(255, 255, 255, 0.15); }
  .feature-box.fbox-border.fbox-dark .fbox-icon i,
  .feature-box.fbox-border.fbox-dark .fbox-icon img {
    color: #444444; }
    .dark .feature-box.fbox-border.fbox-dark .fbox-icon i, .dark
    .feature-box.fbox-border.fbox-dark .fbox-icon img {
      color: white; }

/* Feature Box - Plain
-----------------------------------------------------------------*/
.feature-box.fbox-plain .fbox-icon {
  border: none !important;
  height: auto !important; }
  .feature-box.fbox-plain .fbox-icon i,
  .feature-box.fbox-plain .fbox-icon img {
    border: none !important;
    background-color: transparent !important;
    color: #1abc9c;
    border-radius: 0; }
  .feature-box.fbox-plain .fbox-icon i {
    font-size: 28px;
    line-height: 1 !important; }
  .feature-box.fbox-plain .fbox-icon img {
    height: 64px; }

.feature-box.fbox-plain.fbox-image .fbox-icon {
  width: auto; }

.feature-box.fbox-plain.fbox-image img {
  width: auto;
  display: inline-block; }

.feature-box.fbox-plain.fbox-small {
  padding-left: 42px; }
  .feature-box.fbox-plain.fbox-small .fbox-icon {
    width: 28px; }
    .feature-box.fbox-plain.fbox-small .fbox-icon i {
      font-size: 28px; }
    .feature-box.fbox-plain.fbox-small .fbox-icon img {
      height: 28px; }
  .feature-box.fbox-plain.fbox-small h3, .feature-box.fbox-plain.fbox-small .h3 {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px; }
  .feature-box.fbox-plain.fbox-small p {
    margin-left: -round(42px); }

.feature-box.fbox-plain.fbox-large .fbox-icon i {
  font-size: 42px; }

.feature-box.fbox-plain.fbox-large .fbox-icon img {
  height: 96px; }

.feature-box.fbox-plain.fbox-light .fbox-icon i,
.feature-box.fbox-plain.fbox-light .fbox-icon img {
  color: #888; }
  .dark .feature-box.fbox-plain.fbox-light .fbox-icon i, .dark
  .feature-box.fbox-plain.fbox-light .fbox-icon img {
    color: white; }

.feature-box.fbox-plain.fbox-dark .fbox-icon i,
.feature-box.fbox-plain.fbox-dark .fbox-icon img {
  color: #444444; }
  .dark .feature-box.fbox-plain.fbox-dark .fbox-icon i, .dark
  .feature-box.fbox-plain.fbox-dark .fbox-icon img {
    color: white; }

/* Feature Box - Center
-----------------------------------------------------------------*/
.feature-box.fbox-center {
  padding: 0;
  text-align: center; }
  .feature-box.fbox-center.fbox-small {
    padding-left: 0 !important; }
  .feature-box.fbox-center .fbox-icon {
    position: relative;
    width: 96px;
    height: 96px;
    margin: 0 auto 30px; }
    .feature-box.fbox-center .fbox-icon i {
      font-size: 42px;
      line-height: 96px; }
  .feature-box.fbox-center.fbox-outline .fbox-icon i {
    line-height: 88px; }
  .feature-box.fbox-center p {
    font-size: 14px;
    margin-top: 15px; }
  .dark .feature-box.fbox-center {
    border-top: rgba(255, 255, 255, 0.15); }

/* Feature Box - Center & Large Icons
-----------------------------------------------------------------*/
.feature-box.fbox-center.fbox-large .fbox-icon {
  width: 128px;
  height: 128px; }
  .feature-box.fbox-center.fbox-large .fbox-icon i {
    font-size: 70px;
    line-height: 128px; }

.feature-box.fbox-center.fbox-large.fbox-outline .fbox-icon i {
  line-height: 120px; }

.feature-box.fbox-center.fbox-light .fbox-icon i {
  line-height: 94px; }

.feature-box.fbox-center.fbox-light .fbox-outline .fbox-icon i {
  line-height: 88px; }

.feature-box.fbox-center.fbox-light .fbox-outline .fbox-large .fbox-icon i {
  line-height: 120px; }

.feature-box.fbox-center.fbox-border .fbox-icon i {
  line-height: 90px !important; }

.feature-box.fbox-center.fbox-large.fbox-border .fbox-icon i {
  line-height: 126px !important; }

.feature-box.fbox-center.fbox-large .fbox-plain .fbox-icon i {
  font-size: 112px; }

.feature-box.fbox-center.fbox-large .fbox-plain .fbox-icon img {
  height: 128px; }

.feature-box.fbox-center.fbox-plain .fbox-icon i {
  font-size: 84px; }

.feature-box.fbox-center.fbox-plain .fbox-icon img {
  height: 96px; }

/* Feature Box - Center & Large Icons
-----------------------------------------------------------------*/
.feature-box.fbox-center.fbox-small .fbox-icon {
  width: 64px;
  height: 64px; }
  .feature-box.fbox-center.fbox-small .fbox-icon i {
    font-size: 28px;
    line-height: 64px; }

.feature-box.fbox-center.fbox-small.fbox-outline .fbox-icon i {
  line-height: 122px; }

.feature-box.fbox-center .fbox-light.fbox-outline.fbox-small .fbox-icon i {
  line-height: 120px; }

.feature-box.fbox-center.fbox-small.fbox-border .fbox-icon i {
  line-height: 56px !important; }

.feature-box.fbox-center.fbox-small .fbox-plain .fbox-icon i {
  font-size: 90px; }

.feature-box.fbox-center.fbox-small .fbox-plain .fbox-icon img {
  height: 64px; }

.feature-box.fbox-center.fbox-small p {
  font-size: 14px;
  margin-top: 10px; }

.feature-box.fbox-center .fbox-plain.fbox-small p {
  margin-left: 0; }

/* Feature Box - Right
-----------------------------------------------------------------*/
.feature-box.fbox-right {
  padding: 0 80px 0 0;
  text-align: right; }
  .feature-box.fbox-right.fbox-large {
    padding-right: 115px; }
  .feature-box.fbox-right .fbox-icon {
    left: auto;
    right: 0; }
  .feature-box.fbox-right.fbox-plain.fbox-small {
    padding: 0 42px 0 0; }
    .feature-box.fbox-right.fbox-plain.fbox-small p {
      margin: -42px 0 0; }

/* Feature Box - Subtitle
-----------------------------------------------------------------*/
.feature-box h3 + .fbox-icon, .feature-box .h3 + .fbox-icon {
  margin-top: 20px !important; }

.feature-box h3 span.subtitle, .feature-box .h3 span.subtitle {
  display: block;
  margin-top: 5px;
  color: #444444;
  font-weight: 300;
  text-transform: none; }
  .dark .feature-box h3 span.subtitle, .dark .feature-box .h3 span.subtitle {
    color: #aaaaaa; }

.feature-box.fbox-center:not(.fbox-bg) h3:after, .feature-box.fbox-center:not(.fbox-bg) .h3:after {
  content: '';
  display: block;
  margin: 20px auto;
  width: 30px;
  border-top: 2px solid #555;
  -webkit-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease; }
  .dark .feature-box.fbox-center:not(.fbox-bg) h3:after, .dark .feature-box.fbox-center:not(.fbox-bg) .h3:after {
    border-top: rgba(255, 255, 255, 0.15); }

.feature-box.fbox-center:not(.fbox-bg):hover h3:after, .feature-box.fbox-center:not(.fbox-bg):hover .h3:after {
  width: 50px; }

.feature-box.fbox-center.noborder:not(.fbox-bg) h3:after, .feature-box.fbox-center.noborder:not(.fbox-bg) .h3:after,
.feature-box.fbox-center.nobottomborder:not(.fbox-bg) h3:after,
.feature-box.fbox-center.nobottomborder:not(.fbox-bg) .h3:after {
  display: none; }

.feature-box.fbox-center.fbox-italic p {
  font-style: italic;
  font-family: "Lato", sans-serif; }

/* Feature Box - Background
-----------------------------------------------------------------*/
.feature-box.fbox-bg.fbox-center {
  margin-top: 68px;
  padding: 68px 30px 30px;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 5px; }
  .dark .feature-box.fbox-bg.fbox-center {
    background-color: #383838;
    border-color: rgba(255, 255, 255, 0.1); }
  .feature-box.fbox-bg.fbox-center:first-child {
    margin-top: 48px; }
  .feature-box.fbox-bg.fbox-center.fbox-large {
    margin-top: 84px;
    padding-top: 84px; }
    .feature-box.fbox-bg.fbox-center.fbox-large:first-child {
      margin-top: 64px; }
    .feature-box.fbox-bg.fbox-center.fbox-large .fbox-icon {
      top: -64px;
      left: 50%;
      margin-left: -64px; }
  .feature-box.fbox-bg.fbox-center .fbox-icon {
    position: absolute;
    top: -48px;
    left: 50%;
    margin: 0 0 0 -48px; }
  .feature-box.fbox-bg.fbox-center.fbox-plain .fbox-icon, .feature-box.fbox-bg.fbox-center.fbox-border .fbox-icon, .feature-box.fbox-bg.fbox-center.fbox-outline .fbox-icon {
    background-color: white; }
    .dark .feature-box.fbox-bg.fbox-center.fbox-plain .fbox-icon, .dark .feature-box.fbox-bg.fbox-center.fbox-border .fbox-icon, .dark .feature-box.fbox-bg.fbox-center.fbox-outline .fbox-icon {
      background-color: #383838 !important; }
  .dark .feature-box.fbox-bg.fbox-center.fbox-light .fbox-icon i {
    background-color: #444444 !important; }

/* Animated Icon Hovers
-----------------------------------------------------------------*/
.fbox-effect .fbox-icon i {
  z-index: 1;
  -webkit-transition: background-color 0.3s, color 0.3s;
  -o-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s; }

.fbox-effect .fbox-icon i:after {
  top: -3px;
  left: -3px;
  padding: 3px;
  box-shadow: 0 0 0 2px #333333;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -o-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: -webkit-transform 0.3s, opacity 0.3s;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }
  .dark .fbox-effect .fbox-icon i:after {
    box-shadow: 0 0 0 2px #494949; }

.fbox-rounded.fbox-effect .fbox-icon i:after {
  border-radius: 3px; }

.fbox-effect .fbox-icon i:hover,
.fbox-effect:hover .fbox-icon i {
  background-color: #333;
  color: #fff; }
  .dark .fbox-effect .fbox-icon i:hover, .dark
  .fbox-effect:hover .fbox-icon i {
    background-color: #494949; }

.fbox-effect.fbox-dark .fbox-icon i:after,
.dark .fbox-effect.fbox-dark .fbox-icon i:after {
  box-shadow: 0 0 0 2px #1abc9c; }

.fbox-effect.fbox-dark .fbox-icon i:hover,
.fbox-effect.fbox-dark:hover .fbox-icon i {
  background-color: #1abc9c; }

.fbox-effect .fbox-icon i:hover:after,
.fbox-effect:hover .fbox-icon i:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

/* Icon Effects - Bordered
-----------------------------------------------------------------*/
.fbox-border.fbox-effect .fbox-icon i {
  -webkit-transition: color 0.5s, box-shadow 0.5s, background-color 0.5s;
  -o-transition: color 0.5s, box-shadow 0.5s, background-color 0.5s;
  transition: color 0.5s, box-shadow 0.5s, background-color 0.5s; }

.fbox-border.fbox-effect .fbox-icon i:after {
  top: -2px;
  left: -2px;
  padding: 2px;
  z-index: -1;
  box-shadow: none;
  background-image: url("image/icons/iconalt.svg");
  background-position: center center;
  background-size: 100% 100%;
  -webkit-transition: -webkit-transform 0.5s, opacity 0.5s, background-color 0.5s;
  -o-transition: -webkit-transform 0.5s, opacity 0.5s, background-color 0.5s;
  transition: -webkit-transform 0.5s, opacity 0.5s, background-color 0.5s; }
  .dark .fbox-border.fbox-effect .fbox-icon i:after {
    box-shadow: 0 0 0 1px #494949; }

.fbox-border.fbox-rounded.fbox-effect .fbox-icon i:after {
  border-radius: 3px; }

.fbox-border.fbox-effect .fbox-icon i:hover,
.fbox-border.fbox-effect:hover .fbox-icon i {
  color: #fff;
  box-shadow: 0 0 0 1px #333; }

.fbox-border.fbox-effect .fbox-icon i:after {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  opacity: 0;
  background-color: #333; }

.fbox-border.fbox-effect.fbox-dark .fbox-icon i:hover,
.fbox-border.fbox-effect.fbox-dark:hover .fbox-icon i,
.dark .fbox-border.fbox-effect.fbox-dark .fbox-icon i:hover,
.dark .fbox-border.fbox-effect.fbox-dark:hover .fbox-icon i {
  box-shadow: 0 0 0 1px #1abc9c; }

.fbox-border.fbox-effect.fbox-dark .fbox-icon i:after,
.dark .fbox-border.fbox-effect.fbox-dark .fbox-icon i:after {
  background-color: #1abc9c; }

.fbox-border.fbox-effect .fbox-icon i:hover:after,
.fbox-border.fbox-effect:hover .fbox-icon i:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

/* Media Featured Box
-----------------------------------------------------------------*/
.feature-box.media-box {
  padding: 0; }

.feature-box.media-box .fbox-media {
  margin: 0 0 25px; }

.feature-box.media-box .fbox-media,
.feature-box.media-box .fbox-media a,
.feature-box.media-box .fbox-media img {
  position: relative;
  display: block;
  width: 100%;
  height: auto; }

.feature-box.media-box .fbox-media iframe {
  display: block; }

.feature-box.media-box p {
  margin-top: 17px; }

.feature-box.media-box.fbox-bg .fbox-media {
  margin: 0; }

.feature-box.media-box.fbox-bg .fbox-desc {
  padding: 25px;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-top: 0;
  border-radius: 0 0 5px 5px; }
  .dark .feature-box.media-box.fbox-bg .fbox-desc {
    background-color: #383838;
    border-color: rgba(255, 255, 255, 0.1); }

.feature-box.media-box.fbox-bg .fbox-media img {
  border-radius: 5px 5px 0 0; }

/* ----------------------------------------------------------------
	Flipbox
-----------------------------------------------------------------*/
.flipbox {
  perspective: 1000px; }

.flipbox-inner {
  transition: 0.5s;
  transition-timing-function: ease;
  position: relative;
  transform-style: preserve-3d; }

.flipbox-front,
.flipbox-back {
  -webkit-backface-visibility: hidden; }

.flipbox-front {
  z-index: 2; }

.flipbox-back {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.flipbox:not(.flipbox-vertical) .flipbox-front {
  transform: rotateY(0deg); }

.flipbox:not(.flipbox-vertical) .flipbox-back {
  transform: rotateY(-180deg); }

.flipbox.flipbox-vertical .flipbox-front {
  transform: rotateX(0deg); }

.flipbox.flipbox-vertical .flipbox-back {
  transform: rotateX(-180deg); }

.flipbox:not(.flipbox-vertical):hover .flipbox-inner,
.flipbox:not(.flipbox-vertical).hover .flipbox-inner {
  transform: rotateY(180deg); }

.flipbox.flipbox-vertical:hover .flipbox-inner,
.flipbox.flipbox-vertical.hover .flipbox-inner {
  transform: rotateX(180deg); }

/*-----------------------------------------------------------------------------------

	Shortcodes: process-steps.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Process Steps
-----------------------------------------------------------------*/
.process-steps {
  margin: 0 0 40px;
  list-style: none; }
  .process-steps li {
    float: left;
    position: relative;
    width: 25%;
    text-align: center;
    overflow: hidden; }
    @media (max-width: 767.98px) {
      .process-steps li {
        float: none;
        width: auto !important;
        margin-top: 20px; }
        .process-steps li:first-child {
          margin-top: 0; }
        .process-steps li:before, .process-steps li:after {
          display: none; }
        .process-steps li h5, .process-steps li .h5 {
          margin: 10px 0 0 0; } }
    .process-steps li .i-circled {
      margin-bottom: 0 !important; }
    .process-steps li .i-bordered {
      background-color: white; }
      .dark .process-steps li .i-bordered {
        background-color: #494949; }
    .process-steps li h5, .process-steps li .h5 {
      margin: 15px 0 0 0;
      font-size: 16px;
      font-weight: 300; }
    .process-steps li.active h5, .process-steps li.active .h5, .process-steps li.ui-tabs-active h5, .process-steps li.ui-tabs-active .h5 {
      font-weight: 700;
      color: #1abc9c; }
    .process-steps li.active a, .process-steps li.ui-tabs-active a {
      background-color: #1abc9c !important;
      color: #FFF;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
      border-color: #1abc9c !important; }
    .process-steps li:before, .process-steps li:after {
      content: '';
      position: absolute;
      top: 30px;
      left: 0;
      margin-left: -26px;
      width: 50%;
      border-top: 1px dashed #dddddd; }
      .dark .process-steps li:before, .dark .process-steps li:after {
        border-top: rgba(255, 255, 255, 0.15); }
    .process-steps li:after {
      left: auto;
      right: 0;
      margin: 0 -26px 0 0; }
    .process-steps li:first-child:before, .process-steps li:last-child:after {
      display: none; }
  .process-steps.process-2 li {
    width: 50%; }
  .process-steps.process-3 li {
    width: 33.33%; }
  .process-steps.process-5 li {
    width: 20%; }

/*-----------------------------------------------------------------------------------

	Shortcodes: alerts.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Alerts
-----------------------------------------------------------------*/
.style-msg,
.style-msg2 {
  position: relative;
  display: block;
  color: #444444;
  border-left: 4px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; }

.style-msg2 {
  border-left: 4px solid rgba(0, 0, 0, 0.2); }
  .dark .style-msg2 {
    border-left: 4px solid rgba(0, 0, 0, 0.3); }

.successmsg {
  background: #c5ebd4;
  color: #184b21; }

.errormsg {
  background: #ffcccc;
  color: #740c0a; }

.infomsg {
  background: #b9dff4;
  color: #124f70; }

.alertmsg {
  background: #fce0b6;
  color: #975d06; }

.style-msg .sb-msg,
.style-msg2 .msgtitle,
.style-msg2 .sb-msg {
  display: block;
  padding: 15px;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 14px;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.6); }
  .dark .style-msg .sb-msg, .dark
  .style-msg2 .msgtitle, .dark
  .style-msg2 .sb-msg {
    border-left-color: rgba(255, 255, 255, 0.1); }

.style-msg2 .msgtitle {
  background: rgba(0, 0, 0, 0.15);
  border-top: none;
  border-bottom: none;
  font-size: 15px;
  font-weight: bold; }

.style-msg-light .sb-msg,
.style-msg-light .msgtitle {
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3); }

.style-msg2 .sb-msg {
  font-weight: normal; }

.style-msg2 ol,
.style-msg2 ul {
  margin: 0 0 0 30px; }

.style-msg2 ol {
  list-style-type: decimal; }

.style-msg2 ul {
  list-style-type: disc; }

.alert i,
.sb-msg i,
.msgtitle i {
  position: relative;
  top: 1px;
  font-size: 14px;
  width: 14px;
  text-align: center;
  margin-right: 5px; }

.style-msg .close {
  position: absolute;
  top: 10px;
  left: auto;
  right: 10px; }

.alert a:hover {
  text-decoration: underline !important; }

.alert .close {
  font-size: 1.4rem; }

/*-----------------------------------------------------------------------------------

	Shortcodes: styled-icons.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Styled Icons
-----------------------------------------------------------------*/
.i-rounded,
.i-plain,
.i-circled,
.i-bordered,
.social-icon {
  display: block;
  float: left;
  margin: 4px 11px 7px 0;
  text-align: center !important;
  font-size: 28px;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  font-style: normal; }
  body:not(.device-touch) .i-rounded, body:not(.device-touch)
  .i-plain, body:not(.device-touch)
  .i-circled, body:not(.device-touch)
  .i-bordered, body:not(.device-touch)
  .social-icon {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }

.i-rounded,
.i-circled,
.i-bordered {
  width: 52px !important;
  height: 52px !important;
  line-height: 52px !important;
  background-color: #444;
  border-radius: 3px; }
  .dark .i-rounded, .dark
  .i-circled, .dark
  .i-bordered {
    background-color: #494949; }

.i-bordered {
  border: 1px solid #555555;
  line-height: 50px !important;
  background-color: transparent !important;
  color: #444444;
  text-shadow: none; }
  .dark .i-bordered {
    color: #dddddd;
    border-color: #dddddd; }

.i-plain {
  width: 36px !important;
  height: 36px !important;
  font-size: 28px;
  line-height: 36px !important;
  color: #444444;
  text-shadow: 1px 1px 1px white; }
  .dark .i-plain {
    color: white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }
  .i-plain:hover {
    color: white; }
    .dark .i-plain:hover {
      color: #dddddd; }

.i-circled {
  border-radius: 50%; }

.i-light {
  background-color: whitesmoke;
  color: #444444;
  text-shadow: 1px 1px 1px white;
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.2); }
  .dark .i-light {
    background-color: whitesmoke;
    color: #444444;
    text-shadow: none; }

.i-alt {
  background-image: url("image/icons/iconalt.svg");
  background-position: center center;
  background-size: 100% 100%; }

.i-rounded:hover,
.i-circled:hover {
  background-color: #1abc9c;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2); }
  .dark .i-rounded:hover, .dark
  .i-circled:hover {
    background-color: #1abc9c;
    color: white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.2); }

.i-bordered:hover {
  background-color: #444 !important;
  color: white;
  border-color: #444; }
  .dark .i-bordered:hover {
    background-color: #1abc9c;
    color: white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.2); }

/* Icon Size - Small
-----------------------------------------------------------------*/
.i-small.i-rounded, .i-small.i-plain, .i-small.i-circled, .i-small.i-bordered {
  margin: 4px 11px 7px 0;
  font-size: 14px; }

.i-small.i-rounded, .i-small.i-circled, .i-small.i-bordered {
  width: 28px !important;
  height: 28px !important;
  line-height: 28px !important; }

.i-small.i-bordered {
  line-height: 26px !important; }

.i-small.i-plain {
  width: 18px !important;
  height: 18px !important;
  font-size: 14px;
  line-height: 18px !important; }

/* Icon Size - medium
-----------------------------------------------------------------*/
.i-medium.i-rounded, .i-medium.i-plain, .i-medium.i-circled, .i-medium.i-bordered {
  margin: 6px 13px 9px 0;
  font-size: 21px; }

.i-medium.i-rounded, .i-medium.i-circled, .i-medium.i-bordered {
  width: 36px !important;
  height: 36px !important;
  line-height: 36px !important; }

.i-medium.i-bordered {
  line-height: 34px !important; }

.i-medium.i-plain {
  width: 28px !important;
  height: 28px !important;
  font-size: 20px;
  line-height: 28px !important; }

/* Icon Size - Large
-----------------------------------------------------------------*/
.i-large.i-rounded, .i-large.i-plain, .i-large.i-circled, .i-large.i-bordered {
  margin: 4px 11px 7px 0;
  font-size: 42px; }

.i-large.i-rounded, .i-large.i-circled, .i-large.i-bordered {
  width: 64px !important;
  height: 64px !important;
  line-height: 64px !important; }

.i-large.i-bordered {
  line-height: 62px !important; }

.i-large.i-plain {
  width: 48px !important;
  height: 48px !important;
  font-size: 42px;
  line-height: 48px !important; }

/* Icon Size - Extra Large
-----------------------------------------------------------------*/
.i-xlarge.i-rounded, .i-xlarge.i-plain, .i-xlarge.i-circled, .i-xlarge.i-bordered {
  margin: 4px 11px 7px 0;
  font-size: 56px; }

.i-xlarge.i-rounded, .i-xlarge.i-circled, .i-xlarge.i-bordered {
  width: 84px !important;
  height: 84px !important;
  line-height: 84px !important; }

.i-xlarge.i-bordered {
  line-height: 82px !important; }

.i-xlarge.i-plain {
  width: 64px !important;
  height: 64px !important;
  font-size: 56px;
  line-height: 64px !important; }

/* ----------------------------------------------------------------
	Icon Overlay
-----------------------------------------------------------------*/
.i-overlay,
.i-overlay a {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.2) url("image/grid.png") repeat; }

.i-overlay a {
  background: transparent; }

.i-overlay img,
.i-overlay i {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -24px 0 0 -24px;
  width: 48px !important;
  height: 48px !important;
  font-size: 48px;
  line-height: 1;
  color: #e5e5e5;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15); }

.i-overlay i {
  margin: -18px 0 0 -18px;
  width: 36px !important;
  height: 36px !important;
  line-height: 36px;
  font-size: 24px; }

.on-hover.i-overlay {
  opacity: 0; }
  .on-hover.i-overlay:hover {
    opacity: 1; }
  body:not(.device-touch) .on-hover.i-overlay {
    -webkit-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear; }

/*-----------------------------------------------------------------------------------

	Shortcodes: social-icons.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Social Icons
-----------------------------------------------------------------*/
.social-icon {
  margin: 0 5px 5px 0;
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 38px !important;
  color: #555555;
  text-shadow: none;
  border: 1px solid #aaa;
  border-radius: 3px;
  overflow: hidden; }
  .dark .social-icon {
    color: #dddddd !important;
    border-color: #dddddd; }

/* Social Icons - Rounded
-----------------------------------------------------------------*/
.si-rounded {
  border-radius: 50%; }

/* Social Icons - Borderless
-----------------------------------------------------------------*/
.si-borderless {
  border-color: transparent !important; }
  .dark #footer .si-borderless.social-icon,
  #footer.dark .si-borderless.social-icon {
    color: #555 !important; }

/* Social Icons - Dark
-----------------------------------------------------------------*/
.si-dark {
  background-color: #444444;
  color: #FFF !important;
  border-color: transparent; }
  .dark .si-dark {
    background-color: rgba(0, 0, 0, 0.2);
    border-color: transparent; }

/* Social Icons - Light
-----------------------------------------------------------------*/
.si-light {
  background-color: rgba(0, 0, 0, 0.05);
  color: #555555 !important;
  border-color: transparent; }
  .dark .si-light {
    background-color: rgba(255, 255, 255, 0.1);
    color: #eeeeee !important;
    border-color: transparent; }

/* Social Icons - Colored
-----------------------------------------------------------------*/
.si-colored {
  border-color: transparent !important; }
  .si-colored i {
    color: #FFF; }

/* Social Icons - Large
-----------------------------------------------------------------*/
.social-icon.si-large {
  margin: 0 10px 10px 0;
  width: 56px;
  height: 56px;
  font-size: 30px;
  line-height: 54px !important; }

/* Social Icons - Small
-----------------------------------------------------------------*/
.social-icon.si-small {
  width: 32px;
  height: 32px;
  font-size: 14px;
  line-height: 30px !important; }

.social-icon i {
  display: block;
  position: relative; }
  body:not(.device-touch) .social-icon i {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }

.social-icon i:last-child {
  color: #FFF !important; }
  .dark .social-icon i:last-child {
    color: #FFF !important; }

.social-icon:hover {
  color: #555555;
  border-color: transparent; }
  .dark .social-icon:hover {
    color: #FFF !important;
    border-color: transparent; }
  .social-icon:hover i:first-child {
    margin-top: -38px; }

.social-icon.si-large:hover i:first-child {
  margin-top: -54px; }

.social-icon.si-small:hover i:first-child {
  margin-top: -30px; }

.si-colored.social-icon {
  border-color: transparent; }

/* Social Icons - Sticky
-----------------------------------------------------------------*/
.si-sticky {
  position: fixed;
  top: 50%;
  left: 5px;
  width: 36px;
  z-index: 100;
  /* Social Icons - Sticky Right
	-----------------------------------------------------------------*/ }
  .si-sticky.si-sticky-right {
    left: auto;
    right: 8px; }

/* Social Icons - Share
-----------------------------------------------------------------*/
.si-share {
  position: relative;
  padding-left: 5px;
  border-top: 1px solid #EEE;
  border-bottom: 1px solid #EEE;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 575.98px) {
    .si-share {
      flex-direction: column; } }
  .dark .si-share {
    border-top-color: rgba(255, 255, 255, 0.1);
    border-bottom-color: rgba(255, 255, 255, 0.1); }
  .si-share span {
    line-height: 36px;
    font-weight: bold;
    font-size: 13px; }
  .si-share div {
    display: flex;
    justify-content: flex-end;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
    @media (max-width: 575.98px) {
      .si-share div {
        margin-top: 10px; } }
  .si-share .social-icon {
    margin: -1px 0;
    border-radius: 0;
    font-size: 15px; }

/* Social Icons - bg-colors
-----------------------------------------------------------------*/
:hover.si-facebook,
.si-colored.si-facebook {
  background-color: #3b5998 !important; }

:hover.si-whatsapp,
.si-colored.si-whatsapp {
  background-color: #25d366 !important; }

:hover.si-delicious,
.si-colored.si-delicious {
  background-color: #205cc0 !important; }

:hover.si-paypal,
.si-colored.si-paypal {
  background-color: #00588b !important; }

:hover.si-flattr,
.si-colored.si-flattr {
  background-color: #f67c1a !important; }

:hover.si-android,
.si-colored.si-android {
  background-color: #a4c639 !important; }

:hover.si-smashmag,
.si-colored.si-smashmag {
  background-color: #e53b2c !important; }

:hover.si-gplus,
.si-colored.si-gplus {
  background-color: #dd4b39 !important; }

:hover.si-wikipedia,
.si-colored.si-wikipedia {
  background-color: #333 !important; }

:hover.si-stumbleupon,
.si-colored.si-stumbleupon {
  background-color: #f74425 !important; }

:hover.si-foursquare,
.si-colored.si-foursquare {
  background-color: #25a0ca !important; }

:hover.si-call,
.si-colored.si-call {
  background-color: #444 !important; }

:hover.si-ninetyninedesigns,
.si-colored.si-ninetyninedesigns {
  background-color: #f26739 !important; }

:hover.si-forrst,
.si-colored.si-forrst {
  background-color: #5b9a68 !important; }

:hover.si-digg,
.si-colored.si-digg {
  background-color: #191919 !important; }

:hover.si-spotify,
.si-colored.si-spotify {
  background-color: #81b71a !important; }

:hover.si-reddit,
.si-colored.si-reddit {
  background-color: #c6c6c6 !important; }

:hover.si-blogger,
.si-colored.si-blogger {
  background-color: #fc4f08 !important; }

:hover.si-cc,
.si-colored.si-cc {
  background-color: #688527 !important; }

:hover.si-dribbble,
.si-colored.si-dribbble {
  background-color: #ea4c89 !important; }

:hover.si-evernote,
.si-colored.si-evernote {
  background-color: #5ba525 !important; }

:hover.si-flickr,
.si-colored.si-flickr {
  background-color: #ff0084 !important; }

:hover.si-google,
.si-colored.si-google {
  background-color: #dd4b39 !important; }

:hover.si-instapaper,
.si-colored.si-instapaper {
  background-color: #333 !important; }

:hover.si-klout,
.si-colored.si-klout {
  background-color: #ff5f52 !important; }

:hover.si-linkedin,
.si-colored.si-linkedin {
  background-color: #0e76a8 !important; }

:hover.si-vk,
.si-colored.si-vk {
  background-color: #2b587a !important; }

:hover.si-rss,
.si-colored.si-rss {
  background-color: #ee802f !important; }

:hover.si-skype,
.si-colored.si-skype {
  background-color: #00aff0 !important; }

:hover.si-twitter,
.si-colored.si-twitter {
  background-color: #00acee !important; }

:hover.si-youtube,
.si-colored.si-youtube {
  background-color: #c4302b !important; }

:hover.si-vimeo,
.si-colored.si-vimeo {
  background-color: #86c9ef !important; }

:hover.si-aim,
.si-colored.si-aim {
  background-color: #fcd20b !important; }

:hover.si-yahoo,
.si-colored.si-yahoo {
  background-color: #720e9e !important; }

:hover.si-email3,
.si-colored.si-email3 {
  background-color: #6567a5 !important; }

:hover.si-macstore,
.si-colored.si-macstore {
  background-color: #333333 !important; }

:hover.si-myspace,
.si-colored.si-myspace {
  background-color: #666666 !important; }

:hover.si-podcast,
.si-colored.si-podcast {
  background-color: #e4b21b !important; }

:hover.si-cloudapp,
.si-colored.si-cloudapp {
  background-color: #525557 !important; }

:hover.si-dropbox,
.si-colored.si-dropbox {
  background-color: #3d9ae8 !important; }

:hover.si-ebay,
.si-colored.si-ebay {
  background-color: #89c507 !important; }

:hover.si-github,
.si-colored.si-github {
  background-color: #171515 !important; }

:hover.si-googleplay,
.si-colored.si-googleplay {
  background-color: #dd4b39 !important; }

:hover.si-itunes,
.si-colored.si-itunes {
  background-color: #222 !important; }

:hover.si-plurk,
.si-colored.si-plurk {
  background-color: #cf5a00 !important; }

:hover.si-pinboard,
.si-colored.si-pinboard {
  background-color: #0000e6 !important; }

:hover.si-soundcloud,
.si-colored.si-soundcloud {
  background-color: #ff7700 !important; }

:hover.si-tumblr,
.si-colored.si-tumblr {
  background-color: #34526f !important; }

:hover.si-wordpress,
.si-colored.si-wordpress {
  background-color: #1e8cbe !important; }

:hover.si-yelp,
.si-colored.si-yelp {
  background-color: #c41200 !important; }

:hover.si-intensedebate,
.si-colored.si-intensedebate {
  background-color: #009ee4 !important; }

:hover.si-eventbrite,
.si-colored.si-eventbrite {
  background-color: #f16924 !important; }

:hover.si-scribd,
.si-colored.si-scribd {
  background-color: #666666 !important; }

:hover.si-stripe,
.si-colored.si-stripe {
  background-color: #008cdd !important; }

:hover.si-print,
.si-colored.si-print {
  background-color: #111 !important; }

:hover.si-dwolla,
.si-colored.si-dwolla {
  background-color: #ff5c03 !important; }

:hover.si-statusnet,
.si-colored.si-statusnet {
  background-color: #131a30 !important; }

:hover.si-acrobat,
.si-colored.si-acrobat {
  background-color: #d3222a !important; }

:hover.si-drupal,
.si-colored.si-drupal {
  background-color: #27537a !important; }

:hover.si-buffer,
.si-colored.si-buffer {
  background-color: #333333 !important; }

:hover.si-pocket,
.si-colored.si-pocket {
  background-color: #ee4056 !important; }

:hover.si-bitbucket,
.si-colored.si-bitbucket {
  background-color: #0e4984 !important; }

:hover.si-stackoverflow,
.si-colored.si-stackoverflow {
  background-color: #ef8236 !important; }

:hover.si-hackernews,
.si-colored.si-hackernews {
  background-color: #ff6600 !important; }

:hover.si-xing,
.si-colored.si-xing {
  background-color: #126567 !important; }

:hover.si-instagram,
.si-colored.si-instagram {
  background-color: #3f729b !important; }

:hover.si-quora,
.si-colored.si-quora {
  background-color: #a82400 !important; }

:hover.si-openid,
.si-colored.si-openid {
  background-color: #e16309 !important; }

:hover.si-steam,
.si-colored.si-steam {
  background-color: #111 !important; }

:hover.si-amazon,
.si-colored.si-amazon {
  background-color: #e47911 !important; }

:hover.si-disqus,
.si-colored.si-disqus {
  background-color: #e4e7ee !important; }

:hover.si-plancast,
.si-colored.si-plancast {
  background-color: #222 !important; }

:hover.si-appstore,
.si-colored.si-appstore {
  background-color: #000 !important; }

:hover.si-pinterest,
.si-colored.si-pinterest {
  background-color: #c8232c !important; }

:hover.si-fivehundredpx,
.si-colored.si-fivehundredpx {
  background-color: #111 !important; }

/* Social Icons - colors
-----------------------------------------------------------------*/
.si-text-color.si-facebook i {
  color: #3b5998; }

.si-text-color.si-whatsapp i {
  color: #25d366; }

.si-text-color.si-delicious i {
  color: #205cc0; }

.si-text-color.si-paypal i {
  color: #00588b; }

.si-text-color.si-flattr i {
  color: #f67c1a; }

.si-text-color.si-android i {
  color: #a4c639; }

.si-text-color.si-smashmag i {
  color: #e53b2c; }

.si-text-color.si-gplus i {
  color: #dd4b39; }

.si-text-color.si-wikipedia i {
  color: #333; }

.si-text-color.si-stumbleupon i {
  color: #f74425; }

.si-text-color.si-foursquare i {
  color: #25a0ca; }

.si-text-color.si-call i {
  color: #444; }

.si-text-color.si-ninetyninedesigns i {
  color: #f26739; }

.si-text-color.si-forrst i {
  color: #5b9a68; }

.si-text-color.si-digg i {
  color: #191919; }

.si-text-color.si-spotify i {
  color: #81b71a; }

.si-text-color.si-reddit i {
  color: #c6c6c6; }

.si-text-color.si-blogger i {
  color: #fc4f08; }

.si-text-color.si-cc i {
  color: #688527; }

.si-text-color.si-dribbble i {
  color: #ea4c89; }

.si-text-color.si-evernote i {
  color: #5ba525; }

.si-text-color.si-flickr i {
  color: #ff0084; }

.si-text-color.si-google i {
  color: #dd4b39; }

.si-text-color.si-instapaper i {
  color: #333; }

.si-text-color.si-klout i {
  color: #ff5f52; }

.si-text-color.si-linkedin i {
  color: #0e76a8; }

.si-text-color.si-vk i {
  color: #2b587a; }

.si-text-color.si-rss i {
  color: #ee802f; }

.si-text-color.si-skype i {
  color: #00aff0; }

.si-text-color.si-twitter i {
  color: #00acee; }

.si-text-color.si-youtube i {
  color: #c4302b; }

.si-text-color.si-vimeo i {
  color: #86c9ef; }

.si-text-color.si-aim i {
  color: #fcd20b; }

.si-text-color.si-yahoo i {
  color: #720e9e; }

.si-text-color.si-email3 i {
  color: #6567a5; }

.si-text-color.si-macstore i {
  color: #333333; }

.si-text-color.si-myspace i {
  color: #666666; }

.si-text-color.si-podcast i {
  color: #e4b21b; }

.si-text-color.si-cloudapp i {
  color: #525557; }

.si-text-color.si-dropbox i {
  color: #3d9ae8; }

.si-text-color.si-ebay i {
  color: #89c507; }

.si-text-color.si-github i {
  color: #171515; }

.si-text-color.si-googleplay i {
  color: #dd4b39; }

.si-text-color.si-itunes i {
  color: #222; }

.si-text-color.si-plurk i {
  color: #cf5a00; }

.si-text-color.si-pinboard i {
  color: #0000e6; }

.si-text-color.si-soundcloud i {
  color: #ff7700; }

.si-text-color.si-tumblr i {
  color: #34526f; }

.si-text-color.si-wordpress i {
  color: #1e8cbe; }

.si-text-color.si-yelp i {
  color: #c41200; }

.si-text-color.si-intensedebate i {
  color: #009ee4; }

.si-text-color.si-eventbrite i {
  color: #f16924; }

.si-text-color.si-scribd i {
  color: #666666; }

.si-text-color.si-stripe i {
  color: #008cdd; }

.si-text-color.si-print i {
  color: #111; }

.si-text-color.si-dwolla i {
  color: #ff5c03; }

.si-text-color.si-statusnet i {
  color: #131a30; }

.si-text-color.si-acrobat i {
  color: #d3222a; }

.si-text-color.si-drupal i {
  color: #27537a; }

.si-text-color.si-buffer i {
  color: #333333; }

.si-text-color.si-pocket i {
  color: #ee4056; }

.si-text-color.si-bitbucket i {
  color: #0e4984; }

.si-text-color.si-stackoverflow i {
  color: #ef8236; }

.si-text-color.si-hackernews i {
  color: #ff6600; }

.si-text-color.si-xing i {
  color: #126567; }

.si-text-color.si-instagram i {
  color: #3f729b; }

.si-text-color.si-quora i {
  color: #a82400; }

.si-text-color.si-openid i {
  color: #e16309; }

.si-text-color.si-steam i {
  color: #111; }

.si-text-color.si-amazon i {
  color: #e47911; }

.si-text-color.si-disqus i {
  color: #e4e7ee; }

.si-text-color.si-plancast i {
  color: #222; }

.si-text-color.si-appstore i {
  color: #000; }

.si-text-color.si-pinterest i {
  color: #c8232c; }

.si-text-color.si-fivehundredpx i {
  color: #111; }

.si-text-color:hover {
  color: white; }

/*-----------------------------------------------------------------------------------

	Shortcodes: toggles-accordions.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Toggle
-----------------------------------------------------------------*/
.toggle {
  display: block;
  position: relative;
  margin: 0 0 20px 0; }
  .toggle .togglet,
  .toggle .toggleta {
    display: block;
    position: relative;
    line-height: 24px;
    padding: 0 0 0 24px;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    color: #444444;
    cursor: pointer; }
    .dark .toggle .togglet, .dark
    .toggle .toggleta {
      color: #cccccc; }
  .toggle .togglet i {
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: #444444;
    font-family: "font-icons"; }
  .toggle .toggleta {
    font-weight: bold; }
  .toggle .togglet i.toggle-open,
  .toggle .toggleta i.toggle-closed {
    display: none; }
  .toggle .toggleta i.toggle-open {
    display: block;
    color: #444444; }
  .toggle .togglet i.toggle-open::before {
    content: ""; }
  .toggle .togglet i.toggle-closed::before {
    content: ""; }
  .toggle .togglec {
    display: block;
    position: relative;
    padding: 10px 0 0 24px; }

/* Toggle - with Title Background
-----------------------------------------------------------------*/
.toggle.toggle-bg .togglet,
.toggle.toggle-bg .toggleta {
  background-color: #eeeeee;
  line-height: 44px;
  padding: 0 0 0 36px;
  border-radius: 4px; }
  .dark .toggle.toggle-bg .togglet, .dark
  .toggle.toggle-bg .toggleta {
    background-color: rgba(0, 0, 0, 0.2); }

.toggle.toggle-bg .togglet i {
  left: 14px;
  line-height: 44px; }

.toggle.toggle-bg .togglec {
  padding: 12px 0 0 36px; }

/* Toggle - Bordered
-----------------------------------------------------------------*/
.toggle.toggle-border {
  border: 1px solid #cccccc;
  border-radius: 4px; }
  .dark .toggle.toggle-border {
    border-color: rgba(255, 255, 255, 0.15); }
  .toggle.toggle-border .togglet i {
    left: 14px;
    line-height: 44px; }
  .toggle.toggle-border .togglec {
    padding: 0 15px 15px 36px; }
  .toggle.toggle-border .togglet,
  .toggle.toggle-border .toggleta {
    line-height: 44px;
    padding: 0 15px 0 36px; }

/* ----------------------------------------------------------------
	Accordions
-----------------------------------------------------------------*/
.accordion {
  margin-bottom: 20px; }

.acctitle,
.acctitlec {
  display: block;
  position: relative;
  line-height: 24px;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: #444444;
  cursor: pointer;
  border-top: 1px dotted #dddddd;
  padding: 10px 0 10px 20px; }
  .dark .acctitle, .dark
  .acctitlec {
    color: #cccccc;
    border-top-color: rgba(255, 255, 255, 0.15); }

.acctitlec {
  cursor: auto; }

.acctitle:first-child {
  border-top: none; }

.acctitle i {
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  text-align: center;
  font-size: 14px;
  line-height: 44px;
  color: #444444; }

.acctitle i.acc-open,
.acctitlec i.acc-closed {
  display: none; }

.acctitlec i.acc-open {
  display: block;
  color: #444444; }

.acc_content {
  position: relative;
  padding: 0 0 15px 20px; }

/* Accordion - with Title Background
-----------------------------------------------------------------*/
.accordion.accordion-bg .acctitle,
.accordion.accordion-bg .acctitlec {
  background-color: #eeeeee;
  line-height: 44px;
  padding: 0 0 0 36px;
  margin-bottom: 5px;
  border-top: 0; }
  .dark .accordion.accordion-bg .acctitle, .dark
  .accordion.accordion-bg .acctitlec {
    background-color: rgba(0, 0, 0, 0.2); }

.accordion.accordion-bg .acctitle i {
  left: 14px;
  line-height: 44px; }

.accordion.accordion-bg .acc_content {
  padding: 10px 0 15px 36px; }

/* Accordion - Bordered
-----------------------------------------------------------------*/
.accordion.accordion-border {
  border: 1px solid #dddddd;
  border-radius: 4px; }
  .dark .accordion.accordion-border {
    border-color: rgba(255, 255, 255, 0.1); }
  .accordion.accordion-border .acctitle,
  .accordion.accordion-border .acctitlec {
    border-color: #cccccc;
    line-height: 44px;
    padding: 0 15px 0 36px; }
    .dark .accordion.accordion-border .acctitle, .dark
    .accordion.accordion-border .acctitlec {
      border-color: rgba(255, 255, 255, 0.15); }
  .accordion.accordion-border .acctitle i {
    left: 14px;
    line-height: 44px; }
  .accordion.accordion-border .acc_content {
    padding: 0 15px 15px 15px 15px 36px; }

/* Accordion - Large
-----------------------------------------------------------------*/
.accordion.accordion-lg .acctitle {
  font-size: 23px;
  line-height: 43.93px;
  padding-left: 30px;
  font-weight: 400; }
  .accordion.accordion-lg .acctitle i {
    width: 23px;
    font-size: 23px;
    line-height: 64px; }

.accordion.accordion-lg .acc_content {
  padding: 10px 0 30px 30px; }

/*-----------------------------------------------------------------------------------

	Shortcodes: tabs.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Tabs
-----------------------------------------------------------------*/
.tabs {
  position: relative;
  margin: 0 0 30px 0; }

ul.tab-nav {
  margin: 0;
  border-bottom: 1px solid #dddddd;
  list-style: none; }
  .dark ul.tab-nav {
    border-color: #494949; }
  ul.tab-nav li {
    float: left;
    border: 1px solid #dddddd;
    border-bottom: 0;
    border-left: 0;
    height: 41px;
    text-align: center; }
    ul.tab-nav li:first-child {
      margin-left: 15px;
      border-left: 1px solid #dddddd; }
      .dark ul.tab-nav li:first-child {
        border-left-color: #494949; }
    .dark ul.tab-nav li {
      border-color: #494949; }
    ul.tab-nav li a {
      display: block;
      padding: 0 15px;
      color: #444444;
      height: 40px;
      line-height: 40px;
      background-color: #f2f2f2;
      font-size: 14px;
      font-weight: bold; }
      .dark ul.tab-nav li a {
        color: #dddddd;
        background-color: rgba(0, 0, 0, 0.15); }
    ul.tab-nav li.ui-tabs-active a {
      position: relative;
      top: 0;
      height: 41px;
      background-color: white; }
      .dark ul.tab-nav li.ui-tabs-active a {
        background-color: #383838; }

/* Tabs - Alternate Nav
-----------------------------------------------------------------*/
.tabs.tabs-alt ul.tab-nav li {
  border-color: transparent; }
  .tabs.tabs-alt ul.tab-nav li:first-child {
    border-left: 0; }
  .tabs.tabs-alt ul.tab-nav li a {
    background-color: white; }
    .dark .tabs.tabs-alt ul.tab-nav li a {
      background-color: #383838; }
  .tabs.tabs-alt ul.tab-nav li.ui-tabs-active a {
    border: 1px solid #dddddd;
    border-bottom: 0; }
    .dark .tabs.tabs-alt ul.tab-nav li.ui-tabs-active a {
      border-color: #494949; }

/* Tabs - Top Border
-----------------------------------------------------------------*/
.tabs.tabs-tb ul.tab-nav li.ui-tabs-active a {
  top: -1px;
  height: 42px;
  line-height: 39px;
  border-top: 1px solid #1abc9c; }

.tabs.tabs-alt.tabs-tb ul.tab-nav li.ui-tabs-active a {
  height: 42px; }

/* Tabs - Bottom Border
-----------------------------------------------------------------*/
.tabs.tabs-bb ul.tab-nav li {
  border: 0;
  margin: 0; }
  .tabs.tabs-bb ul.tab-nav li a {
    background-color: transparent;
    border-bottom: 1px solid transparent; }
  .tabs.tabs-bb ul.tab-nav li.ui-tabs-active a {
    border-bottom: 1px solid #1abc9c;
    top: -1px;
    height: 43px;
    line-height: 43px; }

/* Tabs - Navigation Style 2
-----------------------------------------------------------------*/
ul.tab-nav.tab-nav2 {
  border-bottom: 0; }
  ul.tab-nav.tab-nav2 li {
    border: 0;
    margin-left: 10px; }
    ul.tab-nav.tab-nav2 li:first-child {
      margin-left: 0;
      border-left: 0; }
    ul.tab-nav.tab-nav2 li a {
      background-color: #f2f2f2;
      border-radius: 2px;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); }
      .dark ul.tab-nav.tab-nav2 li a {
        background-color: rgba(0, 0, 0, 0.2); }
    ul.tab-nav.tab-nav2 li.ui-state-active a {
      top: 0;
      background-color: #1abc9c;
      color: #FFF; }
      .dark ul.tab-nav.tab-nav2 li.ui-state-active a {
        background-color: #1abc9c; }

/* Tabs - Content Area
-----------------------------------------------------------------*/
.tab-container {
  position: relative;
  padding: 20px 0 0; }
  @media (max-width: 767.98px) {
    .tab-container {
      padding: 0 !important; } }

ul.tab-nav li a i {
  position: relative;
  top: 1px;
  font-size: 14px;
  margin-right: 3px; }

/* Tabs - Bordered
-----------------------------------------------------------------*/
.tabs-bordered ul.tab-nav li:first-child {
  margin-left: 0; }

.tabs-bordered .tab-container {
  border: 1px solid #dddddd;
  border-top: 0;
  padding: 20px; }
  .dark .tabs-bordered .tab-container {
    border-color: #494949; }

/* Tabs - Side Tabs
-----------------------------------------------------------------*/
.side-tabs ul.tab-nav {
  float: left;
  width: 200px;
  padding: 20px 0;
  border-bottom: 0;
  border-right: 1px solid #dddddd; }
  .dark .side-tabs ul.tab-nav {
    border-right-color: #494949; }
  .side-tabs ul.tab-nav li {
    float: none;
    border: 1px solid #dddddd;
    border-right: 0;
    border-top: 0;
    height: auto;
    text-align: left; }
    .dark .side-tabs ul.tab-nav li {
      border-color: #494949; }
    .side-tabs ul.tab-nav li:first-child {
      margin-left: 0;
      border-top: 1px solid #dddddd;
      border-left: 1px solid #dddddd; }
      .dark .side-tabs ul.tab-nav li:first-child {
        border-top-color: #494949;
        border-left-color: #494949; }
    .side-tabs ul.tab-nav li a {
      height: auto;
      line-height: 42px; }
    .side-tabs ul.tab-nav li.ui-tabs-active a {
      top: 0;
      right: -1px; }

.side-tabs .tab-container {
  overflow: hidden;
  padding: 15px 0 0 20px; }

/* Tabs - Side Tabs & Nav Style 2
-----------------------------------------------------------------*/
.side-tabs ul.tab-nav.tab-nav2 {
  border: 0; }
  .side-tabs ul.tab-nav.tab-nav2 li {
    border: 0;
    margin: 6px 0 0; }
    .side-tabs ul.tab-nav.tab-nav2 li:first-child {
      margin-top: 0; }
    .side-tabs ul.tab-nav.tab-nav2 li a {
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); }
    .side-tabs ul.tab-nav.tab-nav2 li.ui-state-active a {
      right: 0; }

/* Tabs - Side Tabs & Bordered
-----------------------------------------------------------------*/
.tabs-bordered.side-tabs ul.tab-nav {
  position: relative;
  padding: 0;
  z-index: 2;
  margin-right: -1px; }
  .tabs-bordered.side-tabs ul.tab-nav li a {
    height: auto;
    line-height: 42px; }
  .tabs-bordered.side-tabs ul.tab-nav li.ui-tabs-active a {
    right: -1px;
    border-right: 1px solid white; }
    .dark .tabs-bordered.side-tabs ul.tab-nav li.ui-tabs-active a {
      border-right-color: #383838; }

.tabs-bordered.side-tabs .tab-container {
  overflow: hidden;
  padding: 20px;
  border-top: 1px solid #dddddd; }
  .dark .tabs-bordered.side-tabs .tab-container {
    border-top-color: #494949; }

/* ----------------------------------------------------------------
	Side Navigation
-----------------------------------------------------------------*/
.sidenav {
  width: 100%;
  padding: 0;
  background-color: white;
  border-radius: 4px; }
  .dark .sidenav {
    background-color: rgba(0, 0, 0, 0.2); }

.sidenav li {
  list-style-type: none; }

.sidenav li a {
  display: block;
  position: relative;
  margin: 0 0 -1px;
  padding: 11px 20px 11px 14px;
  font-size: 14px;
  border: 1px solid #e5e5e5;
  border-right-width: 2px;
  color: #666666; }
  .dark .sidenav li a {
    border-color: #494949;
    color: #bbbbbb; }

.sidenav > li:first-child > a {
  border-radius: 4px 4px 0 0; }

.sidenav > li:last-child > a {
  border-radius: 0 0 4px 4px;
  border-bottom-width: 2px; }

.sidenav > .ui-tabs-active > a,
.sidenav > .ui-tabs-active > a:hover {
  position: relative;
  z-index: 2;
  border-color: rgba(0, 0, 0, 0.1) !important;
  color: #FFF !important;
  font-weight: 700;
  background-color: #1abc9c !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }

.sidenav li ul {
  display: none; }

.sidenav li.ui-tabs-active ul {
  display: block; }

.sidenav li li a {
  padding: 6px 20px 6px 35px;
  font-size: 14px;
  border-top: none;
  border-bottom: none; }

.sidenav li li:first-child a {
  padding-top: 20px; }

.sidenav li li:last-child a {
  padding-bottom: 20px; }

.sidenav li i {
  position: relative;
  top: 1px;
  margin-right: 6px;
  font-size: 14px;
  text-align: center;
  width: 16px; }

/* Chevrons */
.sidenav .icon-chevron-right {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 50%;
  left: auto;
  right: 6px;
  margin-top: -8px;
  margin-right: 0;
  opacity: .4;
  font-size: 12px !important; }

.sidenav > li > a:hover {
  background-color: whitesmoke; }
  .dark .sidenav > li > a:hover {
    background-color: rgba(0, 0, 0, 0.3); }

.sidenav a:hover .icon-chevron-right {
  opacity: .5; }

.sidenav .ui-tabs-active .icon-chevron-right,
.sidenav .ui-tabs-active a:hover .icon-chevron-right {
  opacity: 1; }

@media (max-width: 767.98px) {
  .tabs-responsive .tab-container:not(.accordion-border) {
    border: 0; } }

/*-----------------------------------------------------------------------------------

	Shortcodes: faqs.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Toggle - FAQs
-----------------------------------------------------------------*/
.faqs .toggle {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 12px;
  margin-bottom: 12px; }
  .dark .faqs .toggle {
    border-bottom-color: rgba(255, 255, 255, 0.1); }
  .faqs .toggle .togglet,
  .faqs .toggle .toggleta {
    font-size: 16px; }
  .faqs .toggle .togglec {
    padding-bottom: 10px; }

.faqs i {
  top: 1px;
  font-size: 14px; }

/* FAQ List
-----------------------------------------------------------------*/
.faqlist li {
  margin-bottom: 5px;
  font-size: 16px; }
  .faqlist li a {
    color: #444444; }
    .dark .faqlist li a {
      color: #cccccc; }
    .faqlist li a:hover {
      color: #1abc9c !important; }

/*-----------------------------------------------------------------------------------

	Shortcodes: clients.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Clients
-----------------------------------------------------------------*/
.clients-grid,
.testimonials-grid {
  list-style: none;
  overflow: hidden; }
  .clients-grid li,
  .testimonials-grid li {
    float: left;
    position: relative;
    width: 20%;
    padding: 20px 0;
    -webkit-backface-visibility: hidden; }
    @media (max-width: 767.98px) {
      .clients-grid li,
      .testimonials-grid li {
        width: 33.3333333333%; } }
    .clients-grid li:before, .clients-grid li:after,
    .testimonials-grid li:before,
    .testimonials-grid li:after {
      content: '';
      position: absolute; }

.clients-grid li a img {
  width: 100%; }

.clients-grid li:before,
.testimonials-grid li:before {
  height: 100%;
  top: 0;
  left: -1px;
  border-left: 1px dotted #dddddd; }
  .dark .clients-grid li:before, .dark
  .testimonials-grid li:before {
    border-left-color: rgba(255, 255, 255, 0.15); }

.clients-grid li:after,
.testimonials-grid li:after {
  width: 100%;
  height: 0;
  top: auto;
  left: 0;
  bottom: -1px;
  border-bottom: 1px dotted #dddddd; }
  .dark .clients-grid li:after, .dark
  .testimonials-grid li:after {
    border-bottom-color: rgba(255, 255, 255, 0.15); }

.clients-grid li a,
.clients-grid li img {
  display: block;
  width: 75%;
  margin-left: auto;
  margin-right: auto; }

.clients-grid li a {
  opacity: 0.6; }
  .clients-grid li a:hover {
    opacity: 1; }
  body:not(.device-touch) .clients-grid li a {
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease; }

.clients-grid.grid-1 li {
  width: 100%; }

.clients-grid.grid-2 li {
  width: 50%; }

.clients-grid.grid-3 li {
  width: 33.3333333333%; }

.clients-grid.grid-4 li {
  width: 25%; }

.clients-grid.grid-5 li {
  width: 20%; }

.clients-grid.grid-6 li {
  width: 16.6666666667%; }

@media (max-width: 767.98px) {
  .grid-1.clients-grid li {
    width: 33.3333333333%; }
  .grid-2.clients-grid li {
    width: 33.3333333333%; }
  .grid-3.clients-grid li {
    width: 33.3333333333%; }
  .grid-4.clients-grid li {
    width: 33.3333333333%; }
  .grid-5.clients-grid li {
    width: 33.3333333333%; }
  .grid-6.clients-grid li {
    width: 33.3333333333%; } }

.clients-grid.grid-2 li {
  padding: 25px 0; }
  @media (max-width: 767.98px) {
    .clients-grid.grid-2 li {
      width: 50%; } }

/*-----------------------------------------------------------------------------------

	Shortcodes: testimonials.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Testimonials
-----------------------------------------------------------------*/
/* Testimonials - Grid
-----------------------------------------------------------------*/
.testimonials-grid li {
  width: 50%;
  padding: 25px; }
  @media (max-width: 767.98px) {
    .testimonials-grid li {
      float: none;
      width: auto !important;
      padding-left: 0;
      padding-right: 0; } }
  .testimonials-grid li .testimonial {
    padding: 0;
    background-color: transparent !important;
    border: 0 !important;
    box-shadow: none !important; }

.testimonials-grid.grid-1 li {
  width: 100%; }

.testimonials-grid.grid-2 li {
  width: 50%; }

.testimonials-grid.grid-3 li {
  width: 33.3333333333%; }

@media (max-width: 991.98px) {
  .testimonials-grid.grid-3 li {
    width: 50%; } }

@media (max-width: 767.98px) {
  .testimonials-grid.grid-3 li {
    width: 100%; } }

/* Testimonials - Item
-----------------------------------------------------------------*/
.testimonial {
  position: relative;
  padding: 20px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); }
  .dark .testimonial {
    background-color: rgba(0, 0, 0, 0.2);
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); }

.testi-image,
.testi-image a,
.testi-image img,
.testi-image i {
  display: block;
  width: 64px;
  height: 64px; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .testi-image,
    .testi-image a,
    .testi-image img,
    .testi-image i {
      width: 36px;
      height: 36px; } }
  @media (max-width: 575.98px) {
    .testi-image,
    .testi-image a,
    .testi-image img,
    .testi-image i {
      width: 36px;
      height: 36px; } }

.testi-image {
  float: left;
  margin-right: 15px; }
  .testi-image i {
    text-align: center;
    background-color: #EEE;
    border-radius: 50%;
    line-height: 64px;
    font-size: 28px;
    color: #888888;
    text-shadow: 1px 1px 1px #FFF; }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .testi-image i {
        line-height: 36px;
        font-size: 20px; } }
    @media (max-width: 575.98px) {
      .testi-image i {
        line-height: 36px;
        font-size: 20px; } }
    .dark .testimonial.twitter-scroll .testi-image i {
      background-color: rgba(0, 0, 0, 0.2);
      color: #eeeeee;
      text-shadow: none; }
  .testi-image img {
    border-radius: 50%; }

.testi-content {
  position: relative;
  overflow: hidden;
  font-size: 14px; }
  .testi-content p {
    margin-bottom: 0;
    font-family: "Crete Round", serif !important;
    font-style: italic; }
    .testi-content p:before, .testi-content p:after {
      content: ' " '; }

.testi-meta {
  margin-top: 10px;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase; }
  .testi-meta:before {
    content: '\2013' !important; }
  .testi-meta span {
    display: block;
    font-weight: normal;
    color: #999;
    font-size: 12px;
    text-transform: none;
    padding-left: 10px; }

/* Twitter - Small Scroller
-----------------------------------------------------------------*/
.testi-content p:before,
.testi-content p:after,
.testi-meta:before {
  content: ' " '; }

.testimonial.twitter-scroll .testi-content p:before,
.testimonial.twitter-scroll .testi-content p:after,
.testimonial.twitter-scroll .testi-meta:before {
  content: ' " '; }

.testimonial.twitter-scroll .testi-meta span {
  padding-left: 0; }
  .testimonial.twitter-scroll .testi-meta span a {
    color: #999; }
    .dark .testimonial.twitter-scroll .testi-meta span a {
      color: #aaaaaa; }
    .testimonial.twitter-scroll .testi-meta span a:hover {
      color: #222; }
      .dark .testimonial.twitter-scroll .testi-meta span a:hover {
        color: #888888; }

.dark .testimonial.twitter-scroll .testi-content p {
  border-bottom: 1px dotted black; }
  .dark .testimonial.twitter-scroll .testi-content p:hover {
    border-bottom: 1px dotted black; }

/* Testimonials - Full Width
-----------------------------------------------------------------*/
.testimonial.testimonial-full {
  padding: 30px; }

.testimonial.testimonial-full[data-animation="fade"] {
  padding: 30px; }
  .testimonial.testimonial-full[data-animation="fade"] .flexslider {
    overflow: visible !important; }

.testimonial-full .testi-image,
.testimonial-full .testi-image a,
.testimonial-full .testi-image img,
.testimonial-full .testi-image i {
  display: block;
  width: 72px;
  height: 72px; }

.testimonial-full .testi-image {
  float: none;
  margin: 0 auto 20px; }
  .testimonial-full .testi-image i {
    line-height: 72px; }

.testimonial-full .testi-content {
  text-align: center;
  font-size: 18px; }

.testimonial-full .testi-meta {
  margin-top: 15px; }
  .testimonial-full .testi-meta span {
    padding-left: 0; }

/* Testimonial - Section Scroller
-----------------------------------------------------------------*/
.section > .testimonial {
  padding: 0;
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  max-width: 800px;
  margin: 0 auto; }
  .section > .testimonial .testi-content {
    font-size: 22px; }
  @media (max-width: 991.98px) {
    .section > .testimonial {
      padding: 0 40px !important;
      max-width: none; } }

.section > .testimonial[data-animation="fade"] {
  padding: 0; }

.section > .testimonial .testi-meta,
.section > .testimonial .testi-meta span {
  font-size: 14px; }

/* Testimonial - Pagination
-----------------------------------------------------------------*/
.testimonial .flex-control-nav {
  top: auto;
  bottom: 6px;
  right: 0; }
  .dark.parallax .testimonial .flex-control-nav {
    height: 8px;
    margin-top: 30px; }
  .testimonial .flex-control-nav li {
    margin: 0 2px;
    width: 6px;
    height: 6px; }
    .dark.parallax .testimonial .flex-control-nav li {
      width: 8px;
      height: 8px;
      margin: 0 3px; }
    .testimonial .flex-control-nav li a {
      width: 6px !important;
      height: 6px !important;
      border: none;
      background-color: #1abc9c;
      opacity: 0.5; }
      .dark.parallax .testimonial .flex-control-nav li a {
        width: 8px !important;
        height: 8px !important;
        border: 1px solid white;
        background-color: transparent; }
      .testimonial .flex-control-nav li a.flex-active {
        opacity: 1; }
        .dark.parallax .testimonial .flex-control-nav li a.flex-active {
          background-color: white; }
    .testimonial .flex-control-nav li:hover a {
      opacity: 0.75; }
      .dark.parallax .testimonial .flex-control-nav li:hover a {
        background-color: white; }

/* Testimonial - Full Scroller
-----------------------------------------------------------------*/
.testimonial.testimonial-full .flex-control-nav {
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 6px;
  margin-top: 20px; }
  .testimonial.testimonial-full .flex-control-nav li {
    display: inline-block;
    float: none; }

/*-----------------------------------------------------------------------------------

	Shortcodes: team.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Team
-----------------------------------------------------------------*/
.team-image {
  position: relative; }
  .team-image > a,
  .team-image img {
    display: block;
    width: 100%; }

.team-desc {
  text-align: center; }
  .team-desc.team-desc-bg {
    background-color: white;
    padding: 10px 0 30px; }
    .dark .team-desc.team-desc-bg {
      background-color: rgba(255, 255, 255, 0.05); }

.team {
  position: relative; }
  .team .si-share {
    margin-top: 30px;
    text-align: left; }
    .team .si-share .social-icon {
      font-size: 15px;
      width: 14px; }

.team-title {
  position: relative;
  padding-top: 20px; }
  .team-title h4, .team-title .h4 {
    display: block;
    margin-bottom: 0;
    font-size: 19px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    color: #444444; }
  .team-title span {
    display: block;
    color: #1abc9c;
    font-weight: 400;
    font-family: "Crete Round", serif;
    font-style: italic;
    font-size: 15px;
    margin-top: 5px; }

.team-content {
  margin-top: 15px;
  font-size: 14px;
  color: #777777; }
  .dark .team-content {
    color: #999999; }
  .team-content p:last-child {
    margin-bottom: 0; }

.team-overlay,
.team-overlay:before {
  opacity: 0;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center; }

.team-overlay:before {
  content: '';
  z-index: auto;
  background-color: white;
  opacity: 0.75; }

.team-overlay .social-icon {
  float: none;
  position: relative;
  z-index: 3;
  margin: 0 3px !important;
  display: inline-block; }

body:not(.device-touch) .team-overlay {
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }

.team:hover .team-overlay {
  opacity: 1; }

/* Team Center
-----------------------------------------------------------------*/
.team.center .team-title:after {
  left: 50%;
  margin-left: -40px; }

/* Team List
-----------------------------------------------------------------*/
.team.team-list .team-image {
  float: left;
  width: 250px;
  margin-right: 30px; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .team.team-list .team-image {
      width: 200px; } }
  @media (max-width: 767.98px) {
    .team.team-list .team-image {
      float: none;
      width: 100%;
      margin-right: 0; } }

.team.team-list .team-desc {
  position: relative;
  overflow: hidden;
  text-align: left; }

.team.team-list .team-title {
  padding-top: 0; }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .team.team-list .team-title {
      margin-top: 15px; } }

.team .team-desc > .social-icon {
  margin-top: 20px; }

.team .team-content ~ .social-icon {
  margin-top: 30px; }

.team:not(.team-list) .social-icon {
  display: inline-block;
  float: none;
  margin-left: 6px;
  margin-right: 6px; }

/*-----------------------------------------------------------------------------------

	Shortcodes: pricing.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Pricing Boxes
-----------------------------------------------------------------*/
.pricing {
  position: relative; }

.pricing [class^=col-] {
  padding: 0;
  margin-top: 20px; }

@media (max-width: 991.98px) {
  .pricing [class^=col-md-] .pricing-box.best-price {
    margin-top: 0; } }

/* Pricing Boxes
-----------------------------------------------------------------*/
.pricing-box {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.075);
  border-radius: 3px;
  text-align: center;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  background-color: whitesmoke; }
  .dark .pricing-box {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); }

/* Pricing Boxes - Title
-----------------------------------------------------------------*/
.pricing-title {
  padding: 15px 0;
  background-color: #f9f9f9;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  text-shadow: 1px 1px 1px white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
  .dark .pricing-title {
    background-color: rgba(255, 255, 255, 0.03);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
    border-bottom-color: rgba(255, 255, 255, 0.1); }
  .pricing-title h3, .pricing-title .h3 {
    margin: 0;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    color: #555555; }
    .dark .pricing-title h3, .dark .pricing-title .h3 {
      color: #dddddd; }
  .pricing-title span {
    display: block;
    color: #999999;
    font-weight: 300;
    font-size: 14px;
    margin-top: 3px;
    text-transform: uppercase; }

/* Pricing Boxes - Price
-----------------------------------------------------------------*/
.pricing-price {
  position: relative;
  padding: 25px 0;
  color: #333333;
  font-weight: 300;
  font-size: 64px;
  line-height: 1; }
  .dark .pricing-price {
    color: #eeeeee; }
  .pricing-price::after {
    content: '';
    position: absolute;
    width: 100px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    top: auto;
    bottom: 0;
    left: 50%;
    margin-left: -50px; }
    .dark .pricing-price::after {
      border-top-color: rgba(255, 255, 255, 0.1); }
  .pricing-price span {
    display: inline-block;
    vertical-align: top;
    margin: 7px 3px 0 0;
    font-size: 28px;
    font-weight: normal; }
    .pricing-price span.price-tenure {
      vertical-align: baseline;
      font-size: 16px;
      font-weight: normal;
      color: #999999;
      margin: 0 0 0 3px; }

/* Pricing Boxes - Features
-----------------------------------------------------------------*/
.pricing-features {
  border-bottom: 1px solid rgba(0, 0, 0, 0.075); }
  .dark .pricing-features {
    border-bottom-color: rgba(255, 255, 255, 0.1); }
  .pricing-features ul {
    margin: 0;
    padding: 15px 0;
    list-style: none; }
  .pricing-features li {
    padding: 6px 0;
    font-size: 14px; }

/* Pricing Boxes - Action
-----------------------------------------------------------------*/
.pricing-action {
  padding: 15px 30px; }

/* Pricing Boxes - Best Price
-----------------------------------------------------------------*/
.pricing-box {
  /* Pricing Boxes - Minimal
	-----------------------------------------------------------------*/ }
  .pricing-box.best-price {
    background-color: white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    margin: -20px -1px 0 -1px; }
    @media (max-width: 767.98px) {
      .pricing-box.best-price {
        margin: 0; } }
    .dark .pricing-box.best-price {
      background-color: transparent;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); }
    .pricing-box.best-price .pricing-title {
      background-color: white;
      padding: 20px 0; }
      .dark .pricing-box.best-price .pricing-title {
        background-color: transparent; }
      .pricing-box.best-price .pricing-title h3, .pricing-box.best-price .pricing-title .h3 {
        font-size: 22px; }
    .pricing-box.best-price .pricing-price {
      color: #1abc9c;
      font-size: 72px; }
  .pricing-box.pricing-minimal .pricing-price {
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.075); }
    .dark .pricing-box.pricing-minimal .pricing-price {
      background-color: transparent;
      border-bottom-color: rgba(255, 255, 255, 0.1); }
    .pricing-box.pricing-minimal .pricing-price::after {
      display: none; }

/* Pricing Boxes - 5 Columns
-----------------------------------------------------------------*/
@media (max-width: 991.98px) {
  .pricing.pricing-5 {
    justify-content: center; } }

.pricing.pricing-5 .pricing-box {
  float: left;
  width: 20%;
  margin-top: 20px; }
  @media (max-width: 767.98px) {
    .pricing.pricing-5 .pricing-box {
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (max-width: 575.98px) {
    .pricing.pricing-5 .pricing-box {
      flex: 0 0 100%;
      max-width: 100%; } }
  .pricing.pricing-5 .pricing-box.best-price {
    margin-top: 0; }
    @media (max-width: 991.98px) {
      .pricing.pricing-5 .pricing-box.best-price {
        margin-top: 20px; }
        .pricing.pricing-5 .pricing-box.best-price .pricing-title {
          padding: 15px 0; } }
  .pricing.pricing-5 .pricing-box:nth-child(5) {
    margin-left: -1px; }

@media (max-width: 991.98px) {
  .pricing.pricing-5 .pricing-price {
    font-size: 48px; }
    .pricing.pricing-5 .pricing-price span {
      font-size: 20px; }
      .pricing.pricing-5 .pricing-price span.price-tenure {
        font-size: 12px; } }

.pricing.pricing-5 .pricing-title h3, .pricing.pricing-5 .pricing-title .h3 {
  font-size: 18px; }
  @media (max-width: 991.98px) {
    .pricing.pricing-5 .pricing-title h3, .pricing.pricing-5 .pricing-title .h3 {
      font-size: 14px; } }

@media (max-width: 991.98px) {
  .pricing.pricing-5 .pricing-title span {
    font-size: 12px; } }

.pricing.pricing-5 .best-price .pricing-title h3, .pricing.pricing-5 .best-price .pricing-title .h3 {
  font-size: 20px; }
  @media (max-width: 991.98px) {
    .pricing.pricing-5 .best-price .pricing-title h3, .pricing.pricing-5 .best-price .pricing-title .h3 {
      font-size: 14px; } }

/* Pricing Boxes - Extended
-----------------------------------------------------------------*/
.pricing-box.pricing-extended {
  background-color: white;
  text-align: left; }
  .dark .pricing-box.pricing-extended {
    background-color: transparent; }
  .pricing-box.pricing-extended .pricing-desc {
    float: left;
    width: 75%;
    padding: 25px; }
    @media (max-width: 991.98px) {
      .pricing-box.pricing-extended .pricing-desc {
        position: relative;
        float: none;
        width: auto;
        padding: 25px; } }
  .pricing-box.pricing-extended .pricing-title {
    background-color: transparent;
    padding-top: 0;
    text-align: left; }
  .pricing-box.pricing-extended .pricing-features {
    border: 0; }
    .pricing-box.pricing-extended .pricing-features ul {
      padding: 15px 0 0 0;
      display: flex;
      flex-wrap: wrap;
      margin-right: 0;
      margin-left: 0; }
    .pricing-box.pricing-extended .pricing-features li {
      padding: 6px 0;
      font-size: 15px;
      flex: 0 0 50%;
      max-width: 50%; }
      @media (max-width: 767.98px) {
        .pricing-box.pricing-extended .pricing-features li {
          flex: 0 0 100%;
          max-width: 100%;
          float: none; } }
      .pricing-box.pricing-extended .pricing-features li i {
        position: relative;
        top: 1px;
        width: 16px;
        margin-right: 2px; }
  .pricing-box.pricing-extended .pricing-action-area {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    width: 25%;
    height: 100%;
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    background-color: #f9f9f9;
    padding: 25px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
    flex-wrap: wrap;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    flex-direction: column;
    justify-content: center !important; }
    @media (max-width: 991.98px) {
      .pricing-box.pricing-extended .pricing-action-area {
        display: block;
        position: relative;
        float: none;
        width: auto;
        height: auto;
        padding: 25px;
        border-left: 0;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 0 0 3px 3px; }
        .dark .pricing-box.pricing-extended .pricing-action-area {
          border-top-color: rgba(255, 255, 255, 0.15); } }
    .dark .pricing-box.pricing-extended .pricing-action-area {
      border-left-color: rgba(255, 255, 255, 0.15);
      background-color: rgba(0, 0, 0, 0.2); }
    .pricing-box.pricing-extended .pricing-action-area .pricing-price,
    .pricing-box.pricing-extended .pricing-action-area .pricing-action {
      padding: 0; }
      @media (min-width: 992px) and (max-width: 1199.98px) {
        .pricing-box.pricing-extended .pricing-action-area .pricing-price a,
        .pricing-box.pricing-extended .pricing-action-area .pricing-action a {
          padding: 0 22px;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          border-radius: 3px; } }
    .pricing-box.pricing-extended .pricing-action-area .pricing-price {
      padding-bottom: 20px; }
      .pricing-box.pricing-extended .pricing-action-area .pricing-price::after {
        display: none; }
      .pricing-box.pricing-extended .pricing-action-area .pricing-price span.price-tenure {
        display: block;
        margin: 10px 0 0 0;
        font-weight: 300;
        text-transform: uppercase;
        font-size: 14px; }
  .pricing-box.pricing-extended .pricing-meta {
    color: #999999;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-bottom: 10px; }
    .dark .pricing-box.pricing-extended .pricing-meta {
      color: #999999; }

.table-comparison,
.table-comparison th {
  text-align: center; }

.table-comparison th:first-child,
.table-comparison td:first-child {
  text-align: left;
  font-weight: bold; }

/*-----------------------------------------------------------------------------------

	Shortcodes: counter-skills.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Counter
-----------------------------------------------------------------*/
.counter {
  font-size: 42px;
  font-weight: 700;
  font-family: "Raleway", sans-serif; }
  .dark {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); }
  .counter + h5, .counter + .h5 {
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 10px;
    font-size: 14px; }
  .counter.counter-small {
    font-size: 28px; }
    .counter.counter-small + h5, .counter.counter-small + .h5 {
      font-size: 13px; }
  .counter.counter-large, .counter.counter-xlarge {
    font-size: 56px; }
    .counter.counter-large + h5, .counter.counter-xlarge + h5, .counter.counter-large + .h5, .counter.counter-xlarge + .h5 {
      font-size: 15px;
      font-weight: 300; }
  .counter.counter-xlarge {
    font-size: 64px;
    font-weight: 400; }
  .counter.counter-inherit {
    font-size: inherit;
    font-weight: inherit; }
  .counter.counter-lined + h5:before, .counter.counter-lined + .h5:before {
    display: block;
    position: relative;
    margin: 20px auto 25px auto;
    content: '';
    width: 15%;
    border-top: 2px solid #444444;
    opacity: 0.9; }
    .dark .counter.counter-lined + h5:before, .dark .counter.counter-lined + .h5:before {
      border-color: #eeeeee; }

.widget .counter + h5, .widget .counter + .h5 {
  opacity: 0.7; }

/* ----------------------------------------------------------------
	Animated Rounded Skills
-----------------------------------------------------------------*/
.rounded-skill {
  display: inline-block;
  margin: 0 15px 15px;
  position: relative;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  color: #333333; }
  .dark .rounded-skill {
    color: #eeeeee; }
  .rounded-skill i {
    font-size: 42px; }
  .rounded-skill + h5, .rounded-skill + .h5 {
    margin-bottom: 0; }
  .rounded-skill canvas {
    position: absolute;
    top: 0;
    left: 0; }
  body:not(.device-touch) .rounded-skill {
    -webkit-transition: opacity 0.4s ease;
    -o-transition: opacity 0.4s ease;
    transition: opacity 0.4s ease; }

/* ----------------------------------------------------------------
	Skills Bar
-----------------------------------------------------------------*/
.skills {
  margin: 0;
  list-style: none;
  padding-top: 25px; }
  .skills li {
    position: relative;
    margin-top: 38px;
    height: 4px;
    background-color: whitesmoke;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 2px; }
    .dark .skills li {
      background-color: rgba(255, 255, 255, 0.15); }
    .skills li:first-child {
      margin-top: 0; }
    .skills li .progress {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: #1abc9c;
      overflow: visible !important;
      border-radius: 2px;
      -webkit-transition: width 1.2s ease-in-out;
      -o-transition: width 1.2s ease-in-out;
      transition: width 1.2s ease-in-out; }
    .skills li .progress-percent {
      position: absolute;
      top: -33px;
      right: 0;
      background-color: #333;
      color: #f9f9f9;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
      height: auto;
      padding: 5px;
      font-size: 11px;
      border-radius: 2px; }
      .dark .skills li .progress-percent {
        background-color: #4e4e4e; }
      .skills li .progress-percent .counter {
        font-weight: 400;
        font-family: "Lato", sans-serif; }
      .skills li .progress-percent:before {
        top: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-top-color: #333;
        left: 50%;
        border-width: 5px;
        margin-left: -5px; }
        .dark .skills li .progress-percent:before {
          border-top-color: #4e4e4e; }
    .skills li > span {
      position: absolute;
      z-index: 1;
      top: -23px;
      left: 0;
      line-height: 20px;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: #222222; }
      .dark .skills li > span {
        color: #cccccc; }

/*-----------------------------------------------------------------------------------

	Shortcodes: carousels.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Owl Carousel CSS
-----------------------------------------------------------------*/
.owl-carousel {
  display: none;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
  width: 100%; }
  .owl-carousel .animated {
    -webkit-animation-duration: 1000ms;
    animation-duration: 1000ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }
  .owl-carousel .owl-animated-in {
    z-index: 0; }
  .owl-carousel .owl-animated-out {
    z-index: 1; }
  .owl-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut; }
  .owl-carousel .owl-stage {
    position: relative;
    padding: 20px 0;
    -ms-touch-action: pan-Y; }
    .owl-carousel .owl-stage:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0); }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    display: block;
    opacity: 0;
    min-height: 100px;
    background-image: url("image/preloader.gif");
    background-repeat: no-repeat;
    background-position: center center; }
    .dark .owl-carousel.owl-loading {
      background-image: url("image/preloader-dark.gif"); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .owl-carousel.owl-loading {
        background-image: url("image/preloader@2x.gif");
        background-size: 24px 24px; }
        .dark .owl-carousel.owl-loading {
          background-image: url("image/preloader-dark@2x.gif"); } }
  .owl-carousel .owl-refresh .owl-item {
    display: none; }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
    .owl-carousel .owl-item img {
      display: block;
      width: 100%;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d; }
      #slider .owl-carousel .owl-item img {
        -webkit-transform-style: preserve-3d; }
    .owl-carousel .owl-item .owl-lazy {
      opacity: 0;
      -webkit-transition: opacity 400ms ease;
      -o-transition: opacity 400ms ease;
      transition: opacity 400ms ease; }
  .owl-carousel .owl-nav .disabled {
    display: inline-block; }
  .owl-carousel .owl-dots .disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot,
  .owl-carousel .owl-dots button {
    cursor: pointer;
    cursor: hand;
    padding: 0;
    border: 0;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
    .mega-menu-content .owl-carousel.owl-hidden {
      opacity: 1; }
  .owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab; }
  .owl-carousel .owl-rtl {
    direction: rtl; }
    .owl-carousel .owl-rtl .owl-item {
      float: right; }
  .no-js .owl-carousel {
    display: block; }
  .owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #111; }
  .owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 64px;
    width: 64px;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    background: url("image/icons/play.png") no-repeat;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -webkit-transition: scale 100ms ease;
    -o-transition: scale 100ms ease;
    transition: scale 100ms ease; }
    .owl-carousel .owl-video-play-icon:hover {
      -webkit-transition: scale(1.3);
      -o-transition: scale(1.3);
      transition: scale(1.3); }
  .owl-carousel .owl-video-playing .owl-video-play-icon,
  .owl-carousel .owl-video-playing .owl-video-tn {
    display: none; }
  .owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    -webkit-transition: opacity 400ms ease;
    -o-transition: opacity 400ms ease;
    transition: opacity 400ms ease; }
  .owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out; }

/* Owl Carousel - Controls
-----------------------------------------------------------------*/
.owl-carousel .owl-dots,
.owl-carousel .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  line-height: 1; }

.owl-carousel .owl-nav [class*=owl-] {
  position: absolute;
  top: 50%;
  zoom: 1;
  width: 36px;
  height: 36px;
  line-height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: #666;
  background-color: #fff;
  font-size: 18px;
  transform: translateY(-50%);
  border-radius: 50%;
  opacity: 0;
  left: -36px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }

/* Owl Carousel - Controls - Arrows
-----------------------------------------------------------------*/
.slider-element .owl-nav [class*=owl-],
.owl-carousel-full .owl-nav [class*=owl-] {
  left: 0 !important;
  height: 60px;
  line-height: 60px;
  border: none;
  color: #eee;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 28px;
  border-radius: 0 3px 3px 0; }

.owl-carousel .owl-nav .owl-next {
  left: auto;
  right: -36px; }

.slider-element .owl-nav .owl-next,
.owl-carousel-full .owl-nav .owl-next {
  left: auto !important;
  right: 0 !important;
  border-radius: 3px 0 0 3px; }

.owl-carousel:hover .owl-nav [class*=owl-] {
  opacity: 1;
  left: -18px; }

.owl-carousel:hover .owl-nav .owl-next {
  left: auto;
  right: -18px; }

.owl-carousel .owl-nav [class*=owl-]:hover {
  background-color: #1abc9c !important;
  color: #fff !important;
  text-decoration: none; }

/* Owl Carousel - Controls - Dots
-----------------------------------------------------------------*/
.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  opacity: 0.5;
  width: 8px;
  height: 8px;
  margin: 30px 4px 0 4px;
  background-color: #1abc9c;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .owl-carousel .owl-dots .owl-dot.active, .owl-carousel .owl-dots .owl-dot:hover {
    opacity: 1; }

/* Pagination
-----------------------------------------------------------------*/
.slider-element .owl-dots {
  position: absolute;
  width: 100%;
  z-index: 20;
  margin: 0;
  top: auto;
  bottom: 25px; }
  .slider-element .owl-dots .owl-dot {
    width: 10px;
    height: 10px;
    margin: 0 3px;
    opacity: 1;
    background-color: transparent;
    border: 1px solid white; }
  .slider-element .owl-dots .owl-dot.active,
  .slider-element .owl-dots .owl-dot:hover {
    background-color: white; }

.dark .owl-theme .owl-controls .owl-nav [class*=owl-] {
  border-color: transparent;
  color: #eeeeee;
  background-color: rgba(0, 0, 0, 0.5); }

/* ----------------------------------------------------------------
	Flip Cards
-----------------------------------------------------------------*/
.flip-card-front,
.flip-card-back {
  background-size: cover;
  background-position: center;
  -webkit-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  height: 280px;
  border-radius: 6px;
  color: #fff;
  font-size: 1.5rem; }
  .flip-card-front.no-after:after, .flip-card-front.no-after:after,
  .flip-card-back.no-after:after,
  .flip-card-back.no-after:after {
    content: none; }
  .flip-card-front:after,
  .flip-card-back:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: "";
    display: block;
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 6px; }

.flip-card {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-perspective: 1000px;
  perspective: 1000px; }
  .flip-card:hover .flip-card-front,
  .flip-card:hover .flip-card-back {
    -webkit-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1); }

.flip-card-back {
  background-color: #666;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.flip-card-inner {
  -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
  transform: translateY(-50%) translateZ(60px) scale(0.94);
  top: 50%;
  position: absolute;
  left: 0;
  width: 100%;
  padding: 2rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 1px solid transparent;
  -webkit-perspective: inherit;
  perspective: inherit;
  z-index: 2; }
  .flip-card-inner span {
    font-size: 18px;
    line-height: 20px;
    font-weight: 300; }
  .flip-card-inner p {
    position: relative;
    font-size: 16px;
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.7); }

.flip-card-front,
.flip-card:hover .flip-card-back {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.flip-card:hover .flip-card-front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.top-to-bottom .flip-card-back {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.top-to-bottom:hover .flip-card-front {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.top-to-bottom .flip-card-front,
.top-to-bottom:hover .flip-card-back {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

/*-----------------------------------------------------------------------------------

	Shortcodes: overlays.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Overlays
-----------------------------------------------------------------*/
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.overlay .overlay-wrap,
.text-overlay {
  opacity: 0;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); }
  body:not(.device-touch) .overlay .overlay-wrap, body:not(.device-touch)
  .text-overlay {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }

.overlay .overlay-wrap i {
  position: absolute;
  font-size: 40px;
  line-height: 1;
  width: 40px;
  height: 40px;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.8); }

.overlay:hover .overlay-wrap,
.overlay:hover .text-overlay {
  opacity: 1; }

.text-overlay {
  position: absolute;
  opacity: 0.85;
  top: auto;
  bottom: 0;
  height: auto;
  padding: 40px 15px 15px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  background-color: transparent;
  background-image: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
  /* IE6-9 */
  backface-visibility: hidden; }

.text-overlay-title h2, .text-overlay-title .h2,
.text-overlay-title h3,
.text-overlay-title .h3,
.text-overlay-title h4,
.text-overlay-title .h4 {
  margin: 0;
  color: whitesmoke; }

.text-overlay-title h2, .text-overlay-title .h2 {
  font-size: 28px; }

.text-overlay-title h3, .text-overlay-title .h3 {
  font-size: 17px;
  font-weight: 600; }

.text-overlay-title h2, .text-overlay-title .h2,
.text-overlay-title h3,
.text-overlay-title .h3,
.text-overlay-title h4,
.text-overlay-title .h4 {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.text-overlay-meta {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #cccccc;
  font-size: 13px; }
  .text-overlay-meta span i {
    position: relative;
    top: 1px;
    font-size: 14px; }

/* News Carousel Overlays
-----------------------------------------------------------------*/
.news-carousel .text-overlay {
  padding: 40px 30px 25px; }

.news-carousel .text-overlay-title {
  margin: 15px 0 10px; }
  .news-carousel .text-overlay-title h2, .news-carousel .text-overlay-title .h2,
  .news-carousel .text-overlay-title h3,
  .news-carousel .text-overlay-title .h3,
  .news-carousel .text-overlay-title h4,
  .news-carousel .text-overlay-title .h4 {
    line-height: 1.2;
    text-overflow: none;
    white-space: normal; }

.news-carousel .text-overlay-meta {
  font-size: 14px; }

.news-carousel .rounded-skill {
  position: absolute;
  margin: 0 !important;
  top: 30px;
  right: 30px;
  color: #FFF;
  font-weight: 700; }

@media (max-width: 767.98px) {
  .news-carousel .overlay {
    display: none; } }

/*-----------------------------------------------------------------------------------

	Shortcodes: headings.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Heading Styles
-----------------------------------------------------------------*/
/* Block Titles
-----------------------------------------------------------------*/
.title-block {
  padding: 2px 0 3px 20px;
  border-left: 7px solid #1abc9c;
  margin-bottom: 30px; }
  @media (max-width: 767.98px) {
    .title-block {
      padding: 2px 0 3px 15px;
      border-left-width: 5px; } }
  .title-block-right {
    padding: 2px 20px 3px 0;
    border-left: 0;
    border-right: 7px solid #1abc9c;
    text-align: right; }
    @media (max-width: 767.98px) {
      .title-block-right {
        padding: 2px 15px 3px 0;
        border-right-width: 5px; } }
  .title-block h1, .title-block .h1,
  .title-block h2,
  .title-block .h2,
  .title-block h3,
  .title-block .h3,
  .title-block h4,
  .title-block .h4 {
    margin-bottom: 0;
    color: #444444; }
    .title-block h1 > span:not(.nocolor), .title-block .h1 > span:not(.nocolor),
    .title-block h2 > span:not(.nocolor),
    .title-block .h2 > span:not(.nocolor),
    .title-block h3 > span:not(.nocolor),
    .title-block .h3 > span:not(.nocolor),
    .title-block h4 > span:not(.nocolor),
    .title-block .h4 > span:not(.nocolor) {
      color: #1abc9c; }
  .title-block > span {
    display: block;
    margin-top: 4px;
    color: #333333;
    font-weight: 300; }
    .dark .title-block > span {
      color: #999999; }
  .title-block h1 + span, .title-block .h1 + span {
    font-size: 22px; }
    @media (max-width: 767.98px) {
      .title-block h1 + span, .title-block .h1 + span {
        font-size: 18px; } }
  .title-block h2 + span, .title-block .h2 + span {
    font-size: 19px; }
    @media (max-width: 767.98px) {
      .title-block h2 + span, .title-block .h2 + span {
        font-size: 17px; } }
  .title-block h3 + span, .title-block .h3 + span {
    font-size: 17px; }
    @media (max-width: 767.98px) {
      .title-block h3 + span, .title-block .h3 + span {
        font-size: 15px; } }
  .title-block h4 + span, .title-block .h4 + span {
    font-size: 15px;
    margin-top: 3px; }
    @media (max-width: 767.98px) {
      .title-block h4 + span, .title-block .h4 + span {
        font-size: 13px; } }

/* Heading Block - with Subtitle
-----------------------------------------------------------------*/
.heading-block {
  margin-bottom: 50px; }
  .dark .heading-block:not(.not-dark) {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); }

.heading-block h1, .heading-block .h1,
.heading-block h2,
.heading-block .h2,
.heading-block h3,
.heading-block .h3,
.heading-block h4,
.heading-block .h4 {
  margin-bottom: 0;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #333333; }
  .heading-block h1 > span:not(.nocolor), .heading-block .h1 > span:not(.nocolor),
  .heading-block h2 > span:not(.nocolor),
  .heading-block .h2 > span:not(.nocolor),
  .heading-block h3 > span:not(.nocolor),
  .heading-block .h3 > span:not(.nocolor),
  .heading-block h4 > span:not(.nocolor),
  .heading-block .h4 > span:not(.nocolor) {
    color: #1abc9c; }
  .dark :not(.not-dark).heading-block h1, .dark :not(.not-dark).heading-block .h1, .dark :not(.not-dark).heading-block h2, .dark :not(.not-dark).heading-block .h2, .dark :not(.not-dark).heading-block h3, .dark :not(.not-dark).heading-block .h3, .dark :not(.not-dark).heading-block h4, .dark :not(.not-dark).heading-block .h4 {
    color: #FFF; }
  .dark .not-dark.heading-block h1, .dark .not-dark.heading-block .h1, .dark .not-dark.heading-block h2, .dark .not-dark.heading-block .h2, .dark .not-dark.heading-block h3, .dark .not-dark.heading-block .h3, .dark .not-dark.heading-block h4, .dark .not-dark.heading-block .h4 {
    color: #333333; }

.dark :not(.not-dark).emphasis-title {
  color: #333333; }

.emphasis-title h1, .emphasis-title .h1,
.emphasis-title h2,
.emphasis-title .h2 {
  margin-bottom: 0;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  color: #333333; }
  .dark :not(.not-dark).emphasis-title h1, .dark :not(.not-dark).emphasis-title .h1, .dark :not(.not-dark).emphasis-title h2, .dark :not(.not-dark).emphasis-title .h2 {
    color: #FFF; }
  .emphasis-title h1 > span:not(.nocolor), .emphasis-title .h1 > span:not(.nocolor),
  .emphasis-title h2 > span:not(.nocolor),
  .emphasis-title .h2 > span:not(.nocolor) {
    color: #1abc9c; }
  .dark .not-dark.emphasis-title h1, .dark .not-dark.emphasis-title .h1, .dark .not-dark.emphasis-title h2, .dark .not-dark.emphasis-title .h2 {
    color: #333333; }

.heading-block h1, .heading-block .h1 {
  font-size: 32px; }
  @media (max-width: 767.98px) {
    .heading-block h1, .heading-block .h1 {
      font-size: 30px; } }

.heading-block h2, .heading-block .h2 {
  font-size: 30px; }
  @media (max-width: 767.98px) {
    .heading-block h2, .heading-block .h2 {
      font-size: 26px; } }

.heading-block h3, .heading-block .h3 {
  font-size: 26px; }
  @media (max-width: 767.98px) {
    .heading-block h3, .heading-block .h3 {
      font-size: 22px; } }

.heading-block h4, .heading-block .h4 {
  font-size: 20px;
  font-weight: 700; }
  @media (max-width: 767.98px) {
    .heading-block h4, .heading-block .h4 {
      font-size: 19px; } }

.heading-block > span:not(.before-heading) {
  display: block;
  margin-top: 10px;
  font-weight: 300;
  color: #777777; }

.dark :not(.not-dark).heading-block > span {
  color: #eeeeee; }

.heading-block .before-heading {
  margin-bottom: 7px; }

.heading-block.center > span,
.heading-block.title-center > span,
.center .heading-block > span {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto; }

.heading-block h1 + span, .heading-block .h1 + span {
  font-size: 24px; }
  @media (max-width: 767.98px) {
    .heading-block h1 + span, .heading-block .h1 + span {
      font-size: 20px; } }

.heading-block h2 + span, .heading-block .h2 + span {
  font-size: 22px; }
  @media (max-width: 767.98px) {
    .heading-block h2 + span, .heading-block .h2 + span {
      font-size: 18px; } }

.heading-block h3 + span, .heading-block .h3 + span {
  font-size: 20px; }
  @media (max-width: 767.98px) {
    .heading-block h3 + span, .heading-block .h3 + span {
      font-size: 17px; } }

.heading-block h4 + span, .heading-block .h4 + span {
  font-size: 18px; }
  @media (max-width: 767.98px) {
    .heading-block h4 + span, .heading-block .h4 + span {
      font-size: 17px; } }

.heading-block ~ p:not(.lead) {
  font-size: 15px; }

.heading-block:after {
  content: '';
  display: block;
  margin-top: 30px;
  width: 40px;
  border-top: 2px solid #444444; }
  .dark :not(.not-dark).heading-block:after {
    border-top-color: rgba(255, 255, 255, 0.4); }

.center .heading-block:after,
.heading-block.center:after,
.heading-block.title-center:after {
  margin: 30px auto 0; }

.heading-block.noborder:after,
.heading-block.nobottomborder:after {
  display: none; }

.tright .heading-block,
.heading-block.tright,
.heading-block.title-right {
  direction: rtl; }

.heading-block.border-color:after {
  border-color: #1abc9c; }

/* Emphasis Title
-----------------------------------------------------------------*/
.emphasis-title {
  margin: 0 0 50px; }
  .emphasis-title h1, .emphasis-title .h1,
  .emphasis-title h2,
  .emphasis-title .h2 {
    font-size: 64px;
    letter-spacing: -2px; }
    @media (max-width: 767.98px) {
      .emphasis-title h1, .emphasis-title .h1,
      .emphasis-title h2,
      .emphasis-title .h2 {
        font-size: 44px; } }
    @media (max-width: 575.98px) {
      .emphasis-title h1, .emphasis-title .h1,
      .emphasis-title h2,
      .emphasis-title .h2 {
        font-size: 40px; } }
    .emphasis-title h1 strong, .emphasis-title .h1 strong,
    .emphasis-title h2 strong,
    .emphasis-title .h2 strong {
      font-weight: 700; }

/* Justify Border Title
-----------------------------------------------------------------*/
.fancy-title {
  position: relative;
  margin-bottom: 30px; }
  .fancy-title h1, .fancy-title .h1,
  .fancy-title h2,
  .fancy-title .h2,
  .fancy-title h3,
  .fancy-title .h3,
  .fancy-title h4,
  .fancy-title .h4,
  .fancy-title h5,
  .fancy-title .h5,
  .fancy-title h6,
  .fancy-title .h6 {
    position: relative;
    display: inline-block;
    background-color: white;
    padding-right: 15px;
    margin-bottom: 0;
    color: #444444; }
    .fancy-title h1 > span:not(.nocolor), .fancy-title .h1 > span:not(.nocolor),
    .fancy-title h2 > span:not(.nocolor),
    .fancy-title .h2 > span:not(.nocolor),
    .fancy-title h3 > span:not(.nocolor),
    .fancy-title .h3 > span:not(.nocolor),
    .fancy-title h4 > span:not(.nocolor),
    .fancy-title .h4 > span:not(.nocolor),
    .fancy-title h5 > span:not(.nocolor),
    .fancy-title .h5 > span:not(.nocolor),
    .fancy-title h6 > span:not(.nocolor),
    .fancy-title .h6 > span:not(.nocolor) {
      color: #1abc9c; }
    .dark .fancy-title h1, .dark .fancy-title .h1, .dark
    .fancy-title h2, .dark
    .fancy-title .h2, .dark
    .fancy-title h3, .dark
    .fancy-title .h3, .dark
    .fancy-title h4, .dark
    .fancy-title .h4, .dark
    .fancy-title h5, .dark
    .fancy-title .h5, .dark
    .fancy-title h6, .dark
    .fancy-title .h6 {
      background-color: #383838; }
  .fancy-title h4, .fancy-title .h4,
  .fancy-title h5,
  .fancy-title .h5,
  .fancy-title h6,
  .fancy-title .h6 {
    padding-right: 10px; }
  .fancy-title.title-double-border:before, .fancy-title.title-border:before, .fancy-title.title-border-color:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0;
    border-top: 3px double #e5e5e5;
    left: auto;
    top: 50%;
    right: 0;
    transform: translateY(-50%); }
    .dark .fancy-title.title-double-border:before, .dark .fancy-title.title-border:before, .dark .fancy-title.title-border-color:before {
      border-top-color: rgba(255, 255, 255, 0.15); }
  .fancy-title.title-border:before {
    border-top: 1px solid #eeeeee; }
  .fancy-title.title-border-color:before {
    border-top: 1px solid rgba(26, 188, 156, 0.6); }

/* Fancy Title - Center Align
-----------------------------------------------------------------*/
.title-center,
.title-right {
  text-align: center; }
  .title-center h1, .title-center .h1,
  .title-center h2,
  .title-center .h2,
  .title-center h3,
  .title-center .h3,
  .title-right h1,
  .title-right .h1,
  .title-right h2,
  .title-right .h2,
  .title-right h3,
  .title-right .h3 {
    padding: 0 15px; }
  .title-center h4, .title-center .h4,
  .title-center h5,
  .title-center .h5,
  .title-center h6,
  .title-center .h6,
  .title-right h4,
  .title-right .h4,
  .title-right h5,
  .title-right .h5,
  .title-right h6,
  .title-right .h6 {
    padding: 0 10px; }

/* Fancy Title - Right Align
-----------------------------------------------------------------*/
.title-right {
  text-align: right; }

/* Fancy Title - Bottom Short Border
-----------------------------------------------------------------*/
.fancy-title.title-bottom-border h1, .fancy-title.title-bottom-border .h1,
.fancy-title.title-bottom-border h2,
.fancy-title.title-bottom-border .h2,
.fancy-title.title-bottom-border h3,
.fancy-title.title-bottom-border .h3,
.fancy-title.title-bottom-border h4,
.fancy-title.title-bottom-border .h4,
.fancy-title.title-bottom-border h5,
.fancy-title.title-bottom-border .h5,
.fancy-title.title-bottom-border h6,
.fancy-title.title-bottom-border .h6 {
  display: block;
  background: transparent;
  padding: 0 0 10px;
  border-bottom: 3px solid #1abc9c; }

.fancy-title.title-dotted-border {
  background: url("image/icons/dotted.png") repeat-x center; }

/* Sub Heading
-----------------------------------------------------------------*/
.before-heading {
  display: block;
  margin: 0 0 5px;
  font: normal 400 16px "Crete Round", serif;
  color: #999999; }

/*-----------------------------------------------------------------------------------

	Shortcodes: dividers.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Divider
-----------------------------------------------------------------*/
.divider {
  position: relative;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  overflow: hidden;
  margin: 35px 0;
  color: #e5e5e5;
  width: 100%;
  /* Divider - Icon Align Right
	-----------------------------------------------------------------*/
  /* Divider - Icon Align Center
	-----------------------------------------------------------------*/
  /* Divider - Rounded Icon
	-----------------------------------------------------------------*/
  /* Divider - Rounded & Border
	-----------------------------------------------------------------*/
  /* Divider - Line Only
	-----------------------------------------------------------------*/ }
  .dark .divider {
    color: rgba(255, 255, 255, 0.15); }
  .divider-margin {
    margin: 70px 0; }
  .divider:after, .divider-center:before, .divider-center.divider-short:before {
    content: '';
    position: absolute;
    width: 100%;
    top: 50%;
    left: 30px;
    height: 0;
    transform: translateY(-50%);
    border-top: 1px solid #eeeeee; }
    .dark .divider:after, .dark .divider-center:before, .dark .divider-center.divider-short:before {
      border-top-color: rgba(255, 255, 255, 0.1); }
  .divider-thick:after, .divider-center.divider-thick:before, .divider-center.divider-short.divider-thick:before {
    border-top-width: 3px; }
  .divider-short:after {
    width: 30%; }
  .divider-vshort:after {
    width: 12%; }
  .divider i {
    position: relative;
    width: 18px;
    height: 18px;
    line-height: 1;
    font-size: 18px !important;
    text-align: center; }
  .divider a {
    position: relative;
    display: inline-block;
    color: inherit;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .divider a:hover {
      color: #888888; }
  .divider-right {
    text-align: right;
    justify-content: flex-end; }
    .divider-right:after {
      left: auto;
      right: 30px; }
  .divider-center, .divider-center.divider-short {
    text-align: center;
    justify-content: center; }
  .divider-center {
    /* Short Divider - Icon Align Center
		-----------------------------------------------------------------*/ }
    .divider-center:before {
      left: -50% !important;
      right: 0;
      margin-left: -20px; }
    .divider-center:after {
      left: 50% !important;
      right: 0;
      margin-left: 20px; }
    .divider-center.divider-short:before {
      left: auto !important;
      right: 50%;
      margin-right: 20px;
      width: 15%; }
    .divider-center.divider-short:after {
      left: 50% !important;
      right: auto !important;
      margin-left: 20px;
      width: 15%; }
    .divider-center.divider-vshort:before, .divider-center.divider-vshort:after {
      width: 6%; }
  .divider-rounded {
    color: #bbbbbb; }
  .divider-rounded:after, .divider-rounded.divider-center:before, .divider-rounded.divider-short:before, .divider-border:after, .divider-border.divider-center:before, .divider-border.divider-short:before {
    left: 40px; }
  .dark .divider-rounded, .dark .divider-border {
    color: #999999; }
  .divider-rounded i, .divider-border i {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: whitesmoke;
    border-radius: 50%; }
    .dark .divider-rounded i, .dark .divider-border i {
      background-color: rgba(255, 255, 255, 0.1); }
  .divider-rounded i {
    color: #bbbbbb; }
  .divider-rounded.divider-right:after, .divider-border.divider-right:after {
    left: auto;
    right: 40px; }
  .divider-border {
    color: #e5e5e5; }
    .divider-border i {
      line-height: 38px;
      background-color: transparent;
      border: 1px solid #eeeeee; }
      .dark .divider-border i {
        background-color: transparent;
        border-color: rgba(255, 255, 255, 0.15); }
  .divider-line:before, .divider-line:after {
    margin-left: 0 !important;
    margin-right: 0 !important; }

/*-----------------------------------------------------------------------------------

	Shortcodes: youtubebg.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	HTML5 Youtube Video Backgrounds
-----------------------------------------------------------------*/
.mb_YTVPlayer {
  display: block;
  transform: translateZ(0);
  transform-style: preserve-3d;
  perspective: 1000;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  animation-timing-function: linear; }
  .mb_YTVPlayer :focus {
    outline: 0; }
  .mb_YTVPlayer.fullscreen {
    display: block !important;
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    margin: 0 !important;
    border: none !important; }
    .mb_YTVPlayer.fullscreen .mbYTP_wrapper {
      opacity: 1 !important; }

.mbYTP_wrapper iframe {
  max-width: 4000px !important; }

.inline_YTPlayer {
  margin-bottom: 20px;
  vertical-align: top;
  position: relative;
  left: 0;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  background: rgba(0, 0, 0, 0.5); }
  .inline_YTPlayer img {
    border: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    -o-transform: none !important;
    transform: none !important;
    margin: 0 !important;
    padding: 0 !important; }

/*-----------------------------------------------------------------------------------

	Shortcodes: misc.scss

-----------------------------------------------------------------------------------*/
@media (max-width: 1199.98px) {
  canvas {
    max-width: 100%;
    height: auto !important; } }

/* Infinity Scroll - Message Style
-----------------------------------------------------------------*/
#infscr-loading,
#portfolio-ajax-loader {
  position: fixed;
  z-index: 20;
  top: 50%;
  left: 50%;
  width: 48px;
  height: 48px;
  margin: -24px 0 0 -24px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  line-height: 48px;
  font-size: 24px;
  color: #FFF;
  text-align: center; }

#infscr-loading img,
#portfolio-ajax-loader img {
  display: none;
  width: 24px;
  height: 24px;
  margin: 12px; }

#portfolio-ajax-loader {
  display: none; }
  #portfolio-ajax-loader img {
    display: block; }

.page-load-status {
  position: relative;
  display: none;
  padding: 30px 0; }
  .page-load-status .css3-spinner {
    position: absolute;
    z-index: auto;
    background-color: transparent !important; }
  .page-load-status .css3-spinner-ball-pulse-sync > div {
    background-color: #333; }

/* ----------------------------------------------------------------
	Forms
-----------------------------------------------------------------*/
.sm-form-control {
  display: block;
  width: 100%;
  min-height: 38px;
  padding: 8px 14px;
  font-size: 15px;
  line-height: 1.42;
  color: #333333;
  background-color: white;
  background-image: none;
  border: 2px solid #dddddd;
  border-radius: 0 !important;
  -webkit-transition: border-color ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s; }
  .sm-form-control:focus {
    border-color: #aaaaaa;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .sm-form-control::-webkit-input-placeholder {
    color: #999999;
    opacity: 1; }
  .sm-form-control::-moz-placeholder {
    color: #999999;
    opacity: 1; }
  .sm-form-control:-ms-input-placeholder {
    color: #999999;
    opacity: 1; }

.sm-form-control[disabled],
.sm-form-control[readonly],
fieldset[disabled] .sm-form-control {
  cursor: not-allowed;
  background-color: #eeeeee;
  opacity: 1; }

.form-control {
  border-radius: 3px; }

.form-control:active,
.form-control:focus {
  border-color: #999999;
  box-shadow: none; }

label {
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #333333;
  margin-bottom: 10px;
  cursor: pointer; }
  .dark label {
    color: #bbbbbb; }
  label.label-muted {
    color: #999999;
    font-weight: normal;
    margin-right: 5px; }
    .dark label.label-muted {
      color: #777777; }

form .col_full,
form .col_half,
form .col_one_third,
form .col_two_third,
form .col_three_fourth,
form .col_one_fourth,
form .col_one_fifth,
form .col_two_fifth,
form .col_three_fifth,
form .col_four_fifth,
form .col_one_sixth,
form .col_five_sixth {
  margin-bottom: 25px; }
  @media (max-width: 767.98px) {
    form .col_full,
    form .col_half,
    form .col_one_third,
    form .col_two_third,
    form .col_three_fourth,
    form .col_one_fourth,
    form .col_one_fifth,
    form .col_two_fifth,
    form .col_three_fifth,
    form .col_four_fifth,
    form .col_one_sixth,
    form .col_five_sixth {
      margin-bottom: 25px !important; } }

/* ----------------------------------------------------------------
	Contact Form
-----------------------------------------------------------------*/
#contact-form-overlay,
#contact-form-overlay-mini {
  position: relative;
  max-width: 800px;
  background-color: white;
  padding: 40px;
  margin: 0 auto;
  z-index: 10;
  border-radius: 4px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15); }
  .dark #contact-form-overlay, .dark
  #contact-form-overlay-mini {
    background-color: #383838; }

#contact-form-overlay-mini {
  float: right;
  width: 380px;
  margin: 0; }
  @media (max-width: 991.98px) {
    #contact-form-overlay-mini {
      float: none;
      width: auto;
      max-width: 380px;
      margin: 0 auto; } }

label.error {
  margin-top: 5px;
  color: #dc3545;
  font-weight: 400; }

label.error {
  display: none !important; }

.show-error-msg + label.error {
  display: block !important; }

.form-control.error,
.sm-form-control.error {
  border-color: #dc3545; }

/* ----------------------------------------------------------------
	Google Maps
-----------------------------------------------------------------*/
#google-map {
  position: relative;
  width: 100%;
  height: 450px; }

.gmap img {
  max-width: none !important; }

.gm-style .gm-style-iw h3 span, .gm-style .gm-style-iw .h3 span {
  font-size: inherit;
  font-family: inherit; }

#map-overlay {
  position: relative;
  padding: 100px 0; }
  #map-overlay #google-map {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0; }

/* ----------------------------------------------------------------
	Google Custom Search
-----------------------------------------------------------------*/
#content .cse .gsc-control-cse,
#content .gsc-control-cse,
#content .gsc-above-wrapper-area,
#content .gsc-adBlock,
#content .gsc-thumbnail-inside,
#content .gsc-url-top,
#content .gsc-table-result,
#content .gsc-webResult,
#content .gsc-result {
  padding: 0 !important; }

#content .gsc-selected-option-container {
  width: auto !important; }

#content .gsc-result-info {
  padding-left: 0 !important; }

#content .gsc-above-wrapper-area-container,
#content .gsc-table-result {
  margin-bottom: 10px; }

#content .gcsc-branding {
  display: none; }

#content .gsc-results,
#content .gsc-webResult {
  width: 100% !important; }

#content .gs-no-results-result .gs-snippet,
#content .gs-error-result .gs-snippet {
  margin: 0 !important; }

/* ----------------------------------------------------------------
	Quotes & Blockquotes
-----------------------------------------------------------------*/
blockquote p {
  margin-bottom: 15px; }

blockquote.pull-left {
  max-width: 300px;
  margin: 5px 20px 10px 0;
  padding-right: 0; }

blockquote.pull-right {
  max-width: 300px;
  margin: 5px 0 10px 20px;
  padding-left: 0; }

.quote {
  border: none !important;
  position: relative; }
  .quote p {
    position: relative; }
  .quote:before {
    font-family: 'font-icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    content: "\e7ad";
    position: absolute;
    width: 48px;
    height: 48px;
    line-height: 48px;
    font-size: 42px;
    top: 0;
    left: 0;
    color: #eeeeee; }
    .dark .quote:before {
      color: rgba(255, 255, 255, 0.1); }
  .quote.blockquote-reverse:before {
    content: "\e7ae";
    left: auto;
    right: 0; }

/* ----------------------------------------------------------------
	Dropcaps & Highlights
-----------------------------------------------------------------*/
.dropcap {
  float: left;
  font-size: 42px;
  line-height: 1;
  margin: 0 5px 0 0;
  text-transform: uppercase; }

.highlight {
  padding: 2px 5px;
  background-color: #444;
  color: #FFF;
  border-radius: 2px; }
  .dark .highlight {
    background-color: rgba(0, 0, 0, 0.2); }

/* ----------------------------------------------------------------
	Magazine Specific Classes
-----------------------------------------------------------------*/
.top-advert {
  float: right;
  padding: 5px;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee; }
  .dark .top-advert {
    border-left-color: rgba(255, 255, 255, 0.1);
    border-right-color: rgba(255, 255, 255, 0.1); }
  .top-advert a,
  .top-advert img {
    display: block; }

.bnews-title {
  display: block;
  float: left;
  margin-top: 2px;
  padding-top: .3em;
  text-transform: uppercase; }
  @media (max-width: 991.98px) {
    .bnews-title {
      float: none;
      display: inline-block; } }

.bnews-slider {
  float: left;
  width: 970px;
  margin-left: 20px;
  min-height: 0; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .bnews-slider {
      width: 817px; } }
  @media (max-width: 991.98px) {
    .bnews-slider {
      float: none;
      margin: 10px 0 0;
      width: 100%; } }

/* ----------------------------------------------------------------
	Text Rotater
-----------------------------------------------------------------*/
.text-rotater > .t-rotate > .animated {
  display: inline-block; }

/* ----------------------------------------------------------------
	Go To Top
-----------------------------------------------------------------*/
#gotoTop {
  display: none;
  z-index: 299;
  position: fixed;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 20px;
  line-height: 38px;
  text-align: center;
  color: white;
  top: auto;
  left: auto;
  cursor: pointer;
  right: 30px;
  bottom: 50px;
  border-radius: 2px; }
  #gotoTop:hover {
    background-color: #1abc9c; }
  @media (max-width: 767.98px) {
    #gotoTop {
      display: none !important; } }

body:not(.device-touch) #gotoTop {
  -webkit-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear; }

.stretched #gotoTop {
  right: 30px;
  bottom: 30px; }

/* ----------------------------------------------------------------
	Error 404
-----------------------------------------------------------------*/
.error404 {
  display: block;
  font-size: 216px;
  font-weight: 700;
  color: #dddddd;
  line-height: 1.35;
  letter-spacing: 4px; }
  @media (max-width: 991.98px) {
    .error404 {
      font-size: 180px; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .error404 {
      font-size: 160px; } }
  @media (max-width: 575.98px) {
    .error404 {
      font-size: 120px; } }
  .dark .error404 {
    color: rgba(255, 255, 255, 0.15); }

.error404-wrap .container {
  z-index: 7; }

.error404-wrap .error404 {
  line-height: 0.9;
  margin-bottom: 40px;
  font-weight: bold;
  font-size: 244px;
  color: #FFF !important;
  opacity: 0.2;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4); }

.error404-wrap .heading-block h4, .error404-wrap .heading-block .h4 {
  font-weight: 300;
  margin-bottom: 8px; }

.error404-wrap .heading-block span {
  font-size: 17px; }

.error404-wrap form {
  max-width: 500px; }

.dark.error404-wrap .form-control::-webkit-input-placeholder {
  color: #888888; }

.dark.error404-wrap .form-control::-moz-placeholder {
  color: #888888; }

.dark.error404-wrap .form-control:-ms-input-placeholder {
  color: #888888; }

/* ----------------------------------------------------------------
	Landing Pages
-----------------------------------------------------------------*/
.landing-wide-form {
  background: rgba(0, 0, 0, 0.3);
  padding: 30px;
  border-radius: 3px; }

.landing-form-overlay {
  position: absolute;
  z-index: 10;
  top: auto;
  left: auto;
  right: 0;
  bottom: -154px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 3px 3px 0 0; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .landing-form-overlay {
      bottom: -142px; } }
  @media (max-width: 991.98px) {
    .landing-form-overlay {
      position: absolute;
      width: 100%;
      max-width: 400px;
      right: auto;
      left: 50%;
      margin-left: -200px;
      bottom: 0; } }
  @media (max-width: 575.98px) {
    .landing-form-overlay {
      margin-left: -155px; } }

.landing-video {
  z-index: 1;
  width: 560px;
  height: 315px;
  margin: 22px 0 0 95px;
  overflow: hidden; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .landing-video {
      width: 465px;
      height: 262px;
      margin: 19px 0 0 75px; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .landing-video {
      width: 352px;
      height: 199px;
      margin: 14px 0 0 60px; } }
  @media (max-width: 767.98px) {
    .landing-video {
      position: relative;
      top: 14px;
      width: 76%;
      height: auto;
      margin: 19px auto 0; } }

.landing-promo h3, .landing-promo .h3 {
  font-size: 26px; }

.landing-promo > .container > span {
  font-size: 17px; }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .col_one_third .landing-wide-form {
    padding: 36px 30px; }
    .col_one_third .landing-wide-form .heading-block h2, .col_one_third .landing-wide-form .heading-block .h2 {
      font-size: 32px; }
    .col_one_third .landing-wide-form .heading-block span {
      font-size: 17px; } }

/* ----------------------------------------------------------------
	Preloaders
-----------------------------------------------------------------*/
.preloader,
.preloader2,
.form-process {
  display: block;
  width: 100%;
  height: 100%;
  background-color: white;
  background-image: url("image/preloader.gif");
  background-repeat: no-repeat;
  background-position: center center; }
  .dark .preloader, .dark
  .preloader2, .dark
  .form-process {
    background-image: url("image/preloader-dark.gif"); }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .preloader,
    .preloader2,
    .form-process {
      background-image: url("image/preloader@2x.gif");
      background-size: 24px 24px; }
      .dark .preloader, .dark
      .preloader2, .dark
      .form-process {
        background-image: url("image/preloader-dark@2x.gif"); } }

.preloader2 {
  background-color: transparent; }

.form-process {
  display: none;
  position: absolute;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.7); }

.dark .preloader {
  background-color: rgba(0, 0, 0, 0.2); }

.dark .form-process {
  background-color: transparent; }

/* ----------------------------------------------------------------
	Toastr Notifications
-----------------------------------------------------------------*/
.toast-title {
  font-weight: bold; }

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word; }
  .toast-message a,
  .toast-message label {
    color: #ffffff; }
  .toast-message a:hover {
    color: #cccccc;
    text-decoration: none; }

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
  line-height: 1; }
  .toast-close-button:hover, .toast-close-button:focus {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.4;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
    filter: alpha(opacity=40); }

.rtl .toast-close-button {
  left: -0.3em;
  float: left;
  right: 0.3em; }

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.toast-top-full-width {
  top: 12px;
  right: 0;
  width: 100%; }

.toast-bottom-full-width {
  bottom: 12px;
  right: 0;
  width: 100%; }

.toast-top-left {
  top: 12px;
  left: 12px; }

.toast-top-right {
  top: 12px;
  right: 12px; }

.toast-bottom-right {
  right: 12px;
  bottom: 12px; }

.toast-bottom-left {
  bottom: 12px;
  left: 12px; }

#toast-container {
  position: fixed;
  z-index: 999999;
  pointer-events: none;
  /*overrides*/ }
  #toast-container * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  #toast-container > div {
    position: relative;
    pointer-events: auto;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 20px 25px;
    width: 300px;
    border-radius: 3px;
    background-repeat: no-repeat;
    -moz-box-shadow: 0 0 12px #999999;
    -webkit-box-shadow: 0 0 12px #999999;
    box-shadow: 0 0 12px #999999;
    color: #FFFFFF;
    opacity: 0.8;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    filter: alpha(opacity=80); }
  #toast-container > div.rtl {
    direction: rtl; }
  #toast-container > div:hover {
    -moz-box-shadow: 0 0 12px #000000;
    -webkit-box-shadow: 0 0 12px #000000;
    box-shadow: 0 0 12px #000000;
    opacity: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    cursor: pointer; }
  #toast-container.toast-top-center > div,
  #toast-container.toast-bottom-center > div {
    width: 300px;
    margin-left: auto;
    margin-right: auto; }
  #toast-container.toast-top-full-width > div,
  #toast-container.toast-bottom-full-width > div {
    width: 96%;
    margin-left: auto;
    margin-right: auto; }

.toast {
  background-color: #030303; }

.toast-success {
  background-color: #28a745; }

.toast-error {
  background-color: #dc3545; }

.toast-info {
  background-color: #17a2b8; }

.toast-warning {
  background-color: #ffc107; }

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: black;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40); }

/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 10px 15px;
    width: 11em; }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em; }
  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em; } }

@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 10px 15px;
    width: 18em; }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em; }
  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em; } }

@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 20px;
    width: 25em; } }

/* ----------------------------------------------------------------
	Wedding
-----------------------------------------------------------------*/
.wedding-head {
  position: relative;
  line-height: 1;
  font-size: 80px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15); }
  @media (max-width: 991.98px) {
    .wedding-head {
      font-size: 60px; } }
  @media (max-width: 767.98px) {
    .wedding-head {
      font-size: 48px; } }
  .wedding-head .first-name,
  .wedding-head .last-name,
  .wedding-head .and {
    display: inline-block;
    margin-right: 15px;
    font-weight: bold;
    text-align: right;
    text-transform: uppercase;
    font-family: "Raleway", sans-serif;
    letter-spacing: 2px; }
    @media (max-width: 767.98px) {
      .wedding-head .first-name,
      .wedding-head .last-name,
      .wedding-head .and {
        display: block;
        margin: 0 0 15px;
        text-align: center; } }
  .wedding-head .last-name {
    margin: 0 0 0 15px;
    text-align: left; }
  .wedding-head .first-name span,
  .wedding-head .last-name span {
    display: block;
    margin-top: 10px;
    font-size: 56px;
    font-weight: 400;
    font-style: italic;
    font-family: "Crete Round", serif;
    text-transform: none; }
    @media (max-width: 991.98px) {
      .wedding-head .first-name span,
      .wedding-head .last-name span {
        font-size: 40px; } }
    @media (max-width: 767.98px) {
      .wedding-head .first-name span,
      .wedding-head .last-name span {
        font-size: 36px; } }
  .wedding-head .and {
    margin: 0;
    font-size: 200px;
    font-family: 'Times New Roman', serif; }
    @media (max-width: 991.98px) {
      .wedding-head .and {
        font-size: 144px; } }
    @media (max-width: 767.98px) {
      .wedding-head .and {
        font-size: 120px; } }

/* ----------------------------------------------------------------

	slider.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Flex Slider
-----------------------------------------------------------------*/
.fslider,
.fslider .flexslider,
.fslider .slider-wrap,
.fslider .slide,
.fslider .slide > a,
.fslider .slide > img,
.fslider .slide > a > img {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
  -webkit-backface-visibility: hidden; }

.fslider {
  min-height: 32px; }

.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none;
  border: none; }

.slider-wrap,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none; }

.flexslider {
  position: relative;
  margin: 0;
  padding: 0; }

.flexslider .slider-wrap > .slide {
  display: none;
  -webkit-backface-visibility: hidden; }

.flexslider .slider-wrap img {
  width: 100%;
  display: block; }

.flex-pauseplay span {
  text-transform: capitalize; }

.slider-wrap:after {
  content: '.';
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

html[xmlns] .slider-wrap {
  display: block; }

* html .slider-wrap {
  height: 1%; }

.no-js .slider-wrap > .slide:first-child {
  display: block; }

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }

.flex-control-nav {
  position: absolute;
  z-index: 10;
  text-align: center;
  top: 14px;
  right: 10px;
  margin: 0; }
  .flex-control-nav li {
    float: left;
    display: block;
    margin: 0 3px;
    width: 10px;
    height: 10px; }
    .flex-control-nav li a {
      display: block;
      cursor: pointer;
      text-indent: -9999px;
      width: 10px !important;
      height: 10px !important;
      border: 1px solid white;
      border-radius: 50%;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .flex-control-nav li:hover a,
    .flex-control-nav li a.flex-active {
      background-color: white; }
    @media (max-width: 767.98px) {
      .flex-control-nav li {
        opacity: 1; } }

/* ----------------------------------------------------------------
	Slider
-----------------------------------------------------------------*/
.slider-element {
  position: relative;
  width: 100%;
  overflow: hidden; }

.slider-parallax {
  position: relative; }
  .slider-parallax .swiper-container {
    visibility: visible;
    -webkit-backface-visibility: hidden; }
  .slider-parallax .slider-parallax-inner {
    position: fixed;
    width: 100%;
    height: 100%; }
    @media (max-width: 991.98px) {
      .slider-parallax .slider-parallax-inner {
        position: relative; } }
  .slider-parallax .slider-parallax-inner,
  .slider-parallax .slider-caption,
  .slider-parallax .ei-title {
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: transform 0s linear;
    -o-transition: transform 0s linear;
    transition: transform 0s linear;
    -webkit-backface-visibility: hidden; }

.device-touch .slider-parallax .slider-parallax-inner {
  position: relative; }
  body:not(.side-header) .device-touch .slider-parallax .slider-parallax-inner {
    left: 0; }

#header.transparent-header + .slider-parallax .slider-parallax-inner {
  top: 0; }

/* Boxed Slider
-----------------------------------------------------------------*/
.boxed-slider {
  padding-top: 40px; }

/* Flex Slider - Thumbs
-----------------------------------------------------------------*/
.fslider[data-animation='fade'][data-thumbs='true'] .flexslider,
.fslider.testimonial[data-animation='fade'] .flexslider {
  height: auto !important; }

.flex-control-nav.flex-control-thumbs {
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  right: 0;
  margin: 2px -2px -2px 0; }
  .flex-control-nav.flex-control-thumbs li {
    margin: 0 2px 2px 0; }
    .flex-control-nav.flex-control-thumbs li img {
      cursor: pointer;
      text-indent: -9999px;
      border: 0;
      border-radius: 0;
      margin: 0;
      opacity: 0.5;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
  .flex-control-nav.flex-control-thumbs li,
  .flex-control-nav.flex-control-thumbs li img {
    display: block;
    width: 100px !important;
    height: auto !important; }
  .flex-control-nav.flex-control-thumbs li:hover img,
  .flex-control-nav.flex-control-thumbs li img.flex-active {
    border-width: 0;
    opacity: 1; }

@media (max-width: 767.98px) {
  .fslider:not(.flex-thumb-grid) .flex-control-nav.flex-control-thumbs li,
  .fslider:not(.flex-thumb-grid) .flex-control-nav.flex-control-thumbs li img {
    display: block;
    width: 80px !important; } }
  @media (max-width: 767.98px) and (max-width: 575.98px) {
    .fslider:not(.flex-thumb-grid) .flex-control-nav.flex-control-thumbs li,
    .fslider:not(.flex-thumb-grid) .flex-control-nav.flex-control-thumbs li img {
      display: block;
      width: 60px !important; } }

/* Flex Thumbs - Flexible
-----------------------------------------------------------------*/
.fslider.flex-thumb-grid .flex-control-nav.flex-control-thumbs {
  margin: 2px -2px -2px 0;
  height: auto; }
  .fslider.flex-thumb-grid .flex-control-nav.flex-control-thumbs li {
    width: 25% !important;
    height: auto !important;
    margin: 0;
    padding: 0 2px 2px 0; }
    .fslider.flex-thumb-grid .flex-control-nav.flex-control-thumbs li img {
      width: 100% !important;
      height: auto !important; }

.fslider.flex-thumb-grid.grid-1 .flex-control-nav.flex-control-thumbs li {
  width: 100% !important; }

.fslider.flex-thumb-grid.grid-2 .flex-control-nav.flex-control-thumbs li {
  width: 50% !important; }

.fslider.flex-thumb-grid.grid-3 .flex-control-nav.flex-control-thumbs li {
  width: 33.3333333333% !important; }

.fslider.flex-thumb-grid.grid-4 .flex-control-nav.flex-control-thumbs li {
  width: 25% !important; }

.fslider.flex-thumb-grid.grid-5 .flex-control-nav.flex-control-thumbs li {
  width: 20% !important; }

.fslider.flex-thumb-grid.grid-6 .flex-control-nav.flex-control-thumbs li {
  width: 16.6666666667% !important; }

.fslider.flex-thumb-grid.grid-7 .flex-control-nav.flex-control-thumbs li {
  width: 14.2857142857% !important; }

.fslider.flex-thumb-grid.grid-8 .flex-control-nav.flex-control-thumbs li {
  width: 12.5% !important; }

.fslider.flex-thumb-grid.grid-9 .flex-control-nav.flex-control-thumbs li {
  width: 11.1111111111% !important; }

.fslider.flex-thumb-grid.grid-10 .flex-control-nav.flex-control-thumbs li {
  width: 10% !important; }

.fslider.flex-thumb-grid.grid-11 .flex-control-nav.flex-control-thumbs li {
  width: 9.0909090909% !important; }

.fslider.flex-thumb-grid.grid-12 .flex-control-nav.flex-control-thumbs li {
  width: 8.3333333333% !important; }

/* Swiper Slider
-----------------------------------------------------------------*/
.swiper_wrapper {
  width: 100%;
  height: 500px;
  overflow: hidden; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .swiper_wrapper:not(.force-full-screen):not(.canvas-slider-grid) {
      height: 400px !important; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .swiper_wrapper:not(.force-full-screen):not(.canvas-slider-grid) {
      height: 300px !important; } }
  @media (max-width: 575.98px) {
    .swiper_wrapper:not(.force-full-screen):not(.canvas-slider-grid) {
      height: 180px !important; } }

.full-screen .swiper_wrapper {
  height: auto; }

.swiper_wrapper .swiper-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  background: url("image/pattern2.png") repeat; }

.swiper_wrapper .swiper-slide {
  position: relative;
  margin: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden; }
  .swiper_wrapper .swiper-slide [data-caption-animate] {
    -webkit-backface-visibility: hidden; }

@media (min-width: 768px) and (max-width: 991.98px) {
  .swiper_wrapper:not(.force-full-screen):not(.canvas-slider-grid) .swiper-slide {
    height: 400px !important; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .swiper_wrapper:not(.force-full-screen):not(.canvas-slider-grid) .swiper-slide {
    height: 300px !important; } }

@media (max-width: 575.98px) {
  .swiper_wrapper:not(.force-full-screen):not(.canvas-slider-grid) .swiper-slide {
    height: 180px !important; } }

.swiper-slide .video-wrap,
.section .video-wrap,
.swiper-slide .yt-bg-player,
.section .yt-bg-player {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0; }

.yt-bg-player .inlinePlayButton,
.yt-bg-player .loading {
  display: none !important; }

.slider-element .container {
  position: relative;
  height: 100%; }

.slider-element.canvas-slider .swiper-slide img {
  width: 100%; }

.slider-element.canvas-slider a {
  display: block;
  height: 100%; }

.swiper-pagination {
  position: absolute;
  width: 100%;
  z-index: 20;
  margin: 0;
  top: auto;
  bottom: 20px !important;
  text-align: center;
  line-height: 1; }
  .swiper-pagination span {
    display: inline-block;
    cursor: pointer;
    width: 10px;
    height: 10px;
    margin: 0 4px;
    opacity: 1;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .swiper-pagination span:hover, .swiper-pagination span.swiper-pagination-bullet-active {
      background-color: white !important; }

/* Revolution Slider
-----------------------------------------------------------------*/
.boxedcontainer {
  max-width: 1140px;
  margin: auto; }

.tp-banner-container,
.tp-banner,
.tp-banner-fullscreen-container {
  width: 100%;
  position: relative;
  padding: 0; }

.tp-banner .button {
  height: auto !important; }

.tparrows.preview2:after {
  line-height: 1 !important; }

/* Video Overlay
-----------------------------------------------------------------*/
.video-wrap {
  overflow: hidden;
  width: 100%;
  position: relative; }
  .video-wrap video {
    position: relative;
    z-index: 1;
    width: 100%; }
  .video-wrap .video-overlay,
  .video-wrap .video-placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    background: transparent url("image/grid.png") repeat;
    -webkit-backface-visibility: hidden; }
  .video-wrap .video-placeholder {
    z-index: 4;
    background-repeat: no-repeat !important; }

/* Slider Caption
-----------------------------------------------------------------*/
.slider-caption,
.flex-caption {
  position: absolute;
  top: 0;
  left: 15px;
  z-index: 20;
  max-width: 550px;
  color: #eee;
  font-size: 1.375rem;
  font-weight: 300;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  -webkit-transition: top 0.3s ease;
  -o-transition: top 0.3s ease;
  transition: top 0.3s ease; }

.dark .slider-caption {
  color: #eeeeee;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15); }

.not-dark .slider-caption, .slider-caption:not(.dark),
.slider-caption:not(.dark) h2,
.slider-caption:not(.dark) .h2 {
  color: #222;
  text-shadow: none; }
  .dark .not-dark .slider-caption, .dark .slider-caption:not(.dark), .dark
  .slider-caption:not(.dark) h2, .dark
  .slider-caption:not(.dark) .h2 {
    color: #eeeeee;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15); }

.slider-caption h2, .slider-caption .h2 {
  font-size: 4rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0; }
  @media (max-width: 767.98px) {
    .slider-caption h2, .slider-caption .h2 {
      margin-bottom: 0; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .slider-caption h2, .slider-caption .h2 {
      font-size: 2.2540983607rem; } }
  @media (max-width: 575.98px) {
    .slider-caption h2, .slider-caption .h2 {
      font-size: 1.6975308642rem; } }

.slider-caption p {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 24px; }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .slider-caption p {
      font-size: 20px; } }
  @media (max-width: 575.98px) {
    .slider-caption p {
      font-size: 16px; } }

.slider-caption-right {
  top: 0;
  left: 15px; }

.slider-caption-center {
  position: relative;
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
  left: 0;
  right: 0; }

.slider-caption-top-left {
  top: 50px;
  bottom: auto; }

.slider-caption-top-right {
  top: 50px;
  bottom: auto;
  left: auto;
  right: 50px; }

.slider-caption-bottom-right {
  left: auto;
  right: 50px; }

.slider-caption-bg {
  padding: 5px 12px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.6);
  top: auto;
  bottom: 50px;
  left: 50px; }
  .slider-caption-bg-light {
    background-color: rgba(255, 255, 255, 0.8);
    color: #333;
    font-weight: 400;
    text-shadow: none; }

/* Slider Arrows
-----------------------------------------------------------------*/
.slider-element .owl-carousel {
  margin: 0; }

.slider-arrow-left,
.slider-arrow-right,
.flex-prev,
.flex-next,
.slider-arrow-top-sm,
.slider-arrow-bottom-sm {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  left: 0;
  top: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  width: 52px;
  height: 52px;
  border: 0;
  border-radius: 0;
  transform: translateY(-50%);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  -webkit-transition: background-color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out; }

.slider-arrow-top-sm,
.slider-arrow-bottom-sm {
  top: auto;
  left: auto;
  bottom: 57px;
  right: 20px;
  margin: 0;
  width: 32px;
  height: 32px;
  border-radius: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.slider-arrow-bottom-sm {
  bottom: 20px;
  border-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; }

.slider-arrow-right,
.slider-element .owl-next,
.flex-next {
  border-radius: 0;
  left: auto;
  right: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; }

.slider-arrow-left i,
.slider-arrow-right i,
.flex-prev i,
.flex-next i,
.slider-arrow-top-sm i,
.slider-arrow-bottom-sm i {
  line-height: 50px;
  width: 52px;
  height: 52px;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  font-size: 32px;
  text-align: center;
  padding-right: 2px; }

.slider-arrow-right i,
.flex-next i {
  padding: 0 0 0 3px; }

.slider-arrow-top-sm i,
.slider-arrow-bottom-sm i {
  width: 32px;
  height: 32px;
  margin: 0;
  line-height: 30px;
  font-size: 18px;
  color: white; }

.slider-arrow-left:hover,
.slider-arrow-right:hover,
.flex-prev:hover,
.flex-next:hover,
.slider-arrow-top-sm:hover,
.slider-arrow-bottom-sm:hover {
  background-color: rgba(0, 0, 0, 0.6) !important; }

/* Slide Number
-----------------------------------------------------------------*/
.slide-number {
  position: absolute;
  opacity: 0;
  top: auto;
  right: 20px;
  bottom: 20px;
  z-index: 20;
  width: 36px;
  height: 40px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  font-size: 18px;
  font-weight: 300;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear; }

.slider-element:hover .slide-number {
  opacity: 1; }

.slide-number-current,
.slide-number-total {
  position: absolute;
  line-height: 1;
  top: 5px;
  left: 0; }

.slide-number-total {
  top: auto;
  bottom: 5px;
  left: auto;
  right: 0; }

.slide-number span {
  display: block;
  font-size: 32px;
  line-height: 40px; }

/* ----------------------------------------------------------------

	topbar.scss

-----------------------------------------------------------------*/
#top-bar {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  height: 45px;
  line-height: 44px;
  font-size: 13px;
  background-color: white; }
  @media (max-width: 767.98px) {
    #top-bar {
      height: auto; } }
  #top-bar.dark,
  .dark #top-bar {
    background-color: #383838;
    border-bottom-color: rgba(255, 255, 255, 0.1); }
  #top-bar .col_half {
    width: auto; }
    @media (max-width: 767.98px) {
      #top-bar .col_half {
        width: 100%;
        margin-bottom: 0 !important; } }

/* ----------------------------------------------------------------
	Top Links
-----------------------------------------------------------------*/
.top-links {
  position: relative;
  float: left; }
  .top-links ul {
    margin: 0;
    list-style: none; }
    .top-links ul li {
      float: left;
      position: relative;
      height: 44px;
      border-left: 1px solid #EEE; }
      .dark .top-links ul li {
        border-left-color: rgba(255, 255, 255, 0.1); }
    .top-links ul li:first-child,
    .top-links ul ul li {
      border-left: 0 !important; }
  .top-links li > a {
    display: block;
    padding: 0 12px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    height: 44px;
    color: #666666; }
    .dark .top-links li > a {
      color: #aaaaaa; }
  .top-links li i {
    vertical-align: top; }
  .top-links li i.icon-angle-down {
    margin: 0 0 0 5px !important; }
  .top-links li i:first-child {
    margin-right: 2px; }
  .top-links li.full-icon i {
    top: 2px;
    font-size: 14px;
    margin: 0; }
  .top-links li:hover {
    background-color: #EEE; }
    .dark .top-links li:hover {
      background-color: #333333;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }
    .top-links li:hover ul,
    .top-links li:hover div.top-link-section {
      pointer-events: auto; }
  .top-links ul ul,
  .top-links ul div.top-link-section {
    display: none;
    pointer-events: none;
    position: absolute;
    z-index: 210;
    line-height: 1.5;
    background: #FFF;
    border: 0;
    top: 44px;
    left: 0;
    width: 140px;
    margin: 0;
    border-top: 1px solid #1abc9c;
    border-bottom: 1px solid #EEE;
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2); }
    @media (max-width: 767.98px) {
      .top-links ul ul,
      .top-links ul div.top-link-section {
        text-align: left; } }
    .dark .top-links ul ul, .dark
    .top-links ul div.top-link-section {
      background: #333333;
      border-bottom: none; }
  .top-links ul ul li {
    float: none;
    height: 36px;
    border-top: 1px solid #F5F5F5;
    border-left: 1px solid #EEE; }
    .top-links ul ul li:hover {
      background-color: #F9F9F9; }
    .top-links ul ul li:first-child {
      border-top: none !important;
      border-left: 1px solid #EEE; }
    .dark .top-links ul ul li {
      border-top: 1px solid #444444;
      border-left: 1px solid #444444; }
      .dark .top-links ul ul li:hover {
        background-color: #383838; }
      .dark .top-links ul ul li:first-child {
        border-left: 1px solid #444444; }
  .top-links ul ul a {
    height: 36px;
    line-height: 36px;
    font-size: 12px; }
  .top-links ul ul img {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 16px;
    height: 16px;
    margin-right: 4px; }
  .top-links ul ul.top-demo-lang img {
    top: 4px;
    width: 16px;
    height: 16px; }
  .top-links ul div.top-link-section {
    padding: 25px;
    left: 0;
    width: 280px; }
    @media (max-width: 767.98px) {
      .top-links ul div.top-link-section {
        left: auto;
        right: 0;
        padding: 20px 15px; } }
  @media (max-width: 767.98px) {
    .top-links {
      float: none; }
      .top-links > ul {
        text-align: center; }
        .top-links > ul > li {
          display: inline-block;
          margin-right: -4px;
          float: none; } }

.fright .top-links ul div.top-link-section,
.top-links.fright ul div.top-link-section {
  left: auto;
  right: 0; }

/* ----------------------------------------------------------------
	Top Social
-----------------------------------------------------------------*/
#top-social,
#top-social ul {
  margin: 0; }

#top-social li,
#top-social li a,
#top-social li .ts-icon,
#top-social li .ts-text {
  display: block;
  position: relative;
  float: left;
  width: auto;
  overflow: hidden;
  height: 44px;
  line-height: 44px; }

#top-social li {
  border-left: 1px solid #EEE; }
  .dark #top-social li {
    border-left: 1px solid rgba(255, 255, 255, 0.1); }
  #top-social li:first-child {
    border-left: 0 !important; }
  #top-social li a {
    float: none;
    width: 40px;
    font-weight: bold;
    color: #666;
    -webkit-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, width 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, width 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, width 0.3s ease-in-out; }
    .dark #top-social li a {
      color: #bbbbbb; }
    #top-social li a:hover {
      color: #FFF !important;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }
  #top-social li .ts-icon {
    width: 40px;
    text-align: center;
    font-size: 14px; }

@media (max-width: 767.98px) {
  #top-social {
    display: none !important; } }

/* ----------------------------------------------------------------
	Top Login
-----------------------------------------------------------------*/
#top-login {
  margin-bottom: 0; }
  #top-login .checkbox {
    margin-bottom: 10px; }
  #top-login .form-control {
    position: relative; }
  #top-login .form-control:focus {
    border-color: #CCC; }
  #top-login .input-group#top-login-username {
    margin-bottom: -1px; }
  #top-login #top-login-username input,
  #top-login #top-login-username .input-group-addon {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  #top-login .input-group#top-login-password {
    margin-bottom: 10px; }
  #top-login #top-login-password input,
  #top-login #top-login-password .input-group-addon {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

/* ----------------------------------------------------------------

	widgets.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Widgets
-----------------------------------------------------------------*/
.widget {
  position: relative;
  margin-top: 50px; }
  .widget:first-child {
    margin-top: 0; }
  .widget > h4, .widget > .h4 {
    margin-bottom: 25px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase; }
    .dark .widget > h4, .dark .widget > .h4 {
      color: #dddddd; }
  .widget p {
    line-height: 1.7; }
    .widget p:not(.lead) {
      font-size: 14px; }
    .dark .widget p {
      opacity: .8; }

@media (min-width: 768px) and (max-width: 991.98px) {
  .sidebar-widgets-wrap {
    position: relative;
    margin: 0 -20px -40px -20px; } }

.sidebar-widgets-wrap .widget {
  padding-top: 50px;
  border-top: 1px solid #eee; }
  .dark .sidebar-widgets-wrap .widget {
    border-top-color: rgba(255, 255, 255, 0.1); }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .sidebar-widgets-wrap .widget {
      display: inline-block;
      vertical-align: top;
      float: none;
      width: 49.6%;
      margin: 0 0 40px 0;
      padding-top: 0;
      padding-left: 20px;
      padding-right: 20px;
      border-top: 0; }
      .dark .sidebar-widgets-wrap .widget {
        border-top-color: rgba(255, 255, 255, 0.1); } }
  .sidebar-widgets-wrap .widget:first-child {
    padding-top: 0;
    border-top: 0; }
  .sidebar-widgets-wrap .widget > h4, .sidebar-widgets-wrap .widget > .h4 {
    letter-spacing: 1px; }

/* Tag Cloud
-----------------------------------------------------------------*/
.tagcloud {
  margin-bottom: -2px; }
  .tagcloud a {
    display: block;
    float: left;
    margin-right: 4px;
    margin-bottom: 4px;
    padding: 3px 6px;
    border: 1px solid #e5e5e5;
    font-size: 13px !important;
    color: #666666;
    border-radius: 2px; }
    .dark .tagcloud a {
      color: #999999;
      border-color: transparent;
      background-color: rgba(0, 0, 0, 0.2); }
    .tagcloud a:hover {
      border-color: #1abc9c !important;
      color: #1abc9c !important; }
      .dark .tagcloud a:hover {
        border-color: transparent !important;
        background-color: #1abc9c;
        color: #FFF !important; }

body:not(.device-touch) .tagcloud a {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

/* Widget - Links
-----------------------------------------------------------------*/
.widget_nav_menu ul,
.widget_nav_menu li,
.widget_links ul,
.widget_links li,
.widget_meta ul,
.widget_meta li,
.widget_archive ul,
.widget_archive li,
.widget_recent_comments ul,
.widget_recent_comments li,
.widget_recent_entries ul,
.widget_recent_entries li,
.widget_categories ul,
.widget_categories li,
.widget_pages ul,
.widget_pages li,
.widget_rss ul,
.widget_rss li {
  list-style: none;
  margin: 0; }

.widget_nav_menu li,
.widget_links li,
.widget_meta li,
.widget_archive li,
.widget_recent_comments li,
.widget_recent_entries li,
.widget_categories li,
.widget_pages li,
.widget_rss li {
  padding: 4px; }

.widget_nav_menu li a,
.widget_links li a,
.widget_meta li a,
.widget_archive li a,
.widget_recent_entries li a,
.widget_categories li a,
.widget_pages li a,
.widget_rss li a,
.widget_recent_comments li a {
  display: inline-block;
  padding: 0 3px 0 12px;
  border: none !important;
  color: #444444;
  font-size: 14px;
  background-image: url("image/icons/widget-link.png");
  background-repeat: no-repeat;
  background-position: left top; }
  .dark .widget_nav_menu li a, .dark
  .widget_links li a, .dark
  .widget_meta li a, .dark
  .widget_archive li a, .dark
  .widget_recent_entries li a, .dark
  .widget_categories li a, .dark
  .widget_pages li a, .dark
  .widget_rss li a, .dark
  .widget_recent_comments li a {
    background-image: url("image/icons/widget-link-dark.png"); }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .widget_nav_menu li a,
    .widget_links li a,
    .widget_meta li a,
    .widget_archive li a,
    .widget_recent_entries li a,
    .widget_categories li a,
    .widget_pages li a,
    .widget_rss li a,
    .widget_recent_comments li a {
      background-image: url("image/icons/widget-link@2x.png");
      background-size: 4px 20px; }
      .dark .widget_nav_menu li a, .dark
      .widget_links li a, .dark
      .widget_meta li a, .dark
      .widget_archive li a, .dark
      .widget_recent_entries li a, .dark
      .widget_categories li a, .dark
      .widget_pages li a, .dark
      .widget_rss li a, .dark
      .widget_recent_comments li a {
        background-image: url("image/icons/widget-link-dark@2x.png"); } }
  .widget_nav_menu li a:hover,
  .widget_links li a:hover,
  .widget_meta li a:hover,
  .widget_archive li a:hover,
  .widget_recent_entries li a:hover,
  .widget_categories li a:hover,
  .widget_pages li a:hover,
  .widget_rss li a:hover,
  .widget_recent_comments li a:hover {
    color: #000; }
  .dark .widget_nav_menu li a, .dark
  .widget_links li a, .dark
  .widget_meta li a, .dark
  .widget_archive li a, .dark
  .widget_recent_entries li a, .dark
  .widget_categories li a, .dark
  .widget_pages li a, .dark
  .widget_rss li a, .dark
  .widget_recent_comments li a {
    color: #cccccc; }
    .dark .widget_nav_menu li a:hover, .dark
    .widget_links li a:hover, .dark
    .widget_meta li a:hover, .dark
    .widget_archive li a:hover, .dark
    .widget_recent_entries li a:hover, .dark
    .widget_categories li a:hover, .dark
    .widget_pages li a:hover, .dark
    .widget_rss li a:hover, .dark
    .widget_recent_comments li a:hover {
      color: #aaaaaa; }

.widget_recent_comments li {
  display: block;
  background-image: url("image/icons/widget-comment.png");
  background-repeat: no-repeat;
  background-position: left top;
  padding: 4px 0 4px 20px; }
  .dark .widget_recent_comments li {
    background-image: url("image/icons/widget-comment-dark.png"); }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .widget_recent_comments li {
      background-image: url("image/icons/widget-comment@2x.png");
      background-size: 15px 26px; }
      .dark .widget_recent_comments li {
        background-image: url("image/icons/widget-comment-dark@2x.png"); } }
  .widget_recent_comments li a {
    display: inline;
    padding: 0;
    background: none !important; }

.widget_nav_menu > ul > li:first-child,
.widget_links > ul > li:first-child,
.widget_meta > ul > li:first-child,
.widget_archive > ul > li:first-child,
.widget_recent_comments > ul > li:first-child,
.widget_recent_entries > ul > li:first-child,
.widget_categories > ul > li:first-child,
.widget_pages > ul > li:first-child,
.widget_rss > ul > li:first-child {
  border-top: 0 !important; }

.widget_nav_menu > ul,
.widget_links > ul,
.widget_meta > ul,
.widget_archive > ul,
.widget_recent_comments > ul,
.widget_recent_entries > ul,
.widget_categories > ul,
.widget_pages > ul,
.widget_rss > ul {
  margin-top: -4px !important; }

.widget_nav_menu ul ul,
.widget_links ul ul,
.widget_meta ul ul,
.widget_archive ul ul,
.widget_recent_comments ul ul,
.widget_recent_entries ul ul,
.widget_categories ul ul,
.widget_pages ul ul,
.widget_rss ul ul {
  margin-left: 15px; }

/* Widget - Testimonial & Twitter
-----------------------------------------------------------------*/
.widget .testimonial.no-image .testi-image {
  display: none; }

.widget .testimonial.twitter-scroll .testi-image,
.widget .testimonial.twitter-scroll .testi-image a,
.widget .testimonial.twitter-scroll .testi-image img,
.widget .testimonial.twitter-scroll .testi-image i {
  width: 28px;
  height: 28px; }

.widget .testimonial.twitter-scroll .testi-image {
  margin-right: 10px; }
  .widget .testimonial.twitter-scroll .testi-image i {
    background-color: #eeeeee;
    line-height: 28px;
    font-size: 14px;
    color: #888888; }

.widget .testimonial p:not(.lead) {
  font-size: 14px; }

.widget .testimonial:not(.twitter-scroll) .testi-image,
.widget .testimonial:not(.twitter-scroll) .testi-image a,
.widget .testimonial:not(.twitter-scroll) .testi-image img {
  width: 42px;
  height: 42px; }

/* Widget - Quick Contact Form
-----------------------------------------------------------------*/
.quick-contact-widget.form-widget .form-control,
.quick-contact-widget.form-widget .input-group,
.quick-contact-widget.form-widget .sm-form-control {
  margin-bottom: 10px; }

.quick-contact-widget.form-widget .input-group .form-control {
  margin-bottom: 0; }

.quick-contact-widget.form-widget form,
#template-contactform {
  position: relative; }

/* Newsletter Widget
-----------------------------------------------------------------*/
.subscribe-widget h5, .subscribe-widget .h5 {
  font-weight: 300;
  font-size: 14px;
  line-height: 1.5; }

/* blockquote
-----------------------------------------------------------------*/
blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17px;
  border-left: 5px solid #eeeeee; }
  blockquote .float-left {
    max-width: 300px;
    margin: 5px 20px 10px 0;
    padding-right: 0; }

.blockquote-reverse {
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #eeeeee;
  border-left: 0;
  text-align: right; }

/* Twitter Feed Widget
-----------------------------------------------------------------*/
.widget-twitter-feed li {
  margin: 15px 0 0 0; }
  .widget-twitter-feed li:first-child {
    margin-top: 0; }

.widget-twitter-feed small, .widget-twitter-feed .small {
  display: block;
  margin-top: 3px; }
  .widget-twitter-feed small a, .widget-twitter-feed .small a {
    color: #999; }

.widget-twitter-feed .twitter-feed:not(.twitter-feed-avatar) a.twitter-avatar {
  display: none; }

.widget-twitter-feed .twitter-feed.twitter-feed-avatar i.icon-twitter {
  display: none; }

.widget-twitter-feed .twitter-feed-avatar {
  margin-left: 44px; }

.widget-twitter-feed .twitter-feed-avatar > li a.twitter-avatar {
  display: block;
  position: absolute;
  left: -44px;
  text-align: center;
  top: 2px;
  width: 32px;
  height: 32px; }
  .widget-twitter-feed .twitter-feed-avatar > li a.twitter-avatar a.twitter-avatar img {
    border-radius: 50%; }

/* Widget Filter Links
-----------------------------------------------------------------*/
.widget-filter-links ul {
  list-style: none;
  margin-bottom: 0; }

.widget-filter-links .widget-filter-reset {
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  font-size: 12px;
  line-height: 22px; }

.widget:not(:first-child) .widget-filter-reset {
  top: 50px; }

.widget-filter-links .widget-filter-reset a {
  color: red; }

.widget-filter-links .widget-filter-reset.active-filter {
  display: none; }

.widget-filter-links li {
  position: relative;
  font-size: 15px;
  line-height: 24px; }

.widget-filter-links li:not(:first-child) {
  margin-top: 10px; }

.widget-filter-links li a {
  display: block;
  color: #444444;
  font-family: "Raleway", sans-serif;
  font-weight: 500; }

.widget-filter-links li span {
  position: absolute;
  top: 1px;
  left: auto;
  right: 0;
  font-size: 11px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  color: #777777;
  background-color: #eeeeee;
  border-radius: 50%; }

.widget-filter-links li:hover a {
  color: #777777; }

.widget-filter-links li.active-filter a {
  font-weight: 700; }

.widget-filter-links li.active-filter span {
  color: #FFF;
  background-color: #1abc9c;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); }

/* Navigation Tree
-----------------------------------------------------------------*/
.nav-tree {
  position: relative;
  margin-bottom: 40px; }
  .nav-tree ul {
    margin-bottom: 0;
    list-style: none; }
  .nav-tree > ul {
    margin: -8px 0; }
  .nav-tree li {
    position: relative; }
    .nav-tree li a {
      display: block;
      padding: 8px 0;
      color: #333333;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 700;
      letter-spacing: 2px;
      font-family: "Raleway", sans-serif; }
      .dark .nav-tree li a {
        color: #bbbbbb; }
    .nav-tree li i {
      font-size: 14px;
      width: 16px;
      text-align: center; }
      .nav-tree li i:not(.icon-angle-down) {
        margin-right: 8px;
        position: relative;
        top: 1px; }
      .nav-tree li i.icon-angle-down {
        width: auto;
        font-size: 12px;
        margin-left: 2px; }
    .nav-tree li:hover > a, .nav-tree li.current > a, .nav-tree li.active > a {
      color: #1abc9c !important; }
  .nav-tree ul ul {
    display: none; }
    .nav-tree ul ul a {
      font-size: 12px;
      padding: 6px 0;
      letter-spacing: 1px;
      font-family: "Lato", sans-serif; }
      .nav-tree ul ul a i.icon-angle-down {
        font-size: 12px; }
    .nav-tree ul ul a {
      padding-left: 20px; }
    .nav-tree ul ul ul a {
      padding-left: 40px; }
    .nav-tree ul ul ul ul a {
      padding-left: 60px; }
    .nav-tree ul ul ul ul ul a {
      padding-left: 80px; }
  .nav-tree li.current > ul {
    display: block; }

/*-----------------------------------------------------------------------------------

	Shortcodes: alerts.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Alerts
-----------------------------------------------------------------*/
.style-msg,
.style-msg2 {
  position: relative;
  display: block;
  color: #444444;
  border-left: 4px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; }

.style-msg2 {
  border-left: 4px solid rgba(0, 0, 0, 0.2); }
  .dark .style-msg2 {
    border-left: 4px solid rgba(0, 0, 0, 0.3); }

.successmsg {
  background: #c5ebd4;
  color: #184b21; }

.errormsg {
  background: #ffcccc;
  color: #740c0a; }

.infomsg {
  background: #b9dff4;
  color: #124f70; }

.alertmsg {
  background: #fce0b6;
  color: #975d06; }

.style-msg .sb-msg,
.style-msg2 .msgtitle,
.style-msg2 .sb-msg {
  display: block;
  padding: 15px;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 14px;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.6); }
  .dark .style-msg .sb-msg, .dark
  .style-msg2 .msgtitle, .dark
  .style-msg2 .sb-msg {
    border-left-color: rgba(255, 255, 255, 0.1); }

.style-msg2 .msgtitle {
  background: rgba(0, 0, 0, 0.15);
  border-top: none;
  border-bottom: none;
  font-size: 15px;
  font-weight: bold; }

.style-msg-light .sb-msg,
.style-msg-light .msgtitle {
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3); }

.style-msg2 .sb-msg {
  font-weight: normal; }

.style-msg2 ol,
.style-msg2 ul {
  margin: 0 0 0 30px; }

.style-msg2 ol {
  list-style-type: decimal; }

.style-msg2 ul {
  list-style-type: disc; }

.alert i,
.sb-msg i,
.msgtitle i {
  position: relative;
  top: 1px;
  font-size: 14px;
  width: 14px;
  text-align: center;
  margin-right: 5px; }

.style-msg .close {
  position: absolute;
  top: 10px;
  left: auto;
  right: 10px; }

.alert a:hover {
  text-decoration: underline !important; }

.alert .close {
  font-size: 1.4rem; }

/*-----------------------------------------------------------------------------------

	Shortcodes: buttons.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Buttons
-----------------------------------------------------------------*/
.button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  margin: 5px;
  padding: 0 22px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  background-color: #1abc9c;
  color: #FFF;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  /* Buttons - Border
	-----------------------------------------------------------------*/
  /* Buttons - 3D
	-----------------------------------------------------------------*/
  /* Buttons - Icon Reveal
	-----------------------------------------------------------------*/ }
  body:not(.device-touch) .button {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
  .button-dark {
    background-color: #444444; }
    .dark .button-dark:not(.button-border) {
      background-color: rgba(0, 0, 0, 0.3); }
    .button-dark:hover {
      background-color: #1abc9c; }
      .dark .button-dark:hover {
        background-color: #1abc9c; }
  .button-light {
    background-color: #eeeeee;
    color: #444444;
    text-shadow: none !important; }
    .dark .button-light:not(.button-border):hover {
      background-color: #383838; }
  .button:hover {
    background-color: #444444;
    color: white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }
    .dark .button:hover {
      background-color: rgba(0, 0, 0, 0.3); }
  .button-mini {
    padding: 0 14px;
    font-size: 11px;
    height: 28px;
    line-height: 28px; }
  .button-small {
    padding: 0 17px;
    font-size: 12px;
    height: 34px;
    line-height: 34px; }
  .button-large {
    padding: 0 26px;
    height: 46px;
    font-size: 16px;
    line-height: 46px; }
  .button-xlarge {
    padding: 0 32px;
    font-size: 18px;
    letter-spacing: 2px;
    height: 52px;
    line-height: 52px; }
  .button-desc {
    text-align: left;
    padding: 24px 34px;
    font-size: 22px;
    height: auto;
    line-height: 1;
    font-family: "Raleway", sans-serif; }
    @media (max-width: 767.98px) {
      .button-desc {
        max-width: 100%;
        padding: 14px 34px;
        font-size: 15px;
        line-height: 15px;
        border-radius: 3px; } }
    @media (max-width: 575.98px) {
      .button-desc {
        padding: 20px 17px;
        font-size: 15px;
        max-width: 100%; } }
    .button-desc span {
      display: block;
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1px;
      font-family: "Crete Round", serif;
      font-style: italic;
      text-transform: none; }
      @media (max-width: 767.98px) {
        .button-desc span {
          font-size: 11px; } }
  .button i {
    position: relative;
    top: 1px;
    line-height: 1;
    margin-right: 5px;
    width: 16px;
    text-align: center; }
  .button.tright i {
    margin: 0 0 0 5px; }
  .button-mini i {
    margin-right: 3px; }
  .button-mini.tright i {
    margin: 0 0 0 3px; }
  .button-small i {
    margin-right: 4px; }
  .button-small.tright i {
    margin: 0 0 0 4px; }
  .button-xlarge i {
    top: 2px;
    margin-right: 8px; }
  .button-xlarge.tright i {
    margin: 0 0 0 7px; }
  .button-desc i {
    top: 1px;
    font-size: 48px;
    width: 48px;
    text-align: center;
    margin-right: 12px; }
  .button-desc.tright i {
    margin: 0 0 0 12px; }
  .button-desc div {
    display: inline-block; }
  .button-rounded {
    border-radius: 3px; }
  .button.button-border {
    border: 2px solid #444444;
    background-color: transparent;
    color: #444444;
    line-height: 36px;
    font-weight: 700;
    text-shadow: none; }
    .dark .button.button-border:not(.button-light) {
      border-color: rgba(255, 255, 255, 0.4);
      color: rgba(255, 255, 255, 0.5); }
      .dark .button.button-border:not(.button-light):hover {
        color: #eeeeee; }
    .not-dark .button.button-border:not(.button-light) {
      border-color: #444444;
      color: #444444; }
      .not-dark .button.button-border:not(.button-light) :hover {
        background-color: #444444;
        color: white; }
    .button.button-border.button-mini {
      line-height: 24px; }
    .button.button-border.button-small {
      line-height: 30px; }
    .button.button-border.button-large {
      line-height: 42px; }
    .button.button-border.button-xlarge {
      line-height: 48px; }
    .button.button-border.button-desc {
      line-height: 1; }
    .button.button-border.button-border-thin {
      border-width: 1px;
      line-height: 38px; }
      .button.button-border.button-border-thin.button-mini {
        line-height: 26px; }
      .button.button-border.button-border-thin.button-small {
        line-height: 32px; }
      .button.button-border.button-border-thin.button-large {
        line-height: 44px; }
      .button.button-border.button-border-thin.button-xlarge {
        line-height: 50px; }
    .button.button-border:not(.button-fill):hover {
      background-color: #444444;
      color: white;
      border-color: transparent !important;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }
    .button.button-border.button-light {
      border-color: #eeeeee;
      color: #eeeeee; }
      .button.button-border.button-light:hover {
        background-color: #eeeeee;
        color: #444444;
        text-shadow: none; }
  .button.button-3d {
    border-radius: 3px;
    border-bottom: 3px solid rgba(0, 0, 0, 0.15);
    -webkit-transition: none;
    -o-transition: none;
    transition: none; }
    .button.button-3d:hover {
      background-color: #1abc9c !important;
      opacity: 0.9; }
  .button.button-3d.button-light:hover, .button.button-reveal.button-light:hover {
    text-shadow: none;
    color: #444444; }
  .button.button-reveal {
    padding: 0 28px;
    overflow: hidden; }
    .button.button-reveal i {
      display: block;
      position: absolute;
      top: 0;
      left: -32px;
      width: 32px;
      height: 40px;
      line-height: 40px;
      margin: 0;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.1); }
      body:not(.device-touch) .button.button-reveal i {
        -webkit-transition: left 0.3s ease, right 0.3s ease;
        -o-transition: left 0.3s ease, right 0.3s ease;
        transition: left 0.3s ease, right 0.3s ease; }
    .button.button-reveal.button-border i {
      top: -2px; }
    .button.button-reveal.tright:hover span {
      left: -16px; }
    .button.button-reveal.tright i {
      left: auto;
      right: -32px; }
    .button.button-reveal.button-3d i, .button.button-reveal.button-rounded i {
      border-radius: 3px 0 0 3px; }
    .button.button-reveal.button-3d.tright i,
    .button.button-reveal.button-3d.tright i, .button.button-reveal.button-rounded.tright i,
    .button.button-reveal.button-rounded.tright i {
      border-radius: 0 3px 3px 0; }
    .button.button-reveal span {
      display: inline-block;
      position: relative;
      left: 0; }
      body:not(.device-touch) .button.button-reveal span {
        -webkit-transition: left 0.3s ease, right 0.3s ease;
        -o-transition: left 0.3s ease, right 0.3s ease;
        transition: left 0.3s ease, right 0.3s ease; }
    .button.button-reveal.button-mini {
      padding: 0 17px; }
      .button.button-reveal.button-mini i {
        left: -22px;
        width: 22px;
        height: 28px;
        line-height: 28px; }
      .button.button-reveal.button-mini.tright:hover span {
        left: -11px; }
      .button.button-reveal.button-mini.tright i {
        left: auto;
        right: -22px; }
      .button.button-reveal.button-mini:hover span {
        left: 11px; }
    .button.button-reveal.button-small {
      padding: 0 22px; }
      .button.button-reveal.button-small i {
        left: -26px;
        width: 26px;
        height: 34px;
        line-height: 34px; }
      .button.button-reveal.button-small.tright:hover span {
        left: -13px; }
      .button.button-reveal.button-small.tright i {
        left: auto;
        right: -26px; }
      .button.button-reveal.button-small:hover span {
        left: 13px; }
    .button.button-reveal.button-large {
      padding: 0 32px; }
      .button.button-reveal.button-large i {
        left: -38px;
        width: 38px;
        height: 46px;
        line-height: 46px; }
      .button.button-reveal.button-large.tright:hover span {
        left: -19px; }
      .button.button-reveal.button-large.tright i {
        left: auto;
        right: -38px; }
      .button.button-reveal.button-large:hover span {
        left: 19px; }
    .button.button-reveal.button-xlarge {
      padding: 0 40px; }
      .button.button-reveal.button-xlarge i {
        left: -44px;
        width: 44px;
        height: 52px;
        line-height: 52px; }
      .button.button-reveal.button-xlarge.tright:hover span {
        left: -22px; }
      .button.button-reveal.button-xlarge.tright i {
        left: auto;
        right: -44px; }
      .button.button-reveal.button-xlarge:hover span {
        left: 22px; }
    .button.button-reveal:hover i {
      left: 0; }
    .button.button-reveal:hover span {
      left: 16px; }
    .button.button-reveal.tright:hover i {
      left: auto;
      right: 0; }

/* Buttons - Promo 100% Width
-----------------------------------------------------------------*/
.button.button-full {
  display: block;
  width: 100%;
  white-space: normal;
  margin: 0;
  height: auto;
  line-height: 1.6;
  padding: 30px 0;
  font-size: 30px;
  font-weight: 300;
  text-transform: none;
  border-radius: 0;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); }
  @media (max-width: 991.98px) {
    .button.button-full {
      font-size: 22px; }
      .button.button-full i {
        top: 3px !important; } }
  @media (max-width: 767.98px) {
    .button.button-full {
      font-size: 18px; }
      .button.button-full i {
        top: 2px !important;
        margin-left: 3px !important; } }
  @media (max-width: 575.98px) {
    .button.button-full {
      font-size: 16px; } }
  .button.button-full.button-light {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
    .button.button-full.button-light strong {
      border-bottom-color: #444444; }
    .button.button-full.button-light:hover strong {
      border-bottom-color: #eeeeee; }
  .button.button-full strong {
    font-weight: 700;
    border-bottom: 2px solid #eeeeee; }
    body:not(.device-touch) .button.button-full strong {
      -webkit-transition: border 0.2s ease-in-out;
      -o-transition: border 0.2s ease-in-out;
      transition: border 0.2s ease-in-out; }

/* Buttons - Circle
-----------------------------------------------------------------*/
.button.button-circle {
  border-radius: 20px; }
  .button.button-circle.button-mini {
    border-radius: 14px; }
  .button.button-circle.button-small {
    border-radius: 17px; }
  .button.button-circle.button-large {
    border-radius: 23px; }
  .button.button-circle.button-xlarge {
    border-radius: 26px; }

/* Buttons - Fill Effect
-----------------------------------------------------------------*/
.button.button-border.button-fill {
  overflow: hidden;
  transform-style: preserve-3d;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
  -webkit-backface-visibility: hidden; }
  .button.button-border.button-fill span {
    position: relative; }
  .button.button-border.button-fill:hover {
    background-color: transparent !important;
    color: #FFF;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }
    .button.button-border.button-fill:hover:before {
      width: 100%; }
  .button.button-border.button-fill.button-light:hover {
    border-color: #eeeeee !important;
    color: #444444 !important;
    text-shadow: none; }
  .button.button-border.button-fill.button-light:before {
    background-color: #eeeeee; }
  .button.button-border.button-fill:before {
    content: '';
    position: absolute;
    background-color: #444444;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    -webkit-backface-visibility: hidden; }
  .button.button-border.button-fill.fill-from-right:before {
    left: auto;
    right: 0; }
  .button.button-border.button-fill.fill-from-top:before, .button.button-border.button-fill.fill-from-bottom:before {
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease; }
  .button.button-border.button-fill.fill-from-top:before {
    width: 100%;
    height: 0; }
  .button.button-border.button-fill.fill-from-top:hover:before {
    height: 100%; }
  .button.button-border.button-fill.fill-from-bottom:before {
    width: 100%;
    height: 0;
    top: auto;
    bottom: 0; }
  .button.button-border.button-fill.fill-from-bottom:hover:before {
    height: 100%; }

/* Buttons - Colors
-----------------------------------------------------------------*/
.button-blue {
  background-color: #007bff; }
  .button-blue.button-3d:hover, .button-blue.button-reveal:hover, .button-blue.button-border:hover, .button-blue.button-border.button-fill:before {
    background-color: #007bff !important; }

.button-border.button-blue, .button-border.button-light.button-blue {
  color: #007bff;
  border-color: #007bff; }

.button-indigo {
  background-color: #6610f2; }
  .button-indigo.button-3d:hover, .button-indigo.button-reveal:hover, .button-indigo.button-border:hover, .button-indigo.button-border.button-fill:before {
    background-color: #6610f2 !important; }

.button-border.button-indigo, .button-border.button-light.button-indigo {
  color: #6610f2;
  border-color: #6610f2; }

.button-purple {
  background-color: #6f42c1; }
  .button-purple.button-3d:hover, .button-purple.button-reveal:hover, .button-purple.button-border:hover, .button-purple.button-border.button-fill:before {
    background-color: #6f42c1 !important; }

.button-border.button-purple, .button-border.button-light.button-purple {
  color: #6f42c1;
  border-color: #6f42c1; }

.button-pink {
  background-color: #e83e8c; }
  .button-pink.button-3d:hover, .button-pink.button-reveal:hover, .button-pink.button-border:hover, .button-pink.button-border.button-fill:before {
    background-color: #e83e8c !important; }

.button-border.button-pink, .button-border.button-light.button-pink {
  color: #e83e8c;
  border-color: #e83e8c; }

.button-red {
  background-color: #dc3545; }
  .button-red.button-3d:hover, .button-red.button-reveal:hover, .button-red.button-border:hover, .button-red.button-border.button-fill:before {
    background-color: #dc3545 !important; }

.button-border.button-red, .button-border.button-light.button-red {
  color: #dc3545;
  border-color: #dc3545; }

.button-orange {
  background-color: #fd7e14; }
  .button-orange.button-3d:hover, .button-orange.button-reveal:hover, .button-orange.button-border:hover, .button-orange.button-border.button-fill:before {
    background-color: #fd7e14 !important; }

.button-border.button-orange, .button-border.button-light.button-orange {
  color: #fd7e14;
  border-color: #fd7e14; }

.button-yellow {
  background-color: #ffc107; }
  .button-yellow.button-3d:hover, .button-yellow.button-reveal:hover, .button-yellow.button-border:hover, .button-yellow.button-border.button-fill:before {
    background-color: #ffc107 !important; }

.button-border.button-yellow, .button-border.button-light.button-yellow {
  color: #ffc107;
  border-color: #ffc107; }

.button-green {
  background-color: #28a745; }
  .button-green.button-3d:hover, .button-green.button-reveal:hover, .button-green.button-border:hover, .button-green.button-border.button-fill:before {
    background-color: #28a745 !important; }

.button-border.button-green, .button-border.button-light.button-green {
  color: #28a745;
  border-color: #28a745; }

.button-teal {
  background-color: #20c997; }
  .button-teal.button-3d:hover, .button-teal.button-reveal:hover, .button-teal.button-border:hover, .button-teal.button-border.button-fill:before {
    background-color: #20c997 !important; }

.button-border.button-teal, .button-border.button-light.button-teal {
  color: #20c997;
  border-color: #20c997; }

.button-cyan {
  background-color: #17a2b8; }
  .button-cyan.button-3d:hover, .button-cyan.button-reveal:hover, .button-cyan.button-border:hover, .button-cyan.button-border.button-fill:before {
    background-color: #17a2b8 !important; }

.button-border.button-cyan, .button-border.button-light.button-cyan {
  color: #17a2b8;
  border-color: #17a2b8; }

.button-white {
  background-color: #fff; }
  .button-white.button-3d:hover, .button-white.button-reveal:hover, .button-white.button-border:hover, .button-white.button-border.button-fill:before {
    background-color: #fff !important; }

.button-border.button-white, .button-border.button-light.button-white {
  color: #fff;
  border-color: #fff; }

.button-gray {
  background-color: #6c757d; }
  .button-gray.button-3d:hover, .button-gray.button-reveal:hover, .button-gray.button-border:hover, .button-gray.button-border.button-fill:before {
    background-color: #6c757d !important; }

.button-border.button-gray, .button-border.button-light.button-gray {
  color: #6c757d;
  border-color: #6c757d; }

.button-gray-dark {
  background-color: #343a40; }
  .button-gray-dark.button-3d:hover, .button-gray-dark.button-reveal:hover, .button-gray-dark.button-border:hover, .button-gray-dark.button-border.button-fill:before {
    background-color: #343a40 !important; }

.button-border.button-gray-dark, .button-border.button-light.button-gray-dark {
  color: #343a40;
  border-color: #343a40; }

/* Buttons - No Hover
-----------------------------------------------------------------*/
.button.button-nohover:hover {
  opacity: inherit !important;
  background-color: inherit !important;
  color: inherit !important;
  border-color: inherit !important; }

/*-----------------------------------------------------------------------------------

	Shortcodes: carousels.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Owl Carousel CSS
-----------------------------------------------------------------*/
.owl-carousel {
  display: none;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
  width: 100%; }
  .owl-carousel .animated {
    -webkit-animation-duration: 1000ms;
    animation-duration: 1000ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }
  .owl-carousel .owl-animated-in {
    z-index: 0; }
  .owl-carousel .owl-animated-out {
    z-index: 1; }
  .owl-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut; }
  .owl-carousel .owl-stage {
    position: relative;
    padding: 20px 0;
    -ms-touch-action: pan-Y; }
    .owl-carousel .owl-stage:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0); }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    display: block;
    opacity: 0;
    min-height: 100px;
    background-image: url("image/preloader.gif");
    background-repeat: no-repeat;
    background-position: center center; }
    .dark .owl-carousel.owl-loading {
      background-image: url("image/preloader-dark.gif"); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .owl-carousel.owl-loading {
        background-image: url("image/preloader@2x.gif");
        background-size: 24px 24px; }
        .dark .owl-carousel.owl-loading {
          background-image: url("image/preloader-dark@2x.gif"); } }
  .owl-carousel .owl-refresh .owl-item {
    display: none; }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
    .owl-carousel .owl-item img {
      display: block;
      width: 100%;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d; }
      #slider .owl-carousel .owl-item img {
        -webkit-transform-style: preserve-3d; }
    .owl-carousel .owl-item .owl-lazy {
      opacity: 0;
      -webkit-transition: opacity 400ms ease;
      -o-transition: opacity 400ms ease;
      transition: opacity 400ms ease; }
  .owl-carousel .owl-nav .disabled {
    display: inline-block; }
  .owl-carousel .owl-dots .disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot,
  .owl-carousel .owl-dots button {
    cursor: pointer;
    cursor: hand;
    padding: 0;
    border: 0;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
    .mega-menu-content .owl-carousel.owl-hidden {
      opacity: 1; }
  .owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab; }
  .owl-carousel .owl-rtl {
    direction: rtl; }
    .owl-carousel .owl-rtl .owl-item {
      float: right; }
  .no-js .owl-carousel {
    display: block; }
  .owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #111; }
  .owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 64px;
    width: 64px;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    background: url("image/icons/play.png") no-repeat;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -webkit-transition: scale 100ms ease;
    -o-transition: scale 100ms ease;
    transition: scale 100ms ease; }
    .owl-carousel .owl-video-play-icon:hover {
      -webkit-transition: scale(1.3);
      -o-transition: scale(1.3);
      transition: scale(1.3); }
  .owl-carousel .owl-video-playing .owl-video-play-icon,
  .owl-carousel .owl-video-playing .owl-video-tn {
    display: none; }
  .owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    -webkit-transition: opacity 400ms ease;
    -o-transition: opacity 400ms ease;
    transition: opacity 400ms ease; }
  .owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out; }

/* Owl Carousel - Controls
-----------------------------------------------------------------*/
.owl-carousel .owl-dots,
.owl-carousel .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  line-height: 1; }

.owl-carousel .owl-nav [class*=owl-] {
  position: absolute;
  top: 50%;
  zoom: 1;
  width: 36px;
  height: 36px;
  line-height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: #666;
  background-color: #fff;
  font-size: 18px;
  transform: translateY(-50%);
  border-radius: 50%;
  opacity: 0;
  left: -36px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }

/* Owl Carousel - Controls - Arrows
-----------------------------------------------------------------*/
.slider-element .owl-nav [class*=owl-],
.owl-carousel-full .owl-nav [class*=owl-] {
  left: 0 !important;
  height: 60px;
  line-height: 60px;
  border: none;
  color: #eee;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 28px;
  border-radius: 0 3px 3px 0; }

.owl-carousel .owl-nav .owl-next {
  left: auto;
  right: -36px; }

.slider-element .owl-nav .owl-next,
.owl-carousel-full .owl-nav .owl-next {
  left: auto !important;
  right: 0 !important;
  border-radius: 3px 0 0 3px; }

.owl-carousel:hover .owl-nav [class*=owl-] {
  opacity: 1;
  left: -18px; }

.owl-carousel:hover .owl-nav .owl-next {
  left: auto;
  right: -18px; }

.owl-carousel .owl-nav [class*=owl-]:hover {
  background-color: #1abc9c !important;
  color: #fff !important;
  text-decoration: none; }

/* Owl Carousel - Controls - Dots
-----------------------------------------------------------------*/
.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  opacity: 0.5;
  width: 8px;
  height: 8px;
  margin: 30px 4px 0 4px;
  background-color: #1abc9c;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .owl-carousel .owl-dots .owl-dot.active, .owl-carousel .owl-dots .owl-dot:hover {
    opacity: 1; }

/* Pagination
-----------------------------------------------------------------*/
.slider-element .owl-dots {
  position: absolute;
  width: 100%;
  z-index: 20;
  margin: 0;
  top: auto;
  bottom: 25px; }
  .slider-element .owl-dots .owl-dot {
    width: 10px;
    height: 10px;
    margin: 0 3px;
    opacity: 1;
    background-color: transparent;
    border: 1px solid white; }
  .slider-element .owl-dots .owl-dot.active,
  .slider-element .owl-dots .owl-dot:hover {
    background-color: white; }

.dark .owl-theme .owl-controls .owl-nav [class*=owl-] {
  border-color: transparent;
  color: #eeeeee;
  background-color: rgba(0, 0, 0, 0.5); }

/*-----------------------------------------------------------------------------------

	Shortcodes: clients.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Clients
-----------------------------------------------------------------*/
.clients-grid,
.testimonials-grid {
  list-style: none;
  overflow: hidden; }
  .clients-grid li,
  .testimonials-grid li {
    float: left;
    position: relative;
    width: 20%;
    padding: 20px 0;
    -webkit-backface-visibility: hidden; }
    @media (max-width: 767.98px) {
      .clients-grid li,
      .testimonials-grid li {
        width: 33.3333333333%; } }
    .clients-grid li:before, .clients-grid li:after,
    .testimonials-grid li:before,
    .testimonials-grid li:after {
      content: '';
      position: absolute; }

.clients-grid li a img {
  width: 100%; }

.clients-grid li:before,
.testimonials-grid li:before {
  height: 100%;
  top: 0;
  left: -1px;
  border-left: 1px dotted #dddddd; }
  .dark .clients-grid li:before, .dark
  .testimonials-grid li:before {
    border-left-color: rgba(255, 255, 255, 0.15); }

.clients-grid li:after,
.testimonials-grid li:after {
  width: 100%;
  height: 0;
  top: auto;
  left: 0;
  bottom: -1px;
  border-bottom: 1px dotted #dddddd; }
  .dark .clients-grid li:after, .dark
  .testimonials-grid li:after {
    border-bottom-color: rgba(255, 255, 255, 0.15); }

.clients-grid li a,
.clients-grid li img {
  display: block;
  width: 75%;
  margin-left: auto;
  margin-right: auto; }

.clients-grid li a {
  opacity: 0.6; }
  .clients-grid li a:hover {
    opacity: 1; }
  body:not(.device-touch) .clients-grid li a {
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease; }

.clients-grid.grid-1 li {
  width: 100%; }

.clients-grid.grid-2 li {
  width: 50%; }

.clients-grid.grid-3 li {
  width: 33.3333333333%; }

.clients-grid.grid-4 li {
  width: 25%; }

.clients-grid.grid-5 li {
  width: 20%; }

.clients-grid.grid-6 li {
  width: 16.6666666667%; }

@media (max-width: 767.98px) {
  .grid-1.clients-grid li {
    width: 33.3333333333%; }
  .grid-2.clients-grid li {
    width: 33.3333333333%; }
  .grid-3.clients-grid li {
    width: 33.3333333333%; }
  .grid-4.clients-grid li {
    width: 33.3333333333%; }
  .grid-5.clients-grid li {
    width: 33.3333333333%; }
  .grid-6.clients-grid li {
    width: 33.3333333333%; } }

.clients-grid.grid-2 li {
  padding: 25px 0; }
  @media (max-width: 767.98px) {
    .clients-grid.grid-2 li {
      width: 50%; } }

/*-----------------------------------------------------------------------------------

	Shortcodes: countdown.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Countdown
-----------------------------------------------------------------*/
.countdown {
  display: block; }
  .countdown-row {
    display: block;
    position: relative;
    text-align: center; }
  .countdown-section {
    display: inline-block;
    font-size: 11px;
    line-height: 1;
    text-align: center;
    width: 25%;
    border-left: 1px dotted #bbbbbb;
    color: #888;
    text-transform: capitalize; }
    .countdown-section:first-child {
      border-left: 0; }
    .dark .countdown-section {
      color: #dddddd;
      border-left-color: rgba(255, 255, 255, 0.2); }
  .countdown-amount {
    display: block;
    font-size: 20px;
    color: #333;
    margin-bottom: 5px; }
  .countdown-descr {
    display: block;
    width: 100%; }

/* Countdown - Medium
-----------------------------------------------------------------*/
.countdown-medium .countdown-section {
  font-size: 14px; }

.countdown-medium .countdown-amount {
  font-size: 30px;
  margin-bottom: 7px; }

/* Countdown - Large
-----------------------------------------------------------------*/
.countdown-large .countdown-section {
  font-size: 18px; }
  @media (max-width: 575.98px) {
    .countdown-large .countdown-section {
      font-size: 14px; } }

.countdown-large .countdown-amount {
  font-size: 44px;
  font-weight: 700;
  margin-bottom: 8px; }
  @media (max-width: 575.98px) {
    .countdown-large .countdown-amount {
      font-size: 30px; } }

/* Countdown - Inline
-----------------------------------------------------------------*/
.countdown.countdown-inline {
  display: inline-block; }
  .countdown.countdown-inline .countdown-row {
    display: inline-block;
    text-align: center; }
  .countdown.countdown-inline .countdown-section {
    display: inline-block;
    font-size: inherit;
    line-height: inherit;
    width: auto;
    border: none;
    color: inherit;
    margin-left: 7px;
    text-transform: inherit; }
    .countdown.countdown-inline .countdown-section:first-child {
      margin-left: 0; }
  .countdown.countdown-inline .countdown-amount {
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: bold;
    margin: 0 3px 0 0; }
    .dark .countdown.countdown-inline .countdown-amount {
      color: #eeeeee; }
  .countdown.countdown-inline .countdown-descr {
    display: inline-block;
    width: auto; }

/* Countdown - Coming Soon
-----------------------------------------------------------------*/
body:not(.device-sm):not(.device-xs) .countdown.countdown-large.coming-soon .countdown-section {
  border: none;
  padding: 15px; }

body:not(.device-sm):not(.device-xs) .countdown.countdown-large.coming-soon .countdown-amount {
  height: 140px;
  line-height: 140px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  margin-bottom: 15px !important; }

/*-----------------------------------------------------------------------------------

	Shortcodes: counter-skills.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Counter
-----------------------------------------------------------------*/
.counter {
  font-size: 42px;
  font-weight: 700;
  font-family: "Raleway", sans-serif; }
  .dark {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); }
  .counter + h5, .counter + .h5 {
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 10px;
    font-size: 14px; }
  .counter.counter-small {
    font-size: 28px; }
    .counter.counter-small + h5, .counter.counter-small + .h5 {
      font-size: 13px; }
  .counter.counter-large, .counter.counter-xlarge {
    font-size: 56px; }
    .counter.counter-large + h5, .counter.counter-xlarge + h5, .counter.counter-large + .h5, .counter.counter-xlarge + .h5 {
      font-size: 15px;
      font-weight: 300; }
  .counter.counter-xlarge {
    font-size: 64px;
    font-weight: 400; }
  .counter.counter-inherit {
    font-size: inherit;
    font-weight: inherit; }
  .counter.counter-lined + h5:before, .counter.counter-lined + .h5:before {
    display: block;
    position: relative;
    margin: 20px auto 25px auto;
    content: '';
    width: 15%;
    border-top: 2px solid #444444;
    opacity: 0.9; }
    .dark .counter.counter-lined + h5:before, .dark .counter.counter-lined + .h5:before {
      border-color: #eeeeee; }

.widget .counter + h5, .widget .counter + .h5 {
  opacity: 0.7; }

/* ----------------------------------------------------------------
	Animated Rounded Skills
-----------------------------------------------------------------*/
.rounded-skill {
  display: inline-block;
  margin: 0 15px 15px;
  position: relative;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  color: #333333; }
  .dark .rounded-skill {
    color: #eeeeee; }
  .rounded-skill i {
    font-size: 42px; }
  .rounded-skill + h5, .rounded-skill + .h5 {
    margin-bottom: 0; }
  .rounded-skill canvas {
    position: absolute;
    top: 0;
    left: 0; }
  body:not(.device-touch) .rounded-skill {
    -webkit-transition: opacity 0.4s ease;
    -o-transition: opacity 0.4s ease;
    transition: opacity 0.4s ease; }

/* ----------------------------------------------------------------
	Skills Bar
-----------------------------------------------------------------*/
.skills {
  margin: 0;
  list-style: none;
  padding-top: 25px; }
  .skills li {
    position: relative;
    margin-top: 38px;
    height: 4px;
    background-color: whitesmoke;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 2px; }
    .dark .skills li {
      background-color: rgba(255, 255, 255, 0.15); }
    .skills li:first-child {
      margin-top: 0; }
    .skills li .progress {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: #1abc9c;
      overflow: visible !important;
      border-radius: 2px;
      -webkit-transition: width 1.2s ease-in-out;
      -o-transition: width 1.2s ease-in-out;
      transition: width 1.2s ease-in-out; }
    .skills li .progress-percent {
      position: absolute;
      top: -33px;
      right: 0;
      background-color: #333;
      color: #f9f9f9;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
      height: auto;
      padding: 5px;
      font-size: 11px;
      border-radius: 2px; }
      .dark .skills li .progress-percent {
        background-color: #4e4e4e; }
      .skills li .progress-percent .counter {
        font-weight: 400;
        font-family: "Lato", sans-serif; }
      .skills li .progress-percent:before {
        top: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-top-color: #333;
        left: 50%;
        border-width: 5px;
        margin-left: -5px; }
        .dark .skills li .progress-percent:before {
          border-top-color: #4e4e4e; }
    .skills li > span {
      position: absolute;
      z-index: 1;
      top: -23px;
      left: 0;
      line-height: 20px;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: #222222; }
      .dark .skills li > span {
        color: #cccccc; }

/*-----------------------------------------------------------------------------------

	Shortcodes: dividers.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Divider
-----------------------------------------------------------------*/
.divider {
  position: relative;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  overflow: hidden;
  margin: 35px 0;
  color: #e5e5e5;
  width: 100%;
  /* Divider - Icon Align Right
	-----------------------------------------------------------------*/
  /* Divider - Icon Align Center
	-----------------------------------------------------------------*/
  /* Divider - Rounded Icon
	-----------------------------------------------------------------*/
  /* Divider - Rounded & Border
	-----------------------------------------------------------------*/
  /* Divider - Line Only
	-----------------------------------------------------------------*/ }
  .dark .divider {
    color: rgba(255, 255, 255, 0.15); }
  .divider-margin {
    margin: 70px 0; }
  .divider:after, .divider-center:before, .divider-center.divider-short:before {
    content: '';
    position: absolute;
    width: 100%;
    top: 50%;
    left: 30px;
    height: 0;
    transform: translateY(-50%);
    border-top: 1px solid #eeeeee; }
    .dark .divider:after, .dark .divider-center:before, .dark .divider-center.divider-short:before {
      border-top-color: rgba(255, 255, 255, 0.1); }
  .divider-thick:after, .divider-center.divider-thick:before, .divider-center.divider-short.divider-thick:before {
    border-top-width: 3px; }
  .divider-short:after {
    width: 30%; }
  .divider-vshort:after {
    width: 12%; }
  .divider i {
    position: relative;
    width: 18px;
    height: 18px;
    line-height: 1;
    font-size: 18px !important;
    text-align: center; }
  .divider a {
    position: relative;
    display: inline-block;
    color: inherit;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .divider a:hover {
      color: #888888; }
  .divider-right {
    text-align: right;
    justify-content: flex-end; }
    .divider-right:after {
      left: auto;
      right: 30px; }
  .divider-center, .divider-center.divider-short {
    text-align: center;
    justify-content: center; }
  .divider-center {
    /* Short Divider - Icon Align Center
		-----------------------------------------------------------------*/ }
    .divider-center:before {
      left: -50% !important;
      right: 0;
      margin-left: -20px; }
    .divider-center:after {
      left: 50% !important;
      right: 0;
      margin-left: 20px; }
    .divider-center.divider-short:before {
      left: auto !important;
      right: 50%;
      margin-right: 20px;
      width: 15%; }
    .divider-center.divider-short:after {
      left: 50% !important;
      right: auto !important;
      margin-left: 20px;
      width: 15%; }
    .divider-center.divider-vshort:before, .divider-center.divider-vshort:after {
      width: 6%; }
  .divider-rounded {
    color: #bbbbbb; }
  .divider-rounded:after, .divider-rounded.divider-center:before, .divider-rounded.divider-short:before, .divider-border:after, .divider-border.divider-center:before, .divider-border.divider-short:before {
    left: 40px; }
  .dark .divider-rounded, .dark .divider-border {
    color: #999999; }
  .divider-rounded i, .divider-border i {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: whitesmoke;
    border-radius: 50%; }
    .dark .divider-rounded i, .dark .divider-border i {
      background-color: rgba(255, 255, 255, 0.1); }
  .divider-rounded i {
    color: #bbbbbb; }
  .divider-rounded.divider-right:after, .divider-border.divider-right:after {
    left: auto;
    right: 40px; }
  .divider-border {
    color: #e5e5e5; }
    .divider-border i {
      line-height: 38px;
      background-color: transparent;
      border: 1px solid #eeeeee; }
      .dark .divider-border i {
        background-color: transparent;
        border-color: rgba(255, 255, 255, 0.15); }
  .divider-line:before, .divider-line:after {
    margin-left: 0 !important;
    margin-right: 0 !important; }

/*-----------------------------------------------------------------------------------

	Shortcodes: faqs.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Toggle - FAQs
-----------------------------------------------------------------*/
.faqs .toggle {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 12px;
  margin-bottom: 12px; }
  .dark .faqs .toggle {
    border-bottom-color: rgba(255, 255, 255, 0.1); }
  .faqs .toggle .togglet,
  .faqs .toggle .toggleta {
    font-size: 16px; }
  .faqs .toggle .togglec {
    padding-bottom: 10px; }

.faqs i {
  top: 1px;
  font-size: 14px; }

/* FAQ List
-----------------------------------------------------------------*/
.faqlist li {
  margin-bottom: 5px;
  font-size: 16px; }
  .faqlist li a {
    color: #444444; }
    .dark .faqlist li a {
      color: #cccccc; }
    .faqlist li a:hover {
      color: #1abc9c !important; }

/*-----------------------------------------------------------------------------------

	Shortcodes: feature-box.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Featured Boxes
-----------------------------------------------------------------*/
.feature-box {
  position: relative;
  margin-top: 20px;
  padding: 0 0 0 80px; }
  .feature-box:first-child {
    margin-top: 0; }
  .feature-box .fbox-icon {
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    top: 0;
    left: 0; }
    .feature-box .fbox-icon a,
    .feature-box .fbox-icon i,
    .feature-box .fbox-icon img {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      color: #fff; }
    .feature-box .fbox-icon i,
    .feature-box .fbox-icon img {
      border-radius: 50%;
      background-color: #1abc9c; }
    .feature-box .fbox-icon i {
      font-style: normal;
      font-size: 28px;
      text-align: center;
      line-height: 64px; }
  .feature-box h3, .feature-box .h3 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0;
    color: #333333; }
    .dark .feature-box h3, .dark .feature-box .h3 {
      color: #cccccc; }
  .feature-box p {
    margin: 8px 0 0 0;
    color: #999999; }
    .dark .feature-box p {
      color: #888888; }
  .feature-box .before-heading {
    font-size: 14px; }

/* Feature Box - Icon Large
-----------------------------------------------------------------*/
.feature-box.fbox-large {
  padding: 0 0 0 115px; }
  .feature-box.fbox-large .fbox-icon {
    width: 96px;
    height: 96px; }
    .feature-box.fbox-large .fbox-icon i {
      font-size: 42px;
      line-height: 96px; }

/* Feature Box - Icon Outline
-----------------------------------------------------------------*/
.feature-box.fbox-outline .fbox-icon {
  border: 1px solid #1abc9c;
  border-radius: 50%;
  padding: 3px; }
  .feature-box.fbox-outline .fbox-icon i {
    line-height: 56px; }

/* Feature Box - Icon Outline Large
-----------------------------------------------------------------*/
.feature-box.fbox-outline.fbox-large .fbox-icon {
  padding: 4px; }
  .feature-box.fbox-outline.fbox-large .fbox-icon i {
    line-height: 86px; }

/* Feature Box - Icon Rounded
-----------------------------------------------------------------*/
.feature-box.fbox-rounded .fbox-icon {
  border-radius: 3px !important; }
  .feature-box.fbox-rounded .fbox-icon i,
  .feature-box.fbox-rounded .fbox-icon img {
    border-radius: 3px !important; }

/* Feature Box - Icon Rounded & Large
-----------------------------------------------------------------*/
.feature-box.fbox-rounded.fbox-large .fbox-icon {
  border-radius: 4px !important; }
  .feature-box.fbox-rounded.fbox-large .fbox-icon i,
  .feature-box.fbox-rounded.fbox-large .fbox-icon img {
    border-radius: 4px !important; }

/* Feature Box - Light Background
-----------------------------------------------------------------*/
.feature-box.fbox-light.fbox-outline .fbox-icon {
  border-color: #e5e5e5; }
  .dark .feature-box.fbox-light.fbox-outline .fbox-icon {
    border-color: rgba(255, 255, 255, 0.15); }
  .feature-box.fbox-light.fbox-outline .fbox-icon i {
    line-height: 56px; }

.feature-box.fbox-light.fbox-outline.fbox-large .fbox-icon i {
  line-height: 86px; }

.feature-box.fbox-light .fbox-icon i,
.feature-box.fbox-light .fbox-icon img {
  border: 1px solid #e5e5e5;
  background-color: whitesmoke;
  color: #444444; }
  .dark .feature-box.fbox-light .fbox-icon i, .dark
  .feature-box.fbox-light .fbox-icon img {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.1);
    color: white; }

.feature-box.fbox-light .fbox-icon i {
  line-height: 62px; }

.feature-box.fbox-large .fbox-icon i {
  line-height: 96px; }

/* Feature Box - Dark Background
-----------------------------------------------------------------*/
.feature-box.fbox-dark.fbox-outline .fbox-icon {
  border-color: #444444; }
  .dark .feature-box.fbox-dark.fbox-outline .fbox-icon {
    border-color: rgba(255, 255, 255, 0.15); }

.feature-box.fbox-dark .fbox-icon i,
.feature-box.fbox-dark .fbox-icon img {
  background-color: #333; }
  .dark .feature-box.fbox-dark .fbox-icon i, .dark
  .feature-box.fbox-dark .fbox-icon img {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.1);
    color: white; }

/* Feature Box - Border
-----------------------------------------------------------------*/
.feature-box.fbox-border .fbox-icon {
  border: 1px solid #1abc9c;
  border-radius: 50%; }
  .feature-box.fbox-border .fbox-icon i,
  .feature-box.fbox-border .fbox-icon img {
    border: none;
    background-color: transparent !important;
    color: #1abc9c; }

.feature-box.fbox-border .fbox-icon {
  padding: 0; }
  .feature-box.fbox-border .fbox-icon i {
    line-height: 62px !important; }

.feature-box.fbox-border.fbox-large .fbox-icon i {
  line-height: 94px !important; }

/* Feature Box - Border - Light
-----------------------------------------------------------------*/
.feature-box.fbox-border.fbox-light .fbox-icon {
  border-color: #e5e5e5; }
  .dark .feature-box.fbox-border.fbox-light .fbox-icon {
    border-color: rgba(255, 255, 255, 0.15); }

.feature-box.fbox-border.fbox-light i,
.feature-box.fbox-border.fbox-light img {
  color: #888; }
  .dark .feature-box.fbox-border.fbox-light i, .dark
  .feature-box.fbox-border.fbox-light img {
    color: white; }

/* Feature Box - Border - Dark
-----------------------------------------------------------------*/
.feature-box.fbox-border.fbox-dark .fbox-icon {
  border-color: #333; }
  .dark .feature-box.fbox-border.fbox-dark .fbox-icon {
    border-color: rgba(255, 255, 255, 0.15); }
  .feature-box.fbox-border.fbox-dark .fbox-icon i,
  .feature-box.fbox-border.fbox-dark .fbox-icon img {
    color: #444444; }
    .dark .feature-box.fbox-border.fbox-dark .fbox-icon i, .dark
    .feature-box.fbox-border.fbox-dark .fbox-icon img {
      color: white; }

/* Feature Box - Plain
-----------------------------------------------------------------*/
.feature-box.fbox-plain .fbox-icon {
  border: none !important;
  height: auto !important; }
  .feature-box.fbox-plain .fbox-icon i,
  .feature-box.fbox-plain .fbox-icon img {
    border: none !important;
    background-color: transparent !important;
    color: #1abc9c;
    border-radius: 0; }
  .feature-box.fbox-plain .fbox-icon i {
    font-size: 28px;
    line-height: 1 !important; }
  .feature-box.fbox-plain .fbox-icon img {
    height: 64px; }

.feature-box.fbox-plain.fbox-image .fbox-icon {
  width: auto; }

.feature-box.fbox-plain.fbox-image img {
  width: auto;
  display: inline-block; }

.feature-box.fbox-plain.fbox-small {
  padding-left: 42px; }
  .feature-box.fbox-plain.fbox-small .fbox-icon {
    width: 28px; }
    .feature-box.fbox-plain.fbox-small .fbox-icon i {
      font-size: 28px; }
    .feature-box.fbox-plain.fbox-small .fbox-icon img {
      height: 28px; }
  .feature-box.fbox-plain.fbox-small h3, .feature-box.fbox-plain.fbox-small .h3 {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px; }
  .feature-box.fbox-plain.fbox-small p {
    margin-left: -round(42px); }

.feature-box.fbox-plain.fbox-large .fbox-icon i {
  font-size: 42px; }

.feature-box.fbox-plain.fbox-large .fbox-icon img {
  height: 96px; }

.feature-box.fbox-plain.fbox-light .fbox-icon i,
.feature-box.fbox-plain.fbox-light .fbox-icon img {
  color: #888; }
  .dark .feature-box.fbox-plain.fbox-light .fbox-icon i, .dark
  .feature-box.fbox-plain.fbox-light .fbox-icon img {
    color: white; }

.feature-box.fbox-plain.fbox-dark .fbox-icon i,
.feature-box.fbox-plain.fbox-dark .fbox-icon img {
  color: #444444; }
  .dark .feature-box.fbox-plain.fbox-dark .fbox-icon i, .dark
  .feature-box.fbox-plain.fbox-dark .fbox-icon img {
    color: white; }

/* Feature Box - Center
-----------------------------------------------------------------*/
.feature-box.fbox-center {
  padding: 0;
  text-align: center; }
  .feature-box.fbox-center.fbox-small {
    padding-left: 0 !important; }
  .feature-box.fbox-center .fbox-icon {
    position: relative;
    width: 96px;
    height: 96px;
    margin: 0 auto 30px; }
    .feature-box.fbox-center .fbox-icon i {
      font-size: 42px;
      line-height: 96px; }
  .feature-box.fbox-center.fbox-outline .fbox-icon i {
    line-height: 88px; }
  .feature-box.fbox-center p {
    font-size: 14px;
    margin-top: 15px; }
  .dark .feature-box.fbox-center {
    border-top: rgba(255, 255, 255, 0.15); }

/* Feature Box - Center & Large Icons
-----------------------------------------------------------------*/
.feature-box.fbox-center.fbox-large .fbox-icon {
  width: 128px;
  height: 128px; }
  .feature-box.fbox-center.fbox-large .fbox-icon i {
    font-size: 70px;
    line-height: 128px; }

.feature-box.fbox-center.fbox-large.fbox-outline .fbox-icon i {
  line-height: 120px; }

.feature-box.fbox-center.fbox-light .fbox-icon i {
  line-height: 94px; }

.feature-box.fbox-center.fbox-light .fbox-outline .fbox-icon i {
  line-height: 88px; }

.feature-box.fbox-center.fbox-light .fbox-outline .fbox-large .fbox-icon i {
  line-height: 120px; }

.feature-box.fbox-center.fbox-border .fbox-icon i {
  line-height: 90px !important; }

.feature-box.fbox-center.fbox-large.fbox-border .fbox-icon i {
  line-height: 126px !important; }

.feature-box.fbox-center.fbox-large .fbox-plain .fbox-icon i {
  font-size: 112px; }

.feature-box.fbox-center.fbox-large .fbox-plain .fbox-icon img {
  height: 128px; }

.feature-box.fbox-center.fbox-plain .fbox-icon i {
  font-size: 84px; }

.feature-box.fbox-center.fbox-plain .fbox-icon img {
  height: 96px; }

/* Feature Box - Center & Large Icons
-----------------------------------------------------------------*/
.feature-box.fbox-center.fbox-small .fbox-icon {
  width: 64px;
  height: 64px; }
  .feature-box.fbox-center.fbox-small .fbox-icon i {
    font-size: 28px;
    line-height: 64px; }

.feature-box.fbox-center.fbox-small.fbox-outline .fbox-icon i {
  line-height: 122px; }

.feature-box.fbox-center .fbox-light.fbox-outline.fbox-small .fbox-icon i {
  line-height: 120px; }

.feature-box.fbox-center.fbox-small.fbox-border .fbox-icon i {
  line-height: 56px !important; }

.feature-box.fbox-center.fbox-small .fbox-plain .fbox-icon i {
  font-size: 90px; }

.feature-box.fbox-center.fbox-small .fbox-plain .fbox-icon img {
  height: 64px; }

.feature-box.fbox-center.fbox-small p {
  font-size: 14px;
  margin-top: 10px; }

.feature-box.fbox-center .fbox-plain.fbox-small p {
  margin-left: 0; }

/* Feature Box - Right
-----------------------------------------------------------------*/
.feature-box.fbox-right {
  padding: 0 80px 0 0;
  text-align: right; }
  .feature-box.fbox-right.fbox-large {
    padding-right: 115px; }
  .feature-box.fbox-right .fbox-icon {
    left: auto;
    right: 0; }
  .feature-box.fbox-right.fbox-plain.fbox-small {
    padding: 0 42px 0 0; }
    .feature-box.fbox-right.fbox-plain.fbox-small p {
      margin: -42px 0 0; }

/* Feature Box - Subtitle
-----------------------------------------------------------------*/
.feature-box h3 + .fbox-icon, .feature-box .h3 + .fbox-icon {
  margin-top: 20px !important; }

.feature-box h3 span.subtitle, .feature-box .h3 span.subtitle {
  display: block;
  margin-top: 5px;
  color: #444444;
  font-weight: 300;
  text-transform: none; }
  .dark .feature-box h3 span.subtitle, .dark .feature-box .h3 span.subtitle {
    color: #aaaaaa; }

.feature-box.fbox-center:not(.fbox-bg) h3:after, .feature-box.fbox-center:not(.fbox-bg) .h3:after {
  content: '';
  display: block;
  margin: 20px auto;
  width: 30px;
  border-top: 2px solid #555;
  -webkit-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease; }
  .dark .feature-box.fbox-center:not(.fbox-bg) h3:after, .dark .feature-box.fbox-center:not(.fbox-bg) .h3:after {
    border-top: rgba(255, 255, 255, 0.15); }

.feature-box.fbox-center:not(.fbox-bg):hover h3:after, .feature-box.fbox-center:not(.fbox-bg):hover .h3:after {
  width: 50px; }

.feature-box.fbox-center.noborder:not(.fbox-bg) h3:after, .feature-box.fbox-center.noborder:not(.fbox-bg) .h3:after,
.feature-box.fbox-center.nobottomborder:not(.fbox-bg) h3:after,
.feature-box.fbox-center.nobottomborder:not(.fbox-bg) .h3:after {
  display: none; }

.feature-box.fbox-center.fbox-italic p {
  font-style: italic;
  font-family: "Lato", sans-serif; }

/* Feature Box - Background
-----------------------------------------------------------------*/
.feature-box.fbox-bg.fbox-center {
  margin-top: 68px;
  padding: 68px 30px 30px;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 5px; }
  .dark .feature-box.fbox-bg.fbox-center {
    background-color: #383838;
    border-color: rgba(255, 255, 255, 0.1); }
  .feature-box.fbox-bg.fbox-center:first-child {
    margin-top: 48px; }
  .feature-box.fbox-bg.fbox-center.fbox-large {
    margin-top: 84px;
    padding-top: 84px; }
    .feature-box.fbox-bg.fbox-center.fbox-large:first-child {
      margin-top: 64px; }
    .feature-box.fbox-bg.fbox-center.fbox-large .fbox-icon {
      top: -64px;
      left: 50%;
      margin-left: -64px; }
  .feature-box.fbox-bg.fbox-center .fbox-icon {
    position: absolute;
    top: -48px;
    left: 50%;
    margin: 0 0 0 -48px; }
  .feature-box.fbox-bg.fbox-center.fbox-plain .fbox-icon, .feature-box.fbox-bg.fbox-center.fbox-border .fbox-icon, .feature-box.fbox-bg.fbox-center.fbox-outline .fbox-icon {
    background-color: white; }
    .dark .feature-box.fbox-bg.fbox-center.fbox-plain .fbox-icon, .dark .feature-box.fbox-bg.fbox-center.fbox-border .fbox-icon, .dark .feature-box.fbox-bg.fbox-center.fbox-outline .fbox-icon {
      background-color: #383838 !important; }
  .dark .feature-box.fbox-bg.fbox-center.fbox-light .fbox-icon i {
    background-color: #444444 !important; }

/* Animated Icon Hovers
-----------------------------------------------------------------*/
.fbox-effect .fbox-icon i {
  z-index: 1;
  -webkit-transition: background-color 0.3s, color 0.3s;
  -o-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s; }

.fbox-effect .fbox-icon i:after {
  top: -3px;
  left: -3px;
  padding: 3px;
  box-shadow: 0 0 0 2px #333333;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -o-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: -webkit-transform 0.3s, opacity 0.3s;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }
  .dark .fbox-effect .fbox-icon i:after {
    box-shadow: 0 0 0 2px #494949; }

.fbox-rounded.fbox-effect .fbox-icon i:after {
  border-radius: 3px; }

.fbox-effect .fbox-icon i:hover,
.fbox-effect:hover .fbox-icon i {
  background-color: #333;
  color: #fff; }
  .dark .fbox-effect .fbox-icon i:hover, .dark
  .fbox-effect:hover .fbox-icon i {
    background-color: #494949; }

.fbox-effect.fbox-dark .fbox-icon i:after,
.dark .fbox-effect.fbox-dark .fbox-icon i:after {
  box-shadow: 0 0 0 2px #1abc9c; }

.fbox-effect.fbox-dark .fbox-icon i:hover,
.fbox-effect.fbox-dark:hover .fbox-icon i {
  background-color: #1abc9c; }

.fbox-effect .fbox-icon i:hover:after,
.fbox-effect:hover .fbox-icon i:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

/* Icon Effects - Bordered
-----------------------------------------------------------------*/
.fbox-border.fbox-effect .fbox-icon i {
  -webkit-transition: color 0.5s, box-shadow 0.5s, background-color 0.5s;
  -o-transition: color 0.5s, box-shadow 0.5s, background-color 0.5s;
  transition: color 0.5s, box-shadow 0.5s, background-color 0.5s; }

.fbox-border.fbox-effect .fbox-icon i:after {
  top: -2px;
  left: -2px;
  padding: 2px;
  z-index: -1;
  box-shadow: none;
  background-image: url("image/icons/iconalt.svg");
  background-position: center center;
  background-size: 100% 100%;
  -webkit-transition: -webkit-transform 0.5s, opacity 0.5s, background-color 0.5s;
  -o-transition: -webkit-transform 0.5s, opacity 0.5s, background-color 0.5s;
  transition: -webkit-transform 0.5s, opacity 0.5s, background-color 0.5s; }
  .dark .fbox-border.fbox-effect .fbox-icon i:after {
    box-shadow: 0 0 0 1px #494949; }

.fbox-border.fbox-rounded.fbox-effect .fbox-icon i:after {
  border-radius: 3px; }

.fbox-border.fbox-effect .fbox-icon i:hover,
.fbox-border.fbox-effect:hover .fbox-icon i {
  color: #fff;
  box-shadow: 0 0 0 1px #333; }

.fbox-border.fbox-effect .fbox-icon i:after {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  opacity: 0;
  background-color: #333; }

.fbox-border.fbox-effect.fbox-dark .fbox-icon i:hover,
.fbox-border.fbox-effect.fbox-dark:hover .fbox-icon i,
.dark .fbox-border.fbox-effect.fbox-dark .fbox-icon i:hover,
.dark .fbox-border.fbox-effect.fbox-dark:hover .fbox-icon i {
  box-shadow: 0 0 0 1px #1abc9c; }

.fbox-border.fbox-effect.fbox-dark .fbox-icon i:after,
.dark .fbox-border.fbox-effect.fbox-dark .fbox-icon i:after {
  background-color: #1abc9c; }

.fbox-border.fbox-effect .fbox-icon i:hover:after,
.fbox-border.fbox-effect:hover .fbox-icon i:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

/* Media Featured Box
-----------------------------------------------------------------*/
.feature-box.media-box {
  padding: 0; }

.feature-box.media-box .fbox-media {
  margin: 0 0 25px; }

.feature-box.media-box .fbox-media,
.feature-box.media-box .fbox-media a,
.feature-box.media-box .fbox-media img {
  position: relative;
  display: block;
  width: 100%;
  height: auto; }

.feature-box.media-box .fbox-media iframe {
  display: block; }

.feature-box.media-box p {
  margin-top: 17px; }

.feature-box.media-box.fbox-bg .fbox-media {
  margin: 0; }

.feature-box.media-box.fbox-bg .fbox-desc {
  padding: 25px;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-top: 0;
  border-radius: 0 0 5px 5px; }
  .dark .feature-box.media-box.fbox-bg .fbox-desc {
    background-color: #383838;
    border-color: rgba(255, 255, 255, 0.1); }

.feature-box.media-box.fbox-bg .fbox-media img {
  border-radius: 5px 5px 0 0; }

/* ----------------------------------------------------------------
	Flipbox
-----------------------------------------------------------------*/
.flipbox {
  perspective: 1000px; }

.flipbox-inner {
  transition: 0.5s;
  transition-timing-function: ease;
  position: relative;
  transform-style: preserve-3d; }

.flipbox-front,
.flipbox-back {
  -webkit-backface-visibility: hidden; }

.flipbox-front {
  z-index: 2; }

.flipbox-back {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.flipbox:not(.flipbox-vertical) .flipbox-front {
  transform: rotateY(0deg); }

.flipbox:not(.flipbox-vertical) .flipbox-back {
  transform: rotateY(-180deg); }

.flipbox.flipbox-vertical .flipbox-front {
  transform: rotateX(0deg); }

.flipbox.flipbox-vertical .flipbox-back {
  transform: rotateX(-180deg); }

.flipbox:not(.flipbox-vertical):hover .flipbox-inner,
.flipbox:not(.flipbox-vertical).hover .flipbox-inner {
  transform: rotateY(180deg); }

.flipbox.flipbox-vertical:hover .flipbox-inner,
.flipbox.flipbox-vertical.hover .flipbox-inner {
  transform: rotateX(180deg); }

/* ----------------------------------------------------------------
	Flip Cards
-----------------------------------------------------------------*/
.flip-card-front,
.flip-card-back {
  background-size: cover;
  background-position: center;
  -webkit-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  height: 280px;
  border-radius: 6px;
  color: #fff;
  font-size: 1.5rem; }
  .flip-card-front.no-after:after, .flip-card-front.no-after:after,
  .flip-card-back.no-after:after,
  .flip-card-back.no-after:after {
    content: none; }
  .flip-card-front:after,
  .flip-card-back:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: "";
    display: block;
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 6px; }

.flip-card {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-perspective: 1000px;
  perspective: 1000px; }
  .flip-card:hover .flip-card-front,
  .flip-card:hover .flip-card-back {
    -webkit-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1); }

.flip-card-back {
  background-color: #666;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.flip-card-inner {
  -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
  transform: translateY(-50%) translateZ(60px) scale(0.94);
  top: 50%;
  position: absolute;
  left: 0;
  width: 100%;
  padding: 2rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 1px solid transparent;
  -webkit-perspective: inherit;
  perspective: inherit;
  z-index: 2; }
  .flip-card-inner span {
    font-size: 18px;
    line-height: 20px;
    font-weight: 300; }
  .flip-card-inner p {
    position: relative;
    font-size: 16px;
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.7); }

.flip-card-front,
.flip-card:hover .flip-card-back {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.flip-card:hover .flip-card-front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.top-to-bottom .flip-card-back {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.top-to-bottom:hover .flip-card-front {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.top-to-bottom .flip-card-front,
.top-to-bottom:hover .flip-card-back {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

/*-----------------------------------------------------------------------------------

	Shortcodes: headings.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Heading Styles
-----------------------------------------------------------------*/
/* Block Titles
-----------------------------------------------------------------*/
.title-block {
  padding: 2px 0 3px 20px;
  border-left: 7px solid #1abc9c;
  margin-bottom: 30px; }
  @media (max-width: 767.98px) {
    .title-block {
      padding: 2px 0 3px 15px;
      border-left-width: 5px; } }
  .title-block-right {
    padding: 2px 20px 3px 0;
    border-left: 0;
    border-right: 7px solid #1abc9c;
    text-align: right; }
    @media (max-width: 767.98px) {
      .title-block-right {
        padding: 2px 15px 3px 0;
        border-right-width: 5px; } }
  .title-block h1, .title-block .h1,
  .title-block h2,
  .title-block .h2,
  .title-block h3,
  .title-block .h3,
  .title-block h4,
  .title-block .h4 {
    margin-bottom: 0;
    color: #444444; }
    .title-block h1 > span:not(.nocolor), .title-block .h1 > span:not(.nocolor),
    .title-block h2 > span:not(.nocolor),
    .title-block .h2 > span:not(.nocolor),
    .title-block h3 > span:not(.nocolor),
    .title-block .h3 > span:not(.nocolor),
    .title-block h4 > span:not(.nocolor),
    .title-block .h4 > span:not(.nocolor) {
      color: #1abc9c; }
  .title-block > span {
    display: block;
    margin-top: 4px;
    color: #333333;
    font-weight: 300; }
    .dark .title-block > span {
      color: #999999; }
  .title-block h1 + span, .title-block .h1 + span {
    font-size: 22px; }
    @media (max-width: 767.98px) {
      .title-block h1 + span, .title-block .h1 + span {
        font-size: 18px; } }
  .title-block h2 + span, .title-block .h2 + span {
    font-size: 19px; }
    @media (max-width: 767.98px) {
      .title-block h2 + span, .title-block .h2 + span {
        font-size: 17px; } }
  .title-block h3 + span, .title-block .h3 + span {
    font-size: 17px; }
    @media (max-width: 767.98px) {
      .title-block h3 + span, .title-block .h3 + span {
        font-size: 15px; } }
  .title-block h4 + span, .title-block .h4 + span {
    font-size: 15px;
    margin-top: 3px; }
    @media (max-width: 767.98px) {
      .title-block h4 + span, .title-block .h4 + span {
        font-size: 13px; } }

/* Heading Block - with Subtitle
-----------------------------------------------------------------*/
.heading-block {
  margin-bottom: 50px; }
  .dark .heading-block:not(.not-dark) {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); }

.heading-block h1, .heading-block .h1,
.heading-block h2,
.heading-block .h2,
.heading-block h3,
.heading-block .h3,
.heading-block h4,
.heading-block .h4 {
  margin-bottom: 0;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #333333; }
  .heading-block h1 > span:not(.nocolor), .heading-block .h1 > span:not(.nocolor),
  .heading-block h2 > span:not(.nocolor),
  .heading-block .h2 > span:not(.nocolor),
  .heading-block h3 > span:not(.nocolor),
  .heading-block .h3 > span:not(.nocolor),
  .heading-block h4 > span:not(.nocolor),
  .heading-block .h4 > span:not(.nocolor) {
    color: #1abc9c; }
  .dark :not(.not-dark).heading-block h1, .dark :not(.not-dark).heading-block .h1, .dark :not(.not-dark).heading-block h2, .dark :not(.not-dark).heading-block .h2, .dark :not(.not-dark).heading-block h3, .dark :not(.not-dark).heading-block .h3, .dark :not(.not-dark).heading-block h4, .dark :not(.not-dark).heading-block .h4 {
    color: #FFF; }
  .dark .not-dark.heading-block h1, .dark .not-dark.heading-block .h1, .dark .not-dark.heading-block h2, .dark .not-dark.heading-block .h2, .dark .not-dark.heading-block h3, .dark .not-dark.heading-block .h3, .dark .not-dark.heading-block h4, .dark .not-dark.heading-block .h4 {
    color: #333333; }

.dark :not(.not-dark).emphasis-title {
  color: #333333; }

.emphasis-title h1, .emphasis-title .h1,
.emphasis-title h2,
.emphasis-title .h2 {
  margin-bottom: 0;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  color: #333333; }
  .dark :not(.not-dark).emphasis-title h1, .dark :not(.not-dark).emphasis-title .h1, .dark :not(.not-dark).emphasis-title h2, .dark :not(.not-dark).emphasis-title .h2 {
    color: #FFF; }
  .emphasis-title h1 > span:not(.nocolor), .emphasis-title .h1 > span:not(.nocolor),
  .emphasis-title h2 > span:not(.nocolor),
  .emphasis-title .h2 > span:not(.nocolor) {
    color: #1abc9c; }
  .dark .not-dark.emphasis-title h1, .dark .not-dark.emphasis-title .h1, .dark .not-dark.emphasis-title h2, .dark .not-dark.emphasis-title .h2 {
    color: #333333; }

.heading-block h1, .heading-block .h1 {
  font-size: 32px; }
  @media (max-width: 767.98px) {
    .heading-block h1, .heading-block .h1 {
      font-size: 30px; } }

.heading-block h2, .heading-block .h2 {
  font-size: 30px; }
  @media (max-width: 767.98px) {
    .heading-block h2, .heading-block .h2 {
      font-size: 26px; } }

.heading-block h3, .heading-block .h3 {
  font-size: 26px; }
  @media (max-width: 767.98px) {
    .heading-block h3, .heading-block .h3 {
      font-size: 22px; } }

.heading-block h4, .heading-block .h4 {
  font-size: 20px;
  font-weight: 700; }
  @media (max-width: 767.98px) {
    .heading-block h4, .heading-block .h4 {
      font-size: 19px; } }

.heading-block > span:not(.before-heading) {
  display: block;
  margin-top: 10px;
  font-weight: 300;
  color: #777777; }

.dark :not(.not-dark).heading-block > span {
  color: #eeeeee; }

.heading-block .before-heading {
  margin-bottom: 7px; }

.heading-block.center > span,
.heading-block.title-center > span,
.center .heading-block > span {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto; }

.heading-block h1 + span, .heading-block .h1 + span {
  font-size: 24px; }
  @media (max-width: 767.98px) {
    .heading-block h1 + span, .heading-block .h1 + span {
      font-size: 20px; } }

.heading-block h2 + span, .heading-block .h2 + span {
  font-size: 22px; }
  @media (max-width: 767.98px) {
    .heading-block h2 + span, .heading-block .h2 + span {
      font-size: 18px; } }

.heading-block h3 + span, .heading-block .h3 + span {
  font-size: 20px; }
  @media (max-width: 767.98px) {
    .heading-block h3 + span, .heading-block .h3 + span {
      font-size: 17px; } }

.heading-block h4 + span, .heading-block .h4 + span {
  font-size: 18px; }
  @media (max-width: 767.98px) {
    .heading-block h4 + span, .heading-block .h4 + span {
      font-size: 17px; } }

.heading-block ~ p:not(.lead) {
  font-size: 15px; }

.heading-block:after {
  content: '';
  display: block;
  margin-top: 30px;
  width: 40px;
  border-top: 2px solid #444444; }
  .dark :not(.not-dark).heading-block:after {
    border-top-color: rgba(255, 255, 255, 0.4); }

.center .heading-block:after,
.heading-block.center:after,
.heading-block.title-center:after {
  margin: 30px auto 0; }

.heading-block.noborder:after,
.heading-block.nobottomborder:after {
  display: none; }

.tright .heading-block,
.heading-block.tright,
.heading-block.title-right {
  direction: rtl; }

.heading-block.border-color:after {
  border-color: #1abc9c; }

/* Emphasis Title
-----------------------------------------------------------------*/
.emphasis-title {
  margin: 0 0 50px; }
  .emphasis-title h1, .emphasis-title .h1,
  .emphasis-title h2,
  .emphasis-title .h2 {
    font-size: 64px;
    letter-spacing: -2px; }
    @media (max-width: 767.98px) {
      .emphasis-title h1, .emphasis-title .h1,
      .emphasis-title h2,
      .emphasis-title .h2 {
        font-size: 44px; } }
    @media (max-width: 575.98px) {
      .emphasis-title h1, .emphasis-title .h1,
      .emphasis-title h2,
      .emphasis-title .h2 {
        font-size: 40px; } }
    .emphasis-title h1 strong, .emphasis-title .h1 strong,
    .emphasis-title h2 strong,
    .emphasis-title .h2 strong {
      font-weight: 700; }

/* Justify Border Title
-----------------------------------------------------------------*/
.fancy-title {
  position: relative;
  margin-bottom: 30px; }
  .fancy-title h1, .fancy-title .h1,
  .fancy-title h2,
  .fancy-title .h2,
  .fancy-title h3,
  .fancy-title .h3,
  .fancy-title h4,
  .fancy-title .h4,
  .fancy-title h5,
  .fancy-title .h5,
  .fancy-title h6,
  .fancy-title .h6 {
    position: relative;
    display: inline-block;
    background-color: white;
    padding-right: 15px;
    margin-bottom: 0;
    color: #444444; }
    .fancy-title h1 > span:not(.nocolor), .fancy-title .h1 > span:not(.nocolor),
    .fancy-title h2 > span:not(.nocolor),
    .fancy-title .h2 > span:not(.nocolor),
    .fancy-title h3 > span:not(.nocolor),
    .fancy-title .h3 > span:not(.nocolor),
    .fancy-title h4 > span:not(.nocolor),
    .fancy-title .h4 > span:not(.nocolor),
    .fancy-title h5 > span:not(.nocolor),
    .fancy-title .h5 > span:not(.nocolor),
    .fancy-title h6 > span:not(.nocolor),
    .fancy-title .h6 > span:not(.nocolor) {
      color: #1abc9c; }
    .dark .fancy-title h1, .dark .fancy-title .h1, .dark
    .fancy-title h2, .dark
    .fancy-title .h2, .dark
    .fancy-title h3, .dark
    .fancy-title .h3, .dark
    .fancy-title h4, .dark
    .fancy-title .h4, .dark
    .fancy-title h5, .dark
    .fancy-title .h5, .dark
    .fancy-title h6, .dark
    .fancy-title .h6 {
      background-color: #383838; }
  .fancy-title h4, .fancy-title .h4,
  .fancy-title h5,
  .fancy-title .h5,
  .fancy-title h6,
  .fancy-title .h6 {
    padding-right: 10px; }
  .fancy-title.title-double-border:before, .fancy-title.title-border:before, .fancy-title.title-border-color:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0;
    border-top: 3px double #e5e5e5;
    left: auto;
    top: 50%;
    right: 0;
    transform: translateY(-50%); }
    .dark .fancy-title.title-double-border:before, .dark .fancy-title.title-border:before, .dark .fancy-title.title-border-color:before {
      border-top-color: rgba(255, 255, 255, 0.15); }
  .fancy-title.title-border:before {
    border-top: 1px solid #eeeeee; }
  .fancy-title.title-border-color:before {
    border-top: 1px solid rgba(26, 188, 156, 0.6); }

/* Fancy Title - Center Align
-----------------------------------------------------------------*/
.title-center,
.title-right {
  text-align: center; }
  .title-center h1, .title-center .h1,
  .title-center h2,
  .title-center .h2,
  .title-center h3,
  .title-center .h3,
  .title-right h1,
  .title-right .h1,
  .title-right h2,
  .title-right .h2,
  .title-right h3,
  .title-right .h3 {
    padding: 0 15px; }
  .title-center h4, .title-center .h4,
  .title-center h5,
  .title-center .h5,
  .title-center h6,
  .title-center .h6,
  .title-right h4,
  .title-right .h4,
  .title-right h5,
  .title-right .h5,
  .title-right h6,
  .title-right .h6 {
    padding: 0 10px; }

/* Fancy Title - Right Align
-----------------------------------------------------------------*/
.title-right {
  text-align: right; }

/* Fancy Title - Bottom Short Border
-----------------------------------------------------------------*/
.fancy-title.title-bottom-border h1, .fancy-title.title-bottom-border .h1,
.fancy-title.title-bottom-border h2,
.fancy-title.title-bottom-border .h2,
.fancy-title.title-bottom-border h3,
.fancy-title.title-bottom-border .h3,
.fancy-title.title-bottom-border h4,
.fancy-title.title-bottom-border .h4,
.fancy-title.title-bottom-border h5,
.fancy-title.title-bottom-border .h5,
.fancy-title.title-bottom-border h6,
.fancy-title.title-bottom-border .h6 {
  display: block;
  background: transparent;
  padding: 0 0 10px;
  border-bottom: 3px solid #1abc9c; }

.fancy-title.title-dotted-border {
  background: url("image/icons/dotted.png") repeat-x center; }

/* Sub Heading
-----------------------------------------------------------------*/
.before-heading {
  display: block;
  margin: 0 0 5px;
  font: normal 400 16px "Crete Round", serif;
  color: #999999; }

/*-----------------------------------------------------------------------------------

	Shortcodes: misc.scss

-----------------------------------------------------------------------------------*/
@media (max-width: 1199.98px) {
  canvas {
    max-width: 100%;
    height: auto !important; } }

/* Infinity Scroll - Message Style
-----------------------------------------------------------------*/
#infscr-loading,
#portfolio-ajax-loader {
  position: fixed;
  z-index: 20;
  top: 50%;
  left: 50%;
  width: 48px;
  height: 48px;
  margin: -24px 0 0 -24px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  line-height: 48px;
  font-size: 24px;
  color: #FFF;
  text-align: center; }

#infscr-loading img,
#portfolio-ajax-loader img {
  display: none;
  width: 24px;
  height: 24px;
  margin: 12px; }

#portfolio-ajax-loader {
  display: none; }
  #portfolio-ajax-loader img {
    display: block; }

.page-load-status {
  position: relative;
  display: none;
  padding: 30px 0; }
  .page-load-status .css3-spinner {
    position: absolute;
    z-index: auto;
    background-color: transparent !important; }
  .page-load-status .css3-spinner-ball-pulse-sync > div {
    background-color: #333; }

/* ----------------------------------------------------------------
	Forms
-----------------------------------------------------------------*/
.sm-form-control {
  display: block;
  width: 100%;
  min-height: 38px;
  padding: 8px 14px;
  font-size: 15px;
  line-height: 1.42;
  color: #333333;
  background-color: white;
  background-image: none;
  border: 2px solid #dddddd;
  border-radius: 0 !important;
  -webkit-transition: border-color ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s; }
  .sm-form-control:focus {
    border-color: #aaaaaa;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .sm-form-control::-webkit-input-placeholder {
    color: #999999;
    opacity: 1; }
  .sm-form-control::-moz-placeholder {
    color: #999999;
    opacity: 1; }
  .sm-form-control:-ms-input-placeholder {
    color: #999999;
    opacity: 1; }

.sm-form-control[disabled],
.sm-form-control[readonly],
fieldset[disabled] .sm-form-control {
  cursor: not-allowed;
  background-color: #eeeeee;
  opacity: 1; }

.form-control {
  border-radius: 3px; }

.form-control:active,
.form-control:focus {
  border-color: #999999;
  box-shadow: none; }

label {
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #333333;
  margin-bottom: 10px;
  cursor: pointer; }
  .dark label {
    color: #bbbbbb; }
  label.label-muted {
    color: #999999;
    font-weight: normal;
    margin-right: 5px; }
    .dark label.label-muted {
      color: #777777; }

form .col_full,
form .col_half,
form .col_one_third,
form .col_two_third,
form .col_three_fourth,
form .col_one_fourth,
form .col_one_fifth,
form .col_two_fifth,
form .col_three_fifth,
form .col_four_fifth,
form .col_one_sixth,
form .col_five_sixth {
  margin-bottom: 25px; }
  @media (max-width: 767.98px) {
    form .col_full,
    form .col_half,
    form .col_one_third,
    form .col_two_third,
    form .col_three_fourth,
    form .col_one_fourth,
    form .col_one_fifth,
    form .col_two_fifth,
    form .col_three_fifth,
    form .col_four_fifth,
    form .col_one_sixth,
    form .col_five_sixth {
      margin-bottom: 25px !important; } }

/* ----------------------------------------------------------------
	Contact Form
-----------------------------------------------------------------*/
#contact-form-overlay,
#contact-form-overlay-mini {
  position: relative;
  max-width: 800px;
  background-color: white;
  padding: 40px;
  margin: 0 auto;
  z-index: 10;
  border-radius: 4px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15); }
  .dark #contact-form-overlay, .dark
  #contact-form-overlay-mini {
    background-color: #383838; }

#contact-form-overlay-mini {
  float: right;
  width: 380px;
  margin: 0; }
  @media (max-width: 991.98px) {
    #contact-form-overlay-mini {
      float: none;
      width: auto;
      max-width: 380px;
      margin: 0 auto; } }

label.error {
  margin-top: 5px;
  color: #dc3545;
  font-weight: 400; }

label.error {
  display: none !important; }

.show-error-msg + label.error {
  display: block !important; }

.form-control.error,
.sm-form-control.error {
  border-color: #dc3545; }

/* ----------------------------------------------------------------
	Google Maps
-----------------------------------------------------------------*/
#google-map {
  position: relative;
  width: 100%;
  height: 450px; }

.gmap img {
  max-width: none !important; }

.gm-style .gm-style-iw h3 span, .gm-style .gm-style-iw .h3 span {
  font-size: inherit;
  font-family: inherit; }

#map-overlay {
  position: relative;
  padding: 100px 0; }
  #map-overlay #google-map {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0; }

/* ----------------------------------------------------------------
	Google Custom Search
-----------------------------------------------------------------*/
#content .cse .gsc-control-cse,
#content .gsc-control-cse,
#content .gsc-above-wrapper-area,
#content .gsc-adBlock,
#content .gsc-thumbnail-inside,
#content .gsc-url-top,
#content .gsc-table-result,
#content .gsc-webResult,
#content .gsc-result {
  padding: 0 !important; }

#content .gsc-selected-option-container {
  width: auto !important; }

#content .gsc-result-info {
  padding-left: 0 !important; }

#content .gsc-above-wrapper-area-container,
#content .gsc-table-result {
  margin-bottom: 10px; }

#content .gcsc-branding {
  display: none; }

#content .gsc-results,
#content .gsc-webResult {
  width: 100% !important; }

#content .gs-no-results-result .gs-snippet,
#content .gs-error-result .gs-snippet {
  margin: 0 !important; }

/* ----------------------------------------------------------------
	Quotes & Blockquotes
-----------------------------------------------------------------*/
blockquote p {
  margin-bottom: 15px; }

blockquote.pull-left {
  max-width: 300px;
  margin: 5px 20px 10px 0;
  padding-right: 0; }

blockquote.pull-right {
  max-width: 300px;
  margin: 5px 0 10px 20px;
  padding-left: 0; }

.quote {
  border: none !important;
  position: relative; }
  .quote p {
    position: relative; }
  .quote:before {
    font-family: 'font-icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    content: "\e7ad";
    position: absolute;
    width: 48px;
    height: 48px;
    line-height: 48px;
    font-size: 42px;
    top: 0;
    left: 0;
    color: #eeeeee; }
    .dark .quote:before {
      color: rgba(255, 255, 255, 0.1); }
  .quote.blockquote-reverse:before {
    content: "\e7ae";
    left: auto;
    right: 0; }

/* ----------------------------------------------------------------
	Dropcaps & Highlights
-----------------------------------------------------------------*/
.dropcap {
  float: left;
  font-size: 42px;
  line-height: 1;
  margin: 0 5px 0 0;
  text-transform: uppercase; }

.highlight {
  padding: 2px 5px;
  background-color: #444;
  color: #FFF;
  border-radius: 2px; }
  .dark .highlight {
    background-color: rgba(0, 0, 0, 0.2); }

/* ----------------------------------------------------------------
	Magazine Specific Classes
-----------------------------------------------------------------*/
.top-advert {
  float: right;
  padding: 5px;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee; }
  .dark .top-advert {
    border-left-color: rgba(255, 255, 255, 0.1);
    border-right-color: rgba(255, 255, 255, 0.1); }
  .top-advert a,
  .top-advert img {
    display: block; }

.bnews-title {
  display: block;
  float: left;
  margin-top: 2px;
  padding-top: .3em;
  text-transform: uppercase; }
  @media (max-width: 991.98px) {
    .bnews-title {
      float: none;
      display: inline-block; } }

.bnews-slider {
  float: left;
  width: 970px;
  margin-left: 20px;
  min-height: 0; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .bnews-slider {
      width: 817px; } }
  @media (max-width: 991.98px) {
    .bnews-slider {
      float: none;
      margin: 10px 0 0;
      width: 100%; } }

/* ----------------------------------------------------------------
	Text Rotater
-----------------------------------------------------------------*/
.text-rotater > .t-rotate > .animated {
  display: inline-block; }

/* ----------------------------------------------------------------
	Go To Top
-----------------------------------------------------------------*/
#gotoTop {
  display: none;
  z-index: 299;
  position: fixed;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 20px;
  line-height: 38px;
  text-align: center;
  color: white;
  top: auto;
  left: auto;
  cursor: pointer;
  right: 30px;
  bottom: 50px;
  border-radius: 2px; }
  #gotoTop:hover {
    background-color: #1abc9c; }
  @media (max-width: 767.98px) {
    #gotoTop {
      display: none !important; } }

body:not(.device-touch) #gotoTop {
  -webkit-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear; }

.stretched #gotoTop {
  right: 30px;
  bottom: 30px; }

/* ----------------------------------------------------------------
	Error 404
-----------------------------------------------------------------*/
.error404 {
  display: block;
  font-size: 216px;
  font-weight: 700;
  color: #dddddd;
  line-height: 1.35;
  letter-spacing: 4px; }
  @media (max-width: 991.98px) {
    .error404 {
      font-size: 180px; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .error404 {
      font-size: 160px; } }
  @media (max-width: 575.98px) {
    .error404 {
      font-size: 120px; } }
  .dark .error404 {
    color: rgba(255, 255, 255, 0.15); }

.error404-wrap .container {
  z-index: 7; }

.error404-wrap .error404 {
  line-height: 0.9;
  margin-bottom: 40px;
  font-weight: bold;
  font-size: 244px;
  color: #FFF !important;
  opacity: 0.2;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4); }

.error404-wrap .heading-block h4, .error404-wrap .heading-block .h4 {
  font-weight: 300;
  margin-bottom: 8px; }

.error404-wrap .heading-block span {
  font-size: 17px; }

.error404-wrap form {
  max-width: 500px; }

.dark.error404-wrap .form-control::-webkit-input-placeholder {
  color: #888888; }

.dark.error404-wrap .form-control::-moz-placeholder {
  color: #888888; }

.dark.error404-wrap .form-control:-ms-input-placeholder {
  color: #888888; }

/* ----------------------------------------------------------------
	Landing Pages
-----------------------------------------------------------------*/
.landing-wide-form {
  background: rgba(0, 0, 0, 0.3);
  padding: 30px;
  border-radius: 3px; }

.landing-form-overlay {
  position: absolute;
  z-index: 10;
  top: auto;
  left: auto;
  right: 0;
  bottom: -154px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 3px 3px 0 0; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .landing-form-overlay {
      bottom: -142px; } }
  @media (max-width: 991.98px) {
    .landing-form-overlay {
      position: absolute;
      width: 100%;
      max-width: 400px;
      right: auto;
      left: 50%;
      margin-left: -200px;
      bottom: 0; } }
  @media (max-width: 575.98px) {
    .landing-form-overlay {
      margin-left: -155px; } }

.landing-video {
  z-index: 1;
  width: 560px;
  height: 315px;
  margin: 22px 0 0 95px;
  overflow: hidden; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .landing-video {
      width: 465px;
      height: 262px;
      margin: 19px 0 0 75px; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .landing-video {
      width: 352px;
      height: 199px;
      margin: 14px 0 0 60px; } }
  @media (max-width: 767.98px) {
    .landing-video {
      position: relative;
      top: 14px;
      width: 76%;
      height: auto;
      margin: 19px auto 0; } }

.landing-promo h3, .landing-promo .h3 {
  font-size: 26px; }

.landing-promo > .container > span {
  font-size: 17px; }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .col_one_third .landing-wide-form {
    padding: 36px 30px; }
    .col_one_third .landing-wide-form .heading-block h2, .col_one_third .landing-wide-form .heading-block .h2 {
      font-size: 32px; }
    .col_one_third .landing-wide-form .heading-block span {
      font-size: 17px; } }

/* ----------------------------------------------------------------
	Preloaders
-----------------------------------------------------------------*/
.preloader,
.preloader2,
.form-process {
  display: block;
  width: 100%;
  height: 100%;
  background-color: white;
  background-image: url("image/preloader.gif");
  background-repeat: no-repeat;
  background-position: center center; }
  .dark .preloader, .dark
  .preloader2, .dark
  .form-process {
    background-image: url("image/preloader-dark.gif"); }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .preloader,
    .preloader2,
    .form-process {
      background-image: url("image/preloader@2x.gif");
      background-size: 24px 24px; }
      .dark .preloader, .dark
      .preloader2, .dark
      .form-process {
        background-image: url("image/preloader-dark@2x.gif"); } }

.preloader2 {
  background-color: transparent; }

.form-process {
  display: none;
  position: absolute;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.7); }

.dark .preloader {
  background-color: rgba(0, 0, 0, 0.2); }

.dark .form-process {
  background-color: transparent; }

/* ----------------------------------------------------------------
	Toastr Notifications
-----------------------------------------------------------------*/
.toast-title {
  font-weight: bold; }

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word; }
  .toast-message a,
  .toast-message label {
    color: #ffffff; }
  .toast-message a:hover {
    color: #cccccc;
    text-decoration: none; }

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
  line-height: 1; }
  .toast-close-button:hover, .toast-close-button:focus {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.4;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
    filter: alpha(opacity=40); }

.rtl .toast-close-button {
  left: -0.3em;
  float: left;
  right: 0.3em; }

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.toast-top-full-width {
  top: 12px;
  right: 0;
  width: 100%; }

.toast-bottom-full-width {
  bottom: 12px;
  right: 0;
  width: 100%; }

.toast-top-left {
  top: 12px;
  left: 12px; }

.toast-top-right {
  top: 12px;
  right: 12px; }

.toast-bottom-right {
  right: 12px;
  bottom: 12px; }

.toast-bottom-left {
  bottom: 12px;
  left: 12px; }

#toast-container {
  position: fixed;
  z-index: 999999;
  pointer-events: none;
  /*overrides*/ }
  #toast-container * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  #toast-container > div {
    position: relative;
    pointer-events: auto;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 20px 25px;
    width: 300px;
    border-radius: 3px;
    background-repeat: no-repeat;
    -moz-box-shadow: 0 0 12px #999999;
    -webkit-box-shadow: 0 0 12px #999999;
    box-shadow: 0 0 12px #999999;
    color: #FFFFFF;
    opacity: 0.8;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    filter: alpha(opacity=80); }
  #toast-container > div.rtl {
    direction: rtl; }
  #toast-container > div:hover {
    -moz-box-shadow: 0 0 12px #000000;
    -webkit-box-shadow: 0 0 12px #000000;
    box-shadow: 0 0 12px #000000;
    opacity: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    cursor: pointer; }
  #toast-container.toast-top-center > div,
  #toast-container.toast-bottom-center > div {
    width: 300px;
    margin-left: auto;
    margin-right: auto; }
  #toast-container.toast-top-full-width > div,
  #toast-container.toast-bottom-full-width > div {
    width: 96%;
    margin-left: auto;
    margin-right: auto; }

.toast {
  background-color: #030303; }

.toast-success {
  background-color: #28a745; }

.toast-error {
  background-color: #dc3545; }

.toast-info {
  background-color: #17a2b8; }

.toast-warning {
  background-color: #ffc107; }

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: black;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40); }

/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 10px 15px;
    width: 11em; }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em; }
  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em; } }

@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 10px 15px;
    width: 18em; }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em; }
  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em; } }

@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 20px;
    width: 25em; } }

/* ----------------------------------------------------------------
	Wedding
-----------------------------------------------------------------*/
.wedding-head {
  position: relative;
  line-height: 1;
  font-size: 80px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15); }
  @media (max-width: 991.98px) {
    .wedding-head {
      font-size: 60px; } }
  @media (max-width: 767.98px) {
    .wedding-head {
      font-size: 48px; } }
  .wedding-head .first-name,
  .wedding-head .last-name,
  .wedding-head .and {
    display: inline-block;
    margin-right: 15px;
    font-weight: bold;
    text-align: right;
    text-transform: uppercase;
    font-family: "Raleway", sans-serif;
    letter-spacing: 2px; }
    @media (max-width: 767.98px) {
      .wedding-head .first-name,
      .wedding-head .last-name,
      .wedding-head .and {
        display: block;
        margin: 0 0 15px;
        text-align: center; } }
  .wedding-head .last-name {
    margin: 0 0 0 15px;
    text-align: left; }
  .wedding-head .first-name span,
  .wedding-head .last-name span {
    display: block;
    margin-top: 10px;
    font-size: 56px;
    font-weight: 400;
    font-style: italic;
    font-family: "Crete Round", serif;
    text-transform: none; }
    @media (max-width: 991.98px) {
      .wedding-head .first-name span,
      .wedding-head .last-name span {
        font-size: 40px; } }
    @media (max-width: 767.98px) {
      .wedding-head .first-name span,
      .wedding-head .last-name span {
        font-size: 36px; } }
  .wedding-head .and {
    margin: 0;
    font-size: 200px;
    font-family: 'Times New Roman', serif; }
    @media (max-width: 991.98px) {
      .wedding-head .and {
        font-size: 144px; } }
    @media (max-width: 767.98px) {
      .wedding-head .and {
        font-size: 120px; } }

/*-----------------------------------------------------------------------------------

	Shortcodes: overlays.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Overlays
-----------------------------------------------------------------*/
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.overlay .overlay-wrap,
.text-overlay {
  opacity: 0;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); }
  body:not(.device-touch) .overlay .overlay-wrap, body:not(.device-touch)
  .text-overlay {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }

.overlay .overlay-wrap i {
  position: absolute;
  font-size: 40px;
  line-height: 1;
  width: 40px;
  height: 40px;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.8); }

.overlay:hover .overlay-wrap,
.overlay:hover .text-overlay {
  opacity: 1; }

.text-overlay {
  position: absolute;
  opacity: 0.85;
  top: auto;
  bottom: 0;
  height: auto;
  padding: 40px 15px 15px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  background-color: transparent;
  background-image: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
  /* IE6-9 */
  backface-visibility: hidden; }

.text-overlay-title h2, .text-overlay-title .h2,
.text-overlay-title h3,
.text-overlay-title .h3,
.text-overlay-title h4,
.text-overlay-title .h4 {
  margin: 0;
  color: whitesmoke; }

.text-overlay-title h2, .text-overlay-title .h2 {
  font-size: 28px; }

.text-overlay-title h3, .text-overlay-title .h3 {
  font-size: 17px;
  font-weight: 600; }

.text-overlay-title h2, .text-overlay-title .h2,
.text-overlay-title h3,
.text-overlay-title .h3,
.text-overlay-title h4,
.text-overlay-title .h4 {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.text-overlay-meta {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #cccccc;
  font-size: 13px; }
  .text-overlay-meta span i {
    position: relative;
    top: 1px;
    font-size: 14px; }

/* News Carousel Overlays
-----------------------------------------------------------------*/
.news-carousel .text-overlay {
  padding: 40px 30px 25px; }

.news-carousel .text-overlay-title {
  margin: 15px 0 10px; }
  .news-carousel .text-overlay-title h2, .news-carousel .text-overlay-title .h2,
  .news-carousel .text-overlay-title h3,
  .news-carousel .text-overlay-title .h3,
  .news-carousel .text-overlay-title h4,
  .news-carousel .text-overlay-title .h4 {
    line-height: 1.2;
    text-overflow: none;
    white-space: normal; }

.news-carousel .text-overlay-meta {
  font-size: 14px; }

.news-carousel .rounded-skill {
  position: absolute;
  margin: 0 !important;
  top: 30px;
  right: 30px;
  color: #FFF;
  font-weight: 700; }

@media (max-width: 767.98px) {
  .news-carousel .overlay {
    display: none; } }

/*-----------------------------------------------------------------------------------

	Shortcodes: pricing.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Pricing Boxes
-----------------------------------------------------------------*/
.pricing {
  position: relative; }

.pricing [class^=col-] {
  padding: 0;
  margin-top: 20px; }

@media (max-width: 991.98px) {
  .pricing [class^=col-md-] .pricing-box.best-price {
    margin-top: 0; } }

/* Pricing Boxes
-----------------------------------------------------------------*/
.pricing-box {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.075);
  border-radius: 3px;
  text-align: center;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  background-color: whitesmoke; }
  .dark .pricing-box {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); }

/* Pricing Boxes - Title
-----------------------------------------------------------------*/
.pricing-title {
  padding: 15px 0;
  background-color: #f9f9f9;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  text-shadow: 1px 1px 1px white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
  .dark .pricing-title {
    background-color: rgba(255, 255, 255, 0.03);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
    border-bottom-color: rgba(255, 255, 255, 0.1); }
  .pricing-title h3, .pricing-title .h3 {
    margin: 0;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    color: #555555; }
    .dark .pricing-title h3, .dark .pricing-title .h3 {
      color: #dddddd; }
  .pricing-title span {
    display: block;
    color: #999999;
    font-weight: 300;
    font-size: 14px;
    margin-top: 3px;
    text-transform: uppercase; }

/* Pricing Boxes - Price
-----------------------------------------------------------------*/
.pricing-price {
  position: relative;
  padding: 25px 0;
  color: #333333;
  font-weight: 300;
  font-size: 64px;
  line-height: 1; }
  .dark .pricing-price {
    color: #eeeeee; }
  .pricing-price::after {
    content: '';
    position: absolute;
    width: 100px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    top: auto;
    bottom: 0;
    left: 50%;
    margin-left: -50px; }
    .dark .pricing-price::after {
      border-top-color: rgba(255, 255, 255, 0.1); }
  .pricing-price span {
    display: inline-block;
    vertical-align: top;
    margin: 7px 3px 0 0;
    font-size: 28px;
    font-weight: normal; }
    .pricing-price span.price-tenure {
      vertical-align: baseline;
      font-size: 16px;
      font-weight: normal;
      color: #999999;
      margin: 0 0 0 3px; }

/* Pricing Boxes - Features
-----------------------------------------------------------------*/
.pricing-features {
  border-bottom: 1px solid rgba(0, 0, 0, 0.075); }
  .dark .pricing-features {
    border-bottom-color: rgba(255, 255, 255, 0.1); }
  .pricing-features ul {
    margin: 0;
    padding: 15px 0;
    list-style: none; }
  .pricing-features li {
    padding: 6px 0;
    font-size: 14px; }

/* Pricing Boxes - Action
-----------------------------------------------------------------*/
.pricing-action {
  padding: 15px 30px; }

/* Pricing Boxes - Best Price
-----------------------------------------------------------------*/
.pricing-box {
  /* Pricing Boxes - Minimal
	-----------------------------------------------------------------*/ }
  .pricing-box.best-price {
    background-color: white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    margin: -20px -1px 0 -1px; }
    @media (max-width: 767.98px) {
      .pricing-box.best-price {
        margin: 0; } }
    .dark .pricing-box.best-price {
      background-color: transparent;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); }
    .pricing-box.best-price .pricing-title {
      background-color: white;
      padding: 20px 0; }
      .dark .pricing-box.best-price .pricing-title {
        background-color: transparent; }
      .pricing-box.best-price .pricing-title h3, .pricing-box.best-price .pricing-title .h3 {
        font-size: 22px; }
    .pricing-box.best-price .pricing-price {
      color: #1abc9c;
      font-size: 72px; }
  .pricing-box.pricing-minimal .pricing-price {
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.075); }
    .dark .pricing-box.pricing-minimal .pricing-price {
      background-color: transparent;
      border-bottom-color: rgba(255, 255, 255, 0.1); }
    .pricing-box.pricing-minimal .pricing-price::after {
      display: none; }

/* Pricing Boxes - 5 Columns
-----------------------------------------------------------------*/
@media (max-width: 991.98px) {
  .pricing.pricing-5 {
    justify-content: center; } }

.pricing.pricing-5 .pricing-box {
  float: left;
  width: 20%;
  margin-top: 20px; }
  @media (max-width: 767.98px) {
    .pricing.pricing-5 .pricing-box {
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (max-width: 575.98px) {
    .pricing.pricing-5 .pricing-box {
      flex: 0 0 100%;
      max-width: 100%; } }
  .pricing.pricing-5 .pricing-box.best-price {
    margin-top: 0; }
    @media (max-width: 991.98px) {
      .pricing.pricing-5 .pricing-box.best-price {
        margin-top: 20px; }
        .pricing.pricing-5 .pricing-box.best-price .pricing-title {
          padding: 15px 0; } }
  .pricing.pricing-5 .pricing-box:nth-child(5) {
    margin-left: -1px; }

@media (max-width: 991.98px) {
  .pricing.pricing-5 .pricing-price {
    font-size: 48px; }
    .pricing.pricing-5 .pricing-price span {
      font-size: 20px; }
      .pricing.pricing-5 .pricing-price span.price-tenure {
        font-size: 12px; } }

.pricing.pricing-5 .pricing-title h3, .pricing.pricing-5 .pricing-title .h3 {
  font-size: 18px; }
  @media (max-width: 991.98px) {
    .pricing.pricing-5 .pricing-title h3, .pricing.pricing-5 .pricing-title .h3 {
      font-size: 14px; } }

@media (max-width: 991.98px) {
  .pricing.pricing-5 .pricing-title span {
    font-size: 12px; } }

.pricing.pricing-5 .best-price .pricing-title h3, .pricing.pricing-5 .best-price .pricing-title .h3 {
  font-size: 20px; }
  @media (max-width: 991.98px) {
    .pricing.pricing-5 .best-price .pricing-title h3, .pricing.pricing-5 .best-price .pricing-title .h3 {
      font-size: 14px; } }

/* Pricing Boxes - Extended
-----------------------------------------------------------------*/
.pricing-box.pricing-extended {
  background-color: white;
  text-align: left; }
  .dark .pricing-box.pricing-extended {
    background-color: transparent; }
  .pricing-box.pricing-extended .pricing-desc {
    float: left;
    width: 75%;
    padding: 25px; }
    @media (max-width: 991.98px) {
      .pricing-box.pricing-extended .pricing-desc {
        position: relative;
        float: none;
        width: auto;
        padding: 25px; } }
  .pricing-box.pricing-extended .pricing-title {
    background-color: transparent;
    padding-top: 0;
    text-align: left; }
  .pricing-box.pricing-extended .pricing-features {
    border: 0; }
    .pricing-box.pricing-extended .pricing-features ul {
      padding: 15px 0 0 0;
      display: flex;
      flex-wrap: wrap;
      margin-right: 0;
      margin-left: 0; }
    .pricing-box.pricing-extended .pricing-features li {
      padding: 6px 0;
      font-size: 15px;
      flex: 0 0 50%;
      max-width: 50%; }
      @media (max-width: 767.98px) {
        .pricing-box.pricing-extended .pricing-features li {
          flex: 0 0 100%;
          max-width: 100%;
          float: none; } }
      .pricing-box.pricing-extended .pricing-features li i {
        position: relative;
        top: 1px;
        width: 16px;
        margin-right: 2px; }
  .pricing-box.pricing-extended .pricing-action-area {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    width: 25%;
    height: 100%;
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    background-color: #f9f9f9;
    padding: 25px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
    flex-wrap: wrap;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    flex-direction: column;
    justify-content: center !important; }
    @media (max-width: 991.98px) {
      .pricing-box.pricing-extended .pricing-action-area {
        display: block;
        position: relative;
        float: none;
        width: auto;
        height: auto;
        padding: 25px;
        border-left: 0;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 0 0 3px 3px; }
        .dark .pricing-box.pricing-extended .pricing-action-area {
          border-top-color: rgba(255, 255, 255, 0.15); } }
    .dark .pricing-box.pricing-extended .pricing-action-area {
      border-left-color: rgba(255, 255, 255, 0.15);
      background-color: rgba(0, 0, 0, 0.2); }
    .pricing-box.pricing-extended .pricing-action-area .pricing-price,
    .pricing-box.pricing-extended .pricing-action-area .pricing-action {
      padding: 0; }
      @media (min-width: 992px) and (max-width: 1199.98px) {
        .pricing-box.pricing-extended .pricing-action-area .pricing-price a,
        .pricing-box.pricing-extended .pricing-action-area .pricing-action a {
          padding: 0 22px;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          border-radius: 3px; } }
    .pricing-box.pricing-extended .pricing-action-area .pricing-price {
      padding-bottom: 20px; }
      .pricing-box.pricing-extended .pricing-action-area .pricing-price::after {
        display: none; }
      .pricing-box.pricing-extended .pricing-action-area .pricing-price span.price-tenure {
        display: block;
        margin: 10px 0 0 0;
        font-weight: 300;
        text-transform: uppercase;
        font-size: 14px; }
  .pricing-box.pricing-extended .pricing-meta {
    color: #999999;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-bottom: 10px; }
    .dark .pricing-box.pricing-extended .pricing-meta {
      color: #999999; }

.table-comparison,
.table-comparison th {
  text-align: center; }

.table-comparison th:first-child,
.table-comparison td:first-child {
  text-align: left;
  font-weight: bold; }

/*-----------------------------------------------------------------------------------

	Shortcodes: process-steps.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Process Steps
-----------------------------------------------------------------*/
.process-steps {
  margin: 0 0 40px;
  list-style: none; }
  .process-steps li {
    float: left;
    position: relative;
    width: 25%;
    text-align: center;
    overflow: hidden; }
    @media (max-width: 767.98px) {
      .process-steps li {
        float: none;
        width: auto !important;
        margin-top: 20px; }
        .process-steps li:first-child {
          margin-top: 0; }
        .process-steps li:before, .process-steps li:after {
          display: none; }
        .process-steps li h5, .process-steps li .h5 {
          margin: 10px 0 0 0; } }
    .process-steps li .i-circled {
      margin-bottom: 0 !important; }
    .process-steps li .i-bordered {
      background-color: white; }
      .dark .process-steps li .i-bordered {
        background-color: #494949; }
    .process-steps li h5, .process-steps li .h5 {
      margin: 15px 0 0 0;
      font-size: 16px;
      font-weight: 300; }
    .process-steps li.active h5, .process-steps li.active .h5, .process-steps li.ui-tabs-active h5, .process-steps li.ui-tabs-active .h5 {
      font-weight: 700;
      color: #1abc9c; }
    .process-steps li.active a, .process-steps li.ui-tabs-active a {
      background-color: #1abc9c !important;
      color: #FFF;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
      border-color: #1abc9c !important; }
    .process-steps li:before, .process-steps li:after {
      content: '';
      position: absolute;
      top: 30px;
      left: 0;
      margin-left: -26px;
      width: 50%;
      border-top: 1px dashed #dddddd; }
      .dark .process-steps li:before, .dark .process-steps li:after {
        border-top: rgba(255, 255, 255, 0.15); }
    .process-steps li:after {
      left: auto;
      right: 0;
      margin: 0 -26px 0 0; }
    .process-steps li:first-child:before, .process-steps li:last-child:after {
      display: none; }
  .process-steps.process-2 li {
    width: 50%; }
  .process-steps.process-3 li {
    width: 33.33%; }
  .process-steps.process-5 li {
    width: 20%; }

/*-----------------------------------------------------------------------------------

	Shortcodes: promo.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Promo Boxes
-----------------------------------------------------------------*/
.promo {
  position: relative;
  padding: 30px 200px 30px 0; }
  @media (max-width: 991.98px) {
    .promo:not(.promo-full) {
      padding: 30px 20px !important; } }
  .promo h3, .promo .h3 {
    font-weight: bold;
    margin-bottom: 0;
    font-size: 24px;
    color: #444444; }
    @media (max-width: 1199.98px) {
      .promo h3, .promo .h3 {
        font-size: 20px; } }
  .promo a.button {
    position: absolute;
    top: 50%;
    margin: 0;
    margin-top: -18px;
    left: auto;
    right: 0; }
    @media (max-width: 991.98px) {
      .promo a.button {
        position: relative !important;
        top: 0 !important;
        margin: 20px 0 0 !important;
        left: 0 !important;
        right: 0; } }
  .promo > span,
  .promo > .container > span {
    display: block;
    color: #444444;
    font-weight: 300;
    font-size: 16px;
    margin-top: 6px; }
    @media (max-width: 1199.98px) {
      .promo > span,
      .promo > .container > span {
        font-size: 15px; } }
    .dark .promo > span, .dark
    .promo > .container > span {
      color: rgba(255, 255, 255, 0.8); }
  .promo a.button-mini {
    margin-top: -12px; }
  .promo a.button-small {
    margin-top: -15px; }
  .promo a.button-large {
    margin-top: -22px; }
  .promo a.button-xlarge {
    margin-top: -25px; }
  .promo a.button-desc {
    margin-top: -40px; }

/* Promo Uppercase
-----------------------------------------------------------------*/
.promo-uppercase {
  text-transform: uppercase; }
  .promo-uppercase h3, .promo-uppercase .h3 {
    font-size: 22px; }
    @media (max-width: 1199.98px) {
      .promo-uppercase h3, .promo-uppercase .h3 {
        font-size: 19px; } }
  .promo-uppercase > span,
  .promo-uppercase > .container > span {
    font-size: 15px; }
    @media (max-width: 1199.98px) {
      .promo-uppercase > span,
      .promo-uppercase > .container > span {
        font-size: 14px; } }

/* Promo - with Border
-----------------------------------------------------------------*/
.promo.promo-border {
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  padding-left: 30px; }
  .dark .promo.promo-border {
    border-color: rgba(255, 255, 255, 0.08); }
  .promo.promo-border a.button {
    right: 30px; }

/* Promo - Light
-----------------------------------------------------------------*/
.promo.promo-light {
  background-color: whitesmoke;
  border-radius: 3px;
  padding-left: 30px; }
  .dark .promo.promo-light {
    background-color: rgba(255, 255, 255, 0.1); }
  .promo.promo-light a.button {
    right: 30px; }

/* Promo - Dark
-----------------------------------------------------------------*/
.promo.promo-dark {
  border: 0 !important;
  background-color: #333333;
  padding: 30px 200px 30px 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px; }
  .dark .promo.promo-dark:not(.promo-flat) {
    background-color: rgba(0, 0, 0, 0.3); }
  .promo.promo-dark h3, .promo.promo-dark .h3 {
    color: white; }
    @media (max-width: 991.98px) {
      .promo.promo-dark h3, .promo.promo-dark .h3 {
        line-height: 1.6; } }
  .promo.promo-dark a.button {
    right: 30px; }
  .promo.promo-dark > h3 span, .promo.promo-dark > .h3 span,
  .promo.promo-dark > .container > h3 span,
  .promo.promo-dark > .container > .h3 span {
    padding: 2px 8px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.15);
    color: white;
    font-weight: 400; }
  .promo.promo-dark > span,
  .promo.promo-dark > .container > span {
    color: #cccccc;
    margin-top: 8px; }

/* Promo - Flat
-----------------------------------------------------------------*/
.promo.promo-flat {
  background-color: #1abc9c; }
  .promo.promo-flat a.button:not(.button-border) {
    background-color: rgba(0, 0, 0, 0.2);
    color: white; }
  .promo.promo-flat a.button:hover {
    background-color: white;
    border-color: white;
    color: #444444;
    text-shadow: none; }
  .promo.promo-flat > span,
  .promo.promo-flat .promo.promo-flat > .container > span {
    color: rgba(255, 255, 255, 0.9); }

/* Promo - 100% Full Width
-----------------------------------------------------------------*/
.promo-full {
  border-radius: 3px !important;
  border-left: 0 !important;
  border-right: 0 !important;
  padding: 40px 0 !important; }
  .promo-full a.button {
    right: 15px !important; }
  .promo-full.promo-right a.button {
    right: auto !important;
    left: 15px !important; }

/* Promo - Parallax
-----------------------------------------------------------------*/
.promo.parallax {
  padding: 80px 0 !important; }

/* Promo - Right Align
-----------------------------------------------------------------*/
.promo.promo-right {
  text-align: right;
  padding: 30px 0 30px 200px; }
  .promo.promo-right a.button {
    left: 0;
    right: auto; }

/* Promo - with Border & Right Align
-----------------------------------------------------------------*/
.promo.promo-right.promo-border:not(.promo-mini), .promo.promo-dark.promo-right:not(.promo-mini) {
  padding-right: 30px; }

.promo.promo-right.promo-border a.button, .promo.promo-dark.promo-right a.button {
  left: 30px; }

/* Promo - Center Align
-----------------------------------------------------------------*/
.promo.promo-center {
  text-align: center;
  padding: 30px 20px; }
  .promo.promo-center a.button {
    position: relative;
    top: 0;
    margin: 20px 0 0;
    left: 0 !important;
    right: 0; }

/* Promo - Mini
-----------------------------------------------------------------*/
.promo.promo-mini {
  text-align: left;
  padding: 20px 25px; }
  .promo.promo-mini h3, .promo.promo-mini .h3 {
    font-size: 20px;
    line-height: 1.5; }
  .promo.promo-mini.promo-uppercase h3, .promo.promo-mini.promo-uppercase .h3 {
    font-size: 18px; }
  .promo.promo-mini.promo-dark h3, .promo.promo-mini.promo-dark .h3 {
    line-height: 1.7; }
  .promo.promo-mini a.button {
    position: relative;
    top: 0;
    margin: 20px 0 0;
    left: 0 !important;
    right: 0; }

/* Promo - Mini - Center & Right Align
-----------------------------------------------------------------*/
.promo.promo-mini.promo-center {
  text-align: center; }

.promo.promo-mini.promo-right {
  text-align: right; }

/*-----------------------------------------------------------------------------------

	Shortcodes: social-icons.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Social Icons
-----------------------------------------------------------------*/
.social-icon {
  margin: 0 5px 5px 0;
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 38px !important;
  color: #555555;
  text-shadow: none;
  border: 1px solid #aaa;
  border-radius: 3px;
  overflow: hidden; }
  .dark .social-icon {
    color: #dddddd !important;
    border-color: #dddddd; }

/* Social Icons - Rounded
-----------------------------------------------------------------*/
.si-rounded {
  border-radius: 50%; }

/* Social Icons - Borderless
-----------------------------------------------------------------*/
.si-borderless {
  border-color: transparent !important; }
  .dark #footer .si-borderless.social-icon,
  #footer.dark .si-borderless.social-icon {
    color: #555 !important; }

/* Social Icons - Dark
-----------------------------------------------------------------*/
.si-dark {
  background-color: #444444;
  color: #FFF !important;
  border-color: transparent; }
  .dark .si-dark {
    background-color: rgba(0, 0, 0, 0.2);
    border-color: transparent; }

/* Social Icons - Light
-----------------------------------------------------------------*/
.si-light {
  background-color: rgba(0, 0, 0, 0.05);
  color: #555555 !important;
  border-color: transparent; }
  .dark .si-light {
    background-color: rgba(255, 255, 255, 0.1);
    color: #eeeeee !important;
    border-color: transparent; }

/* Social Icons - Colored
-----------------------------------------------------------------*/
.si-colored {
  border-color: transparent !important; }
  .si-colored i {
    color: #FFF; }

/* Social Icons - Large
-----------------------------------------------------------------*/
.social-icon.si-large {
  margin: 0 10px 10px 0;
  width: 56px;
  height: 56px;
  font-size: 30px;
  line-height: 54px !important; }

/* Social Icons - Small
-----------------------------------------------------------------*/
.social-icon.si-small {
  width: 32px;
  height: 32px;
  font-size: 14px;
  line-height: 30px !important; }

.social-icon i {
  display: block;
  position: relative; }
  body:not(.device-touch) .social-icon i {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }

.social-icon i:last-child {
  color: #FFF !important; }
  .dark .social-icon i:last-child {
    color: #FFF !important; }

.social-icon:hover {
  color: #555555;
  border-color: transparent; }
  .dark .social-icon:hover {
    color: #FFF !important;
    border-color: transparent; }
  .social-icon:hover i:first-child {
    margin-top: -38px; }

.social-icon.si-large:hover i:first-child {
  margin-top: -54px; }

.social-icon.si-small:hover i:first-child {
  margin-top: -30px; }

.si-colored.social-icon {
  border-color: transparent; }

/* Social Icons - Sticky
-----------------------------------------------------------------*/
.si-sticky {
  position: fixed;
  top: 50%;
  left: 5px;
  width: 36px;
  z-index: 100;
  /* Social Icons - Sticky Right
	-----------------------------------------------------------------*/ }
  .si-sticky.si-sticky-right {
    left: auto;
    right: 8px; }

/* Social Icons - Share
-----------------------------------------------------------------*/
.si-share {
  position: relative;
  padding-left: 5px;
  border-top: 1px solid #EEE;
  border-bottom: 1px solid #EEE;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 575.98px) {
    .si-share {
      flex-direction: column; } }
  .dark .si-share {
    border-top-color: rgba(255, 255, 255, 0.1);
    border-bottom-color: rgba(255, 255, 255, 0.1); }
  .si-share span {
    line-height: 36px;
    font-weight: bold;
    font-size: 13px; }
  .si-share div {
    display: flex;
    justify-content: flex-end;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
    @media (max-width: 575.98px) {
      .si-share div {
        margin-top: 10px; } }
  .si-share .social-icon {
    margin: -1px 0;
    border-radius: 0;
    font-size: 15px; }

/* Social Icons - bg-colors
-----------------------------------------------------------------*/
:hover.si-facebook,
.si-colored.si-facebook {
  background-color: #3b5998 !important; }

:hover.si-whatsapp,
.si-colored.si-whatsapp {
  background-color: #25d366 !important; }

:hover.si-delicious,
.si-colored.si-delicious {
  background-color: #205cc0 !important; }

:hover.si-paypal,
.si-colored.si-paypal {
  background-color: #00588b !important; }

:hover.si-flattr,
.si-colored.si-flattr {
  background-color: #f67c1a !important; }

:hover.si-android,
.si-colored.si-android {
  background-color: #a4c639 !important; }

:hover.si-smashmag,
.si-colored.si-smashmag {
  background-color: #e53b2c !important; }

:hover.si-gplus,
.si-colored.si-gplus {
  background-color: #dd4b39 !important; }

:hover.si-wikipedia,
.si-colored.si-wikipedia {
  background-color: #333 !important; }

:hover.si-stumbleupon,
.si-colored.si-stumbleupon {
  background-color: #f74425 !important; }

:hover.si-foursquare,
.si-colored.si-foursquare {
  background-color: #25a0ca !important; }

:hover.si-call,
.si-colored.si-call {
  background-color: #444 !important; }

:hover.si-ninetyninedesigns,
.si-colored.si-ninetyninedesigns {
  background-color: #f26739 !important; }

:hover.si-forrst,
.si-colored.si-forrst {
  background-color: #5b9a68 !important; }

:hover.si-digg,
.si-colored.si-digg {
  background-color: #191919 !important; }

:hover.si-spotify,
.si-colored.si-spotify {
  background-color: #81b71a !important; }

:hover.si-reddit,
.si-colored.si-reddit {
  background-color: #c6c6c6 !important; }

:hover.si-blogger,
.si-colored.si-blogger {
  background-color: #fc4f08 !important; }

:hover.si-cc,
.si-colored.si-cc {
  background-color: #688527 !important; }

:hover.si-dribbble,
.si-colored.si-dribbble {
  background-color: #ea4c89 !important; }

:hover.si-evernote,
.si-colored.si-evernote {
  background-color: #5ba525 !important; }

:hover.si-flickr,
.si-colored.si-flickr {
  background-color: #ff0084 !important; }

:hover.si-google,
.si-colored.si-google {
  background-color: #dd4b39 !important; }

:hover.si-instapaper,
.si-colored.si-instapaper {
  background-color: #333 !important; }

:hover.si-klout,
.si-colored.si-klout {
  background-color: #ff5f52 !important; }

:hover.si-linkedin,
.si-colored.si-linkedin {
  background-color: #0e76a8 !important; }

:hover.si-vk,
.si-colored.si-vk {
  background-color: #2b587a !important; }

:hover.si-rss,
.si-colored.si-rss {
  background-color: #ee802f !important; }

:hover.si-skype,
.si-colored.si-skype {
  background-color: #00aff0 !important; }

:hover.si-twitter,
.si-colored.si-twitter {
  background-color: #00acee !important; }

:hover.si-youtube,
.si-colored.si-youtube {
  background-color: #c4302b !important; }

:hover.si-vimeo,
.si-colored.si-vimeo {
  background-color: #86c9ef !important; }

:hover.si-aim,
.si-colored.si-aim {
  background-color: #fcd20b !important; }

:hover.si-yahoo,
.si-colored.si-yahoo {
  background-color: #720e9e !important; }

:hover.si-email3,
.si-colored.si-email3 {
  background-color: #6567a5 !important; }

:hover.si-macstore,
.si-colored.si-macstore {
  background-color: #333333 !important; }

:hover.si-myspace,
.si-colored.si-myspace {
  background-color: #666666 !important; }

:hover.si-podcast,
.si-colored.si-podcast {
  background-color: #e4b21b !important; }

:hover.si-cloudapp,
.si-colored.si-cloudapp {
  background-color: #525557 !important; }

:hover.si-dropbox,
.si-colored.si-dropbox {
  background-color: #3d9ae8 !important; }

:hover.si-ebay,
.si-colored.si-ebay {
  background-color: #89c507 !important; }

:hover.si-github,
.si-colored.si-github {
  background-color: #171515 !important; }

:hover.si-googleplay,
.si-colored.si-googleplay {
  background-color: #dd4b39 !important; }

:hover.si-itunes,
.si-colored.si-itunes {
  background-color: #222 !important; }

:hover.si-plurk,
.si-colored.si-plurk {
  background-color: #cf5a00 !important; }

:hover.si-pinboard,
.si-colored.si-pinboard {
  background-color: #0000e6 !important; }

:hover.si-soundcloud,
.si-colored.si-soundcloud {
  background-color: #ff7700 !important; }

:hover.si-tumblr,
.si-colored.si-tumblr {
  background-color: #34526f !important; }

:hover.si-wordpress,
.si-colored.si-wordpress {
  background-color: #1e8cbe !important; }

:hover.si-yelp,
.si-colored.si-yelp {
  background-color: #c41200 !important; }

:hover.si-intensedebate,
.si-colored.si-intensedebate {
  background-color: #009ee4 !important; }

:hover.si-eventbrite,
.si-colored.si-eventbrite {
  background-color: #f16924 !important; }

:hover.si-scribd,
.si-colored.si-scribd {
  background-color: #666666 !important; }

:hover.si-stripe,
.si-colored.si-stripe {
  background-color: #008cdd !important; }

:hover.si-print,
.si-colored.si-print {
  background-color: #111 !important; }

:hover.si-dwolla,
.si-colored.si-dwolla {
  background-color: #ff5c03 !important; }

:hover.si-statusnet,
.si-colored.si-statusnet {
  background-color: #131a30 !important; }

:hover.si-acrobat,
.si-colored.si-acrobat {
  background-color: #d3222a !important; }

:hover.si-drupal,
.si-colored.si-drupal {
  background-color: #27537a !important; }

:hover.si-buffer,
.si-colored.si-buffer {
  background-color: #333333 !important; }

:hover.si-pocket,
.si-colored.si-pocket {
  background-color: #ee4056 !important; }

:hover.si-bitbucket,
.si-colored.si-bitbucket {
  background-color: #0e4984 !important; }

:hover.si-stackoverflow,
.si-colored.si-stackoverflow {
  background-color: #ef8236 !important; }

:hover.si-hackernews,
.si-colored.si-hackernews {
  background-color: #ff6600 !important; }

:hover.si-xing,
.si-colored.si-xing {
  background-color: #126567 !important; }

:hover.si-instagram,
.si-colored.si-instagram {
  background-color: #3f729b !important; }

:hover.si-quora,
.si-colored.si-quora {
  background-color: #a82400 !important; }

:hover.si-openid,
.si-colored.si-openid {
  background-color: #e16309 !important; }

:hover.si-steam,
.si-colored.si-steam {
  background-color: #111 !important; }

:hover.si-amazon,
.si-colored.si-amazon {
  background-color: #e47911 !important; }

:hover.si-disqus,
.si-colored.si-disqus {
  background-color: #e4e7ee !important; }

:hover.si-plancast,
.si-colored.si-plancast {
  background-color: #222 !important; }

:hover.si-appstore,
.si-colored.si-appstore {
  background-color: #000 !important; }

:hover.si-pinterest,
.si-colored.si-pinterest {
  background-color: #c8232c !important; }

:hover.si-fivehundredpx,
.si-colored.si-fivehundredpx {
  background-color: #111 !important; }

/* Social Icons - colors
-----------------------------------------------------------------*/
.si-text-color.si-facebook i {
  color: #3b5998; }

.si-text-color.si-whatsapp i {
  color: #25d366; }

.si-text-color.si-delicious i {
  color: #205cc0; }

.si-text-color.si-paypal i {
  color: #00588b; }

.si-text-color.si-flattr i {
  color: #f67c1a; }

.si-text-color.si-android i {
  color: #a4c639; }

.si-text-color.si-smashmag i {
  color: #e53b2c; }

.si-text-color.si-gplus i {
  color: #dd4b39; }

.si-text-color.si-wikipedia i {
  color: #333; }

.si-text-color.si-stumbleupon i {
  color: #f74425; }

.si-text-color.si-foursquare i {
  color: #25a0ca; }

.si-text-color.si-call i {
  color: #444; }

.si-text-color.si-ninetyninedesigns i {
  color: #f26739; }

.si-text-color.si-forrst i {
  color: #5b9a68; }

.si-text-color.si-digg i {
  color: #191919; }

.si-text-color.si-spotify i {
  color: #81b71a; }

.si-text-color.si-reddit i {
  color: #c6c6c6; }

.si-text-color.si-blogger i {
  color: #fc4f08; }

.si-text-color.si-cc i {
  color: #688527; }

.si-text-color.si-dribbble i {
  color: #ea4c89; }

.si-text-color.si-evernote i {
  color: #5ba525; }

.si-text-color.si-flickr i {
  color: #ff0084; }

.si-text-color.si-google i {
  color: #dd4b39; }

.si-text-color.si-instapaper i {
  color: #333; }

.si-text-color.si-klout i {
  color: #ff5f52; }

.si-text-color.si-linkedin i {
  color: #0e76a8; }

.si-text-color.si-vk i {
  color: #2b587a; }

.si-text-color.si-rss i {
  color: #ee802f; }

.si-text-color.si-skype i {
  color: #00aff0; }

.si-text-color.si-twitter i {
  color: #00acee; }

.si-text-color.si-youtube i {
  color: #c4302b; }

.si-text-color.si-vimeo i {
  color: #86c9ef; }

.si-text-color.si-aim i {
  color: #fcd20b; }

.si-text-color.si-yahoo i {
  color: #720e9e; }

.si-text-color.si-email3 i {
  color: #6567a5; }

.si-text-color.si-macstore i {
  color: #333333; }

.si-text-color.si-myspace i {
  color: #666666; }

.si-text-color.si-podcast i {
  color: #e4b21b; }

.si-text-color.si-cloudapp i {
  color: #525557; }

.si-text-color.si-dropbox i {
  color: #3d9ae8; }

.si-text-color.si-ebay i {
  color: #89c507; }

.si-text-color.si-github i {
  color: #171515; }

.si-text-color.si-googleplay i {
  color: #dd4b39; }

.si-text-color.si-itunes i {
  color: #222; }

.si-text-color.si-plurk i {
  color: #cf5a00; }

.si-text-color.si-pinboard i {
  color: #0000e6; }

.si-text-color.si-soundcloud i {
  color: #ff7700; }

.si-text-color.si-tumblr i {
  color: #34526f; }

.si-text-color.si-wordpress i {
  color: #1e8cbe; }

.si-text-color.si-yelp i {
  color: #c41200; }

.si-text-color.si-intensedebate i {
  color: #009ee4; }

.si-text-color.si-eventbrite i {
  color: #f16924; }

.si-text-color.si-scribd i {
  color: #666666; }

.si-text-color.si-stripe i {
  color: #008cdd; }

.si-text-color.si-print i {
  color: #111; }

.si-text-color.si-dwolla i {
  color: #ff5c03; }

.si-text-color.si-statusnet i {
  color: #131a30; }

.si-text-color.si-acrobat i {
  color: #d3222a; }

.si-text-color.si-drupal i {
  color: #27537a; }

.si-text-color.si-buffer i {
  color: #333333; }

.si-text-color.si-pocket i {
  color: #ee4056; }

.si-text-color.si-bitbucket i {
  color: #0e4984; }

.si-text-color.si-stackoverflow i {
  color: #ef8236; }

.si-text-color.si-hackernews i {
  color: #ff6600; }

.si-text-color.si-xing i {
  color: #126567; }

.si-text-color.si-instagram i {
  color: #3f729b; }

.si-text-color.si-quora i {
  color: #a82400; }

.si-text-color.si-openid i {
  color: #e16309; }

.si-text-color.si-steam i {
  color: #111; }

.si-text-color.si-amazon i {
  color: #e47911; }

.si-text-color.si-disqus i {
  color: #e4e7ee; }

.si-text-color.si-plancast i {
  color: #222; }

.si-text-color.si-appstore i {
  color: #000; }

.si-text-color.si-pinterest i {
  color: #c8232c; }

.si-text-color.si-fivehundredpx i {
  color: #111; }

.si-text-color:hover {
  color: white; }

/*-----------------------------------------------------------------------------------

	Shortcodes: tabs.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Tabs
-----------------------------------------------------------------*/
.tabs {
  position: relative;
  margin: 0 0 30px 0; }

ul.tab-nav {
  margin: 0;
  border-bottom: 1px solid #dddddd;
  list-style: none; }
  .dark ul.tab-nav {
    border-color: #494949; }
  ul.tab-nav li {
    float: left;
    border: 1px solid #dddddd;
    border-bottom: 0;
    border-left: 0;
    height: 41px;
    text-align: center; }
    ul.tab-nav li:first-child {
      margin-left: 15px;
      border-left: 1px solid #dddddd; }
      .dark ul.tab-nav li:first-child {
        border-left-color: #494949; }
    .dark ul.tab-nav li {
      border-color: #494949; }
    ul.tab-nav li a {
      display: block;
      padding: 0 15px;
      color: #444444;
      height: 40px;
      line-height: 40px;
      background-color: #f2f2f2;
      font-size: 14px;
      font-weight: bold; }
      .dark ul.tab-nav li a {
        color: #dddddd;
        background-color: rgba(0, 0, 0, 0.15); }
    ul.tab-nav li.ui-tabs-active a {
      position: relative;
      top: 0;
      height: 41px;
      background-color: white; }
      .dark ul.tab-nav li.ui-tabs-active a {
        background-color: #383838; }

/* Tabs - Alternate Nav
-----------------------------------------------------------------*/
.tabs.tabs-alt ul.tab-nav li {
  border-color: transparent; }
  .tabs.tabs-alt ul.tab-nav li:first-child {
    border-left: 0; }
  .tabs.tabs-alt ul.tab-nav li a {
    background-color: white; }
    .dark .tabs.tabs-alt ul.tab-nav li a {
      background-color: #383838; }
  .tabs.tabs-alt ul.tab-nav li.ui-tabs-active a {
    border: 1px solid #dddddd;
    border-bottom: 0; }
    .dark .tabs.tabs-alt ul.tab-nav li.ui-tabs-active a {
      border-color: #494949; }

/* Tabs - Top Border
-----------------------------------------------------------------*/
.tabs.tabs-tb ul.tab-nav li.ui-tabs-active a {
  top: -1px;
  height: 42px;
  line-height: 39px;
  border-top: 1px solid #1abc9c; }

.tabs.tabs-alt.tabs-tb ul.tab-nav li.ui-tabs-active a {
  height: 42px; }

/* Tabs - Bottom Border
-----------------------------------------------------------------*/
.tabs.tabs-bb ul.tab-nav li {
  border: 0;
  margin: 0; }
  .tabs.tabs-bb ul.tab-nav li a {
    background-color: transparent;
    border-bottom: 1px solid transparent; }
  .tabs.tabs-bb ul.tab-nav li.ui-tabs-active a {
    border-bottom: 1px solid #1abc9c;
    top: -1px;
    height: 43px;
    line-height: 43px; }

/* Tabs - Navigation Style 2
-----------------------------------------------------------------*/
ul.tab-nav.tab-nav2 {
  border-bottom: 0; }
  ul.tab-nav.tab-nav2 li {
    border: 0;
    margin-left: 10px; }
    ul.tab-nav.tab-nav2 li:first-child {
      margin-left: 0;
      border-left: 0; }
    ul.tab-nav.tab-nav2 li a {
      background-color: #f2f2f2;
      border-radius: 2px;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); }
      .dark ul.tab-nav.tab-nav2 li a {
        background-color: rgba(0, 0, 0, 0.2); }
    ul.tab-nav.tab-nav2 li.ui-state-active a {
      top: 0;
      background-color: #1abc9c;
      color: #FFF; }
      .dark ul.tab-nav.tab-nav2 li.ui-state-active a {
        background-color: #1abc9c; }

/* Tabs - Content Area
-----------------------------------------------------------------*/
.tab-container {
  position: relative;
  padding: 20px 0 0; }
  @media (max-width: 767.98px) {
    .tab-container {
      padding: 0 !important; } }

ul.tab-nav li a i {
  position: relative;
  top: 1px;
  font-size: 14px;
  margin-right: 3px; }

/* Tabs - Bordered
-----------------------------------------------------------------*/
.tabs-bordered ul.tab-nav li:first-child {
  margin-left: 0; }

.tabs-bordered .tab-container {
  border: 1px solid #dddddd;
  border-top: 0;
  padding: 20px; }
  .dark .tabs-bordered .tab-container {
    border-color: #494949; }

/* Tabs - Side Tabs
-----------------------------------------------------------------*/
.side-tabs ul.tab-nav {
  float: left;
  width: 200px;
  padding: 20px 0;
  border-bottom: 0;
  border-right: 1px solid #dddddd; }
  .dark .side-tabs ul.tab-nav {
    border-right-color: #494949; }
  .side-tabs ul.tab-nav li {
    float: none;
    border: 1px solid #dddddd;
    border-right: 0;
    border-top: 0;
    height: auto;
    text-align: left; }
    .dark .side-tabs ul.tab-nav li {
      border-color: #494949; }
    .side-tabs ul.tab-nav li:first-child {
      margin-left: 0;
      border-top: 1px solid #dddddd;
      border-left: 1px solid #dddddd; }
      .dark .side-tabs ul.tab-nav li:first-child {
        border-top-color: #494949;
        border-left-color: #494949; }
    .side-tabs ul.tab-nav li a {
      height: auto;
      line-height: 42px; }
    .side-tabs ul.tab-nav li.ui-tabs-active a {
      top: 0;
      right: -1px; }

.side-tabs .tab-container {
  overflow: hidden;
  padding: 15px 0 0 20px; }

/* Tabs - Side Tabs & Nav Style 2
-----------------------------------------------------------------*/
.side-tabs ul.tab-nav.tab-nav2 {
  border: 0; }
  .side-tabs ul.tab-nav.tab-nav2 li {
    border: 0;
    margin: 6px 0 0; }
    .side-tabs ul.tab-nav.tab-nav2 li:first-child {
      margin-top: 0; }
    .side-tabs ul.tab-nav.tab-nav2 li a {
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); }
    .side-tabs ul.tab-nav.tab-nav2 li.ui-state-active a {
      right: 0; }

/* Tabs - Side Tabs & Bordered
-----------------------------------------------------------------*/
.tabs-bordered.side-tabs ul.tab-nav {
  position: relative;
  padding: 0;
  z-index: 2;
  margin-right: -1px; }
  .tabs-bordered.side-tabs ul.tab-nav li a {
    height: auto;
    line-height: 42px; }
  .tabs-bordered.side-tabs ul.tab-nav li.ui-tabs-active a {
    right: -1px;
    border-right: 1px solid white; }
    .dark .tabs-bordered.side-tabs ul.tab-nav li.ui-tabs-active a {
      border-right-color: #383838; }

.tabs-bordered.side-tabs .tab-container {
  overflow: hidden;
  padding: 20px;
  border-top: 1px solid #dddddd; }
  .dark .tabs-bordered.side-tabs .tab-container {
    border-top-color: #494949; }

/* ----------------------------------------------------------------
	Side Navigation
-----------------------------------------------------------------*/
.sidenav {
  width: 100%;
  padding: 0;
  background-color: white;
  border-radius: 4px; }
  .dark .sidenav {
    background-color: rgba(0, 0, 0, 0.2); }

.sidenav li {
  list-style-type: none; }

.sidenav li a {
  display: block;
  position: relative;
  margin: 0 0 -1px;
  padding: 11px 20px 11px 14px;
  font-size: 14px;
  border: 1px solid #e5e5e5;
  border-right-width: 2px;
  color: #666666; }
  .dark .sidenav li a {
    border-color: #494949;
    color: #bbbbbb; }

.sidenav > li:first-child > a {
  border-radius: 4px 4px 0 0; }

.sidenav > li:last-child > a {
  border-radius: 0 0 4px 4px;
  border-bottom-width: 2px; }

.sidenav > .ui-tabs-active > a,
.sidenav > .ui-tabs-active > a:hover {
  position: relative;
  z-index: 2;
  border-color: rgba(0, 0, 0, 0.1) !important;
  color: #FFF !important;
  font-weight: 700;
  background-color: #1abc9c !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }

.sidenav li ul {
  display: none; }

.sidenav li.ui-tabs-active ul {
  display: block; }

.sidenav li li a {
  padding: 6px 20px 6px 35px;
  font-size: 14px;
  border-top: none;
  border-bottom: none; }

.sidenav li li:first-child a {
  padding-top: 20px; }

.sidenav li li:last-child a {
  padding-bottom: 20px; }

.sidenav li i {
  position: relative;
  top: 1px;
  margin-right: 6px;
  font-size: 14px;
  text-align: center;
  width: 16px; }

/* Chevrons */
.sidenav .icon-chevron-right {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 50%;
  left: auto;
  right: 6px;
  margin-top: -8px;
  margin-right: 0;
  opacity: .4;
  font-size: 12px !important; }

.sidenav > li > a:hover {
  background-color: whitesmoke; }
  .dark .sidenav > li > a:hover {
    background-color: rgba(0, 0, 0, 0.3); }

.sidenav a:hover .icon-chevron-right {
  opacity: .5; }

.sidenav .ui-tabs-active .icon-chevron-right,
.sidenav .ui-tabs-active a:hover .icon-chevron-right {
  opacity: 1; }

@media (max-width: 767.98px) {
  .tabs-responsive .tab-container:not(.accordion-border) {
    border: 0; } }

/*-----------------------------------------------------------------------------------

	Shortcodes: styled-icons.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Styled Icons
-----------------------------------------------------------------*/
.i-rounded,
.i-plain,
.i-circled,
.i-bordered,
.social-icon {
  display: block;
  float: left;
  margin: 4px 11px 7px 0;
  text-align: center !important;
  font-size: 28px;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  font-style: normal; }
  body:not(.device-touch) .i-rounded, body:not(.device-touch)
  .i-plain, body:not(.device-touch)
  .i-circled, body:not(.device-touch)
  .i-bordered, body:not(.device-touch)
  .social-icon {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }

.i-rounded,
.i-circled,
.i-bordered {
  width: 52px !important;
  height: 52px !important;
  line-height: 52px !important;
  background-color: #444;
  border-radius: 3px; }
  .dark .i-rounded, .dark
  .i-circled, .dark
  .i-bordered {
    background-color: #494949; }

.i-bordered {
  border: 1px solid #555555;
  line-height: 50px !important;
  background-color: transparent !important;
  color: #444444;
  text-shadow: none; }
  .dark .i-bordered {
    color: #dddddd;
    border-color: #dddddd; }

.i-plain {
  width: 36px !important;
  height: 36px !important;
  font-size: 28px;
  line-height: 36px !important;
  color: #444444;
  text-shadow: 1px 1px 1px white; }
  .dark .i-plain {
    color: white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }
  .i-plain:hover {
    color: white; }
    .dark .i-plain:hover {
      color: #dddddd; }

.i-circled {
  border-radius: 50%; }

.i-light {
  background-color: whitesmoke;
  color: #444444;
  text-shadow: 1px 1px 1px white;
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.2); }
  .dark .i-light {
    background-color: whitesmoke;
    color: #444444;
    text-shadow: none; }

.i-alt {
  background-image: url("image/icons/iconalt.svg");
  background-position: center center;
  background-size: 100% 100%; }

.i-rounded:hover,
.i-circled:hover {
  background-color: #1abc9c;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2); }
  .dark .i-rounded:hover, .dark
  .i-circled:hover {
    background-color: #1abc9c;
    color: white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.2); }

.i-bordered:hover {
  background-color: #444 !important;
  color: white;
  border-color: #444; }
  .dark .i-bordered:hover {
    background-color: #1abc9c;
    color: white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.2); }

/* Icon Size - Small
-----------------------------------------------------------------*/
.i-small.i-rounded, .i-small.i-plain, .i-small.i-circled, .i-small.i-bordered {
  margin: 4px 11px 7px 0;
  font-size: 14px; }

.i-small.i-rounded, .i-small.i-circled, .i-small.i-bordered {
  width: 28px !important;
  height: 28px !important;
  line-height: 28px !important; }

.i-small.i-bordered {
  line-height: 26px !important; }

.i-small.i-plain {
  width: 18px !important;
  height: 18px !important;
  font-size: 14px;
  line-height: 18px !important; }

/* Icon Size - medium
-----------------------------------------------------------------*/
.i-medium.i-rounded, .i-medium.i-plain, .i-medium.i-circled, .i-medium.i-bordered {
  margin: 6px 13px 9px 0;
  font-size: 21px; }

.i-medium.i-rounded, .i-medium.i-circled, .i-medium.i-bordered {
  width: 36px !important;
  height: 36px !important;
  line-height: 36px !important; }

.i-medium.i-bordered {
  line-height: 34px !important; }

.i-medium.i-plain {
  width: 28px !important;
  height: 28px !important;
  font-size: 20px;
  line-height: 28px !important; }

/* Icon Size - Large
-----------------------------------------------------------------*/
.i-large.i-rounded, .i-large.i-plain, .i-large.i-circled, .i-large.i-bordered {
  margin: 4px 11px 7px 0;
  font-size: 42px; }

.i-large.i-rounded, .i-large.i-circled, .i-large.i-bordered {
  width: 64px !important;
  height: 64px !important;
  line-height: 64px !important; }

.i-large.i-bordered {
  line-height: 62px !important; }

.i-large.i-plain {
  width: 48px !important;
  height: 48px !important;
  font-size: 42px;
  line-height: 48px !important; }

/* Icon Size - Extra Large
-----------------------------------------------------------------*/
.i-xlarge.i-rounded, .i-xlarge.i-plain, .i-xlarge.i-circled, .i-xlarge.i-bordered {
  margin: 4px 11px 7px 0;
  font-size: 56px; }

.i-xlarge.i-rounded, .i-xlarge.i-circled, .i-xlarge.i-bordered {
  width: 84px !important;
  height: 84px !important;
  line-height: 84px !important; }

.i-xlarge.i-bordered {
  line-height: 82px !important; }

.i-xlarge.i-plain {
  width: 64px !important;
  height: 64px !important;
  font-size: 56px;
  line-height: 64px !important; }

/* ----------------------------------------------------------------
	Icon Overlay
-----------------------------------------------------------------*/
.i-overlay,
.i-overlay a {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.2) url("image/grid.png") repeat; }

.i-overlay a {
  background: transparent; }

.i-overlay img,
.i-overlay i {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -24px 0 0 -24px;
  width: 48px !important;
  height: 48px !important;
  font-size: 48px;
  line-height: 1;
  color: #e5e5e5;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15); }

.i-overlay i {
  margin: -18px 0 0 -18px;
  width: 36px !important;
  height: 36px !important;
  line-height: 36px;
  font-size: 24px; }

.on-hover.i-overlay {
  opacity: 0; }
  .on-hover.i-overlay:hover {
    opacity: 1; }
  body:not(.device-touch) .on-hover.i-overlay {
    -webkit-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear; }

/*-----------------------------------------------------------------------------------

	Shortcodes: team.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Team
-----------------------------------------------------------------*/
.team-image {
  position: relative; }
  .team-image > a,
  .team-image img {
    display: block;
    width: 100%; }

.team-desc {
  text-align: center; }
  .team-desc.team-desc-bg {
    background-color: white;
    padding: 10px 0 30px; }
    .dark .team-desc.team-desc-bg {
      background-color: rgba(255, 255, 255, 0.05); }

.team {
  position: relative; }
  .team .si-share {
    margin-top: 30px;
    text-align: left; }
    .team .si-share .social-icon {
      font-size: 15px;
      width: 14px; }

.team-title {
  position: relative;
  padding-top: 20px; }
  .team-title h4, .team-title .h4 {
    display: block;
    margin-bottom: 0;
    font-size: 19px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    color: #444444; }
  .team-title span {
    display: block;
    color: #1abc9c;
    font-weight: 400;
    font-family: "Crete Round", serif;
    font-style: italic;
    font-size: 15px;
    margin-top: 5px; }

.team-content {
  margin-top: 15px;
  font-size: 14px;
  color: #777777; }
  .dark .team-content {
    color: #999999; }
  .team-content p:last-child {
    margin-bottom: 0; }

.team-overlay,
.team-overlay:before {
  opacity: 0;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center; }

.team-overlay:before {
  content: '';
  z-index: auto;
  background-color: white;
  opacity: 0.75; }

.team-overlay .social-icon {
  float: none;
  position: relative;
  z-index: 3;
  margin: 0 3px !important;
  display: inline-block; }

body:not(.device-touch) .team-overlay {
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }

.team:hover .team-overlay {
  opacity: 1; }

/* Team Center
-----------------------------------------------------------------*/
.team.center .team-title:after {
  left: 50%;
  margin-left: -40px; }

/* Team List
-----------------------------------------------------------------*/
.team.team-list .team-image {
  float: left;
  width: 250px;
  margin-right: 30px; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .team.team-list .team-image {
      width: 200px; } }
  @media (max-width: 767.98px) {
    .team.team-list .team-image {
      float: none;
      width: 100%;
      margin-right: 0; } }

.team.team-list .team-desc {
  position: relative;
  overflow: hidden;
  text-align: left; }

.team.team-list .team-title {
  padding-top: 0; }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .team.team-list .team-title {
      margin-top: 15px; } }

.team .team-desc > .social-icon {
  margin-top: 20px; }

.team .team-content ~ .social-icon {
  margin-top: 30px; }

.team:not(.team-list) .social-icon {
  display: inline-block;
  float: none;
  margin-left: 6px;
  margin-right: 6px; }

/*-----------------------------------------------------------------------------------

	Shortcodes: testimonials.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Testimonials
-----------------------------------------------------------------*/
/* Testimonials - Grid
-----------------------------------------------------------------*/
.testimonials-grid li {
  width: 50%;
  padding: 25px; }
  @media (max-width: 767.98px) {
    .testimonials-grid li {
      float: none;
      width: auto !important;
      padding-left: 0;
      padding-right: 0; } }
  .testimonials-grid li .testimonial {
    padding: 0;
    background-color: transparent !important;
    border: 0 !important;
    box-shadow: none !important; }

.testimonials-grid.grid-1 li {
  width: 100%; }

.testimonials-grid.grid-2 li {
  width: 50%; }

.testimonials-grid.grid-3 li {
  width: 33.3333333333%; }

@media (max-width: 991.98px) {
  .testimonials-grid.grid-3 li {
    width: 50%; } }

@media (max-width: 767.98px) {
  .testimonials-grid.grid-3 li {
    width: 100%; } }

/* Testimonials - Item
-----------------------------------------------------------------*/
.testimonial {
  position: relative;
  padding: 20px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); }
  .dark .testimonial {
    background-color: rgba(0, 0, 0, 0.2);
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); }

.testi-image,
.testi-image a,
.testi-image img,
.testi-image i {
  display: block;
  width: 64px;
  height: 64px; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .testi-image,
    .testi-image a,
    .testi-image img,
    .testi-image i {
      width: 36px;
      height: 36px; } }
  @media (max-width: 575.98px) {
    .testi-image,
    .testi-image a,
    .testi-image img,
    .testi-image i {
      width: 36px;
      height: 36px; } }

.testi-image {
  float: left;
  margin-right: 15px; }
  .testi-image i {
    text-align: center;
    background-color: #EEE;
    border-radius: 50%;
    line-height: 64px;
    font-size: 28px;
    color: #888888;
    text-shadow: 1px 1px 1px #FFF; }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .testi-image i {
        line-height: 36px;
        font-size: 20px; } }
    @media (max-width: 575.98px) {
      .testi-image i {
        line-height: 36px;
        font-size: 20px; } }
    .dark .testimonial.twitter-scroll .testi-image i {
      background-color: rgba(0, 0, 0, 0.2);
      color: #eeeeee;
      text-shadow: none; }
  .testi-image img {
    border-radius: 50%; }

.testi-content {
  position: relative;
  overflow: hidden;
  font-size: 14px; }
  .testi-content p {
    margin-bottom: 0;
    font-family: "Crete Round", serif !important;
    font-style: italic; }
    .testi-content p:before, .testi-content p:after {
      content: ' " '; }

.testi-meta {
  margin-top: 10px;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase; }
  .testi-meta:before {
    content: '\2013' !important; }
  .testi-meta span {
    display: block;
    font-weight: normal;
    color: #999;
    font-size: 12px;
    text-transform: none;
    padding-left: 10px; }

/* Twitter - Small Scroller
-----------------------------------------------------------------*/
.testi-content p:before,
.testi-content p:after,
.testi-meta:before {
  content: ' " '; }

.testimonial.twitter-scroll .testi-content p:before,
.testimonial.twitter-scroll .testi-content p:after,
.testimonial.twitter-scroll .testi-meta:before {
  content: ' " '; }

.testimonial.twitter-scroll .testi-meta span {
  padding-left: 0; }
  .testimonial.twitter-scroll .testi-meta span a {
    color: #999; }
    .dark .testimonial.twitter-scroll .testi-meta span a {
      color: #aaaaaa; }
    .testimonial.twitter-scroll .testi-meta span a:hover {
      color: #222; }
      .dark .testimonial.twitter-scroll .testi-meta span a:hover {
        color: #888888; }

.dark .testimonial.twitter-scroll .testi-content p {
  border-bottom: 1px dotted black; }
  .dark .testimonial.twitter-scroll .testi-content p:hover {
    border-bottom: 1px dotted black; }

/* Testimonials - Full Width
-----------------------------------------------------------------*/
.testimonial.testimonial-full {
  padding: 30px; }

.testimonial.testimonial-full[data-animation="fade"] {
  padding: 30px; }
  .testimonial.testimonial-full[data-animation="fade"] .flexslider {
    overflow: visible !important; }

.testimonial-full .testi-image,
.testimonial-full .testi-image a,
.testimonial-full .testi-image img,
.testimonial-full .testi-image i {
  display: block;
  width: 72px;
  height: 72px; }

.testimonial-full .testi-image {
  float: none;
  margin: 0 auto 20px; }
  .testimonial-full .testi-image i {
    line-height: 72px; }

.testimonial-full .testi-content {
  text-align: center;
  font-size: 18px; }

.testimonial-full .testi-meta {
  margin-top: 15px; }
  .testimonial-full .testi-meta span {
    padding-left: 0; }

/* Testimonial - Section Scroller
-----------------------------------------------------------------*/
.section > .testimonial {
  padding: 0;
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  max-width: 800px;
  margin: 0 auto; }
  .section > .testimonial .testi-content {
    font-size: 22px; }
  @media (max-width: 991.98px) {
    .section > .testimonial {
      padding: 0 40px !important;
      max-width: none; } }

.section > .testimonial[data-animation="fade"] {
  padding: 0; }

.section > .testimonial .testi-meta,
.section > .testimonial .testi-meta span {
  font-size: 14px; }

/* Testimonial - Pagination
-----------------------------------------------------------------*/
.testimonial .flex-control-nav {
  top: auto;
  bottom: 6px;
  right: 0; }
  .dark.parallax .testimonial .flex-control-nav {
    height: 8px;
    margin-top: 30px; }
  .testimonial .flex-control-nav li {
    margin: 0 2px;
    width: 6px;
    height: 6px; }
    .dark.parallax .testimonial .flex-control-nav li {
      width: 8px;
      height: 8px;
      margin: 0 3px; }
    .testimonial .flex-control-nav li a {
      width: 6px !important;
      height: 6px !important;
      border: none;
      background-color: #1abc9c;
      opacity: 0.5; }
      .dark.parallax .testimonial .flex-control-nav li a {
        width: 8px !important;
        height: 8px !important;
        border: 1px solid white;
        background-color: transparent; }
      .testimonial .flex-control-nav li a.flex-active {
        opacity: 1; }
        .dark.parallax .testimonial .flex-control-nav li a.flex-active {
          background-color: white; }
    .testimonial .flex-control-nav li:hover a {
      opacity: 0.75; }
      .dark.parallax .testimonial .flex-control-nav li:hover a {
        background-color: white; }

/* Testimonial - Full Scroller
-----------------------------------------------------------------*/
.testimonial.testimonial-full .flex-control-nav {
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 6px;
  margin-top: 20px; }
  .testimonial.testimonial-full .flex-control-nav li {
    display: inline-block;
    float: none; }

/*-----------------------------------------------------------------------------------

	Shortcodes: toggles-accordions.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Toggle
-----------------------------------------------------------------*/
.toggle {
  display: block;
  position: relative;
  margin: 0 0 20px 0; }
  .toggle .togglet,
  .toggle .toggleta {
    display: block;
    position: relative;
    line-height: 24px;
    padding: 0 0 0 24px;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    color: #444444;
    cursor: pointer; }
    .dark .toggle .togglet, .dark
    .toggle .toggleta {
      color: #cccccc; }
  .toggle .togglet i {
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: #444444;
    font-family: "font-icons"; }
  .toggle .toggleta {
    font-weight: bold; }
  .toggle .togglet i.toggle-open,
  .toggle .toggleta i.toggle-closed {
    display: none; }
  .toggle .toggleta i.toggle-open {
    display: block;
    color: #444444; }
  .toggle .togglet i.toggle-open::before {
    content: ""; }
  .toggle .togglet i.toggle-closed::before {
    content: ""; }
  .toggle .togglec {
    display: block;
    position: relative;
    padding: 10px 0 0 24px; }

/* Toggle - with Title Background
-----------------------------------------------------------------*/
.toggle.toggle-bg .togglet,
.toggle.toggle-bg .toggleta {
  background-color: #eeeeee;
  line-height: 44px;
  padding: 0 0 0 36px;
  border-radius: 4px; }
  .dark .toggle.toggle-bg .togglet, .dark
  .toggle.toggle-bg .toggleta {
    background-color: rgba(0, 0, 0, 0.2); }

.toggle.toggle-bg .togglet i {
  left: 14px;
  line-height: 44px; }

.toggle.toggle-bg .togglec {
  padding: 12px 0 0 36px; }

/* Toggle - Bordered
-----------------------------------------------------------------*/
.toggle.toggle-border {
  border: 1px solid #cccccc;
  border-radius: 4px; }
  .dark .toggle.toggle-border {
    border-color: rgba(255, 255, 255, 0.15); }
  .toggle.toggle-border .togglet i {
    left: 14px;
    line-height: 44px; }
  .toggle.toggle-border .togglec {
    padding: 0 15px 15px 36px; }
  .toggle.toggle-border .togglet,
  .toggle.toggle-border .toggleta {
    line-height: 44px;
    padding: 0 15px 0 36px; }

/* ----------------------------------------------------------------
	Accordions
-----------------------------------------------------------------*/
.accordion {
  margin-bottom: 20px; }

.acctitle,
.acctitlec {
  display: block;
  position: relative;
  line-height: 24px;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: #444444;
  cursor: pointer;
  border-top: 1px dotted #dddddd;
  padding: 10px 0 10px 20px; }
  .dark .acctitle, .dark
  .acctitlec {
    color: #cccccc;
    border-top-color: rgba(255, 255, 255, 0.15); }

.acctitlec {
  cursor: auto; }

.acctitle:first-child {
  border-top: none; }

.acctitle i {
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  text-align: center;
  font-size: 14px;
  line-height: 44px;
  color: #444444; }

.acctitle i.acc-open,
.acctitlec i.acc-closed {
  display: none; }

.acctitlec i.acc-open {
  display: block;
  color: #444444; }

.acc_content {
  position: relative;
  padding: 0 0 15px 20px; }

/* Accordion - with Title Background
-----------------------------------------------------------------*/
.accordion.accordion-bg .acctitle,
.accordion.accordion-bg .acctitlec {
  background-color: #eeeeee;
  line-height: 44px;
  padding: 0 0 0 36px;
  margin-bottom: 5px;
  border-top: 0; }
  .dark .accordion.accordion-bg .acctitle, .dark
  .accordion.accordion-bg .acctitlec {
    background-color: rgba(0, 0, 0, 0.2); }

.accordion.accordion-bg .acctitle i {
  left: 14px;
  line-height: 44px; }

.accordion.accordion-bg .acc_content {
  padding: 10px 0 15px 36px; }

/* Accordion - Bordered
-----------------------------------------------------------------*/
.accordion.accordion-border {
  border: 1px solid #dddddd;
  border-radius: 4px; }
  .dark .accordion.accordion-border {
    border-color: rgba(255, 255, 255, 0.1); }
  .accordion.accordion-border .acctitle,
  .accordion.accordion-border .acctitlec {
    border-color: #cccccc;
    line-height: 44px;
    padding: 0 15px 0 36px; }
    .dark .accordion.accordion-border .acctitle, .dark
    .accordion.accordion-border .acctitlec {
      border-color: rgba(255, 255, 255, 0.15); }
  .accordion.accordion-border .acctitle i {
    left: 14px;
    line-height: 44px; }
  .accordion.accordion-border .acc_content {
    padding: 0 15px 15px 15px 15px 36px; }

/* Accordion - Large
-----------------------------------------------------------------*/
.accordion.accordion-lg .acctitle {
  font-size: 23px;
  line-height: 43.93px;
  padding-left: 30px;
  font-weight: 400; }
  .accordion.accordion-lg .acctitle i {
    width: 23px;
    font-size: 23px;
    line-height: 64px; }

.accordion.accordion-lg .acc_content {
  padding: 10px 0 30px 30px; }

/*-----------------------------------------------------------------------------------

	Shortcodes: youtubebg.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	HTML5 Youtube Video Backgrounds
-----------------------------------------------------------------*/
.mb_YTVPlayer {
  display: block;
  transform: translateZ(0);
  transform-style: preserve-3d;
  perspective: 1000;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  animation-timing-function: linear; }
  .mb_YTVPlayer :focus {
    outline: 0; }
  .mb_YTVPlayer.fullscreen {
    display: block !important;
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    margin: 0 !important;
    border: none !important; }
    .mb_YTVPlayer.fullscreen .mbYTP_wrapper {
      opacity: 1 !important; }

.mbYTP_wrapper iframe {
  max-width: 4000px !important; }

.inline_YTPlayer {
  margin-bottom: 20px;
  vertical-align: top;
  position: relative;
  left: 0;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  background: rgba(0, 0, 0, 0.5); }
  .inline_YTPlayer img {
    border: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    -o-transform: none !important;
    transform: none !important;
    margin: 0 !important;
    padding: 0 !important; }

/* ----------------------------------------------------------------
	CSS Specific to the One Page Module

	Some of the CSS Codes have been modified from the Original
	style.css File to match the Minimal Styling of this Module

	Version: 5.9.1
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------------------
	Header
---------------------------------------------------------------------------- */
#header {
  border-bottom: 0; }

#header #header-wrap {
  z-index: 199;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  -webkit-transition: height 0.4s ease, background-color 0.4s ease, opacity 0.3s ease;
  -o-transition: height 0.4s ease, background-color 0.4s ease, opacity 0.3s ease;
  transition: height 0.4s ease, background-color 0.4s ease, opacity 0.3s ease; }

#header.full-header:not(.border-full-header):not(.sticky-header) #header-wrap {
  border-bottom: 0; }

#header.full-header.border-full-header #header-wrap {
  border-bottom-color: rgba(0, 0, 0, 0.05) !important; }

.dark #header.full-header.border-full-header #header-wrap,
#header.dark.full-header.border-full-header #header-wrap {
  border-bottom-color: rgba(255, 255, 255, 0.15) !important; }

#header.transparent-header:not(.sticky-header) #header-wrap {
  background-color: transparent; }

#header.transparent-header + #slider,
#header.transparent-header + #page-title.page-title-parallax,
#header.transparent-header + #google-map,
#slider + #header.transparent-header {
  top: -70px;
  margin-bottom: -70px; }

#header.full-header #logo {
  border-right: 0; }

#header.full-header #primary-menu > ul {
  padding-right: 0;
  margin-right: 0;
  border-right: 0; }

#header.show-on-sticky #header-wrap {
  opacity: 0;
  pointer-events: none; }

#header.show-on-sticky.sticky-header #header-wrap {
  opacity: 1;
  pointer-events: auto; }

#header,
#header-wrap,
#logo img {
  height: 70px;
  -webkit-transition: height 0.4s ease, opacity 0.3s ease;
  -o-transition: height 0.4s ease, opacity 0.3s ease;
  transition: height 0.4s ease, opacity 0.3s ease; }

/* Sticky Header
---------------------------------------------------------------------------- */
#header.sticky-header #header-wrap,
#header.full-header.border-full-header.sticky-header #header-wrap {
  box-shadow: none;
  border-bottom: 1px solid #f5f5f5 !important;
  -webkit-backface-visibility: hidden; }

/* Side Header
---------------------------------------------------------------------------- */
@media (min-width: 992px) {
  .side-header #header #header-wrap {
    position: relative; }
  .side-header #header,
  .side-header #header-wrap {
    height: 100%; }
  .side-header #header-wrap {
    width: 300px !important; }
  .side-header #logo,
  .side-header #logo img,
  .side-header #primary-menu:not(.style-2),
  .side-header #primary-menu:not(.style-2) > ul {
    height: auto; } }

/* Primary Menu
---------------------------------------------------------------------------- */
#primary-menu ul li a {
  padding: 24px 22px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 3px; }

/* Side Panel
---------------------------------------------------------------------------- */
body.side-push-panel.stretched #header .container {
  right: 0; }

body.side-push-panel.side-panel-open.stretched #header .container {
  right: 300px; }

body.side-panel-left.side-push-panel.stretched #header .container {
  left: 0;
  right: auto; }

body.side-panel-left.side-push-panel.side-panel-open.stretched #header .container {
  left: 300px; }

#side-panel-trigger {
  margin-top: 25px;
  margin-bottom: 25px; }

/* ----------------------------------------------------------------------------
	Page Section
---------------------------------------------------------------------------- */
.page-section {
  padding: 120px 0; }

/* ----------------------------------------------------------------------------
	Heading Block
---------------------------------------------------------------------------- */
.heading-block h2, .heading-block .h2 {
  font-weight: 500;
  letter-spacing: 3px;
  font-family: 'Roboto', sans-serif; }

/* ----------------------------------------------------------------------------
	Portfolio Overlay
---------------------------------------------------------------------------- */
.portfolio-overlay {
  background-color: rgba(255, 255, 255, 0.9); }

.portfolio-overlay .portfolio-desc h3, .portfolio-overlay .portfolio-desc .h3 {
  font-size: 22px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 2px; }

.portfolio-overlay .portfolio-desc h3 a, .portfolio-overlay .portfolio-desc .h3 a {
  color: #333 !important;
  text-shadow: none; }

.portfolio-overlay .portfolio-desc span {
  margin-top: 12px;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 300; }

.portfolio-overlay .portfolio-desc span a {
  color: #999 !important;
  text-shadow: none; }

/* ----------------------------------------------------------------------------
	Buttons
---------------------------------------------------------------------------- */
.button.button-border {
  font-weight: 400;
  letter-spacing: 2px;
  font-size: 13px; }

.button.button-border.button-large {
  font-size: 15px; }

.button.button-border.button-light {
  border-color: #f9f9f9;
  color: #f9f9f9; }

.button.button-border.button-light:hover {
  background-color: #f9f9f9;
  color: #333;
  text-shadow: none; }

/* More Link
---------------------------------------------------------------------------- */
.more-link {
  font-weight: 400;
  border-bottom-width: 1px;
  font-style: normal; }

.more-link i.icon-angle-right {
  position: relative;
  top: 2px;
  margin-left: 3px; }

.link-border {
  font-weight: 600;
  border-bottom: 3px solid #1abc9c;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.link-border:hover {
  border-color: #fff; }

/* ----------------------------------------------------------------------------
	Team & Testimonials
---------------------------------------------------------------------------- */
.team-title h4, .team-title .h4 {
  font-weight: 600;
  font-size: 17px;
  letter-spacing: 2px; }

.team-title span {
  font-style: normal;
  color: #999;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  margin-top: 2px; }

.team-social-icons {
  position: absolute;
  width: 100%;
  bottom: 20px; }

.dark .team-overlay:before {
  background-color: rgba(0, 0, 0, 0.9); }

.parallax .fslider * {
  -webkit-backface-visibility: hidden !important; }

.parallax .fslider .flex-control-paging * {
  -webkit-backface-visibility: visible !important; }

.parallax .testimonial .testi-content p {
  line-height: 1.6 !important;
  font-size: 24px;
  letter-spacing: 1px;
  font-weight: 300;
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal; }

.parallax .testimonial .testi-meta {
  margin-top: 20px;
  font-size: 15px;
  letter-spacing: 2px;
  font-weight: 500; }

.parallax .testimonial .testi-meta span {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 1px; }

/* Page Menu - Dots Style
-----------------------------------------------------------------*/
#page-menu.dots-menu nav ul li {
  margin: 15px 6px; }

#page-menu.dots-menu nav li a {
  background-color: rgba(0, 0, 0, 0.3); }

#page-menu.dots-menu nav li a:hover,
#page-menu.dots-menu nav li.current a {
  background-color: rgba(0, 0, 0, 0.65) !important; }

#page-menu.dots-menu nav li .dots-menu-caption {
  background-color: #eee !important;
  color: #333;
  text-shadow: none;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 400; }

#page-menu.dots-menu nav li .dots-menu-caption:after {
  border-left: 6px solid #eee !important; }

/* Border Form Design
---------------------------------------------------------------------------- */
.border-form-control {
  height: 48px;
  padding: 8px 4px;
  font-size: 21px;
  letter-spacing: 1px;
  background-color: transparent !important;
  border-top: transparent;
  border-right: transparent;
  border-left: transparent;
  border-bottom-width: 1px;
  font-family: 'Source Sans Pro', sans-serif; }

.border-form-control::-moz-placeholder {
  font-weight: 300; }

.border-form-control:-ms-input-placeholder {
  font-weight: 300; }

.border-form-control::-webkit-input-placeholder {
  font-weight: 300; }

textarea.border-form-control {
  resize: none;
  overflow: hidden;
  word-wrap: break-word; }

/* ----------------------------------------------------------------------------
	Helper CSS Styles
---------------------------------------------------------------------------- */
.font-body {
  font-family: 'Roboto', sans-serif !important; }

.font-primary {
  font-family: 'Source Sans Pro', sans-serif !important; }

/* ----------------------------------------------------------------------------
	Footer Widgets
---------------------------------------------------------------------------- */
#footer .widget {
  padding: 30px 0; }

#footer .widget > h4, #footer .widget > .h4 {
  margin-bottom: 50px;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 3px;
  text-transform: uppercase; }

#footer .widget ul.footer-site-links li {
  margin: 5px 0;
  font-size: 17px;
  letter-spacing: 1px;
  font-weight: 300; }

#footer .widget .form-control {
  text-align: center; }

.widget p.lead {
  font-size: 17px;
  letter-spacing: 1px;
  line-height: 1.6; }

/* ----------------------------------------------------------------------------
	Services Grid
---------------------------------------------------------------------------- */
.grid-border {
  overflow: hidden; }

.grid-border [class^='col-']:before,
.grid-border [class^='col-']:after {
  content: '';
  position: absolute; }

.grid-border [class^='col-']:before {
  height: 100%;
  top: 0;
  left: -1px;
  border-left: 1px solid rgba(0, 0, 0, 0.05); }

.grid-border [class^='col-']:after {
  width: 100%;
  height: 0;
  top: auto;
  left: 0;
  bottom: -1px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.dark .grid-border [class^='col-']:before,
.dark .grid-border [class^='col-']:after {
  border-color: rgba(255, 255, 255, 0.05); }

/* ----------------------------------------------------------------------------
	One Page Module: Slider Specifics
---------------------------------------------------------------------------- */
.one-page-arrow i {
  -webkit-animation-duration: 1.5s !important;
  animation-duration: 1.5s !important; }

/* Large One Word in Slider
---------------------------------------------------------------------------- */
.opm-large-word {
  font-size: 116px; }

.device-md .opm-large-word {
  font-size: 96px; }

.device-sm .opm-large-word {
  font-size: 72px; }

.device-xs .opm-large-word {
  font-size: 54px; }

/* Medium One Word in Slider
---------------------------------------------------------------------------- */
.opm-medium-word {
  font-size: 96px; }

.device-md .opm-medium-word {
  font-size: 80px; }

.device-sm .opm-medium-word {
  font-size: 60px; }

.device-xs .opm-medium-word {
  font-size: 48px; }

/* Medium Before Heading
---------------------------------------------------------------------------- */
.before-heading.opm-medium {
  font-size: 30px;
  font-style: normal;
  margin: 0 0 0 5px;
  line-height: 1;
  letter-spacing: 12px; }

.device-md .before-heading.opm-medium {
  font-size: 22px; }

.device-sm .before-heading.opm-medium {
  font-size: 18px; }

.device-xs .before-heading.opm-medium {
  font-size: 14px; }

/* Large Counter in Slider
---------------------------------------------------------------------------- */
.opm-large-counter {
  font-size: 480px;
  color: #f2f2f2;
  line-height: 1; }

.device-md .opm-large-counter {
  font-size: 360px; }

.device-sm .opm-large-counter {
  font-size: 220px; }

.device-xs .opm-large-counter {
  font-size: 150px; }

/* Slider Grid Blocks
---------------------------------------------------------------------------- */
.half-screen {
  position: relative;
  height: 50%; }

.videoplay-on-hover .vertical-middle {
  z-index: 2; }

.videoplay-on-hover .video-wrap {
  z-index: 1; }

.videoplay-on-hover .vertical-middle h2, .videoplay-on-hover .vertical-middle .h2 {
  font-size: 40px;
  font-weight: bold; }

.device-sm .videoplay-on-hover .vertical-middle h2, .device-sm .videoplay-on-hover .vertical-middle .h2 {
  font-size: 32px; }

.device-xs .videoplay-on-hover .vertical-middle h2, .device-xs .videoplay-on-hover .vertical-middle .h2 {
  font-size: 26px; }

/* Content Switch
---------------------------------------------------------------------------- */
.con-switch {
  position: relative;
  display: block;
  overflow: hidden;
  -webkit-backface-visibility: hidden; }

.con-switch .con-default,
.con-switch .con-show {
  display: block;
  opacity: 1;
  -webkit-transition: opacity 0.4s ease;
  -o-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease; }

.con-switch .con-show {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  text-align: center; }

.con-switch:hover .con-show {
  opacity: 1; }

.con-switch:hover .con-default {
  opacity: 0; }

/* Hero Video Play Event
---------------------------------------------------------------------------- */
.big-video-button {
  display: inline-block;
  width: 64px;
  height: 64px;
  line-height: 64px;
  border-radius: 50%;
  background-color: #333;
  color: #f5f5f5 !important;
  font-size: 24px;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  opacity: 1;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }

.big-video-button:hover {
  opacity: 0.9; }

.dark .big-video-button,
.big-video-button.dark {
  background-color: #f5f5f5;
  color: #333 !important;
  text-shadow: none; }

.big-video-button i:nth-of-type(1) {
  position: relative;
  left: 3px; }

.big-video-button i:nth-of-type(2),
.big-video-button.video-played i:nth-of-type(1) {
  display: none; }

.big-video-button.video-played i:nth-of-type(2) {
  display: block; }

.big-video-button.video-played {
  opacity: 0.1; }

#slider:hover .big-video-button.video-played {
  opacity: 1; }

.slider-caption h2, .slider-caption .h2 {
  font-size: 78px;
  letter-spacing: 3px;
  text-transform: lowercase;
  margin-bottom: 50px; }

.slider-caption p {
  font-weight: 300;
  text-transform: lowercase;
  font-size: 26px;
  line-height: 1.8;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto; }

.testimonials-lg {
  overflow: visible; }

.testimonials-lg:before {
  position: absolute;
  top: -20px;
  left: -30px;
  content: '“';
  color: rgba(0, 0, 0, 0.07);
  font-size: 144px;
  line-height: 1;
  font-family: 'Passion One'; }

.testimonials-lg .testi-content p {
  font-size: 26px;
  line-height: 1.6;
  font-style: normal;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  color: #111; }

.testimonials-lg .testi-content p:before,
.testimonials-lg .testi-content p:after {
  content: ''; }

.testimonials-lg .testi-meta {
  font-size: 14px;
  font-weight: 400;
  margin-top: 30px;
  color: #999; }

.testimonials-lg .testi-meta span {
  font-size: 13px;
  font-weight: 300;
  color: #bbb; }

/* ----------------------------------------------------------------------------
	Dark
---------------------------------------------------------------------------- */
.dark #header.sticky-header #header-wrap,
#header.sticky-header.dark #header-wrap {
  border-bottom-color: rgba(255, 255, 255, 0.1) !important; }

/* ----------------------------------------------------------------------------
	Responsive
---------------------------------------------------------------------------- */
@media (max-width: 991px) {
  #logo {
    height: 70px !important; }
  #header {
    min-height: 70px; }
  #header.transparent-header:not(.sticky-header) #header-wrap {
    background-color: #fff;
    border-bottom: 1px solid #eee; }
  #header.transparent-header.dark:not(.sticky-header) #header-wrap {
    background-color: #333;
    border-bottom-color: rgba(255, 255, 255, 0.1) !important; }
  #header.transparent-header:not(.hidden) + #slider,
  #header.transparent-header:not(.hidden) + #page-title.page-title-parallax,
  #header.transparent-header:not(.hidden) + #google-map,
  #slider + #header.transparent-header:not(.hidden) {
    top: 0;
    margin-bottom: 0; }
  #primary-menu-trigger {
    top: 10px !important; }
  #side-panel-trigger {
    margin: 25px 0 !important;
    right: 15px !important; }
  .dark #header.transparent-header #header-wrap,
  .dark #header.semi-transparent #header-wrap,
  #header.dark.transparent-header #header-wrap,
  #header.dark.semi-transparent #header-wrap {
    background-color: #333;
    border-bottom-color: rgba(255, 255, 255, 0.1); }
  .dark.overlay-menu #primary-menu:not(.not-dark) ul,
  .overlay-menu #primary-menu.dark ul {
    background-color: transparent; } }

/*-----------------------------------------------------------------------------------
	sass/responsive.scss
-----------------------------------------------------------------------------------*/
